import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  GridDataResult,
  DataStateChangeEvent,
  DataBindingDirective,
  SelectableSettings,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';
import { process, State, distinct, DataSourceRequestState } from '@progress/kendo-data-query';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { startWith, map } from 'rxjs/operators';

import { MatDialog } from '@angular/material/dialog';
import { PatientService } from '@services/patient.service';
import { MasterOverlayService } from '@services/actionpanel.service';
import { ConfirmDeleteDialogComponent } from '@app/management/dialogs/confirm-delete/confirm-delete.component';
import { Patient } from '@models/patient';
import { PatientStatus } from '@models/patient-status.enum';
import { MergePatientsComponent } from '@app/patients/merge-patients/merge-patients.component';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.less'],
})
export class PatientsListComponent implements OnInit, OnDestroy {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  searchValue = '';
  loading = false;
  disableGrid = false;
  unsub: Subject<void> = new Subject<void>();
  patients: Patient[] = [];
  searchCtrl: FormControl;
  filteredPatients: Observable<Patient[]>;
  public mySelections: string[] = [];
  gridView: GridDataResult;
  gridState: DataSourceRequestState = {
    sort: [
      {
        field: 'firstName',
        dir: 'asc',
      },
    ],
    skip: 0,
    take: 100,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  formGroup: FormGroup;
  editedRowIndex: number;
  editedDataItem: Patient;
  errorMessage: string;

  patientStatusData = PatientStatus;
  statusEnumKeys = [];

  baseUrl = '/patients-list';

  public selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple',
    enabled: true,
  };

  /*
    Date: 05-01-2021
    Description: to store the checked patients data
  */
  checkedPatient = [];
  checkedPatientCount = 0;

  constructor(
    private patientsService: PatientService,
    private deleteDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private masterOverlayService: MasterOverlayService,
    private mergePatientDialog: MatDialog,
    private confirmPatientPortalAccountDialog: MatDialog
  ) {
    this.statusEnumKeys = Object.keys(this.patientStatusData).filter((f) => !isNaN(Number(f)));
    this.searchCtrl = new FormControl();
  }

  ngOnInit() {
    this.patientsService.patientsSourceUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.loading = true;
      this.refreshData();
    });

    this.gridView = {
      data: [],
      total: 0,
    };
    this.loading = true;
    this.refreshData();
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.refreshData();
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.patients, {
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'firstName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'lastName',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'gender',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'homeNumber',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'mobileNumber',
            operator: 'contains',
            value: inputValue,
          },
          {
            field: 'email',
            operator: 'contains',
            value: inputValue,
          },
        ],
      },
    });
  }

  search(searchValue: string) {
    this.searchValue = searchValue;
    this.gridState.skip = 0;
    this.gridState.take = 100;
    this.gridState.sort = [
      {
        field: 'firstName',
        dir: 'asc',
      },
    ];
    this.gridState.filter = {
      logic: 'and',
      filters: [],
    };
    this.refreshData();
  }

  filterPatients(name: string) {
    let filterResults: Patient[] = [];
    let searchArgs: string[] = [];
    searchArgs = name.split(' ', 2);

    if (searchArgs[0] !== '') {
      this.gridView = {
        data: this.patients.filter(
          (patient) =>
            (patient.firstName.toLowerCase().includes(searchArgs[0].toLowerCase()) ||
              patient.lastName.toLowerCase().includes(searchArgs[0].toLowerCase())) &&
            (searchArgs[1] === undefined ||
              searchArgs[1] === '' ||
              patient.firstName.toLowerCase().includes(searchArgs[1].toLowerCase()) ||
              patient.lastName.toLowerCase().includes(searchArgs[1].toLowerCase()))
        ),
        total: this.patients.filter(
          (patient) =>
            (patient.firstName.toLowerCase().includes(searchArgs[0].toLowerCase()) ||
              patient.lastName.toLowerCase().includes(searchArgs[0].toLowerCase())) &&
            (searchArgs[1] === undefined ||
              searchArgs[1] === '' ||
              patient.firstName.toLowerCase().includes(searchArgs[1].toLowerCase()) ||
              patient.lastName.toLowerCase().includes(searchArgs[1].toLowerCase()))
        ).length,
      };
      filterResults = this.patients.filter(
        (patient) =>
          (patient.firstName.toLowerCase().includes(searchArgs[0].toLowerCase()) ||
            patient.lastName.toLowerCase().includes(searchArgs[0].toLowerCase())) &&
          (searchArgs[1] === undefined ||
            searchArgs[1] === '' ||
            patient.firstName.toLowerCase().includes(searchArgs[1].toLowerCase()) ||
            patient.lastName.toLowerCase().includes(searchArgs[1].toLowerCase()))
      );
    } else {
      this.gridView = {
        data: this.patients,
        total: this.patients.length,
      };
      filterResults = [];
    }
    return filterResults;
  }

  refreshData() {
    this.loading = true;
    this.patients = [];
    this.patientsService.getPatientsList(this.gridState, this.searchValue).subscribe((result) => {
      //this.patients = res;
      this.gridView = {
        data: result.data,
        total: result.total || result.data.length,
      };

      this.loading = false;
    });
  }

  onAddClick({ sender }) {
    this.patientsService.editPatientSourceURL = this.baseUrl;
    this.masterOverlayService.masterOverlayState(true);
    this.router.navigate([this.baseUrl, { outlets: { 'action-panel': ['edit-patient', '_'] } }]);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.patientsService.editPatientSourceURL = this.baseUrl;
    this.masterOverlayService.masterOverlayState(true);
    this.router.navigate([this.baseUrl, { outlets: { 'action-panel': ['edit-patient', dataItem.patientId] } }]);
  }

  public editDetailsHandler(dataItem): void {
    this.patientsService.editPatientSourceURL = this.baseUrl;
    this.patientsService.patientPanelPatient = null;
    this.router.navigate([
      this.baseUrl,
      { outlets: { 'action-panel': ['patient', dataItem.patientId + '_patientprofiletab'] } },
    ]);
  }

  public createPatientPortalAccount(dataItem): void {
    const dialogRef = this.confirmPatientPortalAccountDialog.open(GenericDialogComponent, {
      width: '250px',
      data: {
        showCancel: true,
        title: 'Create Patient Portal Account',
        content: 'Are you sure you want to create a Patient Portal account for this Patient?',
        confirmButtonText: 'Yes',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.patientsService.createPatientPortalAccount(dataItem.patientId).subscribe(
            () => {
              if (this.errorMessage) {
                this.errorMessage = '';
              }
            },
            (error) => {
              if (error.error && error.error.errors) {
                const errors = error.error.errors;
                this.errorMessage = errors[0].fieldErrors[0];
              }
            }
          );
        }
      });
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const patient: Patient = formGroup.value;
    if (isNew) {
      this.patientsService.addPatient(patient).subscribe(() => {
        this.refreshData();
      });
    } else {
      this.patientsService.updatePatient(patient).subscribe(() => {
        this.refreshData();
      });
    }
    sender.closeRow(rowIndex);
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          const dataItemToRemove = {
            patientId: dataItem.patientId,
            firstName: dataItem.firstName,
            lastName: dataItem.lastName,
            address: dataItem.address,
            homeNumber: dataItem.homeNumber,
            mobileNumber: dataItem.mobileNumber,
            email: dataItem.email,
            clientId: dataItem.clientId,
            birthDate: dataItem.birthDate,
            gender: dataItem.gender,
            communicationPreference: dataItem.communicationPreference,
            sendAppointmentEmailNotifications: dataItem.sendAppointmentEmailNotifications,
            sendAppointmentSMSNotifications: dataItem.sendAppointmentSMSNotifications,
            notesAndAlerts: dataItem.notesAndAlerts,
            sendRetentionEmails: dataItem.sendRetentionEmails,
            serviceAppointments: dataItem.serviceAppointments,
            signature: dataItem.signature,
            patientStatus: dataItem.patientStatus,
          };
          this.patientsService.removePatient(dataItemToRemove).subscribe(
            () => {
              this.refreshData();
              if (this.errorMessage) {
                this.errorMessage = '';
              }
            },
            (error) => {
              if (error.error && error.error.errors) {
                const errors = error.error.errors;
                this.errorMessage = errors[0].fieldErrors[0];
              }
            }
          );
        }
      });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  loadItems() {
    this.gridView = process(this.patients, this.gridState);
  }

  public distinctGenders(): any {
    return distinct(this.patients, 'gender');
  }

  /*
    Date: 05-01-2021
    Description: to set data of two checked patients
  */
  selectPatient(data: Patient, event:Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked === true) {
      this.checkedPatientCount++;
      this.checkedPatient.push(data.patientId);
    } else if (checked === false) {
      this.checkedPatientCount--;
      if (this.checkedPatient.length > 0) {
        for (let i = 0; i < this.checkedPatient.length; i++) {
          if (this.checkedPatient[i] === data.patientId) {
            this.checkedPatient.splice(i, 1);
          }
        }
      }
    }
  }

  /*
    Date: 05-01-2021
    Description: to send data of checked patients for Merging
  */

  mergePatients() {
    const dialogRef = this.mergePatientDialog.open(MergePatientsComponent, {
      panelClass: 'custom-dialog-container',
      width: '1200px',
      height: 'calc(100dvh - 100px)',
      data: { patientId: this.checkedPatient[0], duplicatePatientId: this.checkedPatient[1] },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === true) {
          this.clearSelection();
          this.refreshData();
        }
      });
  }
  clearSelection() {
    this.checkedPatient = [];
    this.checkedPatient.length = 0;
    this.checkedPatientCount = 0;
  }

  public isPatientSelected(data: any) {
    var isChecked = this.checkedPatient.indexOf(data.patientId) != -1;
    return isChecked;
  }

  public allData = (): Observable<any> => {
    this.loading = true;

    return this.patientsService
      .getPatientsList(
        {
          sort: [
            {
              field: 'lastName',
              dir: 'asc',
            },
          ],
          skip: 0,
          take: 0,
          filter: {
            logic: 'and',
            filters: [],
          },
        },
        ''
      )
      .pipe(
        map((patients) => {
          setTimeout(() => {
            this.loading = false;
          });
          return {
            data: patients.data,
            total: patients.total || patients.data.length,
          };
        })
      );
  };
  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
