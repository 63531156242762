import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { EventsService } from '@services/events.service';
import { VisitStatusDialogComponent } from '../../../visit-status-dialog/visit-status-dialog.component';

@Component({
  selector: 'app-patient-check-in',
  templateUrl: './patient-check-in.component.html',
  styleUrls: ['./patient-check-in.component.less']
})
export class PatientCheckInComponent implements OnInit {
  currentDate: Date;

  constructor(public eventsService: EventsService, private dialog: MatDialog) {}

  @Input() sideNavExpanded: boolean;

  ngOnInit() {
    this.eventsService.currentDate.subscribe(date => (this.currentDate = date));
  }

  patientCheckInClicked() {

    const dialogRef = this.dialog.open(VisitStatusDialogComponent, {
      width: '330px',
      data: { date: this.currentDate }
    });

    dialogRef.afterClosed().subscribe(() => {
      this.eventsService.appointmentAdded.next();
    });
  }
}
