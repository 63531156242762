import { CurrencyPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { Clinic, ClinicTax } from '@models/clinic';
import { ClinicProduct } from '@models/clinic-product';
import { FileData } from '@models/file-data';
import { FinanceTransactionType } from '@models/finance/finance-transaction-type';
import { InvoiceTransaction } from '@models/finance/invoice-transaction';
import { PaymentMethod } from '@models/finance/payment-method';
import { PaymentMethodEnum } from '@models/finance/payment-method.enum';
import { Invoice } from '@models/invoice/invoice';
import { InvoiceLineItem } from '@models/invoice/invoice-line-item';
import { InvoiceType } from '@models/invoice/invoice-type';
import { DiscountType } from '@models/memberships/discount-type.enum';
import { Membership } from '@models/memberships/membership';
import { MembershipProduct } from '@models/memberships/membership-product';
import { MembershipService } from '@models/memberships/membership-service';
import { PatientMembership } from '@models/memberships/patient-membership';
import { Observation } from '@models/observation/observation';
import { Patient } from '@models/patient';
import { Appointment } from '@models/scheduler/event';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { TabType } from '@models/tab-type.enum';
import { User } from '@models/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CellClickEvent, CommandColumnComponent, GridComponent } from '@progress/kendo-angular-grid';
import { IntlService } from '@progress/kendo-angular-intl';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { exportPDF } from '@progress/kendo-drawing';
import { BlobService } from '@services/blob.service';
import { ClinicProductsService } from '@services/clinic-products.service';
import { ClinicsService } from '@services/clinics.service';
import { FinanceService } from '@services/finance.service';
import { InvoicesService } from '@services/invoices.service';
import { MembershipsService } from '@services/memberships.service';
import { PatientMembershipService } from '@services/patient-membership.service';
import { PatientService } from '@services/patient.service';
import { PaymentMethodsService } from '@services/payment-methods.service';
import { CoolsculptingFormService } from '@services/service-detail/coolsculpting-form.service';
import { InjectionFormService } from '@services/service-detail/injection-form.service';
import { ServicesService } from '@services/services.service';
import { UsersService } from '@services/users.service';
import * as moment from 'moment';
import * as printJS from 'print-js';
import { BehaviorSubject, Observable, ReplaySubject, Subject, defer, from, of } from 'rxjs';
import { catchError, concatMap, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { dataURLtoFile } from '../../../../shared/helpers';
import { InvoiceDiscountComponent } from '../invoice-discount/invoice-discount.component';
import { PaymentType } from '../invoice-payment/invoice-payment.component';
import { MembershipSelectionComponent } from '../membership-selection/membership-selection.component';
import { AppointmentSignalrService } from './../../../../services/appointment-signalr.service';
import { UpdateInvoiceDateComponent } from './../update-invoice-date/update-invoice-date.component';
import { PanelComponent } from '@app/shared/components/panel/panel.component';

const matches = (el, selector) => (el.matches || el.msMatchesSelector).call(el, selector);
@Component({
  selector: 'app-standard-invoice',
  templateUrl: './standard-invoice.component.html',
  styleUrls: ['./standard-invoice.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class StandardInvoiceComponent implements OnInit, OnDestroy {
  @Input() clinic: Clinic;
  @Input() invoice: Invoice;
  @Output() invoiceReadyForPrint: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('grid') kendoGrid: GridComponent;
  @ViewChild('transactionsGrid') transactionsGrid: GridComponent;
  @ViewChild('sidePanelContainer') sidePanelContainer: ElementRef;
  @ViewChild('sidePanelHeader') sidePanelHeader: ElementRef;
  @ViewChild('pdf') pdfContainer: PDFExportComponent;
  @ViewChild('anchor') anchorRef: ElementRef;
  @ViewChild('paymentModal') paymentModal: PanelComponent;

  formGroup: FormGroup;
  currentTooltips: string[] = [];
  serviceDetailTemplate = ServiceDetailTemplate;
  selectedInvoice$: ReplaySubject<Invoice> = new ReplaySubject<Invoice>();
  externalInvoiceId: number;
  clinicProducts: ClinicProduct[];
  invoiceLineItems: InvoiceLineItem[] = [];
  lineItemMemberships: Map<number, PatientMembership[]> = new Map<number, PatientMembership[]>();
  recommendedProducts: any = [];
  patientAvailableCredit: number;
  unsub: Subject<void> = new Subject<void>();
  isOpenPaymentModal: boolean;
  updateLineItemsErrors: any[] = [];
  invoiceLineItemTaxKeys: string[];
  invoiceLineItemTaxValuesObject: any;
  loading = false;
  invoiceLineItemsLoading = false;
  users: User[];
  showCloseInvoiceButton = false;
  totalPaid = 0;
  totalCredits = 0;
  outstandingBalance = 0;
  editedRowIndex: any;
  docClickSubscription: any;
  isNew: boolean;
  initialized = false;
  transactionsGridEditedRowIndex: any;
  transactionsGridFormGroup: FormGroup;
  transactionsLoading = false;
  creditLineItemExists = false;
  editingEmptyLineItem: boolean;
  buttonsAvailable = true;
  showDeleteButton: boolean = false;
  showPaidStamp: boolean = true;
  hasMerchantTransactions: boolean = false;
  panelHeight$ = new BehaviorSubject<number>(0);
  resizeObserver: ResizeObserver;
  billingPolicy = Policies.billing;
  patientAccountTransactionsPolicy = Policies.patientAccountTransactions;
  PaymentType = PaymentType;
  TabType = TabType;
  DiscountType = DiscountType;
  InvoiceType = InvoiceType;

  payModalClosed: boolean = false;
  private _paymentMethods: PaymentMethod[];
  get paymentMethods() {
    if (this._paymentMethods && this._paymentMethods.length > 0) return this._paymentMethods;
    else return this.paymentMethodsService.lastPaymentMethodsForUser;
  }

  set paymentMethods(paymentMethods: PaymentMethod[]) {
    this._paymentMethods = paymentMethods;
  }

  get currentPatient(): Patient {
    return this.patientService.patientPanelPatient;
  }

  get financeTransactionTypes(): typeof FinanceTransactionType {
    return FinanceTransactionType;
  }

  get expandedDetailKeys(): number[] {
    return this.invoiceLineItems.map((ili) => ili.id);
  }

  private get emptyInvoiceLineItem() {
    return {
      id: 0,
      invoiceId: this.invoice.id,
      note: null,
      unitPrice: 0,
      quantity: null,
      total: 0,
      serviceType: null,
      serviceTypeDescription: null,
      clinicProductId: null,
      isManual: true,
      isDeleted: false,
      finalTotal: 0,
      isCredit: false,
      serviceTemplateId: null,
      addBackToInventory: false,
      invoiceLineItemTaxes: [],
      attributedToUserId: null,
      attributedToUser: null,
      plannedTreatmentMultipleId: null,
      isRecommendedProduct: false,
    };
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private invoicesService: InvoicesService,
    private financeService: FinanceService,
    private clinicsService: ClinicsService,
    private patientService: PatientService,
    private clinicProductsService: ClinicProductsService,
    private injectionService: InjectionFormService,
    private coolsculptingService: CoolsculptingFormService,
    private blobService: BlobService,
    public authService: AuthService,
    private usersService: UsersService,
    private paymentMethodsService: PaymentMethodsService,
    private renderer: Renderer2,
    private modalService: NgbModal,
    private intl: IntlService,
    private servicesService: ServicesService,
    private appointmentSignalrService: AppointmentSignalrService,
    private dialog: MatDialog,
    private currencyPipe: CurrencyPipe,
    public sanitizer: DomSanitizer,
    private patientMembershipService: PatientMembershipService,
    private membershipsService: MembershipsService
  ) {}

  async ngOnInit() {
    this.clinic = this.clinicsService.clinic;
    this.docClickSubscription = this.renderer.listen('document', 'click', this.onDocumentClick.bind(this));
    await this.getProduct();
    this.patientAvailableCredit = await this.getPatientAvailableCredit().toPromise();
    await this.getInvoiceById(this.invoice == null);
    await this.getUsers();
    await this.getPaymentMethods().toPromise();
    this.initialized = true;
    this.invoiceReadyForPrint.emit(true);
    this.subscribeToChanges();
  }

  private subscribeToChanges() {
    //Detect service changes to appointments - if it affects this invoice we need to get the updated line items and taxes
    this.appointmentSignalrService.apptUpdated$.pipe(takeUntil(this.unsub)).subscribe((appointment: Appointment) => {
      this.prepareAppointmentUpdates(appointment);
    });
    this.appointmentSignalrService.apptDeleted$.pipe(takeUntil(this.unsub)).subscribe((appointment: Appointment) => {
      this.prepareAppointmentUpdates(appointment);
    });
    this.appointmentSignalrService.apptAdded$.pipe(takeUntil(this.unsub)).subscribe((appointment: Appointment) => {
      this.prepareAppointmentUpdates(appointment);
    });

    this.resizeObserver = new ResizeObserver((entries) => {
      const height = entries[0].contentRect.height;
      this.panelHeight$.next(height - this.sidePanelHeader.nativeElement.offsetHeight - 100);
    });

    this.resizeObserver.observe(this.sidePanelContainer.nativeElement);

    this.clinicsService.clinicIdSelected$
      .pipe(takeUntil(this.unsub))
      .subscribe(() => (this.clinic = this.clinicsService.clinic));

    this.invoicesService.invoiceUpdated$.subscribe(async () => {
      this.getInvoiceById();
      this.patientAvailableCredit = await this.getPatientAvailableCredit().toPromise();
    });
  }

  private prepareAppointmentUpdates(appointment: Appointment) {
    let invoiceServiceUpdated =
      this.invoice &&
      this.invoice.invoiceLineItems &&
      this.invoice.invoiceLineItems.findIndex((ili) => ili.serviceId == appointment.serviceId) != -1;
    invoiceServiceUpdated =
      invoiceServiceUpdated ||
      (this.currentPatient &&
        this.currentPatient.patientId == appointment.patientId &&
        !this.invoice.isPaid &&
        moment(appointment.date).isSame(moment(this.invoice.invoiceDate), 'day'));

    if (invoiceServiceUpdated) {
      this.getInvoiceById();
    }
  }

  // Get the Users
  async getUsers() {
    this.users = await this.usersService.getUsers().toPromise();
  }

  getPaymentMethods() {
    this.transactionsLoading = true;
    return this.paymentMethodsService.getPaymentMethods().pipe(
      takeUntil(this.unsub),
      catchError((err) => {
        this.paymentMethods = this.paymentMethodsService.lastPaymentMethodsForUser;
        return of(this.paymentMethods);
      }),
      map((paymentMethod: PaymentMethod[]) => {
        this.paymentMethods = paymentMethod.filter((element) => element.isUserVisible === true);
        this.transactionsLoading = false;
        return this.paymentMethods;
      })
    );
  }

  getPaymentMethodName(paymentMethodId: number): string {
    if (paymentMethodId == 2) {
      return 'Credit Card';
    }
    if (paymentMethodId == PaymentMethodEnum.Square) {
      return 'Square';
    }
    const paymentMethod = this.paymentMethods.filter((p) => p.id === paymentMethodId)[0];
    return paymentMethod.name;
  }

  async getProduct() {
    this.loading = true;
    this.clinicProducts = await this.clinicProductsService.getProducts().toPromise();
    this.filteredProducts = this.clinicProducts;
    this.loading = false;
  }

  async getInvoiceById(forceRefresh = true) {
    if (this.invoice && !forceRefresh) {
      this.getInvoiceLineItemTaxes(this.invoice);
      await this.getInvoiceTransactions(
        this.invoice.invoiceTransactions && this.invoice.invoiceTransactions.length > 0
          ? this.invoice.invoiceTransactions
          : null
      );
      await this.getOutstandingBalance(this.invoice);
      this.updateKendoGridData();
      await this.setLineItemMemberships();
      this.selectedInvoice$.next(this.invoice);
    } else {
      this.loading = true;
      await this.route.params
        .pipe(
          first(),
          switchMap(async (params: Params) => {
            const id = this.externalInvoiceId ? this.externalInvoiceId : Number(params['id']);
            const invoice = await this.invoicesService.getInvoiceById(id).toPromise();
            if (invoice == null) {
              this.backToRoute();
              return;
            }
            this.invoice = invoice;
            this.getInvoiceLineItemTaxes(invoice);
            await this.getInvoiceTransactions();
            await this.getOutstandingBalance(invoice);
            this.updateKendoGridData();
            await this.setLineItemMemberships();
            this.selectedInvoice$.next(invoice);
            this.recommendedProducts = await this.invoicesService
              .getRequiredAndRecommendedProducts(invoice.visitId ? invoice.visitId : -1, invoice.id)
              .toPromise();
          })
        )
        .toPromise();
      this.loading = false;
    }
  }

  private async setLineItemMemberships() {
    const lineItems = this.invoice.invoiceLineItems;
    this.lineItemMemberships.clear();
    await Promise.all(
      lineItems.map(async (lineItem) => {
        const memberships = await this.patientMembershipService
          .getPatentMembershipsForLineItem(lineItem.id)
          .toPromise();
        this.lineItemMemberships.set(lineItem.id, memberships);
      })
    );
  }

  allLineItemsPaid(invoice: Invoice) {
    return invoice && invoice.invoiceLineItems && invoice.invoiceLineItems.findIndex((ili) => !ili.isPaid) == -1;
  }

  async getOutstandingBalance(invoice: Invoice) {
    this.outstandingBalance = await this.financeService.getOutstandingAmount(invoice.id).toPromise();
  }

  getInvoiceLineItemTaxes(invoice: Invoice) {
    const { invoiceLineItemTaxKeys, invoiceLineItemTaxValuesObject } = this.invoicesService.getInvoiceLineItemTaxes(
      invoice,
      this.clinic
    );
    this.invoiceLineItemTaxKeys = invoiceLineItemTaxKeys;
    this.invoiceLineItemTaxValuesObject = invoiceLineItemTaxValuesObject;
  }

  getPatientAvailableCredit() {
    return this.financeService.getPatientAvailableCredit(this.currentPatient.patientId).pipe(
      takeUntil(this.unsub),
      catchError((err) => of(0)),
      map((patientAvailableCredit: number) => {
        return patientAvailableCredit;
      })
    );
  }

  async getInvoiceTransactions(invoiceTransactions: InvoiceTransaction[] = null) {
    this.transactionsLoading = true;

    if (!invoiceTransactions) {
      invoiceTransactions = await this.financeService
        .getInvoiceTransactions(this.invoice.id)
        .toPromise()
        .catch((error: HttpErrorResponse) => {
          this.updateLineItemsErrors = error.error.errors;
          this.transactionsLoading = false;
          return null;
        });
    }

    if (!invoiceTransactions) return;

    this.invoice.invoiceTransactions = invoiceTransactions;
    let totalPaid = 0;
    let totalCredits = 0;
    let delta = 0;
    this.invoice.invoiceTransactions.forEach((trans) => {
      delta = 0;
      if (!this.hasMerchantTransactions && trans.emvData) this.hasMerchantTransactions = true;
      if (
        trans.financeTransactionTypeId === this.financeTransactionTypes.Payment ||
        trans.financeTransactionTypeId === this.financeTransactionTypes.Cancellation
      ) {
        delta = Number(trans.amount);
      } else {
        if (this.invoice.invoiceTypeId === InvoiceType.Refund || this.invoice.invoiceTypeId === InvoiceType.Return) {
          // If the whole Invoice is a return or refund, then we can add to the sum
          delta = Number(trans.amount);
        } else {
          // If it's a refund or return transaction and the invoice itself is not (a refund or return) we will subtract it from the total
          delta = -Number(trans.amount);
        }
      }
      //Credits = 4
      trans.paymentMethodId === 4 ? (totalCredits += delta) : (totalPaid += delta);
    });
    this.totalPaid = totalPaid;
    this.totalCredits = totalCredits;
    if (
      Math.round((this.totalPaid + this.totalCredits + Number.EPSILON) * 100) / 100 ==
      Math.round((this.invoice.totalAmount + Number.EPSILON) * 100) / 100
    ) {
      this.showCloseInvoiceButton = true;
    } else {
      this.showCloseInvoiceButton = false;
    }
    this.transactionsLoading = false;
  }

  updateInvoiceLineItems(invoiceLineItems: InvoiceLineItem[]) {
    this.invoiceLineItemsLoading = true;
    this.buttonsAvailable = false;
    this.invoicesService
      .updateInvoiceLineItems(invoiceLineItems)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        (data) => {
          this.updateLineItemsErrors = [];
          this.invoicesService.invoiceUpdated.next(this.invoice.id);
          this.invoicesService.invoicesListUpdated.next();
          this.invoiceLineItemsLoading = false;
          this.buttonsAvailable = true;
        },
        (errorResponse: HttpErrorResponse) => {
          this.updateLineItemsErrors = errorResponse.error.errors;
          this.invoiceLineItemsLoading = false;
          this.buttonsAvailable = true;
        }
      );
  }

  getAggregate(formControlName: string, aggregate: any[] = this.invoiceLineItems) {
    if (aggregate) {
      if (formControlName == 'subtotal') {
        return aggregate.reduce((sum: number, cur: InvoiceLineItem) => sum + cur.total, 0);
      } else if (formControlName == 'discountPrice') {
        return aggregate.reduce(
          (sum: number, cur: InvoiceLineItem) => sum + cur.unitPrice * (cur.quantity ?? 1) - cur.total,
          0
        );
      } else {
        return Object.values(aggregate)
          .filter((o) => isNaN(+o))
          .reduce(
            (sum, cur) =>
              sum + (cur[formControlName] && !isNaN(cur[formControlName]) ? Number(cur[formControlName]) : 0),
            0
          );
      }
    } else return 0;
  }

  async onDeleteLineItem(invoiceLineItem: InvoiceLineItem) {
    invoiceLineItem.isDeleted = true;

    if (invoiceLineItem && invoiceLineItem.serviceId && invoiceLineItem.isManual) {
      var relatedLineItems = this.invoiceLineItems.filter(
        (ili) => ili.serviceId == invoiceLineItem.serviceId && ili.id != invoiceLineItem.id
      );
      if (relatedLineItems.length > 0) {
        //multiple line items withe the same service id  and isManual (because cs can be scheduled twice in one day but if its manual its def a service with observations being prepaid )

        var cont = await this.confirmMultipleLineItemsDeletion().toPromise();
        if (cont) {
          relatedLineItems.forEach((ili) => (ili.isDeleted = true));
          this.updateInvoiceLineItems([invoiceLineItem, ...relatedLineItems]);
          this.invoicesService.invoiceLineItemDeleted.next(invoiceLineItem);
        }
      } else {
        this.updateInvoiceLineItems([invoiceLineItem]);
        this.invoicesService.invoiceLineItemDeleted.next(invoiceLineItem);
      }
    } else {
      this.updateInvoiceLineItems([invoiceLineItem]);
      this.invoicesService.invoiceLineItemDeleted.next(invoiceLineItem);
    }
  }

  async onVoidTransaction(invoiceTransaction: InvoiceTransaction) {
    this.transactionsLoading = true;

    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '325px',
      data: {
        title: 'Void Transaction?',
        content: 'Are you sure you want to void this Transaction?',
        confirmButtonText: 'Void',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          // Clear out previous errors
          this.updateLineItemsErrors = [];
          this.financeService
            .voidInvoiceTransaction(invoiceTransaction)
            .pipe(takeUntil(this.unsub))
            .subscribe(
              () => {
                this.invoicesService.invoiceUpdated.next(this.invoice.id);
                this.invoicesService.invoicesListUpdated.next();
                this.transactionsLoading = false;
              },
              (errorResponse: HttpErrorResponse) => {
                this.updateLineItemsErrors = errorResponse.error.errors;
                this.transactionsLoading = false;
              }
            );
        } else {
          this.transactionsLoading = false;
        }
      });
  }

  updateKendoGridData() {
    this.invoiceLineItems =
      this.invoice.isPaid || this.invoice.invoiceTypeId === InvoiceType.Recurring
        ? this.invoice.invoiceLineItems
        : [...this.invoice.invoiceLineItems, this.emptyInvoiceLineItem];

    this.creditLineItemExists = this.invoiceLineItems.some((ili) => ili.isCredit);
    this.showDeleteButton = this.invoiceLineItems.length == 0 || this.invoiceLineItems[0].id == 0;
  }

  addProductToInvoice(dataItem) {
    const productLineItem: InvoiceLineItem = {
      id: 0,
      invoiceId: this.invoice.id,
      note: null,
      unitPrice: dataItem.price || dataItem.pricePaid,
      quantity: dataItem.quantity,
      total: null,
      serviceType: null,
      serviceTypeDescription: null,
      clinicProductId: dataItem.clinicProductId,
      isManual: true,
      isDeleted: false,
      finalTotal: null,
      isCredit: false,
      serviceTemplateId: null,
      addBackToInventory: false,
      invoiceLineItemTaxes: [],
      attributedToUserId: dataItem.attributedToUser ? dataItem.attributedToUser.id : null,
      attributedToUser: dataItem.attributedToUser,
      plannedTreatmentMultipleId: null,
      isRecommendedProduct: false,
    };
    const invoiceLineItems: InvoiceLineItem[] = [...this.invoice.invoiceLineItems, productLineItem];
    this.updateInvoiceLineItems(invoiceLineItems);
  }

  async selectItem(event) {
    let lineItems: InvoiceLineItem[] = [];
    if (!isNullOrUndefined(event.productId)) {
      let lineItem = {
        id: 0,
        invoiceId: this.invoice.id,
        note: null,
        unitPrice: event.retailPrice,
        quantity: 1,
        total: null,
        serviceType: null,
        serviceTypeDescription: null,
        clinicProductId: event.id,
        isManual: true,
        isDeleted: false,
        finalTotal: null,
        isCredit: false,
        serviceTemplateId: null,
        addBackToInventory: false,
        invoiceLineItemTaxes: [],
        attributedToUserId: event.attributedToUser ? event.attributedToUser.id : null,
        attributedToUser: event.attributedToUser,
        plannedTreatmentMultipleId: null,
        isRecommendedProduct: false,
      };
      lineItems.push(lineItem);
    } else {
      if (event.plannedTreatmentMultipleId) {
        let unitPrice = !isNullOrUndefined(event.chargeAmount) ? event.chargeAmount : event.defaultPrice;
        let unitPriceCurrency = this.currencyPipe.transform(unitPrice, 'USD', 'symbol', '1.2-2');

        let lineItem = {
          id: 0,
          invoiceId: this.invoice.id,
          note:
            'Multiple Treatment Pre-payment (' +
            (event.plannedTreatmentMultipleQuantity ? event.plannedTreatmentMultipleQuantity : 1) +
            ')',
          serviceTypeDescription: `Package - ${unitPriceCurrency} x ${event.plannedTreatmentMultipleQuantity}`,
          unitPrice: unitPrice,
          quantity: event.quantity ? event.quantity : 1,
          total: null,
          serviceType: event.serviceName,
          clinicProductId: null,
          isManual: true,
          isDeleted: false,
          finalTotal: null,
          isCredit: false,
          serviceTemplateId: event.templateId,
          serviceId: event.serviceId,
          addBackToInventory: false,
          invoiceLineItemTaxes: [],
          attributedToUserId: event.attributedToUser ? event.attributedToUser.id : null,
          attributedToUser: event.attributedToUser,
          plannedTreatmentMultipleId: event.plannedTreatmentMultipleId,
          isRecommendedProduct: false,
        };
        lineItems.push(lineItem);
      } else {
        lineItems = await this.invoicesService
          .generateLineItemsForPrepayment(this.invoice.id, event.serviceId)
          .toPromise();
      }
    }
    const invoiceLineItems: InvoiceLineItem[] = [...this.invoice.invoiceLineItems, ...lineItems];
    this.updateInvoiceLineItems(invoiceLineItems);
  }

  showTooltip(event) {
    //only check for injectables or coolsculpting that is prepayment - we have embedded a serviceId into the element
    if (
      event &&
      event.target &&
      event.target.attributes &&
      event.target.attributes.sectionvalue &&
      event.target.attributes.sectionvalue.value &&
      event.target.attributes.sectionvalue.value != ''
    ) {
      let serviceId = event.target.attributes.sectionvalue.value;
      this.getObservationDetails(serviceId).subscribe((currentTooltips) => {
        this.currentTooltips = currentTooltips;
      });
    } else {
      this.currentTooltips = [];
    }
  }

  showMembershipDetail = (dataItem: InvoiceLineItem): boolean => {
    const show =
      this.lineItemMemberships.get(dataItem.id) != null && this.lineItemMemberships.get(dataItem.id).length > 0;
    return show;
  };

  expandDetailsBy = (dataItem: InvoiceLineItem): number => {
    return dataItem.id;
  };

  getObservationDetails(serviceId: number): Observable<string[]> {
    let obsDetails = [];
    return this.servicesService.getServiceById(serviceId).pipe(
      map((service) => {
        if (service && service.observations && Array.isArray(service.observations) && service.observations.length > 0) {
          let observations: Observation[] = service.observations.map((o) => new Observation(o));
          let serviceDetailType =
            service.serviceDetailTemplateId === ServiceDetailTemplate.Injections
              ? ServiceDetailTemplate.Injections
              : ServiceDetailTemplate.Coolsculpting;

          obsDetails = this.mapObservationToLineItemText(observations, serviceDetailType);
        }

        return obsDetails;
      })
    );
  }

  private mapObservationToLineItemText(observations: Observation[], serviceDetailType: ServiceDetailTemplate) {
    let obsDetails = [];
    let observationDetails =
      serviceDetailType == ServiceDetailTemplate.Injections
        ? this.injectionService.onGetTreatmentGroupsInjections(observations)
        : this.coolsculptingService.onGetTreatmentGroupsCoolsculpting(observations);
    observationDetails.forEach((observation, i) => {
      let observationDetail = observations[i];
      obsDetails.push(
        serviceDetailType == ServiceDetailTemplate.Injections
          ? `${observation.details} - ${observation.unit} - ${observation.area}`
          : `${observation.applicator}: ${observation.area}: ${observationDetail.details.position}: ${
              observationDetail.details.angle
            }: ${observationDetail.details.avaliable ? observationDetail.details.avaliable : 1} Cycles`
      );
    });
    return obsDetails;
  }

  onInvoicePay() {
    this.invoicesService.invoicesListUpdated.next();
    this.invoicesService.invoiceUpdated.next(this.invoice.id);
    this.isOpenPaymentModal = false;
  }

  panelVisibilityChange(visible: boolean) {
    if (!visible) {
      this.payModalClosed = true;
    }
  }

  async addCreditToInvoice() {
    this.loading = true;
    await this.invoicesService.addCreditLineItemToInvoice(this.invoice);
    this.getInvoiceById();
    // loading is set to false in getInvoiceById
  }

  addMembershipToInvoice() {
    const modalRef = this.modalService.open(MembershipSelectionComponent, { backdrop: 'static' });
    (modalRef.componentInstance as MembershipSelectionComponent).patientId = this.currentPatient.patientId;
    (modalRef.componentInstance as MembershipSelectionComponent).invoiceMembershipIds = this.invoiceLineItems
      .filter((ili) => ili.membershipId)
      .map((ili) => ili.membershipId);
    modalRef.closed.subscribe(async (memberships: Membership[]) => {
      if (memberships && memberships.length > 0) {
        const membershipLineItems = memberships.map((membership) =>
          this.membershipsService.createMembershipLineItem(membership, this.invoice.id)
        );
        this.updateInvoiceLineItems(membershipLineItems);
      }
    });
  }

  onDeleteInvoice(id: any) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Delete Invoice?',
        content: 'Are you sure you want to delete this invoice?',
        confirmButtonText: 'Confirm',
        showCancel: true,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.loading = true;
          this.invoicesService.deleteInvoice(id).subscribe(() => {
            this.loading = false;
            this.backToRoute();
          });
        }
      });
  }

  confirmMultipleLineItemsDeletion() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Delete Treatment Prepayment?',
        content:
          'Removing this will force the removal of all related line items for this service. If it has multiple observations, all of the related observation line items will be removed as well to prevent a partial payment of a planned treatment.',
        confirmButtonText: 'Confirm',
        showCancel: true,
      },
    });

    return dialogRef.afterClosed().pipe(
      takeUntil(this.unsub),
      map((result) => {
        return result && result === 'confirm';
      })
    );
  }

  invoiceHasUnpaidRemovableLineItems(invoice: Invoice) {
    return (
      !invoice.isPaid &&
      invoice.invoiceLineItems &&
      invoice.invoiceLineItems.some((ili) => ili.isManual || ili.relatedInvoiceLineItemId)
    );
  }

  invoiceHasValidMerchantTransactions(invoice: Invoice) {
    return (
      !invoice.isPaid &&
      invoice.invoiceTransactions &&
      invoice.invoiceTransactions.findIndex((it) => it.emvData) != -1 &&
      invoice.invoiceTransactions.findIndex((it) => it.emvData.responseText === 'APPROVED') != -1 &&
      invoice.invoiceTransactions.findIndex((it) => moment(it.transactionDate).isSame(moment(), 'day')) != -1
    );
  }

  isVoidTransaction(invoiceTransaction: InvoiceTransaction) {
    return invoiceTransaction.emvData && invoiceTransaction.financeTransactionTypeId == FinanceTransactionType.Void;
  }

  isOldTransaction(invoiceTransaction: InvoiceTransaction) {
    return invoiceTransaction.emvData && moment(invoiceTransaction.transactionDate).isBefore(moment(), 'day');
  }

  isApprovedTransaction(invoiceTransaction: InvoiceTransaction) {
    return invoiceTransaction.emvData && invoiceTransaction.emvData.responseText === 'APPROVED';
  }

  backToRoute() {
    this.router.navigate(['../../overview'], { relativeTo: this.route });
  }

  cellClickHandler({ sender, rowIndex, column, columnIndex, dataItem, isEdited, originalEvent }: CellClickEvent) {
    this.buttonsAvailable = false;
    if (column instanceof CommandColumnComponent) {
      return;
    }
    // If it's the empty row that was clicked on initially, we need to close the editor
    if (this.formGroup && this.editingEmptyLineItem && !this.formGroup.dirty && !isEdited) {
      this.closeEditor();
      return;
    }

    if (this.invoice.isPaid || isEdited || (this.formGroup && !this.formGroup.valid)) {
      return;
    }
    // Save current grid values
    this.saveCurrent();

    // Create form group editor for new row
    this.editedRowIndex = rowIndex;
    this.formGroup = this.createFormGroup(dataItem);

    sender.editRow(rowIndex, this.formGroup);
    sender.focusCell(rowIndex, columnIndex);
  }

  saveCurrent() {
    if (this.formGroup) {
      const editedInvoiceLineItem: InvoiceLineItem = this.formGroup.value;
      if (editedInvoiceLineItem) {
        if (this.formGroup.controls['attributedToUser'].value) {
          editedInvoiceLineItem.attributedToUserId = editedInvoiceLineItem.attributedToUser.id;
        }

        if (this.formGroup.controls['discountPrice'].value) {
          let discount = parseFloat(this.formGroup.controls['discountPrice'].value);
          if (discount === 0) {
            discount = null;
            editedInvoiceLineItem.isPercentDiscount = null;
          }
          editedInvoiceLineItem.discountPrice = discount;
        }

        // Save to the database
        if (this.formGroup.dirty) {
          // set the product info
          if (editedInvoiceLineItem.serviceType) {
            const clinicProduct = this.clinicProducts.find(
              (clinicProduct: ClinicProduct) => clinicProduct.displayName === editedInvoiceLineItem.serviceType
            );
            if (clinicProduct) {
              editedInvoiceLineItem.unitPrice = clinicProduct.retailPrice;
              editedInvoiceLineItem.clinicProductId = clinicProduct.id;
            }
          }
          // Find the Invoice Line Item that needs to be updated
          const invoiceLineItemToUpdate = this.invoiceLineItems.find(
            (invoiceLineItem: InvoiceLineItem) => invoiceLineItem.id === editedInvoiceLineItem.id
          );
          if (invoiceLineItemToUpdate) {
            Object.assign(invoiceLineItemToUpdate, editedInvoiceLineItem);
            this.updateInvoiceLineItems([invoiceLineItemToUpdate]);
          }
        }
        this.closeEditor();
      }
    }
  }

  createFormGroup(dataItem: InvoiceLineItem): FormGroup {
    // If there is no attributed to User, use the logged in User
    if (dataItem.attributedToUserId == null) {
      dataItem.attributedToUserId = this.usersService.loggedInUser.id;
    }

    const user = this.users.find((user: User) => user.id === dataItem.attributedToUserId);

    let unitPrice;
    if (dataItem.serviceType && dataItem.serviceType === 'Credit') {
      unitPrice = new FormControl(dataItem.unitPrice, Validators.required);
    } else {
      unitPrice = dataItem.unitPrice; // Don't want this to be editable
    }

    if (dataItem.serviceType === null) {
      // We are on the empty line item
      this.editingEmptyLineItem = true;
    } else {
      this.editingEmptyLineItem = false;
    }

    this.formGroup = this.formBuilder.group({
      id: dataItem.id,
      serviceType: new FormControl(dataItem.serviceType, Validators.required),
      note: new FormControl(dataItem.note),
      isCredit: dataItem.isCredit === true,
      attributedToUser: user,
      unitPrice: unitPrice,
      isPercentDiscount: dataItem.isPercentDiscount,
      discountPrice: new FormControl(dataItem.discountPrice, [Validators.pattern(/^([0-9]+(\.[0-9][0-9]?)?)\%?$/)]),
      quantity: new FormControl(
        dataItem.quantity,
        Validators.compose([Validators.required, Validators.pattern('^[1-9][0-9]{0,2}$')])
      ),
      finalTotal: this.calculateFinalTotal(dataItem), // calculate tax total for this row so we can get the final price
    });

    return this.formGroup;
  }

  private calculateTotal(dataItem: InvoiceLineItem): number {
    let total = dataItem.unitPrice * dataItem.quantity;
    if (dataItem.discountPrice > 0) {
      if (dataItem.isPercentDiscount) {
        total *= 1 - dataItem.discountPrice / 100;
      } else {
        total -= dataItem.discountPrice;
      }
    }
    return total;
  }

  private calculateFinalTotal(dataItem: any) {
    let finalTotal = dataItem.total;
    this.invoiceLineItemTaxKeys.forEach((taxType) => {
      // Find the Tax values for this Product/Service
      if (dataItem.id) {
        const taxValues = this.invoiceLineItemTaxValuesObject[dataItem.id]; // {GST: 1.5, PST: 2.1}
        if (taxValues) {
          // If there is a Tax, we will calculate the new tax amount
          const taxTypeValue = taxValues[taxType];
          if (taxTypeValue) {
            // Get the Clinic Tax percentage
            const clinicTax = this.clinic.clinicTaxes.find((clinicTax: ClinicTax) => clinicTax.tax.name === taxType);
            if (clinicTax) {
              // Calculate the new tax amount
              const taxValue = clinicTax.tax.value;
              const taxAmount = dataItem.total * taxValue;
              finalTotal = finalTotal + taxAmount;
            }
          }
        }
      }
    });
    // Update the grid value too
    if (this.formGroup) {
      this.formGroup.controls['finalTotal'].setValue(finalTotal);
      this.kendoGrid.data[this.editedRowIndex].finalTotal = finalTotal;
    }
    return finalTotal;
  }

  onProductChange(e: string) {
    // Find the product and set the Quantity and Price
    const clinicProduct = this.clinicProducts.find((clinicProduct: ClinicProduct) => clinicProduct.displayName === e);
    if (clinicProduct) {
      this.formGroup.value.unitPrice = clinicProduct.retailPrice;
      if (this.formGroup.controls['unitPrice']) {
        // Update the Grid Data so it refreshes
        this.kendoGrid.data[this.editedRowIndex].unitPrice = clinicProduct.retailPrice;
      }
      if (this.formGroup.controls['quantity'] && this.formGroup.controls['quantity'].value !== 0) {
        // Set the value in the control
        this.formGroup.controls['quantity'].setValue(1);
        this.kendoGrid.data[this.editedRowIndex].quantity = 1;
      }
    }
  }

  onItemDiscountChange(event: string) {
    let isPercent = event.slice(-1) === '%';
    let discount = parseFloat(event);
    if (discount === 0) {
      discount = null;
      isPercent = null;
    }
    if (this.formGroup) {
      this.formGroup.controls['isPercentDiscount'].setValue(isPercent);
    }
    const dataItem: InvoiceLineItem = this.kendoGrid.data[this.editedRowIndex];
    dataItem.discountPrice = discount;
    dataItem.isPercentDiscount = isPercent;
    dataItem.total = this.calculateTotal(dataItem);
    dataItem.finalTotal = this.calculateFinalTotal(dataItem);
  }

  onQuantityChange(event: string) {
    const dataItem: InvoiceLineItem = this.kendoGrid.data[this.editedRowIndex];
    dataItem.quantity = Number(event);
    dataItem.total = this.calculateTotal(dataItem);
    dataItem.finalTotal = this.calculateFinalTotal(dataItem);
  }

  closeEditor(): void {
    this.kendoGrid.closeRow(this.editedRowIndex);
    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
    this.filteredProducts = this.clinicProducts;
    if (!this.invoiceLineItemsLoading) this.buttonsAvailable = true;
  }

  onDocumentClick(e: any): void {
    if (
      this.formGroup &&
      !this.formGroup.dirty &&
      !this.formGroup.valid &&
      this.editingEmptyLineItem &&
      !matches(
        e.target,
        '#invoiceLineItemsGrid tbody *, #invoiceLineItemsGrid .k-grid-toolbar .k-button, span.mat-option-text, input.dropdown-search-input'
      )
    ) {
      this.closeEditor();
      return;
    }

    if (
      this.formGroup &&
      this.formGroup.valid &&
      !matches(
        e.target,
        '#invoiceLineItemsGrid tbody *, #invoiceLineItemsGrid .k-grid-toolbar .k-button, span.mat-option-text'
      )
    ) {
      this.saveCurrent();
    }

    if (
      this.transactionsGridFormGroup &&
      !this.transactionsGridFormGroup.dirty &&
      !this.transactionsGridFormGroup.valid &&
      !matches(e.target, '#transactionsGrid')
    ) {
      this.closeTransactionsEditor();
      return;
    }

    if (
      this.transactionsGridFormGroup &&
      this.transactionsGridFormGroup.valid &&
      !matches(
        e.target,
        '#transactionsGrid tbody *, #transactionsGrid .k-grid-toolbar .k-button, span.mat-option-text, .k-datetime-container *'
      )
    ) {
      this.saveCurrentTransaction();
    }
  }

  transactionsGridCellClickHandler({ sender, rowIndex, column, columnIndex, dataItem, isEdited }) {
    if (this.invoice.isPaid || isEdited || (this.transactionsGridFormGroup && !this.transactionsGridFormGroup.valid)) {
      return;
    }

    // Save previous Transaction values
    this.saveCurrentTransaction();

    this.transactionsGridEditedRowIndex = rowIndex;
    this.transactionsGridFormGroup = this.createTransactionsGridFormGroup(dataItem);

    sender.editRow(rowIndex, this.transactionsGridFormGroup);
  }

  createTransactionsGridFormGroup(dataItem: any): FormGroup {
    // If there is no created by User, use the logged in User
    if (dataItem.createdById == null) {
      dataItem.createdById = this.usersService.loggedInUser.id;
    }

    const user = this.users.find((user: User) => user.id === dataItem.createdById);
    const paymentMethod = this.paymentMethods.find(
      (paymentMethod: PaymentMethod) => paymentMethod.id === dataItem.paymentMethodId
    );

    this.transactionsGridFormGroup = this.formBuilder.group({
      id: dataItem.id,
      invoiceId: dataItem.invoiceId,
      createdBy: new FormControl(user, Validators.required),
      createdById: user ? user.id : '',
      description: dataItem.description,
    });
    return this.transactionsGridFormGroup;
  }

  saveCurrentTransaction() {
    if (this.transactionsGridFormGroup) {
      const editedTransaction: InvoiceTransaction = this.transactionsGridFormGroup.value;

      if (editedTransaction) {
        // Save to the database if needed
        if (this.transactionsGridFormGroup.dirty) {
          // Record the CreatedByUserId
          if (editedTransaction.createdBy) {
            editedTransaction.createdById = editedTransaction.createdBy.id;
          }
          // Find thePayment Item that needs to be updated
          const updatedItem = this.invoice.invoiceTransactions.find(
            (invoiceTransaction: InvoiceTransaction) => invoiceTransaction.id === editedTransaction.id
          );
          if (updatedItem) {
            Object.assign(updatedItem, editedTransaction);
            this.updateInvoiceTransaction(updatedItem);
          }
        }
        this.closeTransactionsEditor();
      }
    }
  }

  editTransactionsInPayModal() {
    this.isOpenPaymentModal = true;
    this.selectedInvoice$.next(this.invoice);
  }

  closeTransactionsEditor(): void {
    this.transactionsGrid.closeRow(this.transactionsGridEditedRowIndex);
    this.transactionsGridEditedRowIndex = undefined;
    this.transactionsGridFormGroup = undefined;
  }

  updateInvoiceTransaction(invoiceTransaction: InvoiceTransaction) {
    this.transactionsLoading = true;
    this.invoicesService
      .updateInvoiceTransaction(invoiceTransaction)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        () => {
          this.updateLineItemsErrors = [];
          this.invoicesService.invoiceUpdated.next(this.invoice.id);
          this.invoicesService.invoicesListUpdated.next();
          this.transactionsLoading = false;
        },
        (errorResponse: HttpErrorResponse) => {
          this.updateLineItemsErrors = errorResponse.error.errors;
          this.transactionsLoading = false;
        }
      );
  }

  applyDiscountToInvoice() {
    const modalRef = this.modalService.open(InvoiceDiscountComponent, { backdrop: 'static' });
    modalRef.closed.subscribe((discountValue) => {
      if (discountValue) {
        this.invoice.invoiceLineItems.forEach((ili) => {
          if (ili.unitPrice > 0) {
            ili.isPercentDiscount = true;
            ili.discountPrice = discountValue;
          }
        });
        this.updateInvoiceLineItems(this.invoice.invoiceLineItems);
      }
    });
  }

  editInvoiceDate(invoiceId: number, invoiceDate: any) {
    const modalRef = this.modalService.open(UpdateInvoiceDateComponent, { backdrop: 'static' });
    const instance = modalRef.componentInstance as UpdateInvoiceDateComponent;
    const invoiceDateObj = new Date(this.intl.parseDate(invoiceDate));
    instance.invoiceDate = new Date(invoiceDateObj.getFullYear(), invoiceDateObj.getMonth(), invoiceDateObj.getDate());
    instance.invoiceId = invoiceId;
    instance.passResult.subscribe((newDate: Date) => {
      this.invoice.invoiceDate = newDate;
      // Update Transaction Dates
      this.invoice.invoiceTransactions.forEach(function (invoiceTransaction: InvoiceTransaction) {
        const transactionDate: Date = new Date(invoiceTransaction.transactionDate);
        invoiceTransaction.transactionDate = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          transactionDate.getHours(),
          transactionDate.getMinutes(),
          transactionDate.getSeconds()
        );
      });
    });
  }

  getFileName() {
    const patientName = `${this.currentPatient.firstName}_${this.currentPatient.lastName}`;
    const invoiceDate = new Date(this.invoice.invoiceDate);
    const dateString = `${invoiceDate.getFullYear()}${('0' + (invoiceDate.getMonth() + 1)).slice(-2)}${(
      '0' + invoiceDate.getDate()
    ).slice(-2)}`;
    if (this.invoice) {
      let invoiceType = this.getInvoiceType(this.invoice).toLowerCase();
      invoiceType = invoiceType.charAt(0).toUpperCase() + invoiceType.substring(1);
      return patientName + '_' + dateString + '_' + invoiceType + '_' + this.invoice.invoiceNumber + '.pdf';
    } else if (this.externalInvoiceId) {
      return patientName + '_' + dateString + '_INVOICE_' + this.externalInvoiceId;
    } else {
      return patientName + '_' + dateString + '_INVOICE';
    }
  }
  @ViewChildren(GridComponent)
  public grids: QueryList<GridComponent>;

  /*
  public exportToPDF() {
    this.loading = true;
    const promises = this.grids.map((grid) => grid.drawPDF());
    Promise.all(promises)
      .then((groups) => {
        const rootGroup = new Group({
          pdf: {
            multiPage: true,
          },
        });
        rootGroup.append(...groups[0].children); //managed to get the other one inserted via dom template
        // groups.forEach((group) => {
        //     rootGroup.append(...group.children);
        // });

        return exportPDF(rootGroup, { paperSize: 'A4', landscape: false });
      })
      .then(async (dataUri) => {
        this.loading = false;
        const base64 = dataUri.replace('data:application/pdf;base64,', '');
        const blob = await this.blobService.convertBase64toBlob(base64, 'application/pdf');
        const printable = URL.createObjectURL(blob);
        if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
          printJS({ printable, type: 'pdf' });
        } else {
          window.open(printable);
        }
      });
  }
  */

  public async exportToPDF() {
    const dataUri = await this.createInvoiceDataUri();
    const base64 = dataUri.replace('data:application/pdf;base64,', '');
    const blob = await this.blobService.convertBase64toBlob(base64, 'application/pdf');
    const printable = URL.createObjectURL(blob);
    if (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
      printJS({ printable, type: 'pdf' });
    } else {
      window.open(printable);
    }
  }

  public async createInvoiceDataUri() {
    this.loading = true;
    const wasUnpaid = !this.invoice.isPaid;
    if (wasUnpaid) {
      this.invoice.isPaid = true; // Invoice needs to appear locked for the PDF
      this.showPaidStamp = false;
      await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for changes to be applied to the DOM
    }
    const group = await this.pdfContainer.export();
    const dataUri = await exportPDF(group, { paperSize: 'A4', landscape: false });
    this.loading = false;
    if (wasUnpaid) {
      this.invoice.isPaid = false; // Reset the Invoice to its original state
      this.showPaidStamp = true;
    }
    return dataUri;
  }

  public async createInvoiceFile() {
    const dataUri = await this.createInvoiceDataUri();
    const base64 = dataUri.replace('data:application/pdf;base64,', '');
    const fileObject = dataURLtoFile(dataUri, this.getFileName());
    const fileData = new FileData({
      contentType: fileObject.type,
      fileName: fileObject.name,
      base64: base64,
    });
    return fileData;
  }

  /*
  public async createInvoicePDF() {
    const fileData = await this.kendoGrid
      .drawPDF()
      .then((group: Group) => exportPDF(group))
      .then((dataUri) => {
        const base64 = dataUri.replace('data:application/pdf;base64,', '');
        const fileObject = dataURLtoFile(dataUri, this.getFileName());
        return new FileData({
          contentType: fileObject.type,
          fileName: fileObject.name,
          base64: base64,
        });
      });
    return fileData;
  }
  */

  public async emailInvoice(emailAddress: string) {
    // const pdf = await this.createInvoicePDF();
    const fileData = await this.createInvoiceFile();
    this.invoicesService
      .emailInvoice(fileData, emailAddress, this.currentPatient.patientId, this.invoice.id)
      .subscribe();
    return true;
  }

  public onEmailInvoice = (emailAddress: string) => {
    return from(this.createInvoiceFile()).pipe(
      concatMap((fileData) =>
        this.invoicesService.emailInvoice(fileData, emailAddress, this.currentPatient.patientId, this.invoice.id)
      )
    );
  };

  public getEmailInvoiceAsObservable = (email: string) => {
    return defer(() => this.emailInvoice(email));
  };

  public invoicePaidStatusHandler() {
    this.loading = true;
    this.invoicesService
      .updateInvoicePaidStatus(this.invoice.id, !this.invoice.isPaid)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        () => {
          this.invoicesService.invoiceUpdated.next(this.invoice.id);
          this.invoicesService.invoicesListUpdated.next();
          this.loading = false;
        },
        (errorResponse: HttpErrorResponse) => {
          this.updateLineItemsErrors = errorResponse.error.errors;
          this.loading = false;
        }
      );
  }

  public closeInvoiceHandler() {
    this.loading = true;
    this.invoicesService
      .updateInvoicePaidStatus(this.invoice.id, true)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        () => {
          this.invoicesService.invoiceUpdated.next(this.invoice.id);
          this.invoicesService.invoicesListUpdated.next();
          this.loading = false;
          this.financeService.invoicePaid.next();
          this.showDeleteButton = false;
        },
        (errorResponse: HttpErrorResponse) => {
          this.updateLineItemsErrors = errorResponse.error.errors;
          this.loading = false;
        }
      );
  }

  getInvoiceType(invoice: Invoice) {
    return this.invoicesService.getInvoiceType(invoice);
  }

  getLocalFormattedDate(date: Date) {
    const localDate = moment.utc(date).local().format('YYYY-MM-DD');
    return localDate;
  }

  getLocalFormattedDateTime(date: Date) {
    const localDateTime = moment.utc(date).local().format('YYYY-MM-DD hh:mm A');
    return localDateTime;
  }

  isMembershipDiscountOverridden(dataItem: InvoiceLineItem, membership: MembershipService | MembershipProduct) {
    return (
      membership.amount !== dataItem.discountPrice ||
      (membership.discountType === DiscountType.Dollars && dataItem.isPercentDiscount) ||
      (membership.discountType === DiscountType.Percent && !dataItem.isPercentDiscount)
    );
  }

  filteredProducts: ClinicProduct[] = [];
  onSearchProductKey(value: string) {
    const allClinicProducts = this.clinicProducts ? this.clinicProducts : [];
    const searchTerms = value.split(' ');
    this.filteredProducts = allClinicProducts.filter((clinicProduct) => {
      var name = clinicProduct.displayName.toLowerCase();
      let match = true;
      for (var term of searchTerms) {
        if (!name.includes(term.toLowerCase())) {
          match = false;
          break;
        }
      }
      if (match) return clinicProduct;
    });
    if (this.filteredProducts.length == 0) {
      this.filteredProducts = allClinicProducts;
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    this.selectedInvoice$.next();
    this.selectedInvoice$.complete();
    this.docClickSubscription();
    this.resizeObserver.unobserve(this.sidePanelContainer.nativeElement);
  }
}
