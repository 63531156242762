import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { isNullOrUndefined } from '@app/shared/helpers';
import { Subject, zip, of } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { PatientService } from '@services/patient.service';
import { CurrentDataService } from '@services/currentData.service';
import { ObservationService } from '@services/observation.service';
import { PlottingEventService } from '@services/plotting-event.service';
import { AppointmentService } from '@services/appointments.service';
import { ServiceEventService } from '@services/service-event.service';
import { ObservationUnitsService } from '@services/observation-units.service';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { ServicesService } from '@services/services.service';
import { ObservationType, Observation, ObservationUnit, ObservationTypes } from '@models/observation/observation';
import { Patient } from '@models/patient';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { TreatmentType } from '@models/treatment-type';
import { TreatmentObservationGroup } from '@models/treatment-planning/treatment-observation-group';
import { ServiceEventType } from '@models/service/service-event-type';
import { Service } from '@models/service/service';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { GenericInputComponent } from '@app/management/dialogs/generic-input/generic-input.component';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { UsersService } from '@services/users.service';
import { ServiceNote } from '@models/service/service-note';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { FavouriteServicesService } from '../../../../../../../services/favourite-services.service';
import { UserFavouriteService } from '../../../../../../../models/service/user-favourite-service';
import { PaymentStatus } from '@models/scheduler/payment-status';
import { GroupPricingObservationsDialogComponent } from '@app/management/dialogs/group-pricing-observations/group-pricing-observations.component';
import { ServiceDetailTemplate } from '@app/models/service/service-detail-template';

@Component({
  selector: 'app-injection',
  templateUrl: './injection.component.html',
  styleUrls: ['./injection.component.less'],
})
export class InjectionComponent implements OnInit, OnDestroy {
  @Input()
  service: Service;
  @Input()
  serviceId: number;
  @Input()
  observationType: ObservationType;

  isServicePaid = false;
  loading = false;
  navLocked = false;

  patient: Patient;
  templatePatient: Patient;
  unsub = new Subject<any>();
  obrIdsToDelete: number[] = [];
  showObrDetails: boolean;
  treatmentDate: string;
  toxinMap: Map<string, Map<number, Observation>> = new Map();
  fillerMap: Map<string, Map<number, Observation>> = new Map();
  deoxycholateMap: Map<string, Map<number, Observation>> = new Map();
  groupedMap: Map<string, Map<string, Map<number, Observation>>> = new Map();
  completeMap: Map<string, Map<number, Observation>> = new Map();
  annotationMap: Map<number, Observation> = new Map();
  plannedTreatment: Map<string, boolean> = new Map();

  // Observation list items
  units: ObservationUnit[] = [];

  // Plotting settings
  activePlotKey = '';
  plottingActive = false;
  initialPlot = false;

  // Treatment note
  serviceNoteText: string;
  isTreatmentPlanning: boolean;

  @Input() associatedPlannedTreatment: PlannedTreatment;

  ServiceDetailTemplate = ServiceDetailTemplate;
  PaymentStatus = PaymentStatus;
  chargeAmount: number;
  isGrouping: boolean = false;
  selectedObservationsGrouping: string[] = [];
  isToxinSelected: boolean = false;
  isFillerSelected: boolean = false;

  constructor(
    private patientService: PatientService,
    public currentDataService: CurrentDataService,
    private observationService: ObservationService,
    private plottingEventService: PlottingEventService,
    private appointmentsService: AppointmentService,
    private serviceEventService: ServiceEventService,
    private observationUnitsService: ObservationUnitsService,
    private observationListItemsService: ObservationListItemsService,
    private dialog: MatDialog,
    private servicesService: ServicesService,
    private route: ActivatedRoute,
    private router: Router,
    private treatmentPlanService: TreatmentPlanService,
    private usersService: UsersService,
    private favouriteServicesService: FavouriteServicesService
  ) {
    this.isTreatmentPlanning = this.route.snapshot.params.treatmentPlanning === 'true';
    this.patient = this.patientService.patientPanelPatient;
    this.patientService.getTemplatePatient().subscribe((tp) => {
      this.templatePatient = tp;
    });
    // By default we will hide the OBR details, but they are handy to display for debugging purposes
    this.showObrDetails = false;
    this.initObservationUnits();
  }

  ngOnInit() {
    this.isServicePaid = this.servicesService.isPaid;
    // find any existing injection observations for this service
    const patientObrs = this.patient.observations.filter((obr) => obr.serviceId === this.serviceId);

    this.initPlottingSubscription();
    this.initServiceEventSubscription();

    this.serviceNoteText =
      !isNullOrUndefined(this.service.serviceNotes) && this.service.serviceNotes.length > 0
        ? this.service.serviceNotes[0].entryText
        : '';

    this.appointmentsService
      .getAppointmentsByPatientId(this.patient.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((a) => {
        const appt = a.find((s) => s.serviceId === this.serviceId);

        if (!isNullOrUndefined(appt)) {
          this.treatmentDate = moment(appt.date).add(appt.startTime).toString();
        } else {
          this.treatmentDate = Date();
        }

        if (patientObrs.length > 0) {
          this.initialPlot = true;
        }

        this.plottingEventService.plotObservations(patientObrs, !this.service.isLocked);
      });

    this.treatmentPlanService
      .getTreatmentPlanByPatientId(this.patient.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((tp: TreatmentPlan) => {
        if (!isNullOrUndefined(tp) && !isNullOrUndefined(tp.plannedTreatments)) {
          tp.plannedTreatments.forEach((treatment) => {
            if (!isNullOrUndefined(treatment.service.observations)) {
              treatment.service.observations.forEach((item) => {
                const key = this.onGetMapKey(item);
                this.plannedTreatment.set(key, true);
              });
            }
          });
        }
      });

    this.currentDataService.preventUserFromNavigation$.pipe(takeUntil(this.unsub)).subscribe((navLocked) => {
      this.navLocked = navLocked;
    });

    this.currentDataService.treatmentIsDirty = false;
    this.servicesService.servicePhotoUpdated$.pipe(takeUntil(this.unsub)).subscribe((service) => {
      this.service.servicePhotoPath = service.servicePhotoPath
        ? service.servicePhotoPath
        : this.service.servicePhotoPath;
    });
  }

  initPlottingSubscription() {
    this.plottingEventService
      .getPlotSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ObrPlottingEventType.SendDetails: {
            this.activePlotKey = '';
            this.currentDataService.treatmentIsDirty = true;
            if (this.initialPlot) {
              this.currentDataService.treatmentIsDirty = false;
              this.initialPlot = false;
            }

            response.details.forEach((obr: Observation, leafletId: number) => {
              const key = this.onGetMapKey(obr);
              if (obr.details.groupName != null && obr.details.groupName != '') {
                if (!isNullOrUndefined(this.groupedMap.get(obr.details.groupName))) {
                  if (!isNullOrUndefined(this.groupedMap.get(obr.details.groupName).get(key))) {
                    //Has group already, old point
                    let oldObr = this.groupedMap.get(obr.details.groupName).get(key).get(leafletId);

                    if (!isNullOrUndefined(oldObr)) {
                      oldObr.details.plotDetails = { ...oldObr.details.plotDetails, ...obr.details.plotDetails };
                    } else {
                      oldObr = obr;
                    }

                    this.groupedMap.get(obr.details.groupName).get(key).set(leafletId, oldObr);
                    this.completeMap.get(key).set(leafletId, oldObr);
                  } else {
                    //has group, but new point
                    this.groupedMap.get(obr.details.groupName).set(key, new Map());
                    this.groupedMap.get(obr.details.groupName).get(key).set(leafletId, obr);

                    this.completeMap.set(key, new Map());
                    this.completeMap.get(key).set(leafletId, obr);
                  }
                } else {
                  // new group, new point
                  this.groupedMap.set(obr.details.groupName, new Map());
                  this.groupedMap.get(obr.details.groupName).set(key, new Map());
                  this.groupedMap.get(obr.details.groupName).get(key).set(leafletId, obr);

                  this.completeMap.set(key, new Map());
                  this.completeMap.get(key).set(leafletId, obr);
                }
              } else if (obr.name === TreatmentType.Toxins) {
                if (!isNullOrUndefined(this.toxinMap.get(key))) {
                  let oldObr = this.toxinMap.get(key).get(leafletId);

                  if (!isNullOrUndefined(oldObr)) {
                    oldObr.details.plotDetails = { ...oldObr.details.plotDetails, ...obr.details.plotDetails };
                  } else {
                    oldObr = obr;
                  }
                  this.toxinMap.get(key).set(leafletId, oldObr);
                  this.completeMap.get(key).set(leafletId, oldObr);
                } else {
                  // new plotted point
                  this.toxinMap.set(key, new Map());
                  this.completeMap.set(key, new Map());

                  this.toxinMap.get(key).set(leafletId, obr);
                  this.completeMap.get(key).set(leafletId, obr);
                }
              } else if (obr.name === TreatmentType.Deoxycholate) {
                if (!isNullOrUndefined(this.deoxycholateMap.get(key))) {
                  let oldObr = this.deoxycholateMap.get(key).get(leafletId);

                  if (!isNullOrUndefined(oldObr)) {
                    oldObr.details.plotDetails = { ...oldObr.details.plotDetails, ...obr.details.plotDetails };
                  } else {
                    oldObr = obr;
                  }
                  this.deoxycholateMap.get(key).set(leafletId, oldObr);
                  this.completeMap.get(key).set(leafletId, oldObr);
                } else {
                  // new plotted point
                  this.deoxycholateMap.set(key, new Map());
                  this.completeMap.set(key, new Map());

                  this.deoxycholateMap.get(key).set(leafletId, obr);
                  this.completeMap.get(key).set(leafletId, obr);
                }
              } else {
                // Filler
                if (!isNullOrUndefined(this.fillerMap.get(key))) {
                  let oldObr = this.fillerMap.get(key).get(leafletId);
                  if (!isNullOrUndefined(oldObr)) {
                    oldObr.details.plotDetails = obr.details.plotDetails;
                  } else {
                    oldObr = obr;
                  }

                  this.fillerMap.get(key).set(leafletId, oldObr);
                  this.completeMap.get(key).set(leafletId, oldObr);
                } else {
                  // new plotted point
                  this.fillerMap.set(key, new Map());
                  this.completeMap.set(key, new Map());

                  this.fillerMap.get(key).set(leafletId, obr);
                  this.completeMap.get(key).set(leafletId, obr);
                }
              }
            });

            for (const key of this.completeMap.keys()) {
              const obrGroup = new TreatmentObservationGroup({ key: key, observations: this.onGetObservations(key) });
              this.serviceEventService.recalculateObservationBilling.next(obrGroup);
            }
            this.serviceEventService.recalculateTreatmentBilling.next(this.onGetTreatmentObservations());
            break;
          }

          case ObrPlottingEventType.SendAnnotations: {
            response.details.forEach((annotationDetails: any, leafletId: number) => {
              if (!isNullOrUndefined(annotationDetails.id)) {
                this.annotationMap.set(leafletId, annotationDetails);
                if (this.initialPlot) {
                  this.initialPlot = false;
                } else {
                  this.currentDataService.treatmentIsDirty = true;
                }
              } else {
                const obr = new Observation({
                  id: 0,
                  typeId: this.observationType.id,
                  name: 'Annotation',
                  details: annotationDetails,
                  patientId: this.patient.patientId,
                  serviceId: this.serviceId,
                });
                this.annotationMap.set(leafletId, obr);
                this.currentDataService.treatmentIsDirty = true;
              }
            });
            break;
          }
          case ObrPlottingEventType.DeleteAnnotations: {
            response.leafletIds.forEach((leafletId: number) => {
              const obr = this.annotationMap.get(leafletId);
              if (!isNullOrUndefined(obr.id) && obr.id !== 0) {
                this.obrIdsToDelete.push(obr.id);
                this.currentDataService.treatmentIsDirty = true;
              }
              this.annotationMap.delete(leafletId);
            });
            break;
          }
          default:
            break;
        }
      });

    this.plottingEventService
      .getObservationSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ObrPlottingEventType.StartPinDrop: {
            this.plottingActive = true;
            break;
          }
          case ObrPlottingEventType.StopPinDrop: {
            this.plottingActive = false;
            break;
          }
          default:
            break;
        }
      });
  }

  initServiceEventSubscription() {
    this.serviceEventService
      .getServiceSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ServiceEventType.ApplyTreatment: {
            if (response.obrType === ObservationTypes.Injectables) {
              this.onApplyTreatment(response.service);
            }
          }
        }
      });
  }

  initObservationUnits() {
    this.observationUnitsService
      .getObservationUnitsByObservationTypeName(ObservationTypes.Injectables)
      .subscribe((units) => {
        this.units = units;
      });
  }

  onApplyTreatment(service: Service) {
    const observations = this.servicesService.applyTreatmentObservationsToService(service, this.service, this.patient);
    this.plottingEventService.plotObservations(observations, !this.service.isLocked);
    const serviceNoteText = this.servicesService.applyServiceNotesToService(service, this.service);
    if (serviceNoteText.length > 0) {
      if (this.serviceNoteText.length > 0) {
        this.serviceNoteText += '\n' + serviceNoteText;
      } else {
        this.serviceNoteText = serviceNoteText;
      }
    }
  }

  async onSave(isClosed: boolean) {
    this.loading = true;
    this.currentDataService.setPreventUserFromNavigation(true);

    const serviceNote = new ServiceNote({
      serviceNoteId:
        !isNullOrUndefined(this.service.serviceNotes) && this.service.serviceNotes.length > 0
          ? this.service.serviceNotes[0].serviceNoteId
          : 0,
      serviceId: this.service.serviceId,
      entryDate: new Date(),
      enteredBy: this.usersService.loggedInUser.firstName + ' ' + this.usersService.loggedInUser.lastName,
      entryText: this.serviceNoteText,
    });

    const observations: Observation[] = [];
    this.completeMap.forEach((areaDetails: Map<number, Observation>) => {
      areaDetails.forEach((obr: Observation) => {
        observations.push(JSON.parse(JSON.stringify(obr)));
      });
    });
    this.annotationMap.forEach((annotationDetails: Observation) => {
      observations.push(JSON.parse(JSON.stringify(annotationDetails)));
    });

    // if (this.associatedPlannedTreatment){
    //   let service = this.isTreatmentPlanning  && this.associatedPlannedTreatment.scheduledService && this.associatedPlannedTreatment.scheduledService.serviceId ?
    //   this.associatedPlannedTreatment.scheduledService :
    //   !this.isTreatmentPlanning  && this.associatedPlannedTreatment.service && this.associatedPlannedTreatment.service.serviceId ?  this.associatedPlannedTreatment.service   : null;

    //   if (service){
    //     let obsIdsToDelete = this.isTreatmentPlanning  && this.associatedPlannedTreatment.scheduledService && this.associatedPlannedTreatment.scheduledService.observations ? [...new Set(this.associatedPlannedTreatment.scheduledService.observations.map(o => o.id))]
    //       :!this.isTreatmentPlanning  && this.associatedPlannedTreatment.service && this.associatedPlannedTreatment.service.observations ? [...new Set(this.associatedPlannedTreatment.service.observations.map(o => o.id))]
    //       : [];
    //     let srv : Service = new Service();
    //     srv.observations = observations;
    //     let chargeAmount=   srv.getChargeAmount();
    //     service.chargeAmount = chargeAmount;
    //     this.obrIdsToDelete = this.obrIdsToDelete.concat(obsIdsToDelete);
    //     observations.forEach(o =>{
    //       let obs = new Observation(o);
    //       obs.id = 0;
    //       obs.serviceId = service.serviceId;
    //       observations.push(obs);
    //     });

    //     await this.servicesService.updateService(service).toPromise();
    //     this.invoiceService.invoiceUpdated.next()
    //   }
    // }

    this.observationService
      .deleteObservations(this.obrIdsToDelete, this.service.serviceId)
      .pipe(
        switchMap((x) => {
          return zip(
            of(x),
            this.observationService.addObservations(observations, this.service.serviceId, this.isTreatmentPlanning),
            this.servicesService.updateServiceNote(serviceNote)
          );
        })
      )
      .subscribe(([_, savedObrs, updatedServiceNote]) => {
        // Update the maps accordingly
        savedObrs.forEach((obr) => {
          if (obr.name === 'Annotation') {
            this.annotationMap.set(obr.details.leafletId, obr);
          } else {
            const mapKey = this.onGetMapKey(obr);
            if (obr.details.groupName != null && obr.details.groupName != '') {
              this.groupedMap.get(obr.details.groupName).set(mapKey, new Map());
              this.groupedMap.get(obr.details.groupName).get(mapKey).set(obr.details.plotDetails.leafletId, obr);
            } else if (obr.name === TreatmentType.Toxins) {
              this.toxinMap.get(mapKey).set(obr.details.plotDetails.leafletId, obr);
            } else if (obr.name === TreatmentType.Deoxycholate) {
              this.deoxycholateMap.get(mapKey).set(obr.details.plotDetails.leafletId, obr);
            } else {
              this.fillerMap.get(mapKey).set(obr.details.plotDetails.leafletId, obr);
            }
            this.completeMap.get(mapKey).set(obr.details.plotDetails.leafletId, obr);
          }
        });

        // Update the current patient's observations
        this.observationService.getAllObservationsByPatientId(this.patient.patientId).subscribe((response) => {
          this.patient.observations = response;
          this.patientService.thePatientHasBeenUpdated(this.patient);
        });

        this.service.observations = savedObrs;
        if (isNullOrUndefined(this.service.serviceNotes)) {
          this.service.serviceNotes = [updatedServiceNote];
        } else {
          this.service.serviceNotes[0] = updatedServiceNote;
        }

        this.serviceEventService.emitTreatmentSaved(this.service, isClosed);
        this.currentDataService.treatmentIsDirty = false;
        this.loading = false;
      });
  }

  updateChargeAmount(e) {
    this.chargeAmount = e;
  }

  onSaveAsFavourite() {
    if (this.plottingActive) this.plottingEventService.stopPinDrop();
    // Don't let users save favourites that don't have any observations
    if (this.completeMap.size === 0) {
      this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Error',
          content: 'You must have at least one Treatment Detail before creating a Favourite!',
          confirmButtonText: 'Ok',
        },
      });
    } else {
      const dialogRef = this.dialog.open(GenericInputComponent, {
        width: '300px',
        data: {
          title: 'Add to Favourites',
          inputLabel: 'Name',
          confirmButtonText: 'Save',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((favouriteName) => {
          if (favouriteName !== 'cancel') {
            // Get the current service
            this.servicesService.getServiceById(this.serviceId).subscribe((currentService: Service) => {
              // Do a "deep-copy" of the current service
              const favService = JSON.parse(JSON.stringify(currentService));
              favService.serviceId = 0;
              favService.subType = 'Favourite';
              favService.signedById = this.usersService.loggedInUser.id; //using signedById because favourite service will never be used by an appointment so this value is unused for the regular circumastance
              favService.category = null;
              favService.observations = null;
              favService.serviceResources = [];
              if (!isNullOrUndefined(favouriteName)) {
                favService.serviceName = favouriteName;
              }
              // Save the service so that we have the service id so we can add the observations
              this.servicesService.addService(favService).subscribe((favService) => {
                //Create a UserFavouriteService to keep track of order
                var userFavService: UserFavouriteService = {
                  userId: this.usersService.loggedInUser.id,
                  serviceId: favService.serviceId,
                  order: 99,
                };
                this.favouriteServicesService.addFavouriteService(userFavService).subscribe();

                const observations: Observation[] = [];
                this.completeMap.forEach((areaDetails: Map<number, Observation>) => {
                  areaDetails.forEach((obr: Observation) => {
                    const obrToSave = JSON.parse(JSON.stringify(obr));
                    obrToSave.id = 0;
                    obrToSave.patientId = this.templatePatient.patientId;
                    obrToSave.patient = null;
                    obrToSave.serviceId = favService.serviceId;
                    obrToSave.dateCreated = new Date();
                    obrToSave.dateUpdated = new Date();
                    observations.push(obrToSave);
                  });
                });
                this.observationService.addObservations(observations, favService.serviceId).subscribe(() => {
                  favService.chargeAmount = this.chargeAmount;
                  this.servicesService.updateService(favService).subscribe(() => {
                    this.currentDataService.favouritesDataHasBeenUpdated(null);
                  });
                });
              });
            });
          }
        });
    }
  }

  onDeleteTreatment(mapKey: string) {
    const leafletIds: number[] = [];
    this.completeMap.get(mapKey).forEach((obr: Observation, leafletId: number) => {
      leafletIds.push(leafletId);
      // The obr has been saved in the db so we need to delete it
      if (obr.id > 0) {
        this.obrIdsToDelete.push(obr.id);
      }
    });

    this.plottingEventService.deletePoints(leafletIds, mapKey);
    this.completeMap.delete(mapKey);
    this.fillerMap.delete(mapKey);
    this.toxinMap.delete(mapKey);
    this.deoxycholateMap.delete(mapKey);
    this.serviceEventService.treatmentObservationsGroupDeleted.next(mapKey);
    this.currentDataService.treatmentIsDirty = true;
  }

  onAddPin(mapKey: string) {
    if (this.activePlotKey === mapKey) {
      this.plottingEventService.stopPinDrop();
    } else {
      const obr = JSON.parse(JSON.stringify(this.completeMap.get(mapKey).values().next().value));
      obr.id = 0;
      obr.unit = null;
      this.plottingEventService.startPinDrop(obr);
      this.activePlotKey = mapKey;
    }
  }

  onGetMapKey(obr: Observation): string {
    // mapKey is area/injected/value
    return this.observationService.getObrKey(obr);
  }

  onGetArea(mapKey: string): string {
    // mapKey is area/injected/value
    const observationListStatic = this.observationListItemsService.observationListStatic;
    const areaId = parseInt(mapKey.split('/')[0], 10);
    return observationListStatic.get(areaId)?.name;
  }

  onGetInjected(mapKey: string): string {
    // mapKey is area/injected/value
    const injectedId = parseInt(mapKey.split('/')[1], 10);
    const observationListItem = this.observationListItemsService.observationListItems.get(injectedId);
    return observationListItem?.displayName;
  }

  onGetInjectedAmount(mapKey: string): number {
    // mapKey is area/injected/value
    return parseInt(mapKey.split('/')[2], 10);
  }

  onGetInjectedUnits(mapKey: string): string {
    const obr = this.completeMap.get(mapKey).values().next().value;
    const observationUnits = this.observationUnitsService.observationUnits;
    return observationUnits.get(obr.unitId)?.name;
  }

  onGetInjectedUnitsCount(mapKey: string) {
    return Array.from(this.completeMap.get(mapKey).values()).reduce(
      (prev, current) => +(prev + +current['value']).toFixed(2),
      0
    );
  }

  onGetInjectedUnitsPrice(mapKey: string) {
    const price = this.completeMap.get(mapKey).values().next().value.details.isOverrideProductPrice
      ? this.completeMap.get(mapKey).values().next().value.details.overrideProductPrice
      : this.completeMap.get(mapKey).values().next().value.details.productPrice;
    return price;
  }

  onGetInjectedTotalPrice(mapKey: string) {
    return this.onGetInjectedUnitsPrice(mapKey) * this.onGetInjectedUnitsCount(mapKey);
  }

  isPriceOverriden(mapKey: string): boolean {
    const observation = this.completeMap.get(mapKey).values().next().value;
    return observation.details.isOverrideProductPrice || observation.details.isOverrideTotalPrice ? true : false;
  }

  onGetCircleColor(mapKey: string) {
    const obr = this.completeMap.get(mapKey).values().next().value;
    return obr.details.plotDetails.pointColor;
  }

  onGetObservations(mapKey: string) {
    return Array.from(this.completeMap.get(mapKey).values());
  }

  onGetTreatmentObservations() {
    const observations: Observation[] = [];
    this.completeMap.forEach((areaDetails: Map<number, Observation>) => {
      areaDetails.forEach((obr: Observation) => {
        observations.push(JSON.parse(JSON.stringify(obr)));
      });
    });

    return observations;
  }

  disablePlotForTreatment(mapKey: string): boolean {
    if (this.activePlotKey === mapKey) {
      return false;
    }

    if (this.plottingActive) {
      return true;
    }

    if (this.activePlotKey.length === 0) {
      return false;
    }

    return true;
  }

  undoOverridePrice(mapKey: string) {
    Array.from(this.completeMap.get(mapKey).values()).forEach((obr: Observation) => {
      obr.details.overrideProductPrice = null;
      obr.details.overrideTotalPrice = null;
      obr.details.isOverrideProductPrice = false;
      obr.details.isOverrideTotalPrice = false;
    });

    this.currentDataService.treatmentIsDirty = true;
    const obrGroup = new TreatmentObservationGroup({
      key: mapKey,
      observations: Array.from(this.completeMap.get(mapKey).values()),
    });
    this.serviceEventService.recalculateTotal.next(obrGroup);
    this.serviceEventService.billingObservationsOverridden.next(obrGroup);
    this.serviceEventService.observationTotalOverrideCancelled.next(obrGroup);
    this.serviceEventService.observationUnitPriceOverrideCancelled.next(obrGroup);
  }

  public onToggleView() {
    if (!this.currentDataService.treatmentIsDirty || !this.service.isLocked) {
      if (this.isTreatmentPlanning) {
        this.router.navigateByUrl(
          `/schedule/(action-panel:patient/${this.patient.patientId}_patientprofiletab/patienttabs/patienttreatmentplantab/overview)`
        );
      } else {
        this.router.navigateByUrl(
          `/schedule/(action-panel:patient/${this.patient.patientId}_patientprofiletab/patienttabs/patientcharttab/overview)`
        );
      }
    }
  }

  toggleSelection(toggle: boolean) {
    this.selectedObservationsGrouping = [];
    this.isGrouping = toggle;
    this.isToxinSelected = false;
  }

  addPriceGrouping() {
    const dialogRef = this.dialog.open(GroupPricingObservationsDialogComponent, {
      data: {
        title: 'Group Pricing',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result !== 'cancel') {
          this.isGrouping = false;
          var groupName = result.groupName != '' ? result.groupName : 'Group ' + (this.groupedMap.size + 1);
          var unitsTotal = 0;
          var groupKey = groupName + '/' + result.groupPrice;
          this.groupedMap.set(groupKey, new Map());

          this.selectedObservationsGrouping.forEach((key) => {
            unitsTotal += this.onGetInjectedUnitsCount(key);
          });

          var pricePer = result.groupPrice / unitsTotal;
          this.selectedObservationsGrouping.forEach((key) => {
            this.groupedMap.get(groupKey).set(key, this.completeMap.get(key));

            this.toxinMap.delete(key);
            this.fillerMap.delete(key);
            this.deoxycholateMap.delete(key);

            this.completeMap.get(key).forEach((o) => {
              o.details.isOverrideProductPrice = true;
              o.details.isOverrideTotalPrice = false;
              o.details.overrideProductPrice = pricePer;
              o.details.groupName = groupKey;
            });

            this.currentDataService.treatmentIsDirty = true;

            const obrGroup = new TreatmentObservationGroup({ key: key, observations: this.onGetObservations(key) });
            this.serviceEventService.recalculateTotal.next(obrGroup);
            this.serviceEventService.billingObservationsOverridden.next(obrGroup);
            this.serviceEventService.observationUnitPriceOverriden.next(obrGroup);
          });
        }
      });
  }

  onSelection(obsKey, observations) {
    if (this.selectedObservationsGrouping.includes(obsKey)) {
      this.selectedObservationsGrouping = this.selectedObservationsGrouping.filter((o) => o != obsKey);
    } else {
      var obsArr = observations.entries().next().value;
      if (obsArr[1].name === TreatmentType.Toxins) {
        this.isToxinSelected = true;
      } else {
        this.isToxinSelected = false;
      }
      this.selectedObservationsGrouping.push(obsKey);
    }
  }

  removePriceGrouping(mapKey) {
    var toRemove = this.groupedMap.get(mapKey);
    this.groupedMap.delete(mapKey);
    toRemove.forEach((val, key) => {
      var obsArr = val.entries().next().value;
      if (obsArr[1].name === TreatmentType.Toxins) {
        this.toxinMap.set(key, val);
      } else {
        this.fillerMap.set(key, val);
      }

      this.completeMap.get(key).forEach((o) => {
        o.details.isOverrideProductPrice = null;
        o.details.isOverrideTotalPrice = null;
        o.details.overrideProductPrice = null;
        o.details.groupName = null;
      });
      this.currentDataService.treatmentIsDirty = true;

      const obrGroup = new TreatmentObservationGroup({ key: key, observations: this.onGetObservations(key) });
      this.serviceEventService.recalculateTotal.next(obrGroup);
      this.serviceEventService.billingObservationsOverridden.next(obrGroup);
      this.serviceEventService.observationUnitPriceOverriden.next(obrGroup);
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
