import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Claim, Role, User } from '@models/user';
import { Observable } from 'rxjs';
import { authRoles } from './auth-permissions';

@Injectable({
  providedIn: 'root',
})
export class AuthRoleClaimsService {
  constructor(private http: HttpClient) {}

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(environment.baseUrl + 'api/AuthRoles');
  }

  getClaims(): Observable<Claim[]> {
    return this.http.get<Claim[]>(environment.baseUrl + 'api/AuthRoles/Claims');
  }

  getRoleClaims(roleId: string): Observable<Claim[]> {
    return this.http.get<Claim[]>(environment.baseUrl + `api/AuthRoles/RoleClaims/${roleId}`);
  }

  setRoleClaim(roleId: string, claim: Claim): Observable<void> {
    return this.http.put<void>(environment.baseUrl + `api/AuthRoles/AddRoleClaim/${roleId}`, claim);
  }

  removeRoleClaim(roleId: string, claimValue: string): Observable<void> {
    return this.http.delete<void>(environment.baseUrl + `api/AuthRoles/RemoveRoleClaim/${roleId}/${claimValue}`);
  }

  getUserRoles(userId: string) {
    return this.http.get<string[]>(environment.baseUrl + 'api/AuthRoles/UserRoles/' + userId);
  }

  getUserClaims(userId: string) {
    return this.http.get<Claim[]>(environment.baseUrl + 'api/AuthRoles/UserClaims/' + userId);
  }

  setUserClaims(userId: string, claimValues: string[]) {
    return this.http.put(environment.baseUrl + 'api/AuthRoles/AssignClaimsToUser/' + userId, claimValues);
  }

  removeUserClaim(userId: string, claimValue: string) {
    return this.http.delete(environment.baseUrl + `api/AuthRoles/RemoveUserClaim/${userId}/${claimValue}`);
  }

  updateUserRoles(user: User, roles: string[]) {
    return this.http.put<void>(environment.baseUrl + 'api/AuthRoles/AssignRolesToUser/' + user.id, roles);
  }
}
