<mat-expansion-panel [expanded]="true" class="print-element">
  <ngx-loading [show]="isLoading"></ngx-loading>
  <div class="service-chart-color"></div>
  <mat-expansion-panel-header
    [collapsedHeight]="'auto'"
    [expandedHeight]="'auto'"
    #panelHeader
    (click)="panelHeader._toggle()"
  >
    <mat-panel-title>
      <div class="d-flex flex-column panel-box px-3 pt-2 pb-2">
        <!-- Appointment Template -->
        <ng-container>
          <!-- Header row of sc entry -->
          <div class="row align-items-center mb-2 mx-0">
            <div class="col-2 align-items-center pl-0">
              <span *ngIf="chartEntry" class="font-weight-normal medium">
                {{ chartEntry?.date.toString()| convertUTC : true }}
              </span>
            </div>
            <div class="entry-buttons col-4 d-flex justify-content-end align-items-center pr-0 ml-auto fit-content">
              <ng-container *ngIf="chartEntry">
                <button
                  [title]="showServiceDetail ? 'Collapse Text' : 'Expand Text'"
                  [disabled]="parseInt(existingNote.style.height, 10) <= 64"
                  type="button"
                  class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger chart-entry-button"
                  (click)="showServiceDetail = !showServiceDetail; toggleTextArea()"
                >
                  <i style="font-size: 18px" [class]="showServiceDetail ? 'fas fa-caret-up' : 'fas fa-caret-down'"></i>
                </button>

                <button
                  [title]="!chartEntry?.isLocked ? 'Delete Entry' : 'Note Locked - Cannot Delete'"
                  [disabled]="chartEntry?.isLocked"
                  type="button"
                  class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger chart-entry-button"
                  (click)="deleteNote()"
                >
                  <i class="far fa-trash-alt"></i>
                </button>

                <button
                  title="Entry Unlocked"
                  *ngIf="!chartEntry?.isLocked"
                  [disabled]="true"
                  type="button"
                  class="btn btn-sm em-btn em-btn-green btn-clean bigger chart-entry-button lock-btn"
                >
                  <i class="far fa-lock" title="Entry Unlocked"></i>
                </button>

                <button
                  title="Unlock Entry"
                  *ngIf="chartEntry?.isLocked"
                  (click)="onUnlockClick()"
                  type="button"
                  [attr.data-test-id]="'service-chart-unlock'"
                  [attr.data-test-value]="chartEntry?.patientNoteId"
                  class="btn btn-sm em-btn em-btn-green btn-clean bigger chart-entry-button lock-btn"
                >
                  <i class="fas fa-unlock text-dark" title="Entry Locked"></i>
                </button>
              </ng-container>
            </div>
          </div>

          <!-- Main text area for sc entry -->
          <div class="d-flex flex-wrap negative-right-margin">
            <div
              class="d-flex align-items-center flex-column flex-grow-1 font-weight-normal mr-2 mb-2 medium textarea-wrapper"
              (click)="onTextareaFocus()"
            >
              <textarea
                class="service-chart-input px-2 mx-2 w-100"
                [ngClass]="{
                  'collapsed-area': !showServiceDetail,
                  'mask-fade pointer-cursor': !showServiceDetail && parseInt(existingNote.style.height, 10) > 64,
                  'd-none': !chartEntry?.entryText
                }"
                [value]="chartEntry?.entryText ?? ''"
                [readonly]="true"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                [cdkAutosizeMinRows]="1"
                #existingNote
              ></textarea>
              <textarea
                class="service-chart-input active px-2 pb-2 mx-2 w-100"
                [placeholder]="'Add a note'"
                [ngClass]="{
                  'collapsed-area': !showServiceDetail && chartEntry?.isLocked,
                  'empty-chart-note': !chartEntry?.entryText,
                  'mt-2': chartEntry?.entryText,
                  'd-none': chartEntry?.isLocked
                }"
                [value]="chartEntry?.draftText ?? ''"
                (keydown)="onTextareaKeyDown(); $event.stopPropagation()"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                [cdkAutosizeMinRows]="4"
                #newNote
              ></textarea>
            </div>
            <ng-container *ngFor="let photoDrawing of chartEntry?.photoDrawings">
              <img
                *ngIf="photoDrawing['delete'] !== true"
                class="img-thumb mr-2 mb-2"
                [src]="photoDrawing.photo.filePathThumb | safeResource"
                [alt]="photoDrawing.photo.imageName"
                (click)="openPhotoDrawing(photoDrawing)"
                role="button"
              />
            </ng-container>
          </div>
        </ng-container>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <!-- Footer of sc entry -->
  <div
    *ngIf="chartEntry && !chartEntry?.isLocked"
    class="service-chart-detail pl-3 pr-5 py-2 grey-two-background d-flex align-items-center"
  >
    <!-- Save, Sign, and Sign+Close buttons -->
    <div class="btn-group emr-btn-group note-btn draft-btn">
      <button
        type="button"
        title="Save Draft"
        [disabled]="submitButtons.draft == false"
        class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger save-draft-button chart-entry-button"
        (click)="updateChart(false); submitButtons.draft = false; submitButtons.save = true"
      >
        <i class="fab fa-firstdraft"></i>
      </button>
      <button
        type="button"
        title="Sign & Save"
        [attr.data-test-id]="'service-chart-sign-and-save'"
        [attr.data-test-value]="chartEntry?.patientNoteId"
        class="btn btn-sm em-btn em-btn-green btn-clean mr-2 bigger save-draft-button chart-entry-button"
        (click)="updateChart(true); submitButtons.save = false; submitButtons.draft = false"
        ngbDropdownItem
      >
        <i class="fas fa-signature"></i>
      </button>
      <button
        type="button"
        title="Sign, Save, & Exit"
        class="btn btn-sm em-btn em-btn-green btn-clean bigger save-draft-button chart-entry-button"
        (click)="signSaveExit()"
        ngbDropdownItem
      >
        <i class="fas fa-share-square"></i>
      </button>
    </div>
  </div>
</mat-expansion-panel>
