import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { Form } from '@models/forms/form';
import { PatientForm } from '@models/forms/patient-form';
import { Patient } from '@models/patient';
import { Utils } from 'formiojs';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ClinicsService } from './clinics.service';
import { GeographyService } from './geography.service';
import { PatientService } from './patient.service';
import { UsersService } from './users.service';

interface Dict {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class PatientFormService {
  patientForms$ = new BehaviorSubject<PatientForm[]>([]);
  patientFormSelected$ = new BehaviorSubject<PatientForm>(null);
  formEntry$ = new BehaviorSubject<any>('');
  formDirty$ = new BehaviorSubject<boolean>(false);
  private patientFormSubmitted = new Subject<PatientForm>();
  patientFormSubmitted$ = this.patientFormSubmitted.asObservable();
  appointmentFormSubmitted$ = this.patientFormSubmitted.asObservable();

  private httpBackend: HttpClient;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private patientService: PatientService,
    private geoService: GeographyService,
    private clinicService: ClinicsService,
    private usersService: UsersService
  ) {
    this.httpBackend = new HttpClient(this.handler);
  }

  getPatientForms(patientId: number) {
    return this.http
      .get<PatientForm[]>(environment.baseUrl + 'api/PatientForms/' + patientId)
      .pipe(tap((forms) => this.patientForms$.next(forms ?? [])));
  }

  getPatientFormById(id: number) {
    return this.http.get<PatientForm>(environment.baseUrl + 'api/PatientForms/PatientForm/' + id);
  }

  assignFormToPatient(patientId: number, form: Form) {
    let patientForm = PatientForm.fromForm(patientId, form);
    patientForm = this.preFillForm(patientForm);
    return this.http.post<PatientForm>(environment.baseUrl + 'api/PatientForms', patientForm).pipe(
      tap((postedForm) => {
        this.patientForms$.next(this.patientForms$.getValue().concat(postedForm));
      })
    );
  }

  addPatientForm(patientForm: PatientForm) {
    return this.http.post<PatientForm>(environment.baseUrl + 'api/PatientForms', patientForm).pipe(
      tap((postedForm) => {
        this.patientForms$.next(this.patientForms$.getValue().concat(postedForm));
      })
    );
  }

  updatePatientForm(patientForm: PatientForm, sub?: any) {
    let submission = sub ? sub.data : this.formEntry$.getValue().data;
    if (submission.dateTime) {
      submission.dateTime = new Date().toISOString();
    }
    patientForm.formEntry = JSON.stringify({
      data: submission,
      metadata: { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
    });
    let packageJson = require('./../../../package-lock.json');
    patientForm.rendererVersion = packageJson['packages']['node_modules/@formio/angular']['version'];

    if (patientForm.id) {
      //existing patient form
      return this.http.put<PatientForm>(environment.baseUrl + 'api/PatientForms', patientForm).pipe(
        tap((form) => {
          this.patientForms$.next(this.patientForms$.getValue().map((pf) => (pf.id == form.id ? form : pf)));
          if (this.patientFormSelected$.getValue()?.id == form.id) {
            this.patientFormSelected$.next(form);
          }
          if (form.isSigned) {
            this.patientFormSubmitted.next(form);
          }
        })
      );
    } else {
      //new patient form
      return this.http.post<PatientForm>(environment.baseUrl + 'api/PatientForms', patientForm).pipe(
        tap((form) => {
          this.patientForms$.next(this.patientForms$.getValue().concat(form));
          if (form.isSigned) {
            this.patientFormSubmitted.next(form);
          }
        })
      );
    }
  }

  savePatientForm(submission?: any) {
    let patientForm = this.patientFormSelected$.getValue();
    return this.updatePatientForm(patientForm, submission);
  }

  submitPatientForm(submission?: any) {
    let patientForm = this.patientFormSelected$.getValue();
    patientForm.isSigned = true;
    patientForm.signedDate = new Date();
    return this.updatePatientForm(patientForm, submission);
  }

  deletePatientForm(patientFormId: number) {
    return this.http
      .delete(environment.baseUrl + 'api/PatientForms/' + patientFormId)
      .pipe(tap(() => this.patientForms$.next(this.patientForms$.getValue().filter((pf) => pf.id != patientFormId))));
  }

  createFileUrl(): Observable<string> {
    let form = this.patientFormSelected$.getValue();
    if (form.isSigned) {
      let headers = new HttpHeaders().set('Accept', 'application/pdf, application/octet-stream');
      return this.httpBackend.get(form.blobUrl, { headers: headers, responseType: 'blob' }).map((result) => {
        if (result.type !== 'application/pdf') {
          result = result.slice(0, result.size, 'application/pdf');
        }
        return URL.createObjectURL(result);
      });
    }
    return this.convertFormToPdf(form).map((file) => URL.createObjectURL(file));
  }

  private convertFormToPdf(patientForm: PatientForm): Observable<Blob> {
    return this.http
      .put(environment.baseUrl + 'api/PatientForms/ConvertFormToPdf', patientForm, { responseType: 'blob' })
      .pipe(map((data) => new Blob([data], { type: 'application/pdf' })));
  }

  preFillForm(patientForm: PatientForm, patient: Patient = null): PatientForm {
    let presetFields = {
      ...this.generatePatientFields(patient),
      ...this.generateClinicFields(),
      ...this.generateUserFields(),
      dateTime: moment().format(),
    };

    let patientFormEntry = { data: {}, metadata: {} };
    if (patientForm.formEntry) {
      patientFormEntry = JSON.parse(patientForm.formEntry);
      if (!patientFormEntry.data) {
        patientFormEntry = { data: patientFormEntry, metadata: {} };
      }
    }

    if (!isNullOrUndefined(patientForm.clinicForm.formDefinition) && patientForm.clinicForm.formDefinition !== '') {
      let formDef = JSON.parse(patientForm.clinicForm.formDefinition);
      let formComponents = Utils.flattenComponents(formDef.components, false);
      for (const key in presetFields) {
        if (formComponents[key]) {
          patientFormEntry.data[key] = presetFields[key];
        }
      }
    }

    patientFormEntry.metadata['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    patientForm.formEntry = JSON.stringify(patientFormEntry);
    return patientForm;
  }

  private generatePatientFields(patient: Patient): Dict {
    patient = patient ?? this.patientService.patientPanelPatient;
    let patientFields = {};
    if (patient?.patientId !== 0) {
      patientFields = {
        firstName: patient.firstName,
        lastName: patient.lastName,
        fullPatientName: patient.firstName + ' ' + patient.lastName,
        fullName: patient.firstName + ' ' + patient.lastName, //retroactive support for old doc definitions
        name: patient.firstName + ' ' + patient.lastName, //retroactive support for old doc definitions
        genderString: patient.gender,
        genderMale: patient.gender === 'Male',
        genderFemale: patient.gender === 'Female',
        birthMonth: new Date(patient.birthDate).toISOString(),
        birthDay: new Date(patient.birthDate).toISOString(),
        birthYear: new Date(patient.birthDate).toISOString(),
        healthCareNumber: patient.clientId,
        homePhone: patient.homeNumber,
        mobilePhone: patient.mobileNumber,
        email: patient.email,
      };
      if (patient.address) {
        patientFields = {
          ...patientFields,
          address1: patient.address.address1,
          address2: patient.address.address2,
          city: patient.address.city,
          country: patient.address.country,
          postalCode: patient.address.postalCode,
          province: this.geoService.getProvinceCode(patient.address.province),
        };
      }
    }
    return patientFields;
  }

  private generateClinicFields(): Dict {
    let clinic = this.clinicService.clinic;
    let clinicFields = {};
    if (clinic) {
      clinicFields = {
        clinicPhoneNumber: clinic.phoneNumber,
        clinicFaxNumber: clinic.faxNumber,
        clinicEmail: clinic.email,
      };
      if (clinic.address) {
        clinicFields = {
          ...clinicFields,
          clinicAddress1: clinic.address.address1,
          clinicAddress2: clinic.address.address2,
          clinicCity: clinic.address.city,
          clinicCountry: clinic.address.country,
          clinicPostalCode: clinic.address.postalCode,
          clinicProvince: this.geoService.getProvinceCode(clinic.address.province),
        };
      }
    }
    return clinicFields;
  }

  private generateUserFields(): Dict {
    let user = this.usersService.loggedInUser;
    let userFields = {};
    if (user) {
      userFields = {
        mspNumber: user.provincialBillingNumber,
        mspBillingNumber: user.provincialBillingNumber, //retroactive support for old doc definitions
        cpsbc: user.medicalLicenseNumber,
        physicianFirstName: user.firstName,
        physicianLastName: user.lastName,
        fullPhysicianName: user.firstName + ' ' + user.lastName,
        physicianName: user.firstName + ' ' + user.lastName, //retroactive support for old doc definitions
        userFirstName: user.firstName,
        userLastName: user.lastName,
        userFullName: user.firstName + ' ' + user.lastName,
      };
    }
    return userFields;
  }
}
