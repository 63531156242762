<ngx-loading [show]="loading"></ngx-loading>
<div class="d-flex flex-column h-100">
  <app-error *ngIf="errorMessage" [message]="errorMessage" (onClose)="errorMessage = null" class="mx-1"></app-error>
  <ng-scrollbar class="flex-grow-1 minh-0" [track]="'all'">
    <table class="table table-striped table-bordered mx-1">
      <colgroup>
        <col span="1" class="roles" />
        <col span="1" class="user-role" *ngFor="let user of users" />
      </colgroup>
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col" *ngFor="let user of users">{{ user.firstName + ' ' + user.lastName }}</th>
        </tr>
        <tr *ngIf="selectedClinicId">
          <td></td>
          <td *ngFor="let user of users">
            <button class="btn btn-sm em-btn em-btn-green uppercase" (click)="onCopyClick(user)">
              {{ copyUserId == null ? 'Copy' : copyUserId === user.id ? 'Cancel' : 'Paste' }}
            </button>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let role of roles | keyvalue : valueAscOrder">
          <th scope="row">
            <mat-expansion-panel
              (opened)="roleExpanded.set(role.key, true)"
              (closed)="roleExpanded.set(role.key, false)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ role.value | spaceTitleCase }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table>
                <tr *ngFor="let claim of roleClaims.get(role.key)">
                  <td>{{ claim.value | spaceTitleCase }}</td>
                </tr>
              </table>
            </mat-expansion-panel>
          </th>
          <td *ngFor="let user of users">
            <mat-expansion-panel hideToggle disabled [expanded]="roleExpanded.get(role.key)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-checkbox
                    [checked]="userHasRole(user.id, role.key)"
                    [disabled]="copyUserId !== null"
                    (change)="toggleUserRole(user, role.key)"
                    [indeterminate]="userHasRole(user.id, role.key) && !userRoleHasAllClaims(user.id, role.key)"
                  >
                  </mat-checkbox>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table>
                <tr *ngFor="let claim of roleClaims.get(role.key)">
                  <td>
                    <mat-checkbox
                      [checked]="userHasClaim(user.id, claim.value)"
                      [disabled]="copyUserId !== null"
                      (change)="toggleRoleClaim(user, role.key, claim.value)"
                    >
                    </mat-checkbox>
                  </td>
                </tr>
              </table>
            </mat-expansion-panel>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-scrollbar>
</div>
