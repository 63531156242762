import { Appointment } from '@models/scheduler/event';
import { Service } from '../service/service';
import { TreatmentState } from './treatment-state';
import { isNullOrUndefined } from '@app/shared/helpers';
import { PaymentStatus } from '@models/scheduler/payment-status';

export class PlannedTreatment {
  public id: number;
  // Service is the planned treatment
  public service: Service;
  // scheduledService is the service that the patient has booked to have the treatment plan applied
  public scheduledService: Service;
  // ScheduledServiceId is the service that the patient has booked to have the treatment plan applied
  public scheduledServiceId: number;
  public concern: string;
  public notes: string;
  public rank: number;
  public treatmentState: TreatmentState;
  public createdDate: string;
  public updatedDate: string;
  public plannedDate: string;
  public dueDate: string;
  public treatmentColour: string;
  public paymentStatus: PaymentStatus = PaymentStatus.Unpaid;
  public plannedTreatmentMultipleId: number;
  public isPlannedTreatmentMultiple: boolean;
  public plannedTreatmentMultipleQuantity: number;
  public treatmentAppointments?: Appointment[];
  public appointmentCount?: number;

  public constructor(init?: Partial<PlannedTreatment>) {
    Object.assign(this, init);
    if (isNullOrUndefined(this.paymentStatus)) {
      this.paymentStatus = PaymentStatus.Unpaid;
    }
    if (isNullOrUndefined(this.plannedTreatmentMultipleId)) {
      this.isPlannedTreatmentMultiple = false;
    }
  }
}
