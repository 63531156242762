<div class="d-inline-flex flex-column card-info">
  <div *ngIf="!showSmallBrandLogo" class="mb-1">
    <i class="fa-3x fab" [class]="PaymentHelper.getCreditCardIcon(card.card_brand)"></i>
  </div>
  <div>
    <span class="uppercase">{{ getCreditCardString(card) }}</span> ending in {{ card?.last_4 }}
  </div>
  <div>
    Expires
    {{ card?.exp_month | number : '2.0-0' }}/{{ card?.exp_year.toString() | slice : 2 }}
  </div>
</div>
