import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
import { Subject, zip, of } from 'rxjs';
import { takeUntil, switchMap, mergeMap } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';

import { PatientService } from '@services/patient.service';
import { CurrentDataService } from '@services/currentData.service';
import { ObservationService } from '@services/observation.service';
import { PlottingEventService } from '@services/plotting-event.service';
import { AppointmentService } from '@services/appointments.service';
import { ServiceEventService } from '@services/service-event.service';
import { CoolsculptingFormService } from '@services/service-detail/coolsculpting-form.service';
import { ServicesService } from '@services/services.service';
import { Service } from '@models/service/service';
import { ObservationType, Observation, ObservationTypes } from '@models/observation/observation';
import { Patient } from '@models/patient';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { TreatmentObservationGroup } from '@models/treatment-planning/treatment-observation-group';
import { ServiceEventType } from '@models/service/service-event-type';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { GenericInputComponent } from '@app/management/dialogs/generic-input/generic-input.component';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { ServiceNote } from '@models/service/service-note';
import { UsersService } from '@services/users.service';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { UserFavouriteService } from '@models/service/user-favourite-service';
import { FavouriteServicesService } from '../../../../../../../services/favourite-services.service';
import { PaymentStatus } from '@models/scheduler/payment-status';
@Component({
  selector: 'app-coolsculpting',
  templateUrl: './coolsculpting.component.html',
  styleUrls: ['./coolsculpting.component.less'],
})
export class CoolsculptingComponent implements OnInit, OnDestroy {
  @Input() service: Service;
  @Input() serviceId: number;
  @Input() observationType: ObservationType;

  isServicePaid = false;
  loading = false;
  navLocked = false;
  patient: Patient;
  templatePatient: Patient;
  obrIdsToDelete: number[] = [];
  treatmentDate: moment.Moment;
  unsub: Subject<void> = new Subject<void>();
  treatmentMap: Map<string, Map<number, Observation>> = new Map();
  annotationMap: Map<number, Observation> = new Map();
  plannedTreatment: Map<string, boolean> = new Map();
  toggleMap: Map<string, boolean> = new Map();
  isTreatmentPlan: boolean;
  chargeAmount: number;

  @Input() associatedPlannedTreatment: PlannedTreatment;
  // Plotting settings
  activePlotKey = '';
  plottingActive = false;
  initialPlot = false;

  // Treatment note
  serviceNoteText: string;

  PaymentStatus = PaymentStatus;

  constructor(
    private patientService: PatientService,
    public currentDataService: CurrentDataService,
    private observationService: ObservationService,
    private plottingEventService: PlottingEventService,
    private appointmentsService: AppointmentService,
    private serviceEventService: ServiceEventService,
    public coolsculptingFormService: CoolsculptingFormService,
    private dialog: MatDialog,
    private servicesService: ServicesService,
    private route: ActivatedRoute,
    private router: Router,
    private treatmentPlanService: TreatmentPlanService,
    private usersService: UsersService,
    private favouriteServicesService: FavouriteServicesService
  ) {
    this.patient = this.patientService.patientPanelPatient;
    this.patientService.getTemplatePatient().subscribe((tp) => {
      this.templatePatient = tp;
    });
    this.isTreatmentPlan = false;
  }

  ngOnInit() {
    this.isServicePaid = this.servicesService.isPaid;
    this.isTreatmentPlan = this.route.snapshot.params.treatmentPlanning === 'true';
    this.serviceNoteText =
      !isNullOrUndefined(this.service.serviceNotes) && this.service.serviceNotes.length > 0
        ? this.service.serviceNotes[0].entryText
        : '';

    // find any existing coolsculpting observations for this service
    let patientObrs: Observation[] = [];
    if (!isNullOrUndefined(this.patient.observations)) {
      patientObrs = this.patient.observations.filter((obr) => obr.serviceId === this.serviceId);
    }

    this.coolsculptingFormService.saveTrigger.pipe(takeUntil(this.unsub)).subscribe(async () => {
      await this.onSave(false);
      this.coolsculptingFormService.saveTriggerComplete.next();
    });
    this.serviceEventService.billingObservationsOverridden.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.serviceEventService.recalculateTreatmentBilling.next(this.onGetTreatmentObservations());
    });

    this.initPlottingSubscription();
    this.initServiceEventSubscription();

    this.treatmentPlanService
      .getTreatmentPlanByPatientId(this.patient.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((tp: TreatmentPlan) => {
        if (!isNullOrUndefined(tp) && !isNullOrUndefined(tp.plannedTreatments)) {
          tp.plannedTreatments.forEach((treatment) => {
            if (!isNullOrUndefined(treatment.service.observations)) {
              treatment.service.observations.forEach((item) => {
                const key = this.coolsculptingFormService.onGetMapKey(item);
                this.plannedTreatment.set(key, true);
              });
            }
          });
        }
      });

    this.currentDataService.preventUserFromNavigation$.pipe(takeUntil(this.unsub)).subscribe((navLocked) => {
      this.navLocked = navLocked;
    });

    this.appointmentsService
      .getAppointmentsByPatientId(this.patient.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((a) => {
        const appt = a.find((s) => s.serviceId === this.serviceId);

        if (!isNullOrUndefined(appt)) {
          this.treatmentDate = moment(appt.date).add(appt.startTime);
        } else {
          this.treatmentDate = moment();
        }

        if (patientObrs.length > 0) {
          this.initialPlot = true;
        }

        this.plottingEventService.plotObservations(patientObrs, !this.service.isLocked);
      });

    this.currentDataService.treatmentIsDirty = false;
  }

  initPlottingSubscription() {
    this.plottingEventService
      .getPlotSource()
      .pipe(takeUntil(this.unsub))
      .subscribe(async (response) => {
        switch (response.event) {
          case ObrPlottingEventType.SendDetails: {
            // let applyToPT = false;
            // try{
            //   applyToPT = this.associatedPlannedTreatment != null && [...response.details.entries()][0].some(entry => entry.id ==0);
            // }
            // catch(e){

            // }
            // if (applyToPT){

            //   const dialogRef = this.dialog.open(GenericDialogComponent, {
            //     width: '300px',
            //     data: {
            //       title: 'Adding To Planned Treatment',
            //       content: 'You are adding observations to the underlying planned treatment. If you wish to apply one or more of these observations during this appointment, you must select "OK" on this dialog and choose the relevent observations from assessment details to apply.',
            //       confirmButtonText: 'OK'
            //     }
            //   });

            //   dialogRef.afterClosed().pipe(takeUntil(this.unsub)).subscribe(async confirm => {
            //     if (confirm !== 'cancel') {
            //       let observations = [];
            //       response.details.forEach((obr: Observation, leafletId: number) => {
            //         obr.serviceId = this.associatedPlannedTreatment.service.serviceId;
            //         // obr.details.avaliable = 0;

            //         observations.push(obr);
            //       });
            //       let temp_srv : Service = new Service();
            //       temp_srv.observations = observations.concat(this.associatedPlannedTreatment.service.observations);
            //       this.associatedPlannedTreatment.service.chargeAmount = temp_srv.getChargeAmount();

            //       await this.observationService.addObservations(observations, true).toPromise();
            //       await this.servicesService.updateService( this.associatedPlannedTreatment.service).toPromise();
            //       await this.treatmentPlanService.updateTreatmentState(this.associatedPlannedTreatment.id, TreatmentState.Unplanned, new Date()).toPromise(); //Always unplanned because we are adding new observations to TX plan which must be scheduled
            //       this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient.patientId).subscribe((treatmentPlan) => {

            //         this.treatmentPlanService.treatmentPlanUpdated$.next(treatmentPlan);
            //         this.invoiceService.invoiceUpdated.next();
            //       })
            //     }
            //   });
            // }
            // else{
            this.activePlotKey = '';
            if (this.initialPlot) {
              this.initialPlot = false;
            } else {
              this.currentDataService.treatmentIsDirty = true;
            }
            response.details.forEach((obr: Observation, leafletId: number) => {
              const key = this.coolsculptingFormService.onGetMapKey(obr);
              if (!isNullOrUndefined(this.treatmentMap.get(key))) {
                // Only overwrite the plot details
                let oldObr = this.treatmentMap.get(key).get(leafletId);
                if (!isNullOrUndefined(oldObr)) {
                  oldObr.details.plotDetails = { ...oldObr.details.plotDetails, ...obr.details.plotDetails };
                } else {
                  oldObr = obr;
                }
                this.treatmentMap.get(key).set(leafletId, oldObr);
              } else {
                // new plotted point
                this.treatmentMap.set(key, new Map());
                this.treatmentMap.get(key).set(leafletId, obr);

                // include tracking for whether or not the treatment is expanded
                this.toggleMap.set(key, true);
              }
              const obrGroup = new TreatmentObservationGroup({ key: key, observations: this.onGetObservations(key) });
              this.serviceEventService.recalculateObservationBilling.next(obrGroup);
            });
            this.serviceEventService.recalculateTreatmentBilling.next(this.onGetTreatmentObservations());

            break;
          }
          case ObrPlottingEventType.SendAnnotations: {
            response.details.forEach((annotationDetails: any, leafletId: number) => {
              if (!isNullOrUndefined(annotationDetails.id)) {
                this.annotationMap.set(leafletId, annotationDetails);
                if (this.initialPlot) {
                  this.initialPlot = false;
                } else {
                  this.currentDataService.treatmentIsDirty = true;
                }
              } else {
                const obr = new Observation({
                  id: 0,
                  typeId: this.observationType.id,
                  name: 'Annotation',
                  details: annotationDetails,
                  patientId: this.patient.patientId,
                  serviceId: this.serviceId,
                });

                this.annotationMap.set(leafletId, obr);
                this.currentDataService.treatmentIsDirty = true;
              }
            });
            break;
          }
          case ObrPlottingEventType.DeleteAnnotations: {
            response.leafletIds.forEach((leafletId: number) => {
              const obr = this.annotationMap.get(leafletId);
              if (!isNullOrUndefined(obr.id) && obr.id !== 0) {
                this.obrIdsToDelete.push(obr.id);
                this.currentDataService.treatmentIsDirty = true;
              }
              this.annotationMap.delete(leafletId);
            });
            break;
          }
          default:
            break;
        }
      });

    this.plottingEventService
      .getObservationSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ObrPlottingEventType.StartPinDrop: {
            this.plottingActive = true;
            break;
          }
          case ObrPlottingEventType.StopPinDrop: {
            this.plottingActive = false;
            break;
          }
          default:
            break;
        }
      });
  }

  initServiceEventSubscription() {
    this.serviceEventService
      .getServiceSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ServiceEventType.ApplyTreatment: {
            if (response.obrType === ObservationTypes.Coolsculpting) {
              this.onApplyTreatment(response.service);
            }
          }
        }
      });
  }

  async onSave(isClosed: boolean) {
    this.loading = true;

    this.currentDataService.setPreventUserFromNavigation(true);
    const serviceNote = new ServiceNote({
      serviceNoteId:
        !isNullOrUndefined(this.service.serviceNotes) && this.service.serviceNotes.length > 0
          ? this.service.serviceNotes[0].serviceNoteId
          : 0,
      serviceId: this.service.serviceId,
      entryDate: new Date(),
      enteredBy: this.usersService.loggedInUser.firstName + ' ' + this.usersService.loggedInUser.lastName,
      entryText: this.serviceNoteText,
    });

    const observations: Observation[] = [];
    this.treatmentMap.forEach((areaDetails: Map<number, Observation>) => {
      areaDetails.forEach((obr: Observation) => {
        // const leafletId = obr.details.plotDetails.leafletId;
        const cleanedObr = JSON.parse(JSON.stringify(obr));
        // cleanedObr.details.plotDetails.leafletId = leafletId;
        observations.push(cleanedObr);
      });
    });
    this.annotationMap.forEach((annotationDetails: Observation) => {
      observations.push(JSON.parse(JSON.stringify(annotationDetails)));
    });

    this.observationService
      .deleteObservations(this.obrIdsToDelete, this.service.serviceId)
      .pipe(
        switchMap((x) => {
          return zip(
            of(x),
            this.observationService.addObservations(observations, this.service.serviceId, this.isTreatmentPlan),
            this.servicesService.updateServiceNote(serviceNote)
          );
        })
      )
      .subscribe(([_, savedObrs, updatedServiceNote]) => {
        this.obrIdsToDelete = [];

        // Update the maps accordingly
        this.treatmentMap.clear();
        this.treatmentMap = new Map();
        savedObrs.forEach((obr) => {
          if (obr.name === 'Annotation') {
            this.annotationMap.set(obr.details.leafletId, obr);
          } else {
            const mapKey = this.coolsculptingFormService.onGetMapKey(obr);
            if (isNullOrUndefined(this.treatmentMap.get(mapKey))) {
              this.treatmentMap.set(mapKey, new Map());
            }
            this.treatmentMap.get(mapKey).set(obr.details.plotDetails.leafletId, obr);
          }
        });

        // // Notify the assessment feed that the treatment has been saved
        // this.coolsculptingFormService.treatmentSaved.next();

        // Update the current patient's observations
        this.observationService.getAllObservationsByPatientId(this.patient.patientId).subscribe((response) => {
          this.patient.observations = response;
          this.patientService.thePatientHasBeenUpdated(this.patient);
        });
        // this.currentDataService.treatmentIsDirty = false;

        this.service.observations = savedObrs;
        if (isNullOrUndefined(this.service.serviceNotes)) {
          this.service.serviceNotes = [updatedServiceNote];
        } else {
          this.service.serviceNotes[0] = updatedServiceNote;
        }

        this.serviceEventService.emitTreatmentSaved(this.service, isClosed);
        this.currentDataService.treatmentIsDirty = false;
        this.loading = false;
      });
  }

  onDeleteTreatment(mapKey: string) {
    // Get a list of all the leaflet ids to be deleted
    const leafletIds: number[] = [];
    this.treatmentMap.get(mapKey).forEach((obr: Observation, leafletId: number) => {
      leafletIds.push(leafletId);
      // The obr has been saved in the db so we need to delete it
      if (obr.id > 0) {
        this.obrIdsToDelete.push(obr.id);
      }
      if (!isNullOrUndefined(obr.details.treatmentReference)) {
        this.coolsculptingFormService.treatmentRemoved.next(obr.details.treatmentReference);
      }
    });

    this.plottingEventService.deletePoints(leafletIds, mapKey);
    this.treatmentMap.delete(mapKey);
    this.toggleMap.delete(mapKey);
    this.serviceEventService.treatmentObservationsGroupDeleted.next(mapKey);
    this.currentDataService.treatmentIsDirty = true;
  }

  updateChargeAmount(e) {
    this.chargeAmount = e;
  }

  onSaveAsFavourite() {
    if (this.plottingActive) this.plottingEventService.stopPinDrop();
    if (this.treatmentMap.size === 0) {
      this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Error',
          content: 'You must have at least one Treatment Detail before creating a Favourite!',
          confirmButtonText: 'Ok',
        },
      });
    } else {
      const dialogRef = this.dialog.open(GenericInputComponent, {
        width: '300px',
        data: {
          title: 'Add to Favourites',
          inputLabel: 'Name',
          confirmButtonText: 'Save',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((favouriteName) => {
          if (favouriteName !== 'cancel') {
            // Get the current service
            this.servicesService.getServiceById(this.serviceId).subscribe((currentService: Service) => {
              // Do a "deep-copy" of the current service
              const favService = JSON.parse(JSON.stringify(currentService));
              favService.serviceId = 0;
              favService.subType = 'Favourite';
              favService.signedById = this.usersService.loggedInUser.id; //using signedById because favourite service will never be used by an appointment so this value is unused for the regular circumastance
              favService.category = null;
              favService.observations = null;
              favService.serviceResources = [];
              favService.chargeAmount = this.chargeAmount;
              if (!isNullOrUndefined(favouriteName)) {
                favService.serviceName = favouriteName;
              }
              // Save the service so that we have the service id so we can add the observations
              this.servicesService.addService(favService).subscribe((favService) => {
                //Create a UserFavouriteService to keep track of order
                var userFavService: UserFavouriteService = {
                  userId: this.usersService.loggedInUser.id,
                  serviceId: favService.serviceId,
                  order: 99,
                };
                this.favouriteServicesService.addFavouriteService(userFavService).subscribe();

                const observations: Observation[] = [];
                this.treatmentMap.forEach((areaDetails: Map<number, Observation>) => {
                  areaDetails.forEach((obr: Observation) => {
                    const obrToSave = JSON.parse(JSON.stringify(obr));
                    obrToSave.id = 0;
                    obrToSave.patientId = this.templatePatient.patientId;
                    obrToSave.patient = null;
                    obrToSave.serviceId = favService.serviceId;
                    obrToSave.dateCreated = new Date();
                    obrToSave.dateUpdated = new Date();
                    obrToSave.details.massage = false;
                    obrToSave.details.treatmentReference = null;
                    observations.push(obrToSave);
                  });
                });
                this.observationService
                  .addObservations(observations, favService.serviceId)
                  .pipe(mergeMap(() => this.servicesService.updateService(favService)))
                  .subscribe(() => {
                    this.currentDataService.favouritesDataHasBeenUpdated(null);
                  });
              });
            });
          }
        });
    }
  }

  onToggleMassage(mapKey: string) {
    // Loop through each observation and toggle it's massage value
    this.treatmentMap.get(mapKey).forEach((obr) => {
      obr.details.massage = !obr.details.massage;
    });
    this.currentDataService.treatmentIsDirty = true;
  }

  onToggleTreatment(mapKey: string) {
    this.toggleMap.set(mapKey, !this.toggleMap.get(mapKey));
  }

  isTreatmentExpanded(mapKey: string): boolean {
    return this.toggleMap.get(mapKey);
  }

  onGetCycles(mapKey: string): number {
    return this.coolsculptingFormService.onGetCycles(Array.from(this.treatmentMap.get(mapKey).values()));
  }

  onGetCircleColor(mapKey: string) {
    const obr = this.treatmentMap.get(mapKey).values().next().value;
    return obr.details.plotDetails.pointColor;
  }

  isUnitPriceOverriden(mapKey: string): boolean {
    return this.treatmentMap.get(mapKey).values().next().value.details.overrideProductPrice ? true : false;
  }

  isPriceOverriden(mapKey: string): boolean {
    const observation = this.treatmentMap.get(mapKey).values().next().value;
    return observation.details.isOverrideProductPrice || observation.details.isOverrideTotalPrice ? true : false;
  }

  onGetMassage(mapKey: string) {
    const obr = this.treatmentMap.get(mapKey).values().next().value;
    return obr.details.massage;
  }

  onGetObservations(mapKey: string) {
    const obrs = Array.from(this.treatmentMap.get(mapKey).values());
    return obrs;
  }

  onGetTreatmentObservations() {
    const observations: Observation[] = [];
    this.treatmentMap.forEach((areaDetails: Map<number, Observation>) => {
      areaDetails.forEach((obr: Observation) => {
        observations.push(JSON.parse(JSON.stringify(obr)));
      });
    });

    return observations;
  }

  onApplyTreatment(service: Service) {
    // if (this.associatedPlannedTreatment && !this.isTreatmentPlan){
    //   const dialogRef = this.dialog.open(GenericDialogComponent, {
    //     width: '300px',
    //     data: {
    //       title: 'Add To Assessment?',
    //       content: 'You are adding cycles to the assessment.',
    //       confirmButtonText: 'OK'
    //     }
    //   });

    //   dialogRef.afterClosed().pipe(takeUntil(this.unsub)).subscribe(async confirm => {
    //     if (confirm !== 'cancel') {
    //       // Save the obs to pt treatment
    //       const observations = this.servicesService.applyTreatmentObservationsToService(service, this.associatedPlannedTreatment.service, this.patient);
    //       let temp_srv : Service = new Service();
    //       temp_srv.observations = observations.concat(this.associatedPlannedTreatment.service.observations);
    //       this.associatedPlannedTreatment.service.chargeAmount = temp_srv.getChargeAmount();

    //       await this.observationService.addObservations(observations, true).toPromise();
    //       await this.servicesService.updateService( this.associatedPlannedTreatment.service).toPromise();
    //       await this.treatmentPlanService.updateTreatmentState(this.associatedPlannedTreatment.id, TreatmentState.Unplanned, new Date()).toPromise(); //Always unplanned because we are adding new observations to TX plan which must be scheduled
    //       this.treatmentPlanService.getTreatmentPlanByPatientId(this.patient.patientId).subscribe((treatmentPlan) => {

    //         this.treatmentPlanService.treatmentPlanUpdated$.next(treatmentPlan);
    //         this.invoiceService.invoiceUpdated.next();
    //       })

    //     }
    //   })
    // }
    // else{
    const observations = this.servicesService.applyTreatmentObservationsToService(service, this.service, this.patient);
    this.plottingEventService.plotObservations(observations, !this.service.isLocked);
    const serviceNoteText = this.servicesService.applyServiceNotesToService(service, this.service);
    if (serviceNoteText.length > 0) {
      if (this.serviceNoteText.length > 0) {
        this.serviceNoteText += '\n' + serviceNoteText;
      } else {
        this.serviceNoteText = serviceNoteText;
      }
    }

    // }
  }

  public undoOverridePrice(mapKey: string) {
    Array.from(this.treatmentMap.get(mapKey).values()).forEach((obr: Observation) => {
      obr.details.overrideProductPrice = null;
      obr.details.isOverrideProductPrice = false;
      obr.details.isOverrideTotalPrice = false;
      obr.details.overrideTotalPrice = null;
    });

    this.currentDataService.treatmentIsDirty = true;
    const obrGroup = new TreatmentObservationGroup({
      key: mapKey,
      observations: Array.from(this.treatmentMap.get(mapKey).values()),
    });
    this.serviceEventService.recalculateTotal.next(obrGroup);
    this.serviceEventService.billingObservationsOverridden.next(obrGroup);
    this.serviceEventService.observationTotalOverrideCancelled.next(obrGroup);
    this.serviceEventService.observationUnitPriceOverrideCancelled.next(obrGroup);
  }

  public onToggleView() {
    if (!this.currentDataService.treatmentIsDirty || !this.service.isLocked) {
      if (this.isTreatmentPlan) {
        this.router.navigateByUrl(
          `/schedule/(action-panel:patient/${this.patient.patientId}_patientprofiletab/patienttabs/patienttreatmentplantab/overview)`
        );
      } else {
        this.router.navigateByUrl(
          `/schedule/(action-panel:patient/${this.patient.patientId}_patientprofiletab/patienttabs/patientcharttab/overview)`
        );
      }
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
