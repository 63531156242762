<ngx-loading [show]="loading"></ngx-loading>
<ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>
<div class="grid-container pb-2">
  <kendo-grid
    class="ml-1"
    [data]="gridView"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [sort]="gridState.sort"
    [sortable]="true"
    [selectable]="true"
    [style.height.%]="100"
    (add)="onAddClick($event)"
    (cancel)="cancelHandler($event)"
    (save)="saveHandler($event)"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
    (cellClick)="cellClickHandler($event)"
  >
    <ng-template kendoGridToolbarTemplate>
      <div class="d-flex">
        <span class="pl-4 search-box border border-radius">
          <span class="fa fa-search"></span>
          <mat-form-field class="w-75 px-3">
            <input
              matInput
              placeholder="Search..."
              aria-label="Search Users"
              [(ngModel)]="searchValue"
              [formControl]="searchCtrl"
            />
            <button
              mat-button
              *ngIf="searchValue"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="searchValue = ''"
            >
              <mat-icon><i class="fa fa-times-circle"></i></mat-icon>
            </button>
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let user of filteredUsers | async" [value]="user.firstName + ' ' + user.lastName">
                <span>{{ user.firstName }} {{ user.lastName }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </span>
      </div>
      <button kendoGridAddCommand class="p-2 btn em-btn"><i class="fal fa-plus-circle"></i> Add User</button>
    </ng-template>

    <kendo-grid-column field="firstName" title="First Name" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="lastName" title="Last Name" [width]="150"></kendo-grid-column>
    <kendo-grid-column field="avatar" title="Avatar" [width]="170" [headerStyle]="{ width: '170px' }">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-avatar-uploader [selectedUser]="dataItem"></app-avatar-uploader>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="phoneNumber" title="Phone #" [width]="125"></kendo-grid-column>
    <kendo-grid-column field="userName" title="User Name" [width]="125"></kendo-grid-column>
    <kendo-grid-column field="userCategory.categoryName" title="User Category" [width]="125"></kendo-grid-column>
    <kendo-grid-column field="serviceProvider" title="Service Provider" [width]="85">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.serviceProvider ? 'Yes' : 'No' }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="isActive" title="Active" [width]="85" [headerStyle]="{ 'text-align': 'center' }">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div style="text-align: center; width: 100%">
          <div class="font-weight-bold" style="text-align: center">{{ dataItem.isActive ? 'Active' : 'Inactive' }}</div>
          <label class="switch align-text-top mb-0 ml-2">
            <input
              #statusActiveToggle
              id="is-active-{{ dataItem.userName }}-flag"
              type="checkbox"
              name="statusActive"
              [checked]="dataItem.isActive ? 'checked' : ''"
              (change)="toggleActiveStatus(statusActiveToggle.checked)"
            />
            <span class="slider round" id="is-active-{{ dataItem.userName }}-slider"></span>
          </label>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Actions" [width]="100">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand class="btn kendo-btn"><i class="fas fa-edit"></i></button>
        <!--<button kendoGridRemoveCommand class="btn kendo-btn"><i class="fas fa-minus-circle"></i></button>-->
        <!--<div style="float:right; margin-top:-17px;">
            <span class="font-weight-bold d-flex align-self-center">Status: </span>
            <label class="switch align-text-top mb-0 ml-2">
              <input #statusActiveToggle type="checkbox" name="statusActive" checked
                     (change)="toggleActiveStatus(statusActiveToggle.checked)">
              <span class="slider round"></span>
            </label>
          </div>-->
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
<p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false"> Content </p-overlayPanel>
<app-actionpanel></app-actionpanel>
