<ngx-loading [show]="loading || !invoice"></ngx-loading>
<div class="grey-seven-background">
  <ng-container *ngFor="let error of errors">
    <app-error *ngFor="let fieldError of error.fieldErrors" [message]="fieldError"></app-error>
  </ng-container>

  <!-- Total Due, Credit Ballance, ePoints -->
  <div class="p-4 d-flex flex-column align-items-center">
    <button
      *ngIf="
        paymentType !== PaymentType.Regular &&
        paymentType !== PaymentType.Refund &&
        paymentType !== PaymentType.Recurring
      "
      type="button"
      class="btn-clean btn-back navy-font"
      (click)="adminFee = 0; backToReturn()"
    >
      <i class="far fa-long-arrow-left"></i>
    </button>
    <h6 class="mt-0 mb-4 navy-font">
      {{
        paymentType === PaymentType.Regular
          ? editTransactionMode
            ? 'EDIT TRANSACTIONS'
            : 'MAKE A PAYMENT'
          : paymentType === PaymentType.Refund
          ? 'REFUND'
          : paymentType === PaymentType.ReturnCredit
          ? 'RETURN CREDITS'
          : paymentType === PaymentType.Recurring
          ? 'RECURRING PAYMENT'
          : 'RETURN'
      }}
    </h6>
    <div class="payment">
      <div *ngIf="paymentType === PaymentType.Regular || paymentType === PaymentType.Recurring">
        <div class="payment-row px-4 mb-3 font-weight-bold">
          <div>SUBTOTAL:</div>
          <div>{{ invoice?.subTotalAmount | currency }}</div>
        </div>
        <div *ngFor="let invoiceLineItemTax of invoiceLineItemTaxKeys" class="payment-row px-4 mb-3 font-weight-bold">
          <div>{{ invoiceLineItemTax }}:</div>
          <div>{{ invoiceLineItemTaxValuesObject['sum' + invoiceLineItemTax] | currency }}</div>
        </div>
      </div>
      <div class="payment-row p-4 font-weight-bold white-font navy-background">
        <div>TOTAL DUE</div>
        <div>{{ invoice?.totalAmount | currency }}</div>
      </div>
      <div
        *ngIf="paymentType === PaymentType.Refund"
        class="payment-row p-4 font-weight-bold navy-font grey-six-background"
      >
        <div>TOTAL PAID</div>
        <div>{{ invoice?.totalAmount - outstandingAmount | currency }}</div>
      </div>
      <div class="payment-row px-4 py-2 white-font teal-background">
        <div>YOUR BALANCE:</div>
        <div
          [attr.data-test-id]="'invoice-payment-modal-patient-credit-amount'"
          [attr.data-test-value]="patientAvailableCredit | currency"
        >
          {{ patientAvailableCredit | currency }}
        </div>
      </div>
      <div *ngIf="stateOfCredits != 0" class="payment-row px-4 py-2 white-font teal-background">
        <div>CREDITS {{ stateOfCredits > 0 ? 'REFUND' : 'SPEND' }}:</div>
        <div>{{ stateOfCredits | currency }}</div>
      </div>

      <div class="payment-row payment-row--points px-4 py-2 white-font teal-background">
        <div>YOUR ePOINTS:</div>
        <div>
          {{ patientAvailableLoyaltyPoints?.value }} / {{ patientAvailableLoyaltyPoints?.dollarValue | currency }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="p-4 d-flex flex-column align-items-center">
  <!-- Admin Fee and Balance Owed or Square Payment-->
  <div *ngIf="paymentType !== PaymentType.Recurring; else squareCard" class="payment mb-2">
    <div
      [formGroup]="paymentForm"
      class="admin-fee-row px-4 pt-3 navy-font font-weight-bold grey-three-background align-items-center"
      [hidden]="paymentType === PaymentType.Regular || paymentType === PaymentType.ReturnAdminFee"
    >
      <div>ADMIN FEE:</div>
      <mat-slide-toggle
        kendoTooltip
        tooltipClass="em-tooltip"
        title="Charge an optional admin fee"
        position="top"
        class="mx-1"
        #hasAdminFeeToggle
        (change)="adminFeeChanged(null)"
      ></mat-slide-toggle>
      <div class="w-100"></div>
      <div class="w-100" *ngIf="!hasAdminFeeToggle.checked"></div>
      <input
        formControlName="adminFee"
        [value]="adminFee | currency"
        (change)="adminFeeChanged($event)"
        mat-input
        numericType="number"
        [hidden]="!hasAdminFeeToggle.checked"
        placeholder="Fee ($)"
        class="w-100"
        autocomplete="off"
      />
      <div class="w-100"></div>
    </div>
    <div
      class="balance-owed-row px-4 py-3 navy-font font-weight-bold grey-three-background align-items-center"
      [ngClass]="{
        'pb-3': paymentType === PaymentType.Regular || paymentType === PaymentType.ReturnCredit
      }"
    >
      <div>BALANCE OWED:</div>
      <div>{{ outstandingAmount | currency }}</div>
      <ng-container *ngIf="paymentType !== PaymentType.ReturnPaidCancellation">
        <button
          [hidden]="!outstandingAmount"
          kendoTooltip
          tooltipClass="em-tooltip"
          position="top"
          type="button"
          class="btn btn-sm em-btn em-btn-green ml-2"
          style="justify-self: center"
          (click)="addToPaymentTotal(outstandingAmount)"
          title="Add balance to payment"
          data-test-id="add-balance-to-total"
        >
          <i class="far fa-arrow-circle-down"></i>
        </button>
      </ng-container>
    </div>
  </div>

  <ng-template #squareCard>
    <app-square-card
      #squareCardSelection
      *ngIf="isSquareLinked"
      class="px-4 py-3 font-weight-bold grey-three-background align-items-center"
      [patient]="patient"
      (cardSelectedEvent)="onSquareCardSelected($event)"
    ></app-square-card>
    <div *ngIf="!isSquareLinked" class="alert alert-warning" role="alert">
      A Square account is required to purchase recurring memberships. Please link a Square account in the clinic
      settings.
    </div>
  </ng-template>

  <!-- Close Invoice Button -->
  <div
    *ngIf="paymentType === PaymentType.Regular && showCloseInvoiceButton"
    class="mb-4 text-center grey-four-border-bottom w-100 pb-4"
  >
    <button class="btn em-btn em-btn-green ml-2" (click)="closeInvoiceHandler()">Close Invoice</button>
  </div>

  <!-- Payment Form -->
  <form [formGroup]="paymentForm" class="w-100 payment-form">
    <div class="pb-3 grey-four-border-bottom field-wrapper mb-3"></div>
    <ng-container *ngIf="paymentType !== PaymentType.ReturnPaidCancellation && paymentType !== PaymentType.Recurring">
      <!-- Payment methods -->
      <div
        class="d-flex justify-content-start flex-nowrap w-100 align-items-center"
        kendoTooltip
        tooltipClass="em-tooltip"
        position="top"
      >
        <i
          *ngIf="editTransactionMode"
          class="far fa-info-circle pr-2"
          kendoTooltip
          tooltipClass="em-tooltip"
          title="Negative amounts will be treated as refunds when editing transactions"
        ></i>
        <h6 class="my-0 navy-font pr-2">
          {{ paymentType === PaymentType.Regular ? 'Add Payment Method' : 'Add Refund Method' }}
        </h6>
        <button
          type="button"
          class="btn btn-sm em-btn em-btn-green pull-right"
          (click)="addPaymentMethod()"
          [title]="paymentType === PaymentType.Regular ? 'Add Payment Method' : 'Add Refund Method'"
        >
          <i class="far fa-plus-circle"></i>
        </button>
      </div>

      <div *ngIf="paymentForm.get('formPaymentMethods')" class="pb-3 grey-four-border-bottom field-wrapper mb-3">
        <div
          formArrayName="formPaymentMethods"
          *ngFor="let paymentMethodControls of paymentForm.get('formPaymentMethods')['controls']; let i = index"
          [attr.data-test-id]="'form-payment-methods'"
          [attr.data-test-value]="i"
        >
          <div [formGroupName]="i" class="w-100 d-flex align-items-center justify-content-between">
            <div class="navy-font d-flex align-items-center font-weight-bold form-field">
              Method:
              <div class="flex-grow-1 ml-2">
                <mat-form-field [floatLabel]="'never'">
                  <mat-select
                    formControlName="method"
                    [attr.data-test-id]="'payment-method-drop-down'"
                    [attr.data-test-value]="i"
                    (valueChange)="onFormChange($event, i, true)"
                  >
                    <mat-option
                      [attr.data-test-id]="'payment-method-option'"
                      [attr.data-test-value]="item.name"
                      *ngFor="let item of merchantDevicePaymentMethods"
                      [value]="item"
                      [disabled]="
                        (paymentType === PaymentType.Regular &&
                          item.name === 'Credits' &&
                          patientAvailableCredit === 0 &&
                          !editTransactionMode) ||
                        (paymentType === PaymentType.ReturnCredit && item.name === 'Credits') ||
                        (creditFieldExists && item.id === creditsPaymentMethod.id)
                      "
                    >
                      {{ item.name }}
                    </mat-option>

                    <mat-option
                      [attr.data-test-id]="'payment-method-option'"
                      [attr.data-test-value]="item.name"
                      *ngFor="let item of paymentMethods"
                      [value]="item"
                      [disabled]="
                        (paymentType === PaymentType.Regular &&
                          item.name === 'Credits' &&
                          patientAvailableCredit === 0 &&
                          !editTransactionMode) ||
                        (paymentType === PaymentType.ReturnCredit && item.name === 'Credits') ||
                        (creditFieldExists && item.id === creditsPaymentMethod.id)
                      "
                    >
                      {{ item.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="navy-font d-flex align-items-center font-weight-bold form-field ml-1">
              Amount($):
              <div class="flex-grow-1 ml-2">
                <mat-form-field [floatLabel]="'never'">
                  <input
                    matInput
                    [attr.data-test-id]="'payment-method-amount-input'"
                    [attr.data-test-value]="i"
                    formControlName="amount"
                    [allowNegative]="editTransactionMode"
                    (change)="
                      onFormChange($any($event.target).value, i, false);
                      formatCurrency($any($event.target).value, paymentMethodControls)
                    "
                    onlyNumber
                    numericType="decimal"
                    autocomplete="off"
                  />
                </mat-form-field>
              </div>
            </div>
            <div *ngIf="isMerchantDevice(paymentMethodControls)" class="ml-2">
              <label class="d-block mb-0 teal-font font-weight-bold">Manual</label>
              <label
                class="switch align-text-top"
                kendoTooltip
                tooltipClass="em-tooltip"
                title="Manually enter credit card information."
              >
                <input
                  type="checkbox"
                  formControlName="isManualEntry"
                  (click)="toggleManualEntry(paymentMethodControls)"
                />
                <span class="slider round"></span>
              </label>
            </div>
            <button
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              type="button"
              [attr.data-test-id]="'payment-method-delete-row'"
              [attr.data-test-value]="i"
              class="btn btn-sm em-btn btn-circle em-btn-green ml-2"
              (click)="removePaymentMethod(i)"
              [title]="paymentType === PaymentType.Regular ? 'Remove Payment Method' : 'Remove Refund Method'"
              *ngIf="!paymentMethodControls.controls.isMerchantDeviceTransaction.value || !editTransactionMode"
            >
              <i class="far fa-minus-circle"></i>
            </button>
            <i
              *ngIf="paymentMethodControls.controls.isMerchantDeviceTransaction.value && editTransactionMode"
              class="far fa-question-circle pr-2"
              kendoTooltip
              tooltipClass="em-tooltip"
              title="Merchant Transaction cannot be edited"
            ></i>
          </div>
        </div>
        <div class="alert-danger px-1" *ngIf="checkInvalidPaymentAmount()">Not a valid amount.</div>
        <div
          class="alert-danger px-1"
          *ngIf="paymentType === PaymentType.Regular ? patientAvailableCredit + stateOfCredits < 0 : false"
        >
          Your credit balance has been exceeded.
        </div>
        <!-- <div class="alert-danger px-1" *ngIf="this.sum !== 0 && adminFee !== 0 && outstandingAmount !== 0">
        Partial refund cannot have an admin fee.
      </div> -->
      </div>
    </ng-container>

    <div *ngIf="paymentType === PaymentType.ReturnProduct" class="d-flex pb-4">
      <mat-slide-toggle formControlName="addBackToInventory">Add to Inventory: </mat-slide-toggle>
    </div>
    <div
      class="d-flex justify-content-between align-items-center"
      *ngIf="!editTransactionMode"
      [ngClass]="{ 'return-payment': paymentType !== PaymentType.Regular }"
    >
      <button
        type="button"
        class="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-0"
        (click)="onPaymentFormSubmit()"
        [disabled]="buttonsDisabled()"
        data-test-id="pay-button"
      >
        {{ paymentType === PaymentType.Regular || paymentType === PaymentType.Recurring ? 'PAY' : 'REFUND' }}
      </button>
      <app-generic-send-email-popover
        buttonClass="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-0"
        buttonText="{{
          paymentType === PaymentType.Regular || paymentType === PaymentType.Recurring ? 'PAY' : 'REFUND'
        }} + EMAIL"
        [initialEmailAddress]="patient?.email"
        [disabled]="buttonsDisabled()"
        [sendEmailObservable]="getEmailInvoiceObservable"
      ></app-generic-send-email-popover>
      <button
        type="button"
        class="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-0"
        [disabled]="buttonsDisabled()"
        (click)="onPrintInvoice()"
      >
        {{ paymentType === PaymentType.Regular || paymentType !== PaymentType.Recurring ? 'PAY' : 'REFUND' }} + PRINT
      </button>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      *ngIf="editTransactionMode"
      [ngClass]="{ 'return-payment': paymentType !== PaymentType.Regular }"
    >
      <button
        data-test-id="save-transaction-button"
        type="button"
        class="schedNewCommButton center em-btn em-btn-green py-2 px-3 mb-0"
        (click)="saveEditedTransactions()"
        [disabled]="
          !paymentForm.valid ||
          (paymentType === PaymentType.Regular ? patientAvailableCredit + stateOfCredits < 0 : false) ||
          outstandingAmount !== 0
        "
      >
        SAVE TRANSACTIONS
      </button>
    </div>
  </form>
</div>
<div style="opacity: 0; position: absolute; width: 0; height: 0">
  <ng-template #exportTemplate></ng-template>
</div>
