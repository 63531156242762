import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { ChargeableAppointment } from '@models/billing/chargeable-appointment';
import { VisitForms } from '@models/forms/visit-forms';
import { Appointment } from '@models/scheduler/event';
import { Visit } from '@models/visit';
import { VisitConfirmedStatus } from '@models/visit-confirm-status';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MasterOverlayService } from './actionpanel.service';
import { EventsService } from './events.service';
import { UrlHelper } from './helpers/url-helper';
import { PatientService } from './patient.service';
import { UsersService } from './users.service';

@Injectable()
export class VisitService implements OnDestroy {
  activeVisitApptsUpdated = new Subject<any>();
  activeVisitApptsUpdatedListener$ = this.activeVisitApptsUpdated.asObservable();
  visitCreated = new Subject<Visit>();
  visitCreatedListener$ = this.visitCreated.asObservable();
  private unsub: Subject<void> = new Subject<void>();

  clickedVisitAppt: { id: number; visitId: number };
  reservation: Appointment;
  lastVisit: Visit;
  visitPanelLoading = false;

  constructor(
    private http: HttpClient,
    private dialogRef: MatDialog,
    private userService: UsersService,
    private eventService: EventsService,
    private patientService: PatientService,
    private masterOverlayService: MasterOverlayService,
    private router: Router
  ) {}

  // API Methods
  addVisit(visit: Visit) {
    visit.totalVisitCost = 0;
    visit.appointments.forEach((a) => {
      visit.totalVisitCost += a.service.defaultPrice;
    });
    delete visit.patient;
    return this.http.post<Visit>(environment.baseUrl + 'api/Visits', visit).pipe(
      map((newVisit) => {
        this.visitCreated.next(newVisit);
        return newVisit;
      })
    );
  }

  updateVisit(visit: Visit) {
    delete visit.patient;
    return this.http.put<void>(environment.baseUrl + 'api/Visits/' + visit.visitId, visit);
  }

  updateVisitPatient(prevVisitId, visitId: number, patientId: number) {
    return this.http.post<void>(environment.baseUrl + 'api/Visits/ChangePatient', { prevVisitId, visitId, patientId });
  }

  removeVisit(visit: Visit) {
    return this.http.delete(environment.baseUrl + 'api/Visits/' + visit.visitId);
  }

  getVisitsByDate(startDate?: Date, endDate?: Date, patientId?: number): Observable<Visit[]> {
    let url = environment.baseUrl + 'api/Visits/GetByDate';
    url = UrlHelper.addStartEndDateParameters(url, startDate, endDate);

    if (!isNullOrUndefined(patientId)) {
      url += '&patientId=' + patientId;
    }

    return this.http.get<Visit[]>(url);
  }

  getVisitById(visitId): Observable<Visit> {
    return this.http.get<Visit>(environment.baseUrl + 'api/Visits/' + visitId).map((v) => new Visit(v));
  }

  getVisitByEvent(eventId): Observable<Visit> {
    return this.http.get<Visit>(environment.baseUrl + 'api/Visits/VisitByName/' + eventId);
  }

  getAllVisitsByPatientId(patientId): Observable<Visit[]> {
    return this.http.get<Visit[]>(environment.baseUrl + 'api/Visits/Patient/' + Number(patientId));
  }

  getVisitByServiceId(serviceId: number): Observable<Visit> {
    return this.http.get<Visit>(environment.baseUrl + 'api/Visits/Service/' + serviceId);
  }

  getTemplateVisit(): Observable<Visit> {
    return this.http.get<Visit>(environment.baseUrl + 'api/Visits/VisitByName/' + 'Template Visit');
  }

  getBlockedScheduleVisit(): Observable<Visit> {
    return this.http.get<Visit>(environment.baseUrl + 'api/Visits/Blocked');
  }

  getStaffScheduleVisit(): Observable<Visit> {
    return this.http.get<Visit>(environment.baseUrl + 'api/Visits/Staff');
  }

  getVisitFormsByVisitId(visitId: number): Observable<VisitForms> {
    return this.http.get<VisitForms>(environment.baseUrl + 'api/Visits/Forms/' + visitId);
  }

  // Visit Moving Methods
  async updateAutoConfirmation(appt: Appointment, visit: Visit, showUnconfirmPrompt: boolean = false) {
    // Check if the new start time has moved into or out of our 24 hour auto-confirm window
    const sanitizedVisitDate = new Date(visit.date);
    sanitizedVisitDate.setHours(0);
    sanitizedVisitDate.setMinutes(0);
    sanitizedVisitDate.setSeconds(0);
    sanitizedVisitDate.setMilliseconds(0);
    const newDateMoment = this.fixFullCalendarDate(moment(sanitizedVisitDate).add(appt.startTime));
    if (await this.determineMovedVisitConfirmationStatus(visit, newDateMoment, showUnconfirmPrompt))
      this.updateVisit(visit).subscribe(() => {});
  }

  fixFullCalendarDate(fullCalendarDate) {
    try {
      return moment()
        .year(fullCalendarDate.year())
        .month(fullCalendarDate.month())
        .date(fullCalendarDate.date())
        .hour(fullCalendarDate.hour())
        .minute(fullCalendarDate.minute())
        .second(0);
    } catch {
      return moment()
        .year(fullCalendarDate['_i'][0])
        .month(fullCalendarDate['_i'][1])
        .date(fullCalendarDate['_i'][2])
        .hour(fullCalendarDate['_i'][3])
        .minute(fullCalendarDate['_i'][4])
        .second(0);
    }
  }

  async determineMovedVisitConfirmationStatus(
    visit: Visit,
    targetDate: moment.Moment,
    showUnconfirmPrompt: boolean = false
  ) {
    /*
    let manuallyConfirmedByUserId = null;
    let manuallyConfirmedByUser = null;
    if (visit.manuallyConfirmedByUser) {
      manuallyConfirmedByUserId = visit.manuallyConfirmedByUser.id;
      manuallyConfirmedByUser = visit.manuallyConfirmedByUser;
      delete visit.manuallyConfirmedByUser;
    }
    */
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    let now = moment().format(dateFormat);
    let target = moment(targetDate).format(dateFormat);
    const differenceInHours = moment(target).diff(moment(now), 'hours');
    let updateRequired = false;
    if (differenceInHours >= 24) {
      if (showUnconfirmPrompt && visit.confirmedStatus != VisitConfirmedStatus.Unconfirmed) {
        const dialog = this.dialogRef.open(GenericDialogComponent, {
          width: '350px',
          data: {
            showCancel: true,
            title: 'Unconfirm Appointment?',
            content:
              'You are moving an appointment that is part of a confirmed visit. Would you like to unconfirm this visit?',
            confirmButtonText: 'Remain Confirmed',
            cancelButtonText: 'Unconfirm',
          },
        });
        const dialogResult = await dialog.afterClosed().toPromise();
        // Confirm means remain confirmed
        if (dialogResult == 'confirm') return false;
      }
      //if moved to next day but within 24 hours of now - do not reset status of visit
      visit.confirmedStatus = VisitConfirmedStatus.Unconfirmed;
      delete visit.manuallyConfirmedByUser;
      delete visit.confirmedTime;
      updateRequired = true;
    } else if (
      visit.confirmedStatus == VisitConfirmedStatus.Unconfirmed ||
      visit.confirmedStatus == VisitConfirmedStatus.StaffConfirmed
    ) {
      visit.manuallyConfirmedByUserId = this.userService.loggedInUser.id;
      visit.manuallyConfirmedByUser = this.userService.loggedInUser;
      visit.confirmedTime = new Date(Date.now());
      visit.confirmedStatus = VisitConfirmedStatus.StaffConfirmed;
      updateRequired = true;
    }
    return updateRequired;
  }

  initEmptyVisit(patientId: number, visitIdString: string, appointments: any, date: Date, createdBy: string) {
    return new Visit({
      visitId: 0,
      visitIdString: visitIdString,
      patientId: patientId,
      patient: null,
      visitNotes: '',
      patientNotes: '',
      cancellationReason: '',
      cancellationMessage: '',
      isCancellationAlert: false,
      cancellationDate: null,
      cancelled: false,
      appointments: appointments,
      totalVisitCost: 0,
      checkedIn: false,
      confirmedStatus: VisitConfirmedStatus.Unconfirmed,
      noShow: false,
      date: date,
      createdBy: createdBy,
    });
  }

  async getVisitForAppointmentMove(sourceVisitId: number, targetDate: moment.Moment): Promise<Visit> {
    const sourceVisit = await this.getVisitById(sourceVisitId).toPromise();

    let targetVisit: Visit;
    if (new Date(sourceVisit.date).toDateString() !== targetDate.toDate().toDateString()) {
      let visitsOnTargetDate = await this.getVisitsByDate(
        targetDate.toDate(),
        targetDate.toDate(),
        sourceVisit.patientId
      ).toPromise();

      if (visitsOnTargetDate?.length > 0) {
        targetVisit = visitsOnTargetDate[0];
        await this.updateVisitNotes(sourceVisit, targetVisit);
      } else {
        targetVisit = JSON.parse(JSON.stringify(sourceVisit));
        targetVisit.visitId = 0;
        targetVisit.visitIdString = sourceVisit.patientId.toString() + targetDate.toDate().toDateString();
        targetVisit.date = targetDate.toDate();
        targetVisit.appointments = [];
        targetVisit.checkedIn = false;
        await this.determineMovedVisitConfirmationStatus(targetVisit, targetDate);
        targetVisit = await this.addVisit(targetVisit).toPromise();
      }
    } else {
      targetVisit = sourceVisit;
      const updateRequired = await this.determineMovedVisitConfirmationStatus(targetVisit, targetDate, true);
      if (updateRequired) await this.updateVisit(targetVisit).toPromise();
    }

    return targetVisit;
  }

  private async updateVisitNotes(source: Visit, target: Visit) {
    if (!source.visitNotes) return;
    if (!target.visitNotes) target.visitNotes = source.visitNotes;
    else if (target.visitNotes.indexOf(source.visitNotes) == -1) {
      target.visitNotes += `\n${source.visitNotes}`;
    }
    await this.updateVisit(target).toPromise();
  }

  // Visit Panel Methods
  async closePanel(loading?: boolean) {
    if (loading) {
      var continueCancel = false;
      const dialogRef = this.dialogRef.open(GenericDialogComponent, {
        width: '330px',
        data: {
          title: 'Warning',
          content:
            'There are things happening in the background. Wait for loading indicator to disappear before closing. Press OK to ignore this warning.',
          confirmButtonText: 'OK',
          showCancel: true,
        },
      });

      continueCancel = await dialogRef
        .afterClosed()
        .map((result) => {
          return result == 'confirm';
        })
        .toPromise();

      if (!continueCancel) return;
    }

    if (!this.patientService.previousPage) {
      this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);
    } else {
      this.masterOverlayService.masterOverlayState(true);
      this.router.navigateByUrl(this.patientService.previousPage);
      this.patientService.previousPage = '';
    }

    this.clickedVisitAppt = null;
    this.eventService.closePanel();
    this.masterOverlayService.masterOverlayState(false);
  }

  isVisitCancellationChargeable(visitId: number): Observable<ChargeableAppointment> {
    return this.http.get<ChargeableAppointment>(
      `${environment.baseUrl}api/Visits/IsVisitCancellationChargeable/${visitId}`
    );
  }

  getVisitStatus(visit: Visit) {
    if (visit == null) return null;
    if (visit.checkedIn) {
      return 'checkedIn';
    } else if (
      !(
        visit.confirmedStatus === VisitConfirmedStatus.StaffConfirmed ||
        visit.confirmedStatus === VisitConfirmedStatus.SMSConfirmed ||
        visit.confirmedStatus === VisitConfirmedStatus.EmailConfirmed
      ) &&
      visit.noShow
    ) {
      return 'noShow';
    } else if (
      !(
        visit.confirmedStatus === VisitConfirmedStatus.StaffConfirmed ||
        visit.confirmedStatus === VisitConfirmedStatus.SMSConfirmed ||
        visit.confirmedStatus === VisitConfirmedStatus.EmailConfirmed
      ) &&
      !visit.noShow
    ) {
      return 'uncomfirmed';
    } else if (
      (visit.confirmedStatus === VisitConfirmedStatus.StaffConfirmed ||
        visit.confirmedStatus === VisitConfirmedStatus.SMSConfirmed ||
        visit.confirmedStatus === VisitConfirmedStatus.EmailConfirmed) &&
      !visit.checkedIn
    ) {
      return 'confirmed';
    }
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
