import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { Clinic } from '@models/clinic';
import { FileData } from '@models/file-data';
import { InvoicePayment } from '@models/finance/invoice-payment';
import { InvoiceTransaction } from '@models/finance/invoice-transaction';
import { PaymentMethod } from '@models/finance/payment-method';
import { Invoice, PostInvoice } from '@models/invoice/invoice';
import { InvoiceLineItem } from '@models/invoice/invoice-line-item';
import { InvoiceLineItemTax } from '@models/invoice/invoice-line-item-tax';
import { InvoiceType } from '@models/invoice/invoice-type';
import { ProductPurchased } from '@models/invoice/product-purchased';
import { ServicePurchased } from '@models/invoice/service-purchased';
import { Observable, Subject } from 'rxjs';
import { ClinicServiceTemplate } from '../models/service/clinic-service-template';
import { UsersService } from './users.service';
import { AdminFeePurchased } from '@models/invoice/admin-fee-purchased';
import { PaidCancellation } from '@models/invoice/paid-cancellation';
import { ResultModel } from '@models/result-model';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  invoiceUpdated = new Subject<number>();
  invoicesListUpdated = new Subject<Invoice>();
  invoiceLineItemDeleted = new Subject<InvoiceLineItem>();
  invoiceSet = new Subject<Invoice>();
  invoiceUpdated$ = this.invoiceUpdated.asObservable();
  invoicesListUpdated$ = this.invoicesListUpdated.asObservable();
  invoiceLineItemDeleted$ = this.invoiceLineItemDeleted.asObservable();
  invoiceSet$ = this.invoiceSet.asObservable();
  adminFeeServiceTemplate: ClinicServiceTemplate;
  creditsPaymentMethod: PaymentMethod;

  constructor(private http: HttpClient, private usersService: UsersService) {
    this.usersService.loggedInUserUpdated$.subscribe({
      next: (user) => {
        if (user && user.id) {
          this.getAdminFeeServiceTemplate().subscribe((template: ClinicServiceTemplate) => {
            this.adminFeeServiceTemplate = template;
          });
          this.getCreditsPaymentMethod().subscribe((paymentMethod: PaymentMethod) => {
            this.creditsPaymentMethod = paymentMethod;
          });
        }
      },
    });
  }

  addInvoice(blankInvoice: PostInvoice, createNewInvoiceAlways: boolean = false) {
    return this.http.post<ResultModel<Invoice>>(
      `${environment.baseUrl}api/Invoices/AddInvoice/${createNewInvoiceAlways}`,
      blankInvoice
    );
  }

  private getAdminFeeServiceTemplate() {
    return this.http.get(`${environment.baseUrl}api/Invoices/AdminFeeServiceTemplate`);
  }

  /*
    Date:19/01/2021
    Desc: to delete Invoice
  */
  deleteInvoice(invoiceId: number): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}api/Invoices/${invoiceId}`);
  }

  getInvoicesByPatientId(patientId: number): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${environment.baseUrl}api/Invoices/GetInvoicesFor/${patientId}`);
  }

  getInvoiceById(invoiceId: number): Observable<Invoice> {
    return this.http.get<Invoice>(`${environment.baseUrl}api/Invoices/GetInvoice/${invoiceId}`);
  }

  getInvoiceByVisitId(visitId: number) {
    return this.http.get<Invoice>(`${environment.baseUrl}api/Invoices/GetVisitInvoice/${visitId}`);
  }

  getInvoiceByPlannedTreatmentMultipleId(
    plannedTreatmentMultipleId: number,
    invoiceType: InvoiceType = InvoiceType.Regular
  ): Observable<Invoice> {
    return this.http.get<Invoice>(
      `${environment.baseUrl}api/Invoices/GetInvoiceForPlannedTreatmentMultiple/${plannedTreatmentMultipleId}/${invoiceType}`
    );
  }

  getCreditsPaymentMethod(): Observable<PaymentMethod> {
    return this.http.get<PaymentMethod>(`${environment.baseUrl}api/Invoices/GetCreditsPaymentMethod`);
  }

  updatePartialPaymentInvoiceLineItems(invoiceLineItems: InvoiceLineItem[]): Observable<InvoiceLineItem[]> {
    return this.http.post<InvoiceLineItem[]>(
      `${environment.baseUrl}api/Invoices/UpdatePartialPaymentInvoiceLineItems`,
      invoiceLineItems
    );
  }

  updateInvoiceLineItems(invoiceLineItems: InvoiceLineItem[]): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/Invoices/UpdateInvoiceLineItems`, invoiceLineItems);
  }

  updateInvoiceTransaction(invoiceTransaction: InvoiceTransaction): Observable<InvoiceTransaction> {
    return this.http.post<InvoiceTransaction>(
      `${environment.baseUrl}api/Finance/UpdateFinanceTransaction`,
      invoiceTransaction
    );
  }

  calculateLineItemTax(invoiceLineItems: any[]) {
    return this.http.post<any>(`${environment.baseUrl}api/Invoices/CalculateLineItemTax`, invoiceLineItems);
  }

  getRequiredAndRecommendedProducts(visitId: number, invoiceId: number) {
    return this.http.get(
      `${environment.baseUrl}api/Invoices/GetRequiredAndRecommendedProductsForVisit/${visitId}/${invoiceId}`
    );
  }

  deleteInvoiceLineItem(invoiceLineItemId: number): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}api/Invoices/DeleteLineItem/${invoiceLineItemId}`);
  }

  emailInvoice(invoice: FileData, emailAddress: string, patientId: number, invoiceId: number): Observable<void> {
    return this.http.post<void>(
      `${environment.baseUrl}api/Invoices/EmailInvoice/${emailAddress}/${patientId}/${invoiceId}`,
      invoice
    );
  }

  getProductsPurchasedByPatient(patientId: number): Observable<ProductPurchased[]> {
    return this.http.get<ProductPurchased[]>(
      `${environment.baseUrl}api/Invoices/GetProductsPurchasedByPatient/${patientId}`
    );
  }

  getServicesPurchasedByPatient(patientId: number): Observable<ServicePurchased[]> {
    return this.http.get<ServicePurchased[]>(
      `${environment.baseUrl}api/Invoices/GetServicesPurchasedByPatient/${patientId}`
    );
  }

  getAdminFeesPurchasedByPatient(patientId: number): Observable<AdminFeePurchased[]> {
    return this.http.get<AdminFeePurchased[]>(
      `${environment.baseUrl}api/Invoices/GetAdminFeesPurchasedByPatient/${patientId}`
    );
  }

  getPaidCancellationsByPatient(patientId: number): Observable<PaidCancellation[]> {
    return this.http.get<PaidCancellation[]>(
      `${environment.baseUrl}api/Invoices/GetPaidCancellationsByPatient/${patientId}`
    );
  }

  editTransactionsBulk(invoiceId: number, transactions: InvoiceTransaction[]) {
    return this.http.post<InvoiceTransaction[]>(
      `${environment.baseUrl}api/Finance/UpdateFinanceTransactionBulk/${invoiceId}`,
      transactions
    );
  }

  updateInvoicePaidStatus(invoiceId: number, paid: boolean): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<void>(
      `${environment.baseUrl}api/Invoices/UpdateInvoicePaidStatus/${invoiceId}`,
      paid,
      httpOptions
    );
  }

  refundPaidService(serviceId: number): Observable<Invoice> {
    return this.http.get<Invoice>(`${environment.baseUrl}api/Invoices/RefundPaidService/${serviceId}`);
  }

  //right now we generate the line items on the front end but its complicated enough for this process to be done on the backend
  //currently it only does this for cs and inject observations but we will further abstract it when the time comes
  generateLineItemsForPrepayment(invoiceId: number, plannedTreatmentServiceId): Observable<InvoiceLineItem[]> {
    return this.http.get<InvoiceLineItem[]>(
      `${environment.baseUrl}api/Invoices/GenerateInvoiceLineItemsFromTxObservations/${invoiceId}/${plannedTreatmentServiceId}`
    );
  }

  getReturnPayment(
    patientId: number,
    invoiceId: number,
    financeTransactionTypeId: number,
    amount: number,
    description: string = ''
  ): InvoicePayment {
    const transactions: InvoiceTransaction[] = [];

    transactions.push({
      id: 0,
      invoiceId: invoiceId,
      transactionDate: new Date(),
      financeTransactionType: null,
      financeTransactionTypeId: financeTransactionTypeId,
      amount: amount,
      paymentMethodId: this.creditsPaymentMethod.id, //credit type
      paymentMethod: null,
      description: description,
      isMerchantDeviceTransaction: false,
      merchantDeviceId: 0,
      createdBy: null,
      createdById: null,
      emvData: null,
      isManualEntry: false,
    });
    return {
      patientId: patientId,
      transactions: transactions,
      paidCancellation: null,
    };
  }

  updateInvoiceDate(invoiceId: number, invoiceDate: Date): Observable<void> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.put<void>(
      `${environment.baseUrl}api/Invoices/UpdateInvoiceDate/${invoiceId}`,
      invoiceDate,
      httpOptions
    );
  }

  createReturnInvoiceForServicesPurchased(
    patientId: number,
    servicesPurchased: ServicePurchased[]
  ): Observable<Invoice> {
    return this.http.post<Invoice>(
      `${environment.baseUrl}api/Invoices/ReturnInvoiceForServicesPurchased/${patientId}`,
      servicesPurchased
    );
  }

  createReturnInvoiceForProductsPurchased(
    patientId: number,
    productsPurchased: ProductPurchased[]
  ): Observable<Invoice> {
    return this.http.post<Invoice>(
      `${environment.baseUrl}api/Invoices/ReturnInvoiceForProductsPurchased/${patientId}`,
      productsPurchased
    );
  }

  createReturnInvoiceForCreditsPurchased(patientId: number, amounts: number[]): Observable<Invoice> {
    return this.http.post<Invoice>(
      `${environment.baseUrl}api/Invoices/ReturnInvoiceForCreditsPurchased/${patientId}`,
      amounts
    );
  }

  createReturnInvoiceForAdminFeesPurchased(
    patientId: number,
    adminFeesPurchased: AdminFeePurchased[]
  ): Observable<Invoice> {
    return this.http.post<Invoice>(
      `${environment.baseUrl}api/Invoices/ReturnInvoiceForAdminFeesPurchased/${patientId}`,
      adminFeesPurchased
    );
  }

  createReturnInvoiceForPaidCancellations(
    patientId: number,
    paidCancellations: PaidCancellation[]
  ): Observable<Invoice> {
    return this.http.post<Invoice>(
      `${environment.baseUrl}api/Invoices/ReturnInvoiceForPaidCancellations/${patientId}`,
      paidCancellations
    );
  }

  createInvoiceForMemberships(patientId: number, membershipIds: number[]): Observable<Invoice> {
    return this.http.post<Invoice>(
      `${environment.baseUrl}api/Invoices/InvoiceForMemberships/${patientId}`,
      membershipIds
    );
  }

  getInvoiceLineItemTaxes(invoice: Invoice, clinic: Clinic) {
    const invoiceLineItemTaxValuesObject = {};
    const invoiceLineItemTaxKeys = [];
    if (invoice && invoice.invoiceLineItems) {
      invoice.invoiceLineItems.forEach((invoiceLineItem: InvoiceLineItem) => {
        invoiceLineItemTaxValuesObject[invoiceLineItem.id] = {};
        invoiceLineItem.invoiceLineItemTaxes.forEach((invoiceLineItemTax: InvoiceLineItemTax) => {
          invoiceLineItemTaxValuesObject[invoiceLineItem.id][invoiceLineItemTax.taxName] = invoiceLineItemTax.amount;
          if (!invoiceLineItemTaxKeys.some((item: string) => item === invoiceLineItemTax.taxName)) {
            invoiceLineItemTaxKeys.push(invoiceLineItemTax.taxName);
            invoiceLineItemTaxValuesObject['sum' + invoiceLineItemTax.taxName] = 0;
          }
          invoiceLineItemTaxValuesObject['sum' + invoiceLineItemTax.taxName] += invoiceLineItemTax.amount;
        });
      });
    }

    clinic?.clinicTaxes?.forEach((clinicTax) => {
      if (invoiceLineItemTaxKeys.indexOf(clinicTax.tax.name) == -1) {
        invoiceLineItemTaxKeys.push(clinicTax.tax.name);
        invoiceLineItemTaxValuesObject['sum' + clinicTax.tax.name] = 0;
      }
    });

    return { invoiceLineItemTaxKeys, invoiceLineItemTaxValuesObject };
  }

  async addCreditLineItemToInvoice(invoice: Invoice) {
    // Don't add credit line item if one already exists
    if (invoice.invoiceLineItems.some((lineItem) => lineItem.isCredit === true)) return;
    const creditLineItem: InvoiceLineItem = {
      id: 0,
      invoiceId: invoice.id,
      note: null,
      unitPrice: 0.0,
      quantity: 1,
      total: 0.0,
      serviceType: 'Credit',
      serviceTypeDescription: null,
      clinicProductId: null,
      isManual: true,
      isDeleted: false,
      finalTotal: null,
      isCredit: true,
      serviceTemplateId: null,
      addBackToInventory: false,
      invoiceLineItemTaxes: [],
      attributedToUserId: this.usersService.loggedInUser.id,
      attributedToUser: this.usersService.loggedInUser,
      plannedTreatmentMultipleId: null,
      isRecommendedProduct: false,
    };
    await this.updateInvoiceLineItems([creditLineItem])
      .toPromise()
      .catch((error) => {
        throw error;
      });
  }

  public getInvoiceType(invoice: Invoice) {
    if (!isNullOrUndefined(invoice) && invoice.invoiceTypeId === InvoiceType.Refund) {
      return 'REFUND';
    } else if (!isNullOrUndefined(invoice) && invoice.invoiceTypeId === InvoiceType.Return) {
      return 'RETURN';
    } else if (!isNullOrUndefined(invoice) && invoice.invoiceTypeId === InvoiceType.Credit) {
      return 'CREDIT';
    } else if (!isNullOrUndefined(invoice) && invoice.invoiceTypeId === InvoiceType.Cancellation) {
      return 'CANCELLATION';
    } else {
      return 'INVOICE';
    }
  }
}
