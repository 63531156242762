<li (click)="addPatient()" [attr.data-test-id]="'add-new-patient-button'">
  <span
    class="menu-icon"
    [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
    kendoTooltip
    tooltipClass="em-tooltip"
    position="right"
  >
    <i class="fal fa-user-plus fa-lg" aria-hidden="true" title="Add Patient"></i>
  </span>
  <span [ngClass]="{ inactive: !sideNavExpanded }">Add Patient</span>
</li>
