import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { isNullOrUndefined } from '@app/shared/helpers';
import * as moment from 'moment';
import { Subject, of, zip } from 'rxjs';
import { mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { GenericInputComponent } from '@app/management/dialogs/generic-input/generic-input.component';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { Observation, ObservationType, ObservationTypes } from '@models/observation/observation';
import { Patient } from '@models/patient';
import { PaymentStatus } from '@models/scheduler/payment-status';
import { Service } from '@models/service/service';
import { ServiceEventType } from '@models/service/service-event-type';
import { ServiceNote } from '@models/service/service-note';
import { UserFavouriteService } from '@models/service/user-favourite-service';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { TreatmentObservationGroup } from '@models/treatment-planning/treatment-observation-group';
import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { AppointmentService } from '@services/appointments.service';
import { CurrentDataService } from '@services/currentData.service';
import { ObservationListItemsService } from '@services/observation-list-items.service';
import { ObservationService } from '@services/observation.service';
import { PatientService } from '@services/patient.service';
import { PlottingEventService } from '@services/plotting-event.service';
import { CoolToneFormService } from '@services/service-detail/cooltone-form.service';
import { ServiceEventService } from '@services/service-event.service';
import { ServicesService } from '@services/services.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { UsersService } from '@services/users.service';
import { FavouriteServicesService } from '../../../../../../../services/favourite-services.service';

interface ObsLeafId {
  observation: Observation;
  leafletId: number;
}

@Component({
  selector: 'app-cooltone',
  templateUrl: './cooltone.component.html',
  styleUrls: ['./cooltone.component.less'],
})
export class CooltoneComponent implements OnInit, OnDestroy {
  @Input() service: Service;
  @Input() serviceId: number;
  @Input() observationType: ObservationType;
  @Input() associatedPlannedTreatment: PlannedTreatment;

  isTreatmentPlan: boolean;
  isServicePaid = false;
  loading = false;
  navLocked = false;
  patient: Patient;
  templatePatient: Patient;
  obrIdsToDelete: number[] = [];
  treatmentDate: moment.Moment;
  unsub: Subject<void> = new Subject<void>();
  treatmentMap: Map<string, ObsLeafId> = new Map();
  annotationMap: Map<number, Observation> = new Map();
  plannedTreatment: Map<string, boolean> = new Map();
  dirtyDetails = false;
  chargeAmount: number;

  // Plotting settings
  activePlotKey = '';
  plottingActive = false;
  initialPlot = false;

  // Treatment note
  serviceNoteText: string;

  PaymentStatus = PaymentStatus;

  constructor(
    private patientService: PatientService,
    public currentDataService: CurrentDataService,
    private observationService: ObservationService,
    private plottingEventService: PlottingEventService,
    private appointmentsService: AppointmentService,
    private serviceEventService: ServiceEventService,
    public coolToneFormService: CoolToneFormService,
    private dialog: MatDialog,
    private servicesService: ServicesService,
    private route: ActivatedRoute,
    private router: Router,
    private treatmentPlanService: TreatmentPlanService,
    private usersService: UsersService,
    private favouriteServicesService: FavouriteServicesService,
    private observationListItemService: ObservationListItemsService
  ) {
    this.patient = this.patientService.patientPanelPatient;
    this.patientService.getTemplatePatient().subscribe((tp) => {
      this.templatePatient = tp;
    });
    this.isTreatmentPlan = false;
  }

  ngOnInit() {
    this.isServicePaid = this.servicesService.isPaid;
    this.isTreatmentPlan = this.route.snapshot.params.treatmentPlanning === 'true';
    this.serviceNoteText =
      !isNullOrUndefined(this.service.serviceNotes) && this.service.serviceNotes.length > 0
        ? this.service.serviceNotes[0].entryText
        : '';

    let patientObrs: Observation[] = [];
    if (!isNullOrUndefined(this.patient.observations)) {
      patientObrs = this.patient.observations.filter((obr) => obr.serviceId === this.serviceId);
    }

    this.coolToneFormService.saveTrigger.pipe(takeUntil(this.unsub)).subscribe(async () => {
      await this.saveTreatment(false);
      this.coolToneFormService.saveTriggerComplete.next();
    });

    this.serviceEventService.billingObservationsOverridden.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.serviceEventService.recalculateTreatmentBilling.next(this.getTreatmentObservations());
    });

    this.initPlottingSubscription();
    this.initServiceEventSubscription();

    this.treatmentPlanService
      .getTreatmentPlanByPatientId(this.patient.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((tp: TreatmentPlan) => {
        if (!isNullOrUndefined(tp) && !isNullOrUndefined(tp.plannedTreatments)) {
          tp.plannedTreatments.forEach((treatment) => {
            if (!isNullOrUndefined(treatment.service.observations)) {
              treatment.service.observations.forEach((item) => {
                const key = this.coolToneFormService.getObsKey(item);
                this.plannedTreatment.set(key, true);
              });
            }
          });
        }
      });

    this.currentDataService.preventUserFromNavigation$.pipe(takeUntil(this.unsub)).subscribe((navLocked) => {
      this.navLocked = navLocked;
    });

    this.appointmentsService
      .getAppointmentsByPatientId(this.patient.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((a) => {
        const appt = a.find((s) => s.serviceId === this.serviceId);

        if (!isNullOrUndefined(appt)) {
          this.treatmentDate = moment(appt.date).add(appt.startTime);
        } else {
          this.treatmentDate = moment();
        }

        if (patientObrs.length > 0) {
          this.initialPlot = true;
        }

        this.plottingEventService.plotObservations(patientObrs, !this.service.isLocked);
      });

    this.currentDataService.treatmentIsDirty = false;
  }

  private initPlottingSubscription() {
    this.plottingEventService
      .getPlotSource()
      .pipe(takeUntil(this.unsub))
      .subscribe(async (response) => {
        switch (response.event) {
          case ObrPlottingEventType.SendDetails: {
            this.activePlotKey = '';
            if (this.initialPlot) {
              this.initialPlot = false;
            } else {
              this.currentDataService.treatmentIsDirty = true;
            }
            response.details.forEach((obs: Observation, leafletId: number) => {
              const key = this.coolToneFormService.getObsKey(obs);
              this.updateTreatmentMap(key, leafletId, obs);
            });
            this.serviceEventService.recalculateTreatmentBilling.next(this.getTreatmentObservations());

            break;
          }
          case ObrPlottingEventType.SendAnnotations: {
            response.details.forEach((annotationDetails: any, leafletId: number) => {
              if (!isNullOrUndefined(annotationDetails.id)) {
                this.annotationMap.set(leafletId, annotationDetails);
                if (this.initialPlot) {
                  this.initialPlot = false;
                } else {
                  this.currentDataService.treatmentIsDirty = true;
                }
              } else {
                const obr = new Observation({
                  id: 0,
                  typeId: this.observationType.id,
                  name: 'Annotation',
                  details: annotationDetails,
                  patientId: this.patient.patientId,
                  serviceId: this.serviceId,
                });

                this.annotationMap.set(leafletId, obr);
                this.currentDataService.treatmentIsDirty = true;
              }
            });
            break;
          }
          case ObrPlottingEventType.DeleteAnnotations: {
            response.leafletIds.forEach((leafletId: number) => {
              const obr = this.annotationMap.get(leafletId);
              if (!isNullOrUndefined(obr.id) && obr.id !== 0) {
                this.obrIdsToDelete.push(obr.id);
                this.currentDataService.treatmentIsDirty = true;
              }
              this.annotationMap.delete(leafletId);
            });
            break;
          }
          default:
            break;
        }
      });

    this.plottingEventService
      .getObservationSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ObrPlottingEventType.StartPinDrop: {
            this.plottingActive = true;
            break;
          }
          case ObrPlottingEventType.StopPinDrop: {
            this.plottingActive = false;
            break;
          }
          default:
            break;
        }
      });
  }

  private updateTreatmentMap(obsKey: string, leafletId: number, obs: Observation) {
    const existing = this.treatmentMap.get(obsKey);
    if (existing) {
      existing.observation.details.plotDetails = {
        ...existing.observation.details.plotDetails,
        ...obs.details.plotDetails,
      };
    } else {
      this.treatmentMap.set(obsKey, { observation: obs, leafletId: leafletId });
      if (!this.service.isLocked) this.updateTreatmentPrices(obs);
    }
  }

  private updateTreatmentPrices(obs: Observation) {
    const areaKey = this.coolToneFormService.getAreaKey(obs);
    const obsKey = this.coolToneFormService.getObsKey(obs);
    const obsListItem = this.observationListItemService.observationListItems.get(obs.details.applicator);
    const areaObservations = [...this.treatmentMap.values()]
      .map((obsLeafId) => obsLeafId.observation)
      .filter((obs) => obs.details.area == areaKey);
    const newPrice = (obsListItem.pricePerUnit ?? 0) / areaObservations.length;
    areaObservations.forEach((obs) => (obs.details.productPrice = newPrice));
    const obrGroup = new TreatmentObservationGroup({ key: obsKey, observations: [obs] });
    this.serviceEventService.recalculateTotal.next(obrGroup);
  }

  private initServiceEventSubscription() {
    this.serviceEventService
      .getServiceSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ServiceEventType.ApplyTreatment: {
            if (response.obrType === ObservationTypes.CoolTone) {
              this.applyTreatment(response.service);
            }
          }
        }
      });
  }

  async saveTreatment(isClosed: boolean) {
    this.loading = true;

    this.currentDataService.setPreventUserFromNavigation(true);
    const serviceNote = new ServiceNote({
      serviceNoteId:
        !isNullOrUndefined(this.service.serviceNotes) && this.service.serviceNotes.length > 0
          ? this.service.serviceNotes[0].serviceNoteId
          : 0,
      serviceId: this.service.serviceId,
      entryDate: new Date(),
      enteredBy: this.usersService.loggedInUser.firstName + ' ' + this.usersService.loggedInUser.lastName,
      entryText: this.serviceNoteText,
    });

    const observations: Observation[] = [];
    this.treatmentMap.forEach((obsLeafletId) => {
      const cleanedObs = JSON.parse(JSON.stringify(obsLeafletId.observation));
      observations.push(cleanedObs);
    });

    this.annotationMap.forEach((annotationDetails: Observation) => {
      observations.push(JSON.parse(JSON.stringify(annotationDetails)));
    });

    this.observationService
      .deleteObservations(this.obrIdsToDelete, this.service.serviceId)
      .pipe(
        switchMap((x) => {
          return zip(
            of(x),
            this.observationService.addObservations(observations, this.service.serviceId, this.isTreatmentPlan),
            this.servicesService.updateServiceNote(serviceNote)
          );
        })
      )
      .subscribe(([_, savedObrs, updatedServiceNote]) => {
        this.obrIdsToDelete = [];

        // Update the maps accordingly
        const oldTreatmentMap = new Map(this.treatmentMap);
        this.treatmentMap.clear();
        this.treatmentMap = new Map();
        savedObrs.forEach((obs) => {
          if (obs.name === 'Annotation') {
            this.annotationMap.set(obs.details.leafletId, obs);
          } else {
            const mapKey = this.coolToneFormService.getObsKey(obs);
            const leafletId = oldTreatmentMap.get(mapKey).leafletId;
            this.treatmentMap.set(mapKey, { observation: obs, leafletId: leafletId });
          }
        });

        this.observationService.getAllObservationsByPatientId(this.patient.patientId).subscribe((response) => {
          this.patient.observations = response;
          this.patientService.thePatientHasBeenUpdated(this.patient);
        });

        this.service.observations = savedObrs;
        if (isNullOrUndefined(this.service.serviceNotes)) {
          this.service.serviceNotes = [updatedServiceNote];
        } else {
          this.service.serviceNotes[0] = updatedServiceNote;
        }

        this.serviceEventService.emitTreatmentSaved(this.service, isClosed);
        this.currentDataService.treatmentIsDirty = false;
        this.loading = false;
      });
  }

  deleteTreatment(mapKey: string) {
    const leafletId = this.treatmentMap.get(mapKey)?.leafletId;
    const obs = this.treatmentMap.get(mapKey)?.observation;
    if (obs.id > 0) {
      this.obrIdsToDelete.push(obs.id);
    }
    if (!isNullOrUndefined(obs.details.treatmentReference)) {
      this.coolToneFormService.treatmentRemoved.next(obs.details.treatmentReference);
    }

    this.treatmentMap.delete(mapKey);
    this.updateTreatmentPrices(obs);

    this.serviceEventService.recalculateTreatmentBilling.next(this.getTreatmentObservations());
    this.plottingEventService.deletePoints([leafletId], mapKey);
    this.serviceEventService.treatmentObservationsGroupDeleted.next(mapKey);
    this.currentDataService.treatmentIsDirty = true;
  }

  updateChargeAmount(e) {
    this.chargeAmount = e;
  }

  saveAsFavourite() {
    if (this.plottingActive) this.plottingEventService.stopPinDrop();
    if (this.treatmentMap.size === 0) {
      this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Error',
          content: 'You must have at least one Treatment Detail before creating a Favourite!',
          confirmButtonText: 'Ok',
        },
      });
    } else {
      const dialogRef = this.dialog.open(GenericInputComponent, {
        width: '300px',
        data: {
          title: 'Add to Favourites',
          inputLabel: 'Name',
          confirmButtonText: 'Save',
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((favouriteName) => {
          if (favouriteName !== 'cancel') {
            // Get the current service
            this.servicesService.getServiceById(this.serviceId).subscribe((currentService: Service) => {
              // Do a "deep-copy" of the current service
              const favService = JSON.parse(JSON.stringify(currentService));
              favService.serviceId = 0;
              favService.subType = 'Favourite';
              favService.signedById = this.usersService.loggedInUser.id; //using signedById because favourite service will never be used by an appointment so this value is unused for the regular circumastance
              favService.category = null;
              favService.observations = null;
              favService.serviceResources = [];
              favService.chargeAmount = this.chargeAmount;
              if (!isNullOrUndefined(favouriteName)) {
                favService.serviceName = favouriteName;
              }
              // Save the service so that we have the service id so we can add the observations
              this.servicesService.addService(favService).subscribe((favService) => {
                //Create a UserFavouriteService to keep track of order
                var userFavService: UserFavouriteService = {
                  userId: this.usersService.loggedInUser.id,
                  serviceId: favService.serviceId,
                  order: 99,
                };
                this.favouriteServicesService.addFavouriteService(userFavService).subscribe();

                const observations: Observation[] = [];
                this.treatmentMap.forEach((obsLeafletId) => {
                  const obsToSave = JSON.parse(JSON.stringify(obsLeafletId.observation));
                  obsToSave.id = 0;
                  obsToSave.patientId = this.templatePatient.patientId;
                  obsToSave.patient = null;
                  obsToSave.serviceId = favService.serviceId;
                  obsToSave.dateCreated = new Date();
                  obsToSave.dateUpdated = new Date();
                  obsToSave.details.massage = false;
                  obsToSave.details.treatmentReference = null;
                  observations.push(obsToSave);
                });
                this.observationService
                  .addObservations(observations, favService.serviceId)
                  .pipe(mergeMap(() => this.servicesService.updateService(favService)))
                  .subscribe(() => {
                    this.currentDataService.favouritesDataHasBeenUpdated(null);
                  });
              });
            });
          }
        });
    }
  }

  getCircleColor(mapKey: string) {
    const obr = this.treatmentMap.get(mapKey).observation;
    return obr.details.plotDetails.pointColor;
  }

  isUnitPriceOverriden(mapKey: string): boolean {
    return this.treatmentMap.get(mapKey).observation.details.overrideProductPrice ? true : false;
  }

  isPriceOverridden(mapKey: string): boolean {
    const observation = this.treatmentMap.get(mapKey).observation;
    return observation.details.isOverrideProductPrice || observation.details.isOverrideTotalPrice ? true : false;
  }

  getObservation(mapKey: string) {
    return this.treatmentMap.get(mapKey).observation;
  }

  getTreatmentObservations(): Observation[] {
    return [...this.treatmentMap.values()].map((obsLeafId) => JSON.parse(JSON.stringify(obsLeafId.observation)));
  }

  applyTreatment(service: Service) {
    const observations = this.servicesService.applyTreatmentObservationsToService(service, this.service, this.patient);
    this.plottingEventService.plotObservations(observations, !this.service.isLocked);
    const serviceNoteText = this.servicesService.applyServiceNotesToService(service, this.service);
    if (serviceNoteText.length > 0) {
      if (this.serviceNoteText.length > 0) {
        this.serviceNoteText += '\n' + serviceNoteText;
      } else {
        this.serviceNoteText = serviceNoteText;
      }
    }
  }

  undoOverridePrice(mapKey: string) {
    const obs = this.treatmentMap.get(mapKey).observation;
    obs.details.overrideProductPrice = null;
    obs.details.isOverrideProductPrice = false;
    obs.details.isOverrideTotalPrice = false;
    obs.details.overrideTotalPrice = null;

    this.currentDataService.treatmentIsDirty = true;
    const obrGroup = new TreatmentObservationGroup({
      key: mapKey,
      observations: [obs],
    });
    this.serviceEventService.recalculateTotal.next(obrGroup);
    this.serviceEventService.billingObservationsOverridden.next(obrGroup);
    this.serviceEventService.observationTotalOverrideCancelled.next(obrGroup);
    this.serviceEventService.observationUnitPriceOverrideCancelled.next(obrGroup);
  }

  toggleView() {
    if (!this.currentDataService.treatmentIsDirty || !this.service.isLocked) {
      if (this.isTreatmentPlan) {
        this.router.navigateByUrl(
          `/schedule/(action-panel:patient/${this.patient.patientId}_patientprofiletab/patienttabs/patienttreatmentplantab/overview)`
        );
      } else {
        this.router.navigateByUrl(
          `/schedule/(action-panel:patient/${this.patient.patientId}_patientprofiletab/patienttabs/patientcharttab/overview)`
        );
      }
    }
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    this.coolToneFormService.observationKeys.clear();
  }
}
