import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-move-appointment',
  templateUrl: './move-appointment.component.html',
  styleUrls: ['./move-appointment.component.less']
})
export class MoveAppointmentDialogComponent {
  public dialog: MatDialog;
  newDate: string;

  constructor(
    public dialogRef: MatDialogRef<MoveAppointmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public targetDate) {
    this.newDate = targetDate.targetDate.format('YYYY-MM-DD');
  }

  onNoClick(): void {
    this.targetDate = null;
  }
}
