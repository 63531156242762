import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MasterOverlayService } from '@services/actionpanel.service';
import { PatientService } from '@services/patient.service';

@Component({
  selector: 'app-add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.less']
})
export class AddPatientComponent implements OnInit {

  @Input() sideNavExpanded: boolean;

  constructor(
    private patientService: PatientService,
    private router: Router,
    private masterOverlayService: MasterOverlayService
  ) {}

  ngOnInit() {}

  addPatient() {
    const baseURL = this.router.url.split('/(action')[0];
    this.patientService.editPatientSourceURL = baseURL;
    this.masterOverlayService.masterOverlayState(true);
    this.patientService.editedPatient = null;
    this.router.navigate([baseURL, { outlets: { 'action-panel': ['edit-patient', '_'] } }]);
  }
}
