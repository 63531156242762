import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CanComponentDeactivate } from '@app/shared/guards/confirm-deactivate.guard';
import { TabType } from '@models/tab-type.enum';
import { ActionPanelService } from '@services/actionpanel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-chart-overview',
  templateUrl: './chart-overview.component.html',
  styleUrls: ['./chart-overview.component.less'],
})
export class ChartOverviewComponent implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate, OnChanges {
  isChartEdited = false;
  unsub: Subject<void> = new Subject<void>();
  @ViewChild('panelContainer', { static: true }) panelContainer: ElementRef;
  panelHeight: number;
  todaysDate = new Date();
  TabType = TabType;

  @HostListener('window:resize', ['$event'])
  onScreenResize() {
    this.adjustComponentHeights();
  }

  constructor(private cdr: ChangeDetectorRef, private actionPanelService: ActionPanelService) {
    const actionPanelOpened$ = this.actionPanelService.actionPanelVisible().pipe(takeUntil(this.unsub));
    let sub = actionPanelOpened$.subscribe(() => {
      setTimeout(() => {
        sub.unsubscribe();
        this.adjustComponentHeights();
      });
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    this.adjustComponentHeights();
  }

  ngAfterViewInit() {
    this.adjustComponentHeights();
  }

  private adjustComponentHeights() {
    this.panelHeight = this.panelContainer.nativeElement.offsetHeight; //50px padding top/bottom and 16px padding between
    this.cdr.detectChanges();
  }

  onServiceChartNoteChange(event: boolean) {
    this.isChartEdited = event;
  }

  confirm(): boolean {
    return !this.isChartEdited;
  }

  chartEdited(edited: boolean) {
    this.isChartEdited = edited;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
