import { PaymentStatus } from './payment-status';

export class AppointmentTile {
  classList: string;
  start: string;
  end: string;
  color: string;
  className: string;
  resourceId: string;
  id: number;
  altid: number | undefined;
  source: any;
  title: string;
  notes: string;
  visitIdString: string;
  backColor: string;
  rendering: string;
  patientName: string;
  patientBirthday: string;
  serviceName: string;
  visitStatus: string;
  apptType: number;
  isPreferred: boolean;
  isLocked: boolean;
  daysUntlBirthday: number = 365;
  paymentStatus: PaymentStatus;
  isMinistry: boolean;
  serviceIcon: string;
  roomName: string;
  onlineBooked: boolean = false;
  createdDate: Date;
  isPlaceholder: boolean;
  reasonCancellationNotCharged: string;
  squarePaymentId: number;
  convergePaymentId: number;
  requestCreditCard: boolean;
}
