import { Appointment } from '@models/scheduler/event';
import { Form } from './form';

export class PatientForm {
  public id: number;
  public clinicFormId: number;
  public clinicForm: Form;
  public patientId: number;
  public appointmentId: number;
  public formEntry: string;
  public blobUrl: string;
  public rendererVersion: string;
  public assignedDate: Date;
  public modifiedDate: Date;
  public signedDate: Date;
  public isDeleted: boolean;
  public isSigned: boolean;

  public static fromForm(patientId: number, form: Form) {
    let patientForm = new PatientForm();
    patientForm.clinicFormId = form.id;
    patientForm.clinicForm = form;
    patientForm.patientId = patientId;
    patientForm.formEntry = form.formDefinition;
    patientForm.isDeleted = false;
    patientForm.isSigned = false;
    return patientForm;
  }

  public constructor(init?: Partial<PatientForm>) {
    Object.assign(this, init);
  }
}
