<div class="h-100">
  <div class="container-fluid">
    <div class="row py-4 px-2 navy-background">
      <div class="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center py-1">
        <h5 class="white-font text-uppercase m-0 mr-2">Patients</h5>
      </div>
    </div>
  </div>

  <ngx-loading [show]="loading"></ngx-loading>
  <ngx-loading [show]="disableGrid" id="gridDisableDiv"></ngx-loading>

  <app-error *ngIf="errorMessage" [message]="errorMessage"></app-error>

  <div [ngStyle]="{ height: 'calc(100dvh - 75px)' }">
    <kendo-grid
      [data]="gridView"
      [pageSize]="gridState.take"
      [skip]="gridState.skip"
      [sort]="gridState.sort"
      [sortable]="true"
      [selectable]="selectableSettings"
      [pageable]="true"
      kendoGridSelectBy="patientId"
      [selectedKeys]="mySelections"
      [style.height.%]="100"
      [filter]="gridState.filter"
      [filterable]="false"
      (dataStateChange)="dataStateChange($event)"
      (add)="onAddClick($event)"
      (cancel)="cancelHandler($event)"
      (save)="saveHandler($event)"
      (edit)="editHandler($event)"
      (remove)="removeHandler($event)"
    >
      <ng-template kendoGridToolbarTemplate>
        <div class="d-flex">
          <span class="pl-4 search-box border border-radius">
            <span class="fa fa-search"></span>
            <mat-form-field class="w-75 p-3">
              <input
                matInput
                placeholder="Search patients..."
                aria-label="Search Patients"
                [formControl]="searchCtrl"
                [(ngModel)]="searchValue"
                (ngModelChange)="search($event)"
              />
              <button
                mat-button
                *ngIf="searchValue"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="searchValue = ''"
                style="vertical-align: bottom"
                class="btn-clean"
              >
                <i class="fas fa-times-circle"></i>
              </button>
            </mat-form-field>
          </span>
        </div>
        <button kendoGridAddCommand type="button" class="btn em-btn">
          <i class="fal fa-plus-circle"></i> Add Patient
        </button>
        <button
          kendoButton
          type="button"
          [disabled]="checkedPatientCount == 2"
          class="btn em-btn"
          (click)="mergePatients()"
        >
          <i class="fal fa-user-friends"></i> Merge Patients
        </button>
        <button
          kendoButton
          type="button"
          [disabled]="checkedPatientCount == 0"
          class="btn em-btn"
          (click)="clearSelection()"
        >
          <i class="fal fa-ban"></i> Clear Selections
        </button>
        <button kendoGridExcelCommand class="btn em-btn m-0" type="button">
          <i class="fal fa-file-excel"></i> Export to Excel
        </button>
        <!-- Not woking, exceeding memory, possibly too much data -->
        <!-- <button kendoGridPDFCommand class="btn kendo-btn kendo-btn-green em-btn" type="button">
          <i class="fal fa-file-pdf"></i> Export to PDF
        </button> -->
      </ng-template>

      <!--    <kendo-grid-checkbox-column
        [width]="30"
        [headerClass]="{'text-center': true}"
        [class]="{'text-center': true}"
        [resizable]="false"
        [columnMenu]="false" showSelectAll="true">
        (change)="selectPatient(dataItem, $event.target.checked)">
        </kendo-grid-checkbox-column> -->
      <kendo-grid-checkbox-column
        [width]="30"
        [headerClass]="{ 'text-center': true }"
        [class]="{ 'text-center': true }"
        [resizable]="false"
        [columnMenu]="false"
        [showSelectAll]="true"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-idx="rowIndex">
          <input
            type="checkbox"
            (change)="selectPatient(dataItem, $event)"
            [checked]="isPatientSelected(dataItem)"
          />
        </ng-template>
      </kendo-grid-checkbox-column>
      <kendo-grid-column field="avatar" title="Avatar" [width]="50" [headerStyle]="{ width: '50px' }" [sortable]="false">
        <ng-template kendoGridFilterCellTemplate let-filter> </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <img
            class="avatar-placeholder"
            [src]="dataItem.avatar ? '' + dataItem.avatar + '' : '/assets/Avatars/avatar-placeholder.png'"
            width="50"
          />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="firstName" title="First Name" [width]="115"></kendo-grid-column>
      <kendo-grid-column field="lastName" title="Last Name" [width]="115"></kendo-grid-column>
      <kendo-grid-column field="gender" title="Gender" [width]="75">
        <ng-template kendoGridFilterCellTemplate let-filter>
          <app-dropdownlist-filter [filter]="filter" [data]="distinctGenders()" textField="gender" valueField="gender">
          </app-dropdownlist-filter>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="homeNumber" title="Home Phone #" [width]="100" [sortable]="false"></kendo-grid-column>
      <kendo-grid-column
        field="mobileNumber"
        title="Mobile Phone #"
        [width]="100"
        [sortable]="false"
      ></kendo-grid-column>
      <kendo-grid-column field="email" title="Email Address" [width]="135" [sortable]="false"></kendo-grid-column>
      <kendo-grid-column field="isPreferred" title="Preferred" [width]="60" filter="boolean" [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <input type="checkbox" [checked]="dataItem.isPreferred" disabled />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="address.city" title="City" [width]="100"></kendo-grid-column>
      <kendo-grid-column field="address.province" title="Province" [width]="100" [sortable]="false">
      </kendo-grid-column>
      <kendo-grid-column field="birthDate" title="Birth Date" [width]="85">
        <ng-template kendoGridFilterCellTemplate let-filter> </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.birthDate | convertUTC }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="patientStatus" title="Status" [width]="75" [sortable]="false">
        <ng-template kendoGridFilterCellTemplate let-filter> </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div *ngFor="let patientStatus of statusEnumKeys">
            <div *ngIf="dataItem.patientStatus == patientStatus">{{ patientStatusData[patientStatus] }}</div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="Actions" min-width="110px" [width]="110">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem" style="text-overflow: clip">
          <button
            kendoGridEditCommand
            class="btn kendo-btn"
            kendoTooltip
            tooltipClass="em-tooltip"
            position="top"
            title="Quick Edit"
          >
            <i class="fas fa-pencil"></i>
          </button>
          <button
            class="btn kendo-btn"
            (click)="editDetailsHandler(dataItem)"
            kendoTooltip
            tooltipClass="em-tooltip"
            position="top"
            title="Edit Details"
          >
            <i class="fas fa-edit"></i>
          </button>
          <button
            class="btn kendo-btn"
            (click)="createPatientPortalAccount(dataItem)"
            kendoTooltip
            tooltipClass="em-tooltip"
            position="top"
            title="Create Patient Portal Account"
          >
            <i class="fas fa-user"></i>
          </button>
          <button kendoGridSaveCommand [disabled]="formGroup?.invalid">{{ isNew ? 'Add' : 'Update' }}</button>
          <button kendoGridCancelCommand>{{ isNew ? 'Discard changes' : 'Cancel' }}</button>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-excel fileName="Patients.xlsx" [fetchData]="allData"></kendo-grid-excel>
      <kendo-grid-pdf fileName="Patients.pdf" [allPages]="true">
        <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
      </kendo-grid-pdf>
    </kendo-grid>
  </div>

  <p-overlayPanel #hoverPanel id="hoverPanel" [dismissable]="false"> Content </p-overlayPanel>
  <app-actionpanel></app-actionpanel>
</div>
