<ngx-loading [show]="loading"></ngx-loading>

<ng-container *ngIf="false">
  <!-- Patient Demographic Info -->
  <div class="row panel-item-border mt-2 py-2">
    <div class="col">
      <div class="row mb-2 form-row">
        <div class="col-6 mb-2">
          <mat-form-field [appearance]="editIsEnabled ? 'standard' : null">
            <mat-label class="mb-0">Status</mat-label>
            <mat-select [value]="'Active'" style [readonly]="editIsEnabled ? null : true">
              <mat-option value="Active">Active</mat-option>
              <mat-option value="Inactive">Inactive</mat-option>
              <mat-option value="Merged">Merged</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <label class="d-block mb-0 teal-font fake-mat-label">VIP Customer</label>
          <label class="switch align-text-top">
            <input type="checkbox" [ngModel]="false" [disabled]="editIsEnabled === false" />
            <span
              [ngStyle]="editIsEnabled === false && { 'pointer-events': 'none' }"
              class="slider round"
              [ngClass]="{ 'disabled-slider': !editIsEnabled }"
            ></span>
          </label>
          <span class="ml-1">{{ thePatient?.isPreferred ? 'Yes' : 'No' }}</span>
        </div>
        <div class="col-2"></div>
      </div>
      <div *ngIf="false; else row2">
        <div class="row mb-2">
          <div class="col">
            <label class="d-block mb-0 teal-font">First Name</label>
            {{ thePatient?.firstName | titlecase }}
          </div>
          <div class="col">
            <label class="d-block mb-0 teal-font">Last Name</label>
            {{ thePatient?.lastName | titlecase }}
          </div>
        </div>
      </div>
      <ng-template #row2>
        <!--Using Material-->
        <div class="row mb-2 form-row">
          <div class="col-6">
            <mat-form-field [appearance]="editIsEnabled ? 'standard' : null">
              <mat-label class="mb-0">First Name</mat-label>
              <input matInput [ngModel]="thePatient?.firstName" placeholder="First Name" />
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field [appearance]="editIsEnabled ? 'standard' : null">
              <mat-label>Last Name</mat-label>
              <input matInput [ngModel]="thePatient?.lastName" placeholder="Last Name" />
            </mat-form-field>
          </div>
        </div>
      </ng-template>
      <div class="row mb-2">
        <div class="col">
          <label class="d-block mb-0 teal-font">Nickname</label>
          <span *ngIf="thePatient?.nickName">{{ thePatient?.nickName | titlecase }}</span>
          <span *ngIf="!thePatient?.nickName">Not on record</span>
        </div>
        <div class="col">
          <label class="d-block mb-0 teal-font">Gender</label>
          <i
            class="fas"
            [ngClass]="{
              'fa-mars': thePatient?.gender === 'Male',
              'fa-venus': thePatient?.gender === 'Female',
              'fa-genderless': thePatient?.gender !== 'Male' && thePatient?.gender !== 'Female'
            }"
            title="Gender"
          ></i>
          {{ thePatient?.gender }}
        </div>
      </div>
      <div class="row">
        <div #bdaySection defaultBirthdayCheck [birthDate]="thePatient?.birthDate" class="col-6">
          <mat-form-field [appearance]="editIsEnabled ? 'standard' : null">
            <mat-label>Date of Birth</mat-label>
            <input matInput [matDatepicker]="$any(picker)" [value]="thePatient?.birthDate" />
            <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
            <sat-datepicker #picker></sat-datepicker>
          </mat-form-field>
        </div>
        <div class="col">
          <label class="d-block mb-0 teal-font">Health Care Number</label>
          {{ thePatient?.clientId }}
        </div>
      </div>
    </div>
  </div>
  <div class="row pb-2 panel-item-border grey-two-background">
    <div class="col">
      <div class="row mt-2">
        <div class="col">
          <label class="d-block mb-0 teal-font">Address 1</label>
          {{ thePatient?.address.address1 }}
        </div>
        <div class="col">
          <label class="d-block mb-0 teal-font">Address 2</label>
          {{ thePatient?.address.address2 }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <label class="d-block mb-0 teal-font">City</label>
          {{ thePatient?.address.city }}
        </div>
        <div class="col">
          <label class="d-block mb-0 teal-font">Province/State</label>
          {{ thePatient?.address.province }}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <label class="d-block mb-0 teal-font">Postal Code</label>
          {{ thePatient?.address.postalCode.toUpperCase() }}
        </div>
        <div class="col">
          <label class="d-block mb-0 teal-font">Country</label>
          {{ thePatient?.address.country | titlecase }}
        </div>
      </div>
    </div>
  </div>
  <!-- Patient Contact Info-->
  <div class="row mt-2 pb-2 panel-item-border">
    <div class="col">
      <div class="row">
        <div class="col-6">
          <label class="d-block mt-2 mb-0 teal-font">Mobile Phone</label>
          <span *ngIf="thePatient?.mobileNumber.length">{{ thePatient?.mobileNumber }}</span>
          <span *ngIf="!thePatient?.mobileNumber.length">Not on record</span>
        </div>
        <div class="col">
          <label class="d-block mt-2 mb-0 teal-font">Home Phone</label>
          <span *ngIf="thePatient?.homeNumber.length">{{ thePatient?.homeNumber }}</span>
          <span *ngIf="!thePatient?.homeNumber.length">Not on record</span>
        </div>
        <div class="col-2 align-middle d-flex">
          <button *ngIf="allowEdit" class="btn btn-sm em-btn em-btn-green pull-right m-auto justify-self-end">
            <i class="fas fa-pencil small" title="Edit"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="d-block mt-2 mb-0 teal-font">Email Address</label>
          <span class="text-truncate">{{ thePatient?.email }}</span>
        </div>
        <div class="col">
          <label class="d-block mt-2 mb-0 teal-font"> Communication Preference </label>
          {{ thePatient?.communicationPreference }}
        </div>
      </div>
    </div>
  </div>
  <!-- Notifications Settings -->
  <div class="row pb-2 panel-item-border grey-two-background">
    <div class="col">
      <div class="row">
        <div class="col-6">
          <label class="d-block mt-2 mb-0 teal-font">SMS Appt. Reminders</label>
          <label class="switch align-text-top">
            <input
              type="checkbox"
              [ngModel]="thePatient?.sendAppointmentSMSNotifications"
              [disabled]="editIsEnabled === false"
            />
            <span
              [ngStyle]="editIsEnabled === false && { 'pointer-events': 'none' }"
              class="slider round"
              [ngClass]="{ 'disabled-slider': !editIsEnabled }"
            ></span>
          </label>
          <span *ngIf="thePatient?.sendAppointmentSMSNotifications" class="pl-2">Send</span>
          <span *ngIf="!thePatient?.sendAppointmentSMSNotifications" class="pl-2">Don't send</span>
        </div>
        <div class="col-6">
          <label class="d-block mt-2 mb-0 teal-font">Email Appt. Reminders</label>
          <label class="switch align-text-top">
            <input
              type="checkbox"
              [ngModel]="thePatient?.sendAppointmentEmailNotifications"
              [disabled]="editIsEnabled === false"
            />
            <span
              [ngStyle]="editIsEnabled === false && { 'pointer-events': 'none' }"
              class="slider round"
              [ngClass]="{ 'disabled-slider': !editIsEnabled }"
            ></span>
          </label>
          <span *ngIf="thePatient?.sendAppointmentEmailNotifications" class="pl-2">Send</span>
          <span *ngIf="!thePatient?.sendAppointmentEmailNotifications" class="pl-2">Don't send</span>
        </div>
      </div>
      <div *ngIf="thePatient?.sendAppointmentEmailNotifications" class="row">
        <div class="col">
          <label class="switch align-text-top">
            <input
              type="checkbox"
              [ngModel]="thePatient?.sendAppointmentEmailNotifications"
              [attr.disabled]="editIsEnabled ? null : true"
            />
            <span
              [ngStyle]="editIsEnabled === false && { 'pointer-events': 'none' }"
              class="slider round"
              [ngClass]="{ 'disabled-slider': !editIsEnabled }"
            ></span>
          </label>
          <span class="pl-2">Unsubscribed From Email Appointment Reminders</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label class="d-block mt-2 mb-0 teal-font">Reviews 123</label>
          <label class="switch align-text-top">
            <input type="checkbox" [ngModel]="true" [disabled]="editIsEnabled === false" />
            <span
              [ngStyle]="editIsEnabled === false && { 'pointer-events': 'none' }"
              class="slider round"
              [ngClass]="{ 'disabled-slider': !editIsEnabled }"
            ></span>
          </label>
          <span *ngIf="true" class="pl-2">Send</span>
        </div>
        <div class="col">
          <label class="d-block mt-2 mb-0 teal-font"> Retention Messages 123 </label>
          <label class="switch align-text-top">
            <input type="checkbox" [ngModel]="thePatient?.sendRetentionEmails" [disabled]="editIsEnabled === false" />
            <span
              [ngStyle]="editIsEnabled === false && { 'pointer-events': 'none' }"
              class="slider round"
              [ngClass]="{ 'disabled-slider': !editIsEnabled }"
            ></span>
          </label>
          <span *ngIf="thePatient?.sendRetentionEmails" class="pl-2">Send</span>
          <span *ngIf="!thePatient?.sendRetentionEmails" class="pl-2">Don't send</span>
        </div>
      </div>
    </div>
  </div>
  <!--Physician & Pharmacy selection-->
  <div class="row mt-0 pb-2 panel-item-border">
    <div class="col">
      <div class="row">
        <div class="col">
          <label class="d-block mt-2 mb-0 teal-font">Family Physician</label>
          {{ thePatient?.familyPhysician?.proTitle }} {{ thePatient?.familyPhysician?.firstName }}
          {{ thePatient?.familyPhysician?.lastName }}
        </div>
        <div class="col">
          <label class="d-block mt-2 mb-0 teal-font">Preferred Pharmacy</label>
          {{ thePatient?.preferredPharmacy?.name }}
        </div>
      </div>
      <div class="row"></div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="thePatient">
  <app-edit-patient-content [currentPatient]="thePatient" [viewModeDefault]="true"> </app-edit-patient-content>
</ng-container>
<!-- Patient Notes/Alerts -->
<div class="patient-notes p-2 grey-two-background" *ngIf="thePatient && showNotes">
  <div class="d-flex justify-content-between align-items-center pb-2">
    <h6 class="m-0">Notes / Alerts</h6>
    <mat-slide-toggle
      [color]="'warn'"
      class="rush-note-toggle"
      [ngModel]="thePatient.rushPatientNote"
      title="Toggle important patient note"
      (change)="togglePatientRushNote($event?.checked)"
    >
    </mat-slide-toggle>
  </div>
  <app-textarea-widget
    *ngIf="thePatient"
    [minRows]="4"
    [maxRows]="4"
    [textValue]="thePatient.notesAndAlerts"
    (emitText)="savePatientNotes($event)"
  >
  </app-textarea-widget>
</div>
<!-- Directly request review from patient -->
<div *appAuthGuard="reviewsRequestPolicy" class="d-flex flex-column justify-content-center my-3 w-100">
  <button
    (click)="reviewManuallyRequested ? null : showRequestReviewPopup()"
    data-test-id="request-review-button"
    class="btn btn-sm em-btn em-btn-green py-2 px-3 mx-auto font-weight-bold"
    kendoTooltip
    tooltipClass="em-tooltip"
    [title]="
      !clinicHasReviewsEnabled
        ? 'Clinic has Google reviews disabled'
        : !thePatient?.sendReviews
        ? 'Review requests disabled for this patient'
        : (thePatient?.email == null || thePatient?.email == '') &&
          (thePatient?.mobileNumber == null || thePatient.mobileNumber == '')
        ? 'Please enter an email and/or mobile # for this patient'
        : 'Request a Google review from this patient'
    "
    [disabled]="requestReviewButtonDisabled()"
    style="line-height: 1em"
  >
    <i *ngIf="!reviewManuallyRequested" class="fas fa-envelope small pr-2"></i>
    {{ reviewManuallyRequested ? 'Review Requested' : 'Request Google Review' }}
  </button>
  <span class="mx-auto" style="font-size: 1.1em">{{ latestReviewRequestMessage }}</span>
</div>
