<div class="sidenav" [ngClass]="{ sideNavCollapsed: !sideNavExpanded }">
  <div class="d-flex h-100 flex-column">
    <div class="profile">
      <div
        kendoTooltip
        tooltipClass="em-tooltip"
        position="right"
        class="img-container"
        [ngClass]="{ 'mx-auto': !sideNavExpanded }"
      >
        <img
          class="img-avatar"
          [src]="loggedInUserAvatar"
          alt="User Avatar"
          title="Account"
          (click)="userAccountClick()"
        />
      </div>
      <div [ngClass]="{ inactive: !sideNavExpanded }" class="info">
        <h5 class="text-uppercase">{{ loggedInUserName }}</h5>
      </div>
    </div>
    <div class="navbar">
      <ul class="nav flex-column">
        <li class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/schedule">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <i class="fal fa-home fa-lg" title="Schedule"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Schedule</span>
          </a>
        </li>
        <li class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/nudges">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon position-relative"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <div *ngIf="nudgeCounter" class="nav-link-badge position-absolute">{{ nudgeCounter }}</div>
              <i class="far fa-bell fa-lg" title="Nudges"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Nudges</span>
          </a>
        </li>
        <li *appAuthGuard="patientMessaging" class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/patient-messaging">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon position-relative"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <div *ngIf="patientMessageCount" class="nav-link-badge position-absolute">{{ patientMessageCount }}</div>
              <i class="far fa-comments-alt fa-lg" title="Patient Messaging"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Patient Messaging</span>
          </a>
        </li>
        <li *appAuthGuard="reviewsPolicy" class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/reviews">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon position-relative"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <div *ngIf="reviewNotificationsCounter" class="nav-link-badge position-absolute">
                {{ reviewNotificationsCounter }}
              </div>
              <i class="far fa-star fa-lg" title="Reviews"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Reviews</span>
          </a>
        </li>
        <li *appAuthGuard="galleryPolicy" class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/gallery">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <i class="fal fa-images fa-lg" title="Gallery"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Gallery</span>
          </a>
        </li>
        <li *appAuthGuard="communicationsPolicy" class="nav-item no-parent" routerLinkActive="active">
          <a class="nav-link" routerLink="/management/communication">
            <span
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              class="menu-icon"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <i class="fal fa-envelope-open-text fa-lg" title="Communications"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Communication</span>
          </a>
        </li>
        <li class="nav-item position-relative" *appAuthGuard="managementPolicy" routerLinkActive="active">
          <a class="nav-link" (clickOutside)="closeSubMenu()" (click)="adminClick()">
            <span
              class="menu-icon"
              kendoTooltip
              tooltipClass="em-tooltip"
              position="right"
              [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
            >
              <i class="fal fa-cogs fa-lg" title="Management"></i>
            </span>
            <span [ngClass]="{ inactive: !sideNavExpanded }">Management</span>
          </a>
          <div class="sub-menu-panel" [ngClass]="{ inactive: !adminOpen, 'sub-menu-panel-expanded': sideNavExpanded }">
            <ul>
              <li
                (click)="closeSubMenu()"
                class="sub-menu-item"
                [ngClass]="{ inactive: !adminOpen }"
                routerLinkActive="active"
                *appAuthGuard="cataloguePolicy"
                routerLink="/management/catalogue"
              >
                Catalogue
              </li>
              <li
                (click)="closeSubMenu()"
                class="sub-menu-item"
                [ngClass]="{ inactive: !adminOpen }"
                routerLinkActive="active"
                *appAuthGuard="formsPolicy"
                routerLink="/management/forms"
              >
                eForms
              </li>
              <li
                (click)="closeSubMenu()"
                class="sub-menu-item"
                [ngClass]="{ inactive: !adminOpen }"
                routerLinkActive="active"
                *appAuthGuard="managementOrganizationPolicy"
                routerLink="/management/organization/clinics"
              >
                Organization
              </li>
              <!--
              <li
                (click)="closeSubMenu()"
                class="sub-menu-item"
                [ngClass]="{ inactive: !adminOpen }"
                routerLinkActive="active"
                routerLink="/management/gift-cards"
              >
                Gift Cards
              </li>
              -->
            </ul>
          </div>
        </li>
      </ul>
      <div class="w-100">
        <hr />
        <app-quick-links></app-quick-links>
      </div>
    </div>
    <div
      class="expand-control flex-grow-1"
      [ngClass]="{ sideNavCollapsed: !sideNavExpanded }"
      (click)="sideNavExpandClick()"
    >
      <span
        class="fal"
        [class.fa-chevron-double-left]="sideNavExpanded"
        [class.fa-chevron-double-right]="!sideNavExpanded"
      ></span>
    </div>

    <div #logoContainer class="m-2">
      <a routerLink="/schedule">
        <img
          *ngIf="clinic?.logoUrl; else emilyLogo"
          [src]="clinic.logoUrl"
          alt="Clinic Logo"
          [width]="logoContainer.clientWidth"
        />
        <ng-template #emilyLogo>
          <div
            [ngClass]="{ 'logo-large': sideNavExpanded }"
            [inlineSVG]="'../../../../../../../assets/svg/emily-logo-final.svg'"
          ></div>
        </ng-template>
      </a>
    </div>

    <div class="pl-2 py-2 white-font">
      <a class="logout-link" data-test-id="logout-button" (click)="logout()">Log Out</a>
    </div>
  </div>
</div>
