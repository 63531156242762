<div class="nudges-container h-100 content">
  <div *ngIf="!patientId" class="nudges-header container-fluid">
    <div class="nudges-toolbar row py-4 px-2 navy-background">
      <div class="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center py-1">
        <h5 class="white-font text-uppercase m-0 mr-2">Nudges</h5>
      </div>
    </div>
  </div>

  <div class="nudges-container flex-grow-1 minh-0 p-4">
    <div class="nudges-type d-flex">
      <div *ngIf="!patientId" class="nudges-type d-flex">
        <button
          class="btn text-uppercase em-btn-nav-tab mr-2 position-relative"
          *ngFor="let item of types"
          [class.selected]="item.title === selectedBtn"
          (click)="selectBtn(item)"
        >
          <div *ngIf="myNudgesCounters[item.value]" class="my-badge position-absolute">
            {{ myNudgesCounters[item.value] }}
          </div>
          {{ item.title }}
        </button>
      </div>
      <div class="d-flex ml-auto">
        <div class="row mr-2">
          <div class="col d-flex align-items-center">
            <label class="switch align-text-top m-0">
              <input type="checkbox" [(ngModel)]="filterByUser" (change)="filterNudges()" />
              <span class="slider round"></span>
            </label>
            <label class="mb-0 pl-1 teal-font"> My Nudges </label>
          </div>
        </div>
        <button type="button" class="btn-clean teal-font mr-1" (click)="clearFilters()">Default Filter</button>
      </div>
    </div>

    <div *ngIf="state" class="flex-grow-1 minh-0">
      <kendo-grid
        class="emr-kendo-grid large-header hide-default-sort"
        [data]="gridView"
        [style.height.%]="100"
        [sortable]="{
          allowUnsort: allowUnsort,
          mode: 'single'
        }"
        filterable="menu"
        [sort]="state.sort"
        [filter]="state.filter"
        [pageSize]="pageSize"
        [skip]="skip"
        [loading]="loading"
        [pageable]="{
          buttonCount: buttonCount,
          info: info,
          pageSizes: pageSizes,
          previousNext: previousNext
        }"
        (dataStateChange)="dataStateChange($event)"
        (pageChange)="pageChange($event)"
        (edit)="openNudgeModal($event)"
      >
        <kendo-grid-column [width]="40">
          <ng-template kendoGridCellTemplate let-isNew="isNew">
            <button kendoGridEditCommand class="btn p-0 navy-font">
              <i class="fal fa-pencil"></i>
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [width]="80" title="Done">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem.status !== Status.Deleted" class="d-flex justify-content-center">
              <label class="switch align-text-top">
                <input type="checkbox" (change)="updateStatus(dataItem, $event)" [(ngModel)]="dataItem.completed" />
                <span class="slider round"></span>
              </label>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="status" title="Status" [width]="120">
          <ng-template kendoGridHeaderTemplate let-column>
            {{ column.title }}
            <i [ngClass]="sortIconClass(column)"></i>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate> </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <app-multicheck-filter
              [isPrimitive]="false"
              [field]="column.field"
              [currentFilter]="filter"
              [filterService]="filterService"
              textField="title"
              valueField="value"
              [data]="status"
            ></app-multicheck-filter>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div
              class="text-uppercase font-weight-bold text-center"
              [ngClass]="{
                'active-status': dataItem.status === Status.Active,
                'deffered-status': dataItem.status === Status.Deferred,
                'completed-status': dataItem.status === Status.Completed,
                'cancelled-status': dataItem.status === Status.Cancelled,
                'overdue-greater-one-week-status':
                  dataItem.status === Status.Overdue &&
                  dataItem.deferredUntil &&
                  isGreaterThanOneWeekOverdue(dataItem.deferredUntil),
                'overdue-less-one-week-status':
                  dataItem.status === Status.Overdue &&
                  dataItem.deferredUntil &&
                  !isGreaterThanOneWeekOverdue(dataItem.deferredUntil)
              }"
            >
              {{ Status[dataItem.status] }}
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="!patientId" field="patient" title="Patient" [width]="180" [filterable]="false">
          <ng-template kendoGridHeaderTemplate let-column>
            {{ column.title }}
            <i [ngClass]="sortIconClass(column)"></i>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <a class="pointer underline" *ngIf="dataItem?.patient" (click)="navigateToPatientChart(dataItem.patient.id)"
              >{{ dataItem.patient.firstName }} {{ dataItem.patient.lastName }}</a
            >
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="nudgeType" title="Type" [width]="130" [filterable]="false" [sortable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ Type[dataItem.nudgeType] }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="assignedTo" title="Assigned To" [width]="180">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div *ngIf="dataItem?.assignedTo">
              {{ dataItem.assignedTo.firstName }} {{ dataItem.assignedTo.lastName }}
            </div>
          </ng-template>
          <ng-template kendoGridHeaderTemplate let-column>
            {{ column.title }}
            <i [ngClass]="sortIconClass(column)"></i>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate> </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <app-multicheck-filter
              [isPrimitive]="false"
              [field]="column.field"
              [currentFilter]="filter"
              [filterService]="filterService"
              textField="fullName"
              valueField="id"
              [data]="users"
            ></app-multicheck-filter>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="deferredUntil" title="Due Date" [width]="118" [filterable]="false">
          <ng-template kendoGridHeaderTemplate let-column>
            {{ column.title }}
            <i [ngClass]="sortIconClass(column)"></i>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.deferredUntil ? (dataItem.deferredUntil | convertUTC) : '' }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="subject" title="Text" [filterable]="false" [sortable]="false" min-width="200px">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="row pb-3" *ngFor="let line of dataItem.subject">
              <div class="col-4">
                <div *ngIf="line?.authorFirstName && line?.authorLastName">
                  {{ line.authorFirstName + '&nbsp;' + (line.authorLastName | slice : 0 : 1) + '.&nbsp;' }}
                </div>
                {{ returnDateTimeUTC(line.timestamp) }}
              </div>
              <div class="col font-weight-bold break-space">
                {{ line.line }}
              </div>
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [width]="45">
          <ng-template kendoGridCellTemplate let-dataItem>
            <button
              *ngIf="isServiceReferenceType(dataItem)"
              (click)="navigateToReferenceLink(dataItem)"
              class="btn p-0 navy-font"
            >
              <i class="fal fa-external-link"></i>
            </button>
            <button
              *ngIf="isPatientDocumentReferenceType(dataItem)"
              (click)="previewPatientDocument(dataItem)"
              class="btn p-0 navy-font"
            >
              <i class="far fa-file-alt"></i>
            </button>
            <button
              *ngIf="isPatientClinicDocumentReferenceType(dataItem)"
              (click)="previewPatientClinicDocument(dataItem)"
              class="btn p-0 navy-font"
            >
              <i class="far fa-file-alt"></i>
            </button>
            <button
              *ngIf="isPatientFormReferenceType(dataItem)"
              (click)="previewPatientForm(dataItem)"
              class="btn p-0 navy-font"
            >
              <i class="far fa-file-alt"></i>
            </button>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="importance" title="Importance" [width]="140">
          <ng-template kendoGridHeaderTemplate let-column>
            {{ column.title }}
            <i [ngClass]="sortIconClass(column)"></i>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ Priority[dataItem.importance] }}
          </ng-template>
          <ng-template kendoGridFilterCellTemplate> </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-column="column"
            let-filter="filter"
            let-filterService="filterService"
          >
            <app-multicheck-filter
              [isPrimitive]="false"
              [field]="column.field"
              [currentFilter]="filter"
              [filterService]="filterService"
              textField="title"
              valueField="value"
              [data]="priority"
            ></app-multicheck-filter>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
</div>

<app-actionpanel></app-actionpanel>
