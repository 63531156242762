import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, combineLatest, zip, ReplaySubject, of, BehaviorSubject } from 'rxjs';
import { first, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';
import { environment } from '@environments/environment';

import { PatientDocument } from '@models/document/patient-document';
import { BlobService } from './blob.service';
import { removeURIQueryString } from '../lib/fun';
import { BaseUrlService } from './base-url.service';

@Injectable({
  providedIn: 'root',
})
export class PatientDocumentService {
  documentSelected = new BehaviorSubject<PatientDocument>(null);
  documentDeleted = new Subject<number>();
  documentAssigned = new Subject<PatientDocument>();
  documentSigned = new Subject<string>();
  documents$ = new ReplaySubject<PatientDocument[]>(1);
  documentSubmitted = new Subject<PatientDocument>();
  documentsTabInitialized$ = new Subject<boolean>();

  documents: PatientDocument[];
  defaultId = 0;
  eFormDefinition: any;

  constructor(private http: HttpClient, private blobService: BlobService, private baseUrlService: BaseUrlService) {
    this.documents$.asObservable().subscribe((documents) => {
      this.documents = documents.sort(this.sortByUploadDateDesc);
    });
  }

  getDocuments(patientId: number) {
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<PatientDocument[]>(environment.baseUrl + 'api/PatientDocuments/' + patientId),
    ]).pipe(
      map(([readOnlySAS, documents]) => {
        documents = documents.map((document) => {
          document = new PatientDocument(document);
          document.filePath = (document.filePath ? document.filePath.trim() : '') + readOnlySAS;
          if (document.pdfToHtmlUrl) {
            document.pdfToHtmlUrl += readOnlySAS;
          }
          if (document && document.modifiedDate && typeof document.modifiedDate == 'string') {
            document.modifiedDate = new Date(document.modifiedDate);
          }
          if (document && document.uploadDate && typeof document.uploadDate == 'string') {
            document.uploadDate = new Date(document.uploadDate);
          }

          return document;
        });

        this.documents$.next(documents);
        return documents;
      })
    );
  }

  getDocument(docId: number) {
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<PatientDocument>(environment.baseUrl + 'api/PatientDocuments/PatientDocumentByDocId/' + docId),
    ]).pipe(
      map(([readOnlySAS, document]) => {
        document.filePath = (document.filePath ? document.filePath.trim() : '') + readOnlySAS;
        if (document.pdfToHtmlUrl) {
          document.pdfToHtmlUrl += readOnlySAS;
        }
        if (document && document.modifiedDate && typeof document.modifiedDate == 'string') {
          document.modifiedDate = new Date(document.modifiedDate);
        }
        if (document && document.uploadDate && typeof document.uploadDate == 'string') {
          document.uploadDate = new Date(document.uploadDate);
        }

        return document;
      })
    );
  }

  getDocumentsByPatientDocTypeName(patientId: number, docTypeName: string) {
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<PatientDocument[]>(environment.baseUrl + 'api/PatientDocuments/' + patientId + '/' + docTypeName),
    ]).pipe(
      map(([readOnlySAS, documents]) => {
        documents = documents.map((document) => {
          document = new PatientDocument(document);
          document.filePath = (document.filePath ? document.filePath.trim() : '') + readOnlySAS;
          if (document.pdfToHtmlUrl) {
            document.pdfToHtmlUrl += readOnlySAS;
          }
          if (document && document.modifiedDate && typeof document.modifiedDate == 'string') {
            document.modifiedDate = new Date(document.modifiedDate);
          }
          if (document && document.uploadDate && typeof document.uploadDate == 'string') {
            document.uploadDate = new Date(document.uploadDate);
          }

          return document;
        });

        this.documents = documents.sort(this.sortByName);
        return documents;
      })
    );
  }

  getLoadedDocument(id: number): PatientDocument {
    const itemIndex = this.documents.findIndex((doc) => doc.id === id);
    return this.documents[itemIndex];
  }

  updateDocument(patientDocument: PatientDocument) {
    patientDocument.filePath = removeURIQueryString(patientDocument.filePath);
    if (patientDocument.pdfToHtmlUrl) {
      patientDocument.pdfToHtmlUrl = removeURIQueryString(patientDocument.pdfToHtmlUrl);
    }
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.put<PatientDocument>(environment.baseUrl + 'api/PatientDocuments', patientDocument),
    ]).pipe(
      map(([readOnlySAS, doc]) => {
        const itemIndex = this.documents.findIndex((doc) => doc.id === patientDocument.id);
        doc.filePath = (doc.filePath ? doc.filePath.trim() : '') + readOnlySAS;
        if (doc.pdfToHtmlUrl) {
          doc.pdfToHtmlUrl += readOnlySAS;
        }
        this.documents[itemIndex] = doc;
        this.documents$.next(this.documents);
        return doc;
      })
    );
  }

  addDocument(doc: PatientDocument) {
    var homeRoute = this.baseUrlService.getHomeRouteForBlob();
    const filePath = `${homeRoute}patients/document/`;

    return this.blobService
      .uploadFileToBlobStorage(doc.file, filePath + `${doc.patientId}/${Date.now()}.${doc.file.name}`, {
        // This options object is purely here to allow you to have a progress function that may update the UI otherwise you can remove it
        progress: (progress) => {
          // TODO: Do something with percent if you want
          // const percent = progress.loadedBytes / doc.file.size;
        },
      })
      .pipe(
        switchMap((blobURI) => {
          doc.filePath = removeURIQueryString(blobURI);
          if (doc.pdfToHtmlUrl) {
            doc.pdfToHtmlUrl = removeURIQueryString(doc.pdfToHtmlUrl);
          }
          doc.file = null;
          return zip(
            this.blobService.getReadOnlySASObservable(),
            this.documents$,
            this.http.post<PatientDocument>(environment.baseUrl + 'api/PatientDocuments', doc)
          );
        }),
        map(([readOnlySAS, oldPatientDocuments, addedDoc]) => {
          addedDoc.filePath = (addedDoc.filePath ? addedDoc.filePath.trim() : '') + readOnlySAS;
          if (addedDoc.pdfToHtmlUrl) {
            addedDoc.pdfToHtmlUrl += readOnlySAS;
          }
          const updatedPatientDocuments = [...oldPatientDocuments, ...[addedDoc]];
          this.documentSelected.next(addedDoc);
          this.documents$.next(updatedPatientDocuments.slice());
          return addedDoc;
        }),
        first()
      );
  }

  addMultipleDocuments(doc: PatientDocument, fileList: File[]) {
    const files = of(...fileList);
    return files.pipe(
      mergeMap((file) => {
        if (isNullOrUndefined(doc.name)) {
          doc = new PatientDocument(doc);
          doc.name = file.name;
        }
        doc.file = file;
        return this.addDocument(doc);
      })
    );
  }

  sortByName(doc1: PatientDocument, doc2: PatientDocument) {
    if (doc1.name < doc2.name) {
      return -1;
    }
    if (doc1.name > doc2.name) {
      return 1;
    }
    return 0;
  }

  sortByUploadDateDesc(doc1: PatientDocument, doc2: PatientDocument) {
    if (doc1.uploadDate > doc2.uploadDate) {
      return -1;
    }
    if (doc1.uploadDate < doc2.uploadDate) {
      return 1;
    }
    return 0;
  }

  deleteDocument(document: PatientDocument) {
    let docId = document.id;
    return this.http.post(`${environment.baseUrl}api/PatientDocuments/Delete`, document).pipe(
      map(() => {
        const itemIndex = this.documents.findIndex((deletedDoc) => deletedDoc.id === document.id);

        this.documents.splice(itemIndex, 1);
        this.documents$.next(this.documents);
        this.documentDeleted.next(docId);

        return this.documents;
      })
    );
  }

  assignPatientClinicDocuments(patientClinicDocument: PatientDocument) {
    patientClinicDocument.filePath = removeURIQueryString(patientClinicDocument.filePath);
    if (patientClinicDocument.pdfToHtmlUrl) {
      patientClinicDocument.pdfToHtmlUrl = removeURIQueryString(patientClinicDocument.pdfToHtmlUrl);
    }
    patientClinicDocument.file = null;
    combineLatest([
      this.http.post<PatientDocument>(`${environment.baseUrl}api/PatientDocuments/`, patientClinicDocument),
      this.documents$,
      this.blobService.getReadOnlySASObservable(),
    ])
      .pipe(take(1))
      .subscribe(([newPatientClinicDocument, oldPatientClinicDocuments, readOnlySAS]) => {
        newPatientClinicDocument.filePath += readOnlySAS;
        if (newPatientClinicDocument.pdfToHtmlUrl) {
          newPatientClinicDocument.pdfToHtmlUrl += readOnlySAS;
        }
        const updatedPatientClinicDocuments = [newPatientClinicDocument, ...oldPatientClinicDocuments];
        this.documentSelected.next(newPatientClinicDocument);
        this.documents$.next(updatedPatientClinicDocuments.slice());
        this.documentAssigned.next(newPatientClinicDocument);
      });
  }

  getPatientClinicDocument(docId: number) {
    return combineLatest([
      this.blobService.getReadOnlySASObservable(),
      this.http.get<PatientDocument>(`${environment.baseUrl}api/PatientDocuments/PatientClinicDocument/${docId}`),
    ]).pipe(
      map(([readOnlySAS, document]) => {
        document.filePath = (document.filePath ? document.filePath.trim() : '') + readOnlySAS;
        if (document.pdfToHtmlUrl) {
          document.pdfToHtmlUrl += readOnlySAS;
        }
        return document;
      })
    );
  }

  updatePatientClinicDocument(patientClinicDocumentToUpdate: PatientDocument) {
    if (patientClinicDocumentToUpdate.id) {
      patientClinicDocumentToUpdate.filePath = removeURIQueryString(patientClinicDocumentToUpdate.filePath);
      if (patientClinicDocumentToUpdate.pdfToHtmlUrl) {
        patientClinicDocumentToUpdate.pdfToHtmlUrl = removeURIQueryString(patientClinicDocumentToUpdate.pdfToHtmlUrl);
      }
      patientClinicDocumentToUpdate.file = null;
      return combineLatest([
        this.blobService.getReadOnlySASObservable(),
        this.http.put<PatientDocument>(`${environment.baseUrl}api/PatientDocuments`, patientClinicDocumentToUpdate),
        this.documents$,
      ])
        .pipe(take(1))
        .map(([readOnlySAS, patientClinicDocument, oldPatientClinicDocuments]) => {
          patientClinicDocument.filePath =
            (patientClinicDocument.filePath ? patientClinicDocument.filePath.trim() : '') + readOnlySAS;
          if (patientClinicDocument.pdfToHtmlUrl) {
            patientClinicDocument.pdfToHtmlUrl += readOnlySAS;
          }

          const updatedPatientClinicDocuments = oldPatientClinicDocuments.map((doc) =>
            patientClinicDocument.id == doc.id ? patientClinicDocument : doc
          );
          this.documents$.next(updatedPatientClinicDocuments);
          this.documentSelected.next(patientClinicDocument);
          this.documentSubmitted.next(patientClinicDocument);
          return patientClinicDocument;
        });
    } else {
      return of(null);
    }
  }
}
