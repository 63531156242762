import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Appointment } from '@models/scheduler/event';

@Component({
  selector: 'app-confirm-unsaved-changes',
  templateUrl: './confirm-unsaved-changes.component.html',
  styleUrls: ['./confirm-unsaved-changes.component.less']
})
export class ConfirmUnsavedChangesDialogComponent {
  public dialog: MatDialog;

  constructor(
    public dialogRef: MatDialogRef<ConfirmUnsavedChangesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Appointment) { }

  onNoClick(): void {
    this.data = null;
  }
}
