import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { QuickAddPhysicianComponent } from '@app/management/address-book/actionpanel/quick-add-physician/quick-add-physician.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { MinistryBilling } from '@models/billing/ministry-billing/ministry-billing';
import { Doctor } from '@models/doctor';
import { Patient } from '@models/patient';
import { PaymentStatus } from '@models/scheduler/payment-status';
import { ChartAppointment } from '@models/service-chart/chart-appointment';
import { ServiceBillingCode } from '@models/service/service-billing-code';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentService } from '@services/appointments.service';
import { DoctorsService } from '@services/doctors.service';
import { MinistryService } from '@services/ministry.service';
import { PatientService } from '@services/patient.service';
import { ServicesService } from '@services/services.service';
import { ValidationService } from '@services/validation.service';
import * as moment from 'moment';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-ministry-billing',
  templateUrl: './ministry-billing.component.html',
  styleUrls: ['./ministry-billing.component.less'],
  providers: [NgbDropdownConfig],
})
export class MinistryBillingComponent implements OnInit, OnDestroy {
  @Input() set selectedChart(entry: ChartAppointment) {
    if (entry) {
      this.selectedServiceChartEntry = entry;
      this.ministryService.getMinistryInvoice(entry.invoiceId).subscribe((ministryBilling) => {
        this.ministryBilling = ministryBilling;
        this.initFormValue(this.ministryBilling, this.selectedServiceChartEntry);
      });
    }
  }
  @Input() patientId: number;
  @ViewChild('searchDxCodeInput') searchDxCodeInput: ElementRef;
  @ViewChild('dxCodeInput') dxCodeInput: ElementRef;
  @ViewChildren('searchBillingCodeInput') searchBillingCodeInput: QueryList<ElementRef>;
  @Output() closePanel = new EventEmitter();

  ministryBillingForm: FormGroup;
  serviceBillingCodes: FormArray;
  selectedServiceChartEntry: ChartAppointment;
  ministryBilling: MinistryBilling;
  loader: boolean = false;
  serviceBillingCodeActiveIndex: number;
  errors: any[] = [];
  unsub = new Subject<any>();
  patient: Patient = null;
  theDoctors: Doctor[] = [];
  selectedDoctor: Doctor = null;
  PaymentStatus = PaymentStatus;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private ministryService: MinistryService,
    private appointmentService: AppointmentService,
    private servicesService: ServicesService,
    private config: NgbDropdownConfig,
    private patientService: PatientService,
    private validationService: ValidationService,
    private doctorsService: DoctorsService
  ) {
    config.placement = 'bottom-right';
  }

  async ngOnInit() {
    await this.getPatient(this.patientId).toPromise();
    this.theDoctors = await this.doctorsService.getDoctors().toPromise();
    this.theDoctors = this.theDoctors.filter((doc) => doc.provincialIdNumber != null);
    this.initForm();
  }

  quickAddDoctor() {
    const dialogRef = this.dialog.open(QuickAddPhysicianComponent, {
      panelClass: 'custom-dialog-container',
      data: { doctors: this.theDoctors },
      width: '420px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((physician) => {
        if (physician) {
          this.theDoctors = [physician].concat(this.theDoctors);
          this.ministryBillingForm.controls['referralCode'].patchValue(physician.provincialIdNumber);
          this.selectedDoctor = physician;
        }
      });
  }

  setReferralCode(code: string) {
    this.ministryBillingForm.controls['referralCode'].patchValue(code);
  }

  initForm() {
    this.ministryBillingForm = this.formBuilder.group({
      invoiceDate: [new Date(), [this.validationService.validateDate, this.validationService.validateNotInFuture]],
      dxCode: [null, Validators.required],
      serviceBillingCodes: this.formBuilder.array([]),
      referralCode: null,
      claimNote: null,
      isReferredTo: false,
    });
    this.ministryBillingForm.valueChanges.subscribe(() => {
      this.errors = [];
    });
    this.ministryBillingForm.controls['serviceBillingCodes'].valueChanges.subscribe(() => {
      this.errors = [];
    });
  }

  checkFormDisabled() {
    if (this.errors.length != 0) return true;
    else if (this.ministryBillingForm.valid) return false;
    else if (
      this.ministryBillingForm.controls['serviceBillingCodes'].valid &&
      this.dxCodeInput &&
      this.dxCodeInput.nativeElement.value != ''
    )
      return false;
    else return true;
  }

  getPatient(patientId: number) {
    if (this.patientService.patientPanelPatient && this.patientService.patientPanelPatient.patientId == patientId) {
      this.patient = this.patientService.patientPanelPatient;
      return of(this.patient);
    } else {
      return this.patientService.getPatientById(patientId).pipe(
        takeUntil(this.unsub),
        map((patient) => {
          this.patient = patient;
          return patient;
        })
      );
    }
  }

  initFormValue(ministryBilling: MinistryBilling, service: ChartAppointment) {
    this.ministryBillingForm.controls['invoiceDate'].patchValue(
      new Date(ministryBilling.invoiceDate ? ministryBilling.invoiceDate : service.date)
    );
    this.ministryBillingForm.controls['invoiceDate'].markAsTouched();
    this.ministryBillingForm.controls['dxCode'].patchValue(service.dxCode);
    this.ministryBillingForm.controls['serviceBillingCodes'] = this.formBuilder.array([]);
    service.billingCodes.forEach((billingCodeItem: ServiceBillingCode) => {
      this.addServiceCode(billingCodeItem);
    });
    this.ministryBillingForm.controls['referralCode'].patchValue(
      !isNullOrUndefined(ministryBilling.referralPractitioner) ? ministryBilling.referralPractitioner : ''
    );
    this.ministryBillingForm.controls['isReferredTo'].patchValue(
      !isNullOrUndefined(ministryBilling.isReferredTo) && ministryBilling.referralPractitioner
        ? ministryBilling.isReferredTo
        : false
    );
    this.ministryBillingForm.controls['claimNote'].patchValue(
      !isNullOrUndefined(ministryBilling.claimNote) ? ministryBilling.claimNote : ''
    );

    if (ministryBilling.referralPractitioner) {
      this.selectedDoctor = this.theDoctors.find(
        (doc) => String(doc.provincialIdNumber) == ministryBilling.referralPractitioner
      );
    }
  }

  createServiceCode(billingCodeItem?: ServiceBillingCode): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(billingCodeItem ? billingCodeItem.id : null),
      billingCode: new FormControl(billingCodeItem ? billingCodeItem.billingCode : null, [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
      ]),
      serviceUnits: new FormControl(billingCodeItem ? billingCodeItem.serviceUnits : 1, Validators.required),
    });
  }

  addServiceCode(billingCodeItem?: ServiceBillingCode) {
    this.serviceBillingCodes = this.ministryBillingForm.get('serviceBillingCodes') as FormArray;
    let formcontrol: FormGroup = this.createServiceCode(billingCodeItem ? billingCodeItem : null);
    formcontrol.valueChanges.subscribe(() => {
      this.errors = [];
      this.ministryBillingForm.updateValueAndValidity();
    });
    this.serviceBillingCodes.push(formcontrol);
  }

  removeServiceCode(index: number) {
    this.serviceBillingCodes = this.ministryBillingForm.get('serviceBillingCodes') as FormArray;
    this.serviceBillingCodes.removeAt(index);
  }

  serviceBillingCodesValidation(array) {
    this.errors = [];
    const valueArr = array.map((item) => {
      return !isNullOrUndefined(item.billingCode.code) ? item.billingCode.code : item.billingCode;
    });

    const isDuplicate = valueArr.some((item, idx) => {
      return valueArr.indexOf(item) !== idx;
    });

    const isEmptyBillingCode = valueArr.some((item) => {
      return item === '';
    });
    if (isDuplicate) {
      this.errors.push({ fieldErrors: ["Service Billing Codes can't be duplicated"] });
    }
    if (isEmptyBillingCode) {
      this.errors.push({ fieldErrors: ["Service Billing Code can't be empty"] });
    }

    return isDuplicate || isEmptyBillingCode;
  }

  onMinistryBillingSave(isMinistryBilling?: boolean) {
    if (this.serviceBillingCodesValidation(this.ministryBillingForm.controls['serviceBillingCodes'].value)) {
      return;
    }
    this.loader = true;
    this.errors = [];
    this.ministryBilling.invoiceDate = moment(this.ministryBillingForm.controls['invoiceDate'].value)
      .startOf('day')
      .toDate();
    this.ministryBilling.referralPractitioner = this.ministryBillingForm.controls['referralCode'].value;
    this.ministryBilling.claimNote = this.ministryBillingForm.controls['claimNote'].value;
    this.ministryBilling.isReferredTo = this.ministryBillingForm.controls['isReferredTo'].value;
    const serviceBillingCodes = this.ministryBillingForm.controls['serviceBillingCodes'].value.map((item) => {
      return {
        id: item.id ? item.id : 0,
        billingCode: !isNullOrUndefined(item.billingCode.code) ? item.billingCode.code : item.billingCode,
        serviceUnits: item.serviceUnits,
        serviceId: this.selectedServiceChartEntry.serviceId,
      };
    });
    const dxCode = !isNullOrUndefined(this.ministryBillingForm.controls['dxCode'].value.code)
      ? this.ministryBillingForm.controls['dxCode'].value.code
      : this.ministryBillingForm.controls['dxCode'].value;

    const ministryRequests: any = [
      this.ministryService.updateMinistryInvoice(this.ministryBilling),
      this.appointmentService.postDxCodeByAppointmentId(this.selectedServiceChartEntry.appointmentId, String(dxCode)),
      this.appointmentService.postBillingCodeByAppointmentId(
        this.selectedServiceChartEntry.appointmentId,
        serviceBillingCodes
      ),
    ];

    forkJoin(ministryRequests).subscribe(
      (response: any) => {
        if (isMinistryBilling) {
          this.ministryService.sendInvoice(this.ministryBilling.id).subscribe((res) => {
            if (res && !res.isSuccess) {
              let errorMsg = 'Something unknown went wrong. Please check billing settings if this fails again';
              switch (res.httpStatusCode) {
                case 401:
                  errorMsg = 'ClinicAid rejected for unauthorized action. Check billing settings in Emily.';
                  break;
                case -1:
                  let resErr = JSON.parse(res.errorMessage);
                  let error: {
                    invoice?: { field_errors: any; standard_errors: string[] };
                    patient?: { field_errors: any; standard_errors: string[] };
                    provider?: { field_errors: any; standard_errors: string[] };
                  } = resErr.errors;
                  if (error && (error.invoice || error.patient || error.provider)) {
                    errorMsg = '';
                    if (error.invoice && error.invoice.field_errors) {
                      Object.keys(error.invoice.field_errors).forEach((errKey) => {
                        errorMsg +=
                          '<b>' +
                          errKey +
                          '</b>: ' +
                          error.invoice.field_errors[errKey].join('<br><b>' + errKey + '</b>: ') +
                          '<br>';
                      });
                    }
                    if (error.invoice && error.invoice.standard_errors) {
                      errorMsg += error.invoice.standard_errors.join('<br>');
                    }

                    if (error.patient && error.patient.field_errors) {
                      Object.keys(error.patient.field_errors).forEach((errKey) => {
                        errorMsg +=
                          '<b>' +
                          errKey +
                          '</b>: ' +
                          error.patient.field_errors[errKey].join('<br><b>' + errKey + '</b>: ') +
                          '<br>';
                      });
                    }
                    if (error.patient && error.patient.standard_errors) {
                      errorMsg += error.patient.standard_errors.join('<br>');
                    }

                    if (error.provider && error.provider.field_errors) {
                      Object.keys(error.provider.field_errors).forEach((errKey) => {
                        errorMsg +=
                          '<b>' +
                          errKey +
                          '</b>: ' +
                          error.provider.field_errors[errKey].join('<br><b>' + errKey + '</b>: ') +
                          '<br>';
                      });
                    }
                    if (error.provider && error.provider.standard_errors) {
                      errorMsg += error.provider.standard_errors.join('<br>');
                    }
                  }
                  break;
                default:
                  errorMsg = res && res.errorMessage ? res.errorMessage : errorMsg;
                  break;
              }
              this.errors.push({ fieldErrors: [errorMsg] });
            } else {
              this.selectedServiceChartEntry.paymentStatus = PaymentStatus.Paid;
              this.updateServiceChartEntry(dxCode, serviceBillingCodes);
            }
            this.loader = false;
          });
        } else {
          this.updateServiceChartEntry(dxCode, serviceBillingCodes);
          this.loader = false;
        }
      },
      (errorResponse: HttpErrorResponse) => {
        this.loader = false;
        this.errors = errorResponse.error.errors;
      }
    );
  }

  public disabledDates = (date: Date): boolean => {
    return date > new Date(); //cant select future dates
  };

  updateServiceChartEntry(dxCode: any, serviceBillingCodes: any) {
    this.selectedServiceChartEntry.dxCode = dxCode;
    this.selectedServiceChartEntry.billingCodes = serviceBillingCodes;
    this.closePanel.next();
  }

  codeResultFormatter = (x: { code: string; description: string }) => x.code + ' (' + x.description + ')';

  codeDxInputFormatter = (x: { code: string } | any) => {
    if (!isNullOrUndefined(x.code)) {
      this.ministryBillingForm.controls['dxCode'].patchValue(x.code);
    }
    return x.code;
  };

  dxCodeUpdated(event) {
    if (event && event.target) {
      this.ministryBillingForm.controls['dxCode'].patchValue(event.target.value);
    }
  }
  billingCodeUpdated(event, serviceBillingCodeActiveIndex: number) {
    if (event && event.target) {
      const billingCodes = this.ministryBillingForm.get('serviceBillingCodes') as FormArray;
      const newBillingCode = {
        id: billingCodes.at(serviceBillingCodeActiveIndex).value['id'],
        billingCode: event.target.value,
        serviceUnits: billingCodes.at(serviceBillingCodeActiveIndex).value['serviceUnits'],
      };
      billingCodes.at(serviceBillingCodeActiveIndex).patchValue(newBillingCode);
    }
  }

  codeBillingInputFormatter = (x: { code: string } | any) => {
    if (!isNullOrUndefined(x.code)) {
      const billingCodes = this.ministryBillingForm.get('serviceBillingCodes') as FormArray;
      const newBillingCode = {
        id: billingCodes.at(this.serviceBillingCodeActiveIndex).value['id'],
        billingCode: x.code,
        serviceUnits: billingCodes.at(this.serviceBillingCodeActiveIndex).value['serviceUnits'],
      };
      billingCodes.at(this.serviceBillingCodeActiveIndex).patchValue(newBillingCode);
    }
    return x.code
  };

  searchBillingCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(() => {
        this.errors = [];
        this.loader = true;
      }),
      switchMap((term: string) =>
        this.servicesService.getBillingCodeByTerm(term).pipe(
          catchError(() => {
            this.loader = false;
            return Observable.of([]);
          })
        )
      ),
      map((data) => {
        if (data && Array.isArray(data) && data.length == 0) {
          this.errors.push({ fieldErrors: ["Entered Billing code doesn't exist"] });
        }
        return data;
      }),
      tap(() => {
        this.loader = false;
      })
    );

  searchDxCode = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(() => {
        this.errors = [];
        this.loader = true;
      }),
      switchMap((term: string) =>
        this.servicesService.getDxCodeByTerm(term).pipe(
          catchError((err) => {
            this.loader = false;
            return Observable.of([]);
          }),
          map((data) => {
            if (data && Array.isArray(data) && data.length == 0) {
              this.errors.push({ fieldErrors: ["Entered Dx code doesn't exist"] });
            }
            return data;
          })
        )
      ),
      tap(() => {
        this.loader = false;
      })
    );

  searchDxCodeFunc() {
    this.searchDxCodeInput.nativeElement.dispatchEvent(new Event('input'));
    this.searchDxCodeInput.nativeElement.focus();
  }

  searchBillingCodeFunc(index: number) {
    this.serviceBillingCodeActiveIndex = index;
    this.searchBillingCodeInput.toArray()[index].nativeElement.dispatchEvent(new Event('input'));
    this.searchBillingCodeInput.toArray()[index].nativeElement.focus();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
