import { Subject } from 'rxjs';
import { Appointment } from '@models/scheduler/event';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { SignalrBase } from './signalr-base';
import { SignalrHttpClientService } from './signalr-http-client.service';
import { Policies } from '@app/auth/auth-policies';

@Injectable({
  providedIn: 'root',
})
export class AppointmentSignalrService extends SignalrBase<any> {
  private apptAdded = new Subject<Appointment>();
  apptAdded$ = this.apptAdded.asObservable();

  private apptUpdated = new Subject<Appointment>();
  apptUpdated$ = this.apptUpdated.asObservable();

  private apptDeleted = new Subject<Appointment>();
  apptDeleted$ = this.apptDeleted.asObservable();

  constructor(authService: AuthService, private signalrHttpClientService: SignalrHttpClientService) {
    // Permissions to have this connect
    super(
      'Appointments',
      Policies.appointments,
      authService,
      signalrHttpClientService
    );
    this.registerServerEvents();
  }

  private registerServerEvents(): void {
    super.registerServerEvent('AddAppointment', (appointment: Appointment) => {
      this.apptAdded.next(appointment);
    });

    super.registerServerEvent('UpdateAppointment', (appointment: Appointment) => {
      this.apptUpdated.next(appointment);
    });

    super.registerServerEvent('DeleteAppointment', (appointment: Appointment) => {
      this.apptDeleted.next(appointment);
    });
  }

  updateAppointment(appointment: Appointment) {
    this._hubConnection.invoke('UpdateAppointmentFromClient', appointment.appointmentId);
  }

  addAppointment(appointment: Appointment) {
    this._hubConnection.invoke('AddAppointmentFromClient', appointment.appointmentId);
  }

  deleteAppointment(appointment: Appointment) {
    this._hubConnection.invoke('DeleteAppointmentFromClient', appointment.appointmentId);
  }
}
