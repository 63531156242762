import { DecimalPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SquareCardComponent } from '@app/components/payments/square-card/square-card.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { Clinic } from '@models/clinic';
import { FinanceTransactionType } from '@models/finance/finance-transaction-type';
import { InvoicePayment } from '@models/finance/invoice-payment';
import { InvoiceTransaction } from '@models/finance/invoice-transaction';
import { PaymentMethod } from '@models/finance/payment-method';
import { PaymentMethodEnum } from '@models/finance/payment-method.enum';
import { Invoice } from '@models/invoice/invoice';
import { InvoiceLineItem } from '@models/invoice/invoice-line-item';
import { InvoiceType } from '@models/invoice/invoice-type';
import { PaidCancellation } from '@models/invoice/paid-cancellation';
import { Patient } from '@models/patient';
import { SquareCard } from '@models/square/square-card';
import { SquarePayment, SquarePaymentStatus, SquarePaymentType } from '@models/square/square-payment';
import { AppointmentService } from '@services/appointments.service';
import { BlobService } from '@services/blob.service';
import { ClinicsService } from '@services/clinics.service';
import { FinanceService } from '@services/finance.service';
import { InvoicesService } from '@services/invoices.service';
import { MerchantDeviceService } from '@services/merchant-device.service';
import { PatientService } from '@services/patient.service';
import { PaymentMethodsService } from '@services/payment-methods.service';
import { SquareService } from '@services/square.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { UsersService } from '@services/users.service';
import { Observable, Subject, defer, of } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { StandardInvoiceComponent } from '../standard-invoice/standard-invoice.component';

export enum PaymentType {
  Regular,
  Recurring,
  Refund, // Still used for negative value invoice payments
  ReturnProduct,
  ReturnService,
  ReturnAdminFee,
  ReturnPaidCancellation,
  ReturnCredit,
}

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.less'],
})
export class InvoicePaymentComponent implements OnInit, OnDestroy {
  @Input() parentClosed;

  _paymentType: PaymentType = PaymentType.Regular;
  @Input() set paymentType(paymentType: PaymentType) {
    this._paymentType = paymentType;
  }
  get paymentType() {
    return this._paymentType;
  }

  get isSquareLinked() {
    return this.clinicsService.isSquareLinked;
  }

  get financeTransactionTypes(): typeof FinanceTransactionType {
    return FinanceTransactionType;
  }

  private _patientId: number;
  @Input() set patientId(patientId: number) {
    this._patientId = patientId;
    this.setPatient();
  }
  get patientId() {
    return this._patientId;
  }

  @Input() selectedInvoice: Subject<Invoice>;
  @Input() paidCancellation: PaidCancellation;
  @Output() paymentComplete = new EventEmitter();
  @Output() backToReturnPanel = new EventEmitter();
  @ViewChild('exportTemplate', { read: ViewContainerRef, static: true }) exportTemplate: ViewContainerRef;
  @ViewChild(SquareCardComponent, { static: false }) squareCardComponent: SquareCardComponent;
  clinic: Clinic;
  invoice: Invoice;
  patient: Patient;
  paymentForm: FormGroup;
  formPaymentMethods: FormArray;
  formMerchantDevicePaymentMethods: FormArray;
  paymentMethods: PaymentMethod[];
  merchantDevicePaymentMethods: PaymentMethod[];
  patientAvailableCredit = 0;
  patientAvailableLoyaltyPoints: { value: number; dollarValue: number } = { value: 0, dollarValue: 0 };
  outstandingAmount: number;
  initialOutstandingAmount: number;
  paidAmount: number;
  errors: any[] = [];
  invoiceLineItemTaxKeys: string[];
  invoiceLineItemTaxValuesObject: any;
  unsub = new Subject<any>();
  isGeneratedPDF = false;
  loading = false;
  showCloseInvoiceButton = false;
  editTransactionMode = false;
  creditsPaymentMethod: PaymentMethod;
  PaymentType = PaymentType;
  adminFee: number = 0;
  stateOfCredits = 0;
  creditFieldExists = false;

  // Square payment
  selectedSquareCard: SquareCard = null;
  patientTokenId: number;
  squarePaymentMethod: PaymentMethod;

  constructor(
    private formBuilder: FormBuilder,
    private financeService: FinanceService,
    private paymentMethodsService: PaymentMethodsService,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private invoicesService: InvoicesService,
    private decimalPipe: DecimalPipe,
    private appointmentService: AppointmentService,
    private invoiceService: InvoicesService,
    private usersService: UsersService,
    private clinicsService: ClinicsService,
    private blobService: BlobService,
    private treatmentPlanService: TreatmentPlanService,
    private merchantDeviceService: MerchantDeviceService,
    private squareService: SquareService
  ) {}

  ngOnInit() {
    this.getCurrentPatientId();
    this.getPatientAvailableCredit();
    this.getPatientAvailableLoyaltyPoints();
    this.getPatientAvailableLoyaltyPointsDollarValue();
    this.getClinicInfo().subscribe(() => {
      this.getPaymentMethods();
      this.getSelectedInvoiceDetails();
      this.detectInvoiceUpdates();
    });
    this.initForm();
  }

  ngOnChanges(changes: { [property: string]: SimpleChange }) {
    // If the parent component is closed, clear the errors
    let change: SimpleChange = changes['parentClosed'];
    if (change?.currentValue == true) {
      this.errors = [];
    }
  }

  private setPatient() {
    if (this.patientId) {
      this.patientService.getPatientById(this.patientId).subscribe((patient) => {
        if (patient) {
          this.patient = patient;
          this.paymentForm.get('emailAddress')?.setValue(patient.email);
        }
      });
    } else {
      this.patient = null;
    }
  }

  initForm() {
    //This determines which version to initialize but clinic is currenty not there at the right time and therefore does not work correctly
    // if (this.clinic && this.clinic.isUsingMerchantDevices) {
    //   this.paymentForm = this.formBuilder.group(
    //     {
    //       formMerchantDevicePaymentMethods: this.formBuilder.array([]),
    //       formPaymentMethods: this.formBuilder.array([]),
    //       emailAddress: null,
    //       addBackToInventory: false,
    //       adminFee: 0,
    //     },
    //     {
    //       validators: [this.paymentValidator],
    //     }
    //   );
    // } else {
    //   this.paymentForm = this.formBuilder.group(
    //     {
    //       formMerchantDevicePaymentMethods: this.formBuilder.array([this.createPaymentMethod()]),
    //       formPaymentMethods: this.formBuilder.array([this.createPaymentMethod()]),
    //       emailAddress: null,
    //       addBackToInventory: false,
    //       adminFee: 0,
    //     },
    //     {
    //       validators: [this.paymentValidator],
    //     }
    //   );
    // }

    this.paymentForm = this.formBuilder.group(
      {
        formMerchantDevicePaymentMethods: this.formBuilder.array([]),
        formPaymentMethods: this.formBuilder.array([]),
        emailAddress: this.patient?.email,
        addBackToInventory: false,
        adminFee: 0,
      },
      {
        validators: [this.paymentValidator],
      }
    );

    this.onChangeBackToInventoryField();
  }

  paymentValidator(formGroup: FormGroup) {
    if (
      (formGroup.controls.formPaymentMethods && formGroup.controls.formPaymentMethods.status == 'VALID') ||
      (formGroup.controls.formMerchantDevicePaymentMethods &&
        formGroup.controls.formMerchantDevicePaymentMethods.status == 'VALID')
    ) {
      return null;
    }
    return { invalid: true };
  }

  detectInvoiceUpdates() {
    this.invoicesService.invoicesListUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getPatientAvailableLoyaltyPoints();
      this.getPatientAvailableLoyaltyPointsDollarValue();
      this.getPatientAvailableCredit();
      this.initForm();
    });
    this.financeService.invoicePaid$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.getPatientAvailableLoyaltyPoints();
      this.getPatientAvailableLoyaltyPointsDollarValue();
      this.getPatientAvailableCredit();
      this.initForm();
    });
  }

  createPaymentMethod(
    id?: number,
    transactionDate?: Date,
    createdById?: string,
    method?: PaymentMethod,
    amount?: number,
    isMerchantDeviceTransaction: boolean = false,
    isManualEntry: boolean = false,
    merchantDeviceId: number = 0
  ): FormGroup {
    return this.formBuilder.group({
      id: id ? id : 0,
      transactionDate: transactionDate
        ? transactionDate
        : this.invoice && this.invoice.invoiceTransactions?.length != 0
        ? this.invoice.invoiceDate
        : new Date(),
      createdById: createdById ? createdById : this.usersService.loggedInUser.id,
      method: new FormControl(
        method ? { value: method, disabled: isMerchantDeviceTransaction } : '',
        Validators.required
      ),
      amount: new FormControl(amount ? { value: amount, disabled: isMerchantDeviceTransaction } : '', {
        validators: Validators.required,
        updateOn: 'blur',
      }),
      isMerchantDeviceTransaction: isMerchantDeviceTransaction ? isMerchantDeviceTransaction : false,
      isManualEntry: isManualEntry ? isManualEntry : false,
      merchantDeviceId: merchantDeviceId ? merchantDeviceId : 0,
    });
  }

  formatCurrency(unformattedCurrency: string | number, paymentMethod: FormGroup | FormControl) {
    try {
      let formattedCurrency = this.decimalPipe
        .transform(String(unformattedCurrency).replace(',', ''), '1.2-2')
        .replace(',', '');
      paymentMethod.get('amount').setValue(formattedCurrency, { emitEvent: false });
    } catch (e) {}
  }

  addToPaymentTotal(amount: number) {
    // Ensure that the outstanding amount is a positive number
    if (!this.editTransactionMode && amount < 0) {
      amount = amount * -1;
    }
    let pcs: Array<FormControl>;
    pcs = this.paymentForm.controls.formPaymentMethods['controls'];

    if (pcs.length > 0) {
      const found = pcs.some((pc) => {
        if (!pc.get('amount').value) {
          this.formatCurrency(amount, pc);
          this.outstandingAmount -= amount;
          if (pc.get('method').value?.id == this.creditsPaymentMethod.id) {
            this.stateOfCredits -= amount;
          }
          return true;
        }
        return false;
      });
      if (!found) {
        this.addPaymentMethod();
        this.addToPaymentTotal(amount);
      }
    } else {
      this.addPaymentMethod();
      this.addToPaymentTotal(amount);
    }
  }

  addPaymentMethod() {
    this.formPaymentMethods = this.paymentForm.get('formPaymentMethods') as FormArray;
    this.formPaymentMethods.push(this.createPaymentMethod());
  }

  removePaymentMethod(index: number) {
    //if credit is removed give back
    if (this.formPaymentMethods.value[index].method.id == this.creditsPaymentMethod.id) {
      this.stateOfCredits += Number(this.formPaymentMethods.value[index].amount);
      this.creditFieldExists = false;
    }

    this.outstandingAmount += Number(this.formPaymentMethods.value[index].amount);
    this.formPaymentMethods = this.paymentForm.get('formPaymentMethods') as FormArray;
    this.formPaymentMethods.removeAt(index);
  }

  adminFeeChanged(event) {
    let previousAdminFee = this.adminFee;
    this.adminFee = event && event.target && event.target.value ? +event.target.value.replace('$', '') : 0;
    this.outstandingAmount =
      this.paymentType === PaymentType.Regular || this.paymentType === PaymentType.Recurring
        ? this.outstandingAmount + this.adminFee - previousAdminFee
        : this.outstandingAmount - this.adminFee + previousAdminFee;
  }

  onFormChange(newItem, i, typeChange) {
    // If PaymentMethod Type changes, determine if we weill show Manual entry or not
    if (typeChange) {
      if (this.clinic?.isUsingMerchantDevices) {
        if (newItem.isMerchantDeviceTransaction) {
          var merchantDevicePaymentMethod = this.merchantDevicePaymentMethods.find((p) => p.id == newItem.id);
          if (merchantDevicePaymentMethod) {
            this.paymentForm.controls.formPaymentMethods['controls'][i]
              .get('isMerchantDeviceTransaction')
              .setValue(true, { emitEvent: false });
          } else {
            this.paymentForm.controls.formPaymentMethods['controls'][i]
              .get('isMerchantDeviceTransaction')
              .setValue(false, { emitEvent: false });
          }
        } else {
          this.paymentForm.controls.formPaymentMethods['controls'][i]
            .get('isMerchantDeviceTransaction')
            .setValue(false, { emitEvent: false });
        }
      }
    }

    var oldValues = this.paymentForm.controls.formPaymentMethods['controls'];

    var diff = 0;
    //Get the difference between old and new value when changing amount manually
    if (newItem != undefined && i != undefined && !typeChange) {
      diff = (Number(newItem) * 100 - Number(oldValues[i].value.amount) * 100) / 100;
    }

    //Modify outstanding amount with difference in value
    if (!typeChange) {
      this.outstandingAmount = Math.round(this.outstandingAmount * 100 - diff * 100) / 100;
    }

    //Handle amount credit changes
    if (this.creditFieldExists && !typeChange && oldValues[i].value.method?.id == this.creditsPaymentMethod.id) {
      var diff = Number(newItem) - Number(oldValues[i].value.amount);
      //GIVE BACK OR DEDUCT MORE CREDIT
      this.stateOfCredits -= diff;
    }

    //Determine if credit line added or removed by changing type (not deleting)
    if (typeChange) {
      if (newItem.id == this.creditsPaymentMethod.id && oldValues[i].value.method?.id != this.creditsPaymentMethod.id) {
        this.creditFieldExists = true;
        //Use credits
        this.stateOfCredits -= Number(oldValues[i].value.amount);
      }
      if (newItem.id != this.creditsPaymentMethod.id && oldValues[i].value.method?.id == this.creditsPaymentMethod.id) {
        this.creditFieldExists = false;
        // GIVE BACK FULL CREDIT
        this.stateOfCredits += Number(oldValues[i].value.amount);
      }
    }
  }

  isMerchantDevice(paymentMethodControls: any): any {
    return paymentMethodControls.controls.isMerchantDeviceTransaction.value;
  }

  toggleManualEntry(paymentMethod: FormGroup | FormControl) {
    let currentValue = paymentMethod.get('isManualEntry').value;
    let newValue = !currentValue;

    paymentMethod.get('isManualEntry').setValue(newValue, { emitEvent: false });
  }

  checkForDifferences(a, b) {
    return a.filter((el) => {
      return !b.includes(el);
    });
  }

  getCurrentPatientId() {
    if (!this.patientId) {
      this.patientId = this.route.snapshot.params.patId.split('_')[0];
    }
  }

  getSelectedInvoiceDetails() {
    if (!isNullOrUndefined(this.selectedInvoice)) {
      this.selectedInvoice
        .pipe(
          filter((invoice) => invoice !== null),
          switchMap((invoice: Invoice) => {
            this.invoice = invoice;
            this.adminFee = 0;
            this.getInvoiceLineItemTaxes(invoice);
            this.getInvoicePaidAmount(invoice.id);
            this.paymentForm.reset();
            this.selectedSquareCard = null;
            this.creditFieldExists = false;
            this.stateOfCredits = 0;
            if (this.formPaymentMethods) {
              for (let i = 1; i < this.formPaymentMethods.length; i++) {
                this.formPaymentMethods.removeAt(i);
              }
            }
            return this.financeService.getOutstandingAmount(invoice.id);
          }),
          takeUntil(this.unsub),
          map((outstandingAmount: number) => {
            if (
              outstandingAmount == 0 &&
              !this.invoice.isPaid &&
              this.invoice.invoiceTransactions &&
              this.invoice.invoiceTransactions.length > 0
            ) {
              this.paymentForm.removeControl('formPaymentMethods');
              this.formPaymentMethods = this.formBuilder.array([], Validators.required);
              this.paymentForm.addControl('formPaymentMethods', this.formPaymentMethods);
              this.editTransactionMode = true;
              this.formPaymentMethods = this.paymentForm.get('formPaymentMethods') as FormArray;
              this.invoice.invoiceTransactions.forEach((transaction) => {
                this.formPaymentMethods.push(
                  this.createPaymentMethod(
                    transaction.id,
                    transaction.transactionDate,
                    transaction.createdById,
                    transaction.paymentMethod,
                    transaction.financeTransactionTypeId == FinanceTransactionType.Payment
                      ? transaction.amount
                      : -1 * transaction.amount,
                    transaction.emvData ? true : false,
                    false,
                    transaction.merchantDeviceId ? transaction.merchantDeviceId : 0
                  )
                );
                if (transaction.paymentMethodId == this.creditsPaymentMethod.id) {
                  this.creditFieldExists = true;
                }
              });
            } else {
              this.editTransactionMode = false;
            }
            return outstandingAmount;
          })
        )
        .subscribe((outstandingAmount: number) => {
          this.outstandingAmount =
            this.invoice.id !== 0 && !this.editTransactionMode ? outstandingAmount : this.invoice.totalAmount;
          if (this.outstandingAmount === 0) {
            this.showCloseInvoiceButton = true;
          } else {
            this.showCloseInvoiceButton = false;
            if (this.outstandingAmount < 0) {
              this.paymentType = PaymentType.Refund;
              this.outstandingAmount = this.outstandingAmount * -1;
            }
          }
          if (this.editTransactionMode) this.outstandingAmount = 0;

          this.initialOutstandingAmount = this.outstandingAmount;
        });
    }
  }

  async saveEditedTransactions() {
    const invoicePayment: InvoicePayment = await this.createInvoicePayment(this.invoice.id, this.invoice.invoiceTypeId);
    this.loading = true;

    this.invoiceService.editTransactionsBulk(this.invoice.id, invoicePayment.transactions).subscribe(
      (transactions) => {
        this.stateOfCredits = 0;
        this.invoice.invoiceTransactions = transactions;
        this.editTransactionMode = false;
        this.invoiceService.invoiceUpdated.next(this.invoice.id);
        this.paymentForm.reset();
        this.selectedSquareCard = null;
        this.creditFieldExists = false;
        this.paymentComplete.next();
        this.financeService.invoicePaid.next();
        this.loading = false;
      },
      (errorResponse: HttpErrorResponse) => {
        this.errors = errorResponse.error.errors;
        this.loading = false;
      }
    );
  }

  getClinicInfo(): Observable<Clinic> {
    // TODO Update this to use the appropriate Clinic -mmm
    this.loading = true;
    if (this.clinic) {
      this.loading = false;
      return of(this.clinic);
    }

    if (this.clinicsService.clinic) {
      this.clinic = this.clinicsService.clinic;
      return of(this.clinic);
    }

    return this.clinicsService.getClinicById(localStorage.clinicId).pipe(
      map((clinic: Clinic) => {
        if (clinic.logoUrl) {
          clinic.logoUrl = clinic.logoUrl.trim() + this.blobService.getReadOnlySAS();
        }
        this.clinic = clinic;
        this.loading = false;
        return this.clinic;
      })
    );
  }

  getInvoiceLineItemTaxes(invoice: Invoice) {
    this.loading = true;
    const { invoiceLineItemTaxKeys, invoiceLineItemTaxValuesObject } = this.invoicesService.getInvoiceLineItemTaxes(
      invoice,
      this.clinic
    );
    this.invoiceLineItemTaxKeys = invoiceLineItemTaxKeys;
    this.invoiceLineItemTaxValuesObject = invoiceLineItemTaxValuesObject;
    this.loading = false;
  }

  getPaymentMethods() {
    this.loading = true;
    this.paymentMethodsService
      .getPaymentMethods()
      .pipe(takeUntil(this.unsub))
      .subscribe((paymentMethods: PaymentMethod[]) => {
        this.squarePaymentMethod = paymentMethods.find((p) => p.id == PaymentMethodEnum.Square);
        this.paymentMethods = paymentMethods.filter((element) => element.isUserVisible === true);
        this.creditsPaymentMethod = this.paymentMethods.find((p) => p.id === PaymentMethodEnum.Credits);
        this.formPaymentMethods = this.paymentForm.get('formPaymentMethods') as FormArray;

        // Check if using merchant devices
        if (this.clinic?.isUsingMerchantDevices) {
          this.merchantDeviceService
            .getMerchantDevicePaymentMethods()
            .pipe(takeUntil(this.unsub))
            .subscribe((merchantDevicePaymentMethods: PaymentMethod[]) => {
              this.merchantDevicePaymentMethods = merchantDevicePaymentMethods;
              // Filter out merchant device payment methods
              if (this.merchantDevicePaymentMethods.length > 0) {
                this.paymentMethods = this.paymentMethods.filter((element) => !element.isMerchantDeviceCapable);
              }
              this.formMerchantDevicePaymentMethods = this.paymentForm.get(
                'formMerchantDevicePaymentMethods'
              ) as FormArray;
              this.loading = false;
            });
        }
        this.loading = false;
      });
  }

  getPatientAvailableCredit() {
    this.financeService
      .getPatientAvailableCredit(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((patientAvailableCredit: number) => {
        this.patientAvailableCredit = patientAvailableCredit;
      });
  }

  getPatientAvailableLoyaltyPoints() {
    this.financeService
      .getPatientAvailableLoyaltyPoints(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((value: number) => {
        this.patientAvailableLoyaltyPoints.value = value;
      });
  }

  getPatientAvailableLoyaltyPointsDollarValue() {
    this.financeService
      .getPatientAvailableLoyaltyPointsDollarValue(this.patientId)
      .pipe(takeUntil(this.unsub))
      .subscribe((value: number) => {
        this.patientAvailableLoyaltyPoints.dollarValue = value;
      });
  }

  getInvoicePaidAmount(invoiceId: number) {
    this.financeService
      .getInvoicePaidAmount(invoiceId)
      .pipe(takeUntil(this.unsub))
      .subscribe((paidAmount: number) => (this.paidAmount = paidAmount));
  }

  async onPaymentFormSubmit(delayClose: boolean = false) {
    this.loading = true;
    const invoiceType: InvoiceType =
      this.paymentType === PaymentType.Regular
        ? InvoiceType.Regular
        : this.paymentType === PaymentType.Refund
        ? InvoiceType.Refund
        : this.paymentType === PaymentType.Recurring
        ? InvoiceType.Recurring
        : InvoiceType.Return;

    const transactionType: FinanceTransactionType =
      this.paymentType === PaymentType.Regular || this.paymentType === PaymentType.Recurring
        ? FinanceTransactionType.Payment
        : this.paymentType === PaymentType.Refund
        ? FinanceTransactionType.Refund
        : FinanceTransactionType.Return;

    try {
      if (this.invoice.id === 0) {
        // Pay new invoice

        const blankInvoice = {
          patientId: Number(this.patientId),
          invoiceDate: new Date(),
          invoiceTypeId: invoiceType,
        };
        const result = await this.invoicesService
          .addInvoice(blankInvoice, this.paymentType != PaymentType.Regular)
          .toPromise();
        const invoice = result.model;
        this.invoice.id = invoice.id;

        // Append admin fee line item if applicable
        if (this.adminFee != 0) {
          const adminFeeLineItem: InvoiceLineItem = this.createAdminFeeLineItem(invoice.id, this.adminFee * -1);
          this.invoice.invoiceLineItems.push(adminFeeLineItem);
        }

        // Update line items with new invoice id
        this.invoice.invoiceLineItems.forEach((item) => {
          item.invoiceId = invoice.id;
        });
        await this.invoicesService.updateInvoiceLineItems(this.invoice.invoiceLineItems).toPromise();

        // Check if we are returning plannedTreatmentMultiples
        let plannedMultiples = this.invoice.invoiceLineItems.filter(
          (invoiceLineItem) => invoiceLineItem.plannedTreatmentMultipleId != null
        );
        if (plannedMultiples.length > 0) {
          await this.treatmentPlanService
            .returnPlannedTreatmentMultiple(plannedMultiples[0].plannedTreatmentMultipleId, plannedMultiples.length)
            .toPromise();
        }

        const invoicePayment: InvoicePayment = await this.createInvoicePayment(invoice.id, transactionType);
        await this.financeService.addTransactions(invoicePayment).toPromise();

        // Update patients available credit if credit returned
        if (this.paymentType === PaymentType.ReturnCredit) {
          const transactionsTotal = invoicePayment.transactions.reduce(
            (sum: number, cur: { amount: any }) => sum + Number(cur.amount),
            0
          );
          await this.financeService
            .updatePatientAvailableCredit(
              this.patientId,
              this.patientAvailableCredit - transactionsTotal - this.adminFee
            )
            .toPromise();
          this.invoiceService.invoicesListUpdated.next();
        }
      } else {
        //Pay existing invoice
        const invoicePayment: InvoicePayment = await this.createInvoicePayment(this.invoice.id, transactionType);
        await this.financeService.addTransactions(invoicePayment).toPromise();
      }

      // Set invoice as paid
      this.invoice.isPaid = true;
      this.financeService.invoicePaid.next();

      // Reset modal
      this.paymentForm.reset();
      this.squareCardComponent?.ngOnInit();
      this.selectedSquareCard = null;
      this.creditFieldExists = false;
      this.stateOfCredits = 0;
      this.appointmentService.onAllApptsUpdated();

      if (!delayClose) {
        this.paymentComplete.next();
        this.loading = false;
      }

      return true;
    } catch (error) {
      this.loading = false;
      if (error instanceof HttpErrorResponse) {
        if (error.error?.errors) this.errors = error.error.errors;
        else if (error.error) this.errors = error.error;
        else this.errors = [error.message];
        if (this.invoice.id !== 0) {
          this.getInvoicePaidAmount(this.invoice.id);
          this.getSelectedInvoiceDetails();
          this.getPatientAvailableCredit();
          this.getPatientAvailableLoyaltyPoints();
          this.getPatientAvailableLoyaltyPointsDollarValue();
        }
      } else {
        throw error;
      }
      return false;
    }
  }

  private createAdminFeeLineItem(invoiceId: number, amount: number): InvoiceLineItem {
    return {
      id: 0,
      invoiceId: invoiceId,
      note: null,
      unitPrice: amount,
      quantity: 1,
      total: amount,
      serviceType: null,
      serviceTypeDescription: 'Admin Fee',
      clinicProductId: null,
      relatedInvoiceLineItemId:
        this.invoice && this.invoice.invoiceLineItems && this.invoice.invoiceLineItems.length > 0
          ? this.invoice.invoiceLineItems[0].relatedInvoiceLineItemId
          : 0,
      isManual: true,
      isDeleted: false,
      finalTotal: amount,
      isCredit: false,
      serviceTemplateId: this.invoiceService.adminFeeServiceTemplate.id,
      addBackToInventory: false,
      invoiceLineItemTaxes: [],
      attributedToUserId: this.usersService.loggedInUser.id,
      attributedToUser: this.usersService.loggedInUser,
      plannedTreatmentMultipleId: null,
      isRecommendedProduct: false,
    };
  }

  private async createInvoicePayment(
    invoiceId: number,
    financeTransactionType: FinanceTransactionType,
    addUserAsCreatedBy: boolean = false
  ): Promise<InvoicePayment> {
    const transactions: InvoiceTransaction[] = [];
    (this.paymentForm.get('formPaymentMethods') as FormArray)['controls'].forEach((item) => {
      var paymentMethod = null;
      if (item.value.isMerchantDeviceTransaction)
        paymentMethod = this.merchantDevicePaymentMethods.find((p) => p.id == item.value.method.id);
      else paymentMethod = this.paymentMethods.find((p) => p.id == item.value.method.id);
      var isManualEntry = item.value.isManualEntry ? item.value.isManualEntry : false;
      this.addTransaction(
        transactions,
        item,
        invoiceId,
        financeTransactionType,
        paymentMethod,
        addUserAsCreatedBy,
        isManualEntry
      );
    });
    if (this.paymentType === PaymentType.ReturnPaidCancellation) {
      const squareTransaction = this.createSquareTransaction(
        invoiceId,
        financeTransactionType,
        this.outstandingAmount,
        this.squarePaymentMethod.id
      );
      transactions.push(squareTransaction);
    }
    if (this.paymentType === PaymentType.Recurring && this.selectedSquareCard) {
      let squarePayment = this.createSquarePayment(this.outstandingAmount, this.selectedSquareCard.id);
      const squareTransaction = this.createSquareTransaction(
        invoiceId,
        financeTransactionType,
        this.outstandingAmount,
        this.squarePaymentMethod.id
      );
      squareTransaction.squarePaymentId = 0;
      squareTransaction.squarePayment = squarePayment;
      transactions.push(squareTransaction);
    }

    let transactionsTotal = transactions.reduce((sum: number, cur: { amount: any }) => sum + Number(cur.amount), 0);
    this.paymentType === PaymentType.Regular || this.paymentType === PaymentType.Recurring
      ? (transactionsTotal -= this.adminFee)
      : (transactionsTotal += this.adminFee);
    if (transactionsTotal < this.initialOutstandingAmount) {
      throw new Error(
        'The total of the payment cannot be less than the total of the invoice. Partial payments are not permitted.'
      );
    }

    return {
      patientId: this.patientId,
      transactions: transactions,
      paidCancellation: this.paidCancellation,
    };
  }

  private addTransaction(
    transactions: InvoiceTransaction[],
    item,
    invoiceId: number,
    financeTransactionType: FinanceTransactionType,
    paymentMethod: PaymentMethod,
    addUserAsCreatedBy: boolean,
    isManualEntry: boolean = false
  ) {
    transactions.push({
      id: item.value.id ? item.value.id : 0,
      invoiceId: invoiceId,
      transactionDate: item.value.transactionDate ? item.value.transactionDate : new Date(),
      financeTransactionType: null,
      patientId: this.patientId,
      financeTransactionTypeId: financeTransactionType,
      amount: item.value.amount,
      paymentMethodId: item.value.method.id,
      paymentMethod: null,
      isMerchantDeviceTransaction: paymentMethod.isMerchantDeviceTransaction ?? false,
      merchantDeviceId: paymentMethod.merchantDeviceId ?? 0,
      description: '',
      createdBy: null,
      createdById: addUserAsCreatedBy
        ? this.usersService.loggedInUser.id
        : item.value.createdById
        ? item.value.createdById
        : this.usersService.loggedInUser.id,
      emvData: null,
      isManualEntry: isManualEntry ?? false,
    });
  }

  private createSquareTransaction(
    invoiceId: number,
    financeTransactionTypeId: number,
    amount: number,
    paymentMethodId: number
  ): InvoiceTransaction {
    return {
      id: 0,
      invoiceId: invoiceId,
      transactionDate: new Date(),
      financeTransactionType: null,
      patientId: this.patientId,
      financeTransactionTypeId: financeTransactionTypeId,
      amount: amount,
      paymentMethodId: paymentMethodId,
      paymentMethod: null,
      isMerchantDeviceTransaction: false,
      merchantDeviceId: 0,
      description: '',
      createdBy: null,
      createdById: this.usersService.loggedInUser.id,
      emvData: null,
      isManualEntry: false,
    };
  }

  private createSquarePayment(amount: number, squareCardId: string): SquarePayment {
    return {
      id: 0,
      type: SquarePaymentType.Payment,
      status: SquarePaymentStatus.Reserved,
      squareCardId: squareCardId,
      amount: amount,
      clinicId: this.clinic.clinicId,
    };
  }

  backToReturn() {
    this.backToReturnPanel.next();
  }

  async onPrintInvoice() {
    this.loading = true;
    let result = await this.onPaymentFormSubmit(true);
    if (result) {
      const componentRef = await this.createStandardInvoiceComponent();
      setTimeout(async () => {
        await componentRef.instance.exportToPDF();
        componentRef.destroy();
        this.exportTemplate.clear();
        this.loading = false;
        this.paymentComplete.next();
      }, 150);
    }
  }

  async onEmailInvoice(email: string) {
    let result = await this.onPaymentFormSubmit(true);
    if (result) {
      const componentRef = await this.createStandardInvoiceComponent();
      await componentRef.instance.emailInvoice(email);
      componentRef.destroy();
      this.exportTemplate.clear();
      this.loading = false;
      this.paymentComplete.next();
    }
    return result;
  }

  getEmailInvoiceObservable = (email: string) => {
    return defer(() => this.onEmailInvoice(email));
  };

  private async createStandardInvoiceComponent() {
    const componentRef = this.exportTemplate.createComponent(StandardInvoiceComponent);
    const invoiceComponent = componentRef.instance as StandardInvoiceComponent;
    invoiceComponent.externalInvoiceId = this.invoice.id;
    invoiceComponent.clinic = this.clinic;
    invoiceComponent.invoice = this.invoice;
    await invoiceComponent.invoiceReadyForPrint.pipe(first((ready) => ready == true)).toPromise();
    return componentRef;
  }

  onChangeBackToInventoryField() {
    this.paymentForm.get('addBackToInventory').valueChanges.subscribe((val) => {
      if (!isNullOrUndefined(val)) {
        this.invoice.invoiceLineItems.forEach((item) => (item.addBackToInventory = val));
      }
    });
  }

  closeInvoiceHandler() {
    this.loading = true;
    this.invoicesService
      .updateInvoicePaidStatus(this.invoice.id, true)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        () => {
          this.invoicesService.invoiceUpdated.next(this.invoice.id);
          this.invoicesService.invoicesListUpdated.next();
          this.loading = false;
          this.paymentComplete.next();
          this.financeService.invoicePaid.next();
        },
        (errorResponse: HttpErrorResponse) => {
          this.errors = errorResponse.error.errors;
          this.loading = false;
        }
      );
  }

  buttonsDisabled(): boolean {
    if (this.paymentType === PaymentType.Recurring) {
      return !this.selectedSquareCard;
    }
    if (
      this.paymentType !== PaymentType.ReturnPaidCancellation &&
      this.formPaymentMethods?.length === 0 &&
      this.formMerchantDevicePaymentMethods?.length === 0
    )
      return true;
    if (!this.paymentForm.valid) return true;
    if (this.patientAvailableCredit - this.stateOfCredits < 0) return true;
    if (this.paymentType !== PaymentType.Regular && this.paymentType !== PaymentType.ReturnProduct) return false;
    return this.outstandingAmount !== 0;
  }

  checkInvalidPaymentAmount(): boolean {
    const isReturnCredit = this.paymentType === PaymentType.ReturnCredit;
    const isOutstandingZero = this.outstandingAmount === 0;
    const isPatientCreditNegative = this.patientAvailableCredit < 0;
    const isOutstandingAmountInvalid = isReturnCredit ? !isOutstandingZero : this.outstandingAmount < 0;

    return isOutstandingAmountInvalid || isPatientCreditNegative;
  }

  onSquareCardSelected(card: SquareCard) {
    this.selectedSquareCard = card;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
