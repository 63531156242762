import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';
import { Nudge } from '@models/nudges/nudge';
import { NudgeTypes } from '@models/nudges/type';
import { NudgeResponse } from '@models/nudges/nudge-response';

@Injectable({
  providedIn: 'root'
})
export class NudgeService {

  private nudgeCountUpdated = new Subject<void>();
  public nudgeCountUpdated$ = this.nudgeCountUpdated.asObservable();
  constructor(private http: HttpClient) { }

  getNudges({ state, addState }: { state; addState; }): Observable<NudgeResponse> {
    const params = Object.assign({}, state, addState);

    return this.http.get<NudgeResponse>(environment.baseUrl + 'api/nudges', { params: params });
  }

  updateNudgeCount(){
    this.nudgeCountUpdated.next();
  }

  createNudge(data: Nudge): Observable<Nudge> {
    return this.http.post<Nudge>(environment.baseUrl + 'api/nudges', data);
  }

  updateNudge(data: Nudge, id: number): Observable<Nudge>  {
    return this.http.put<Nudge>(environment.baseUrl + `api/nudges/${id}`, data);
  }

  getNudgesCount(type?: NudgeTypes): Observable<number> {
    let params = {};
    if (type) {
      params = {
        nudgeType: type
      };
    }

    return this.http.get<number>(environment.baseUrl + 'api/Nudges/my-nudges-count', {params: params});
  }

  getNudgeHistory(id: number): Observable<any> {
    return this.http.get<any>(environment.baseUrl + `api/Nudges/${id}/history`);
  }
}
