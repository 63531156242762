import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isNullOrUndefined } from '@app/shared/helpers';
import { ClinicEmailTemplate, EmailTemplateType } from '@models/clinic-email-template';
import { editorOptions, emptyDesign } from '@models/email-editor-options';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { DataSourceRequestState, SortDescriptor, State, process } from '@progress/kendo-data-query';
import { ClinicEmailTemplateService } from '@services/clinic-email-template.service';
import { CommunicationsService } from '@services/communications.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '../dialogs/confirm-delete/confirm-delete.component';
import { ImportJSONComponent } from './import-json/import-json.component';
import { UnlayerEmailEditorComponent } from '@app/shared/components/unlayer-email-editor/unlayer-email-editor.component';

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.less'],
})
export class EmailTemplatesComponent implements OnInit, OnDestroy {
  @ViewChild('grid') grid;
  @ViewChild('templatesFrame') frame: ElementRef;
  @ViewChild(UnlayerEmailEditorComponent) emailEditor: UnlayerEmailEditorComponent;
  @ViewChild('buttonHeader') buttonHeader: ElementRef;
  @ViewChild('buttonFooter') buttonFooter: ElementRef;

  iPad =
    navigator.userAgent.match(/(iPad)/) /* iOS pre 13 */ ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */;

  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  pageable: any;
  disableGrid = false;
  loading = false;
  unsub: Subject<void> = new Subject<void>();
  active = false;
  globalStatus = 'Suspended';
  emailSenderName = '';
  emailSenderEmail = '';
  emailTemplateTypes = EmailTemplateType;

  emailTemplates: ClinicEmailTemplate[] = [];
  selectedTemplate: ClinicEmailTemplate;
  frameLoaded = true;
  displayData: GridDataResult;

  editorOptions = editorOptions;

  emailTemplateType:
    | 'AppointmentReminder'
    | 'RetentionCampaign'
    | 'Reviews'
    | 'PortalWelcome'
    | 'PortalResetPassword'
    | 'PortalSetPassword'
    | 'PortalCompleteDocuments'
    | 'AppointmentScheduled' = 'AppointmentReminder';

  form: FormGroup;
  showDetails: boolean = false;
  showEditor: boolean = false;
  showList: boolean = true;
  showDeleteMessage: boolean = false;
  showSaveMessage: boolean = false;

  displayEmailTemplateType: string = '-';
  displayName: string = '-';
  displayDescription: string = '-';
  displayCreatedDate: string = '-';
  displayCreatedBy: string = '-';
  displayModifiedDate: string = '-';
  displayModifiedBy: string = '-';

  heightToSubtractSidePanel: number;

  public clickedIndex;
  public pageSize = 10;
  public skip = 0;

  public state: State = {
    skip: this.skip,
    take: this.pageSize,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [
      {
        field: '',
        dir: 'desc',
      },
    ],
  };

  constructor(
    private router: Router,
    private communicationService: CommunicationsService,
    private clinicEmailTemplateService: ClinicEmailTemplateService,
    private dialog: MatDialog,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.initForm();
    this.initDefaultValue();
    this.detectChanges();
    this.getEmailTemplates();
  }

  ngAfterViewInit() {
    // this.frame.nativeElement.onload = (e) => {
    //   // this.frameLoaded = true;
    //   console.log(isNullOrUndefined(document.getElementById('sub-frame-error')));
    // };
    this.emailEditor.minHeight = '750px';
  }

  ngAfterViewChecked() {
    this.heightToSubtractSidePanel =
      this.buttonHeader.nativeElement.offsetHeight + this.buttonFooter.nativeElement.offsetHeight;
  }

  initForm() {
    this.form = new FormGroup({
      template: new FormControl(null),
      emailTemplateType: new FormControl(1, Validators.required),
      name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      createdDate: new FormControl(null),
      createdBy: new FormControl(),
      modifiedDate: new FormControl(null),
      modifiedBy: new FormControl(),
    });

    // this.form.get('template').valueChanges.subscribe((item: ClinicEmailTemplate) => {
    //   this.showDetails = true;
    //   this.selectedTemplate = item;
    //   this.loadEditor(item);
    // });
  }

  mailjetLogin() {
    window.open('https://app.mailjet.com/signin');
  }

  initDefaultValue() {
    this.disableGrid = false;
    this.gridState = {
      skip: 0,
      take: 10,
      sort: [{ dir: 'desc', field: 'name' }],
    };

    this.gridView = {
      data: [],
      total: 0,
    };
  }

  detectChanges() {
    this.communicationService.communicationsSettingsUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.communicationService.refreshRequired) {
        this.communicationService.refreshRequired = false;
        this.getEmailTemplates();
      }
    });
  }

  getEmailTemplates() {
    this.loading = true;
    this.clinicEmailTemplateService.getClinicEmailTemplates().subscribe((result) => {
      this.emailTemplates = result;
      //this.gridView = process(this.emailTemplates, this.gridState);
      this.gridView = {
        data: result,
        total: result.length,
      };
      this.displayData = this.gridView;
      this.loading = false;
    });
  }

  resizeIframe(frame) {
    return window.innerHeight - frame.getBoundingClientRect().top - 24 + 'px';
  }

  openEmailTemplatePanel(e = null) {
    this.disableGrid = true;
    let id = '_';
    if (!isNullOrUndefined(e) && !isNullOrUndefined(e.dataItem)) {
      id = e.dataItem.id.toString();
    }
    this.router.navigate([
      '/management/communication/email-templates',
      { outlets: { 'action-panel': ['edit-email-templates', id, e.dataItem.name] } },
    ]);
  }

  openEmailTemplatePanel2(e = null) {
    this.router.navigate([
      '/management/communication/email-templates',
      { outlets: { 'action-panel': ['edit-email-templates', 'id', 'e.dataItem.name'] } },
    ]);
  }

  pageChange(event: PageChangeEvent) {
    this.gridState.skip = event.skip;
    this.gridView = process(this.emailTemplates, this.gridState);
  }

  sortChange(sort: SortDescriptor[]) {
    this.gridState.sort = sort;
    this.gridView = process(this.emailTemplates, this.gridState);
  }

  sortIconClass(column: any) {
    return this.gridState.sort.length &&
      this.gridState.sort[0].field === column.field &&
      this.gridState.sort[0].dir === 'asc'
      ? 'fad fa-sort-up'
      : this.gridState.sort.length &&
        this.gridState.sort[0].field === column.field &&
        this.gridState.sort[0].dir === 'desc'
      ? 'fad fa-sort-down'
      : 'fas fa-sort';
  }

  loadEditor(item: ClinicEmailTemplate) {
    console.log('loadEditor');
    if (Object.keys(item).length === 0) return;

    // this.emailEditor.editor.loadDesign(sample2890892);
    // if (item.id === 2890892) {
    //   this.emailEditor.editor.loadDesign(sample2890892);
    //   this.form.controls['emailTemplateType'].setValue('AppointmentReminder');
    // } else if (item.id === 2894815) {
    //   this.emailEditor.editor.loadDesign(sample2894815);
    //   this.form.controls['emailTemplateType'].setValue('RetentionCampaign');
    // } else if (item.id === 2998269) {
    //   this.emailEditor.editor.loadDesign(sample2998269);
    //   this.form.controls['emailTemplateType'].setValue('Reviews');
    // } else if (item.id === 3304352) {
    //   this.emailEditor.editor.loadDesign(sample3304352);
    //   this.form.controls['emailTemplateType'].setValue('AppointmentReminder');
    // } else this.emailEditor.editor.loadDesign()

    this.form.controls['emailTemplateType'].setValue(item.emailTemplateType);
    this.form.controls['name'].setValue(item.name);
    this.form.controls['description'].setValue(item.description);
    this.form.controls['createdDate'].setValue(moment(item.createdDate).format('YYYY-MM-DD h:mm A'));
    this.form.controls['createdBy'].setValue(item.createdByUser ? item.createdByUser.userName : '-');
    this.form.controls['modifiedDate'].setValue(
      item.modifiedDate.toString() == '0001-01-01T00:00:00'
        ? '-'
        : moment(item.modifiedDate).format('YYYY-MM-DD h:mm A')
    );
    this.form.controls['modifiedBy'].setValue(item.modifiedByUser ? item.modifiedByUser.userName : '-');
    this.emailEditor.editor.loadDesign(JSON.parse(item.definition));
    if (this.iPad) this.emailEditor.editor.showPreview('Mobile');
  }

  editorReady(event) {
    console.log('editorReady');
    /*  this.emailEditor.editor.init({
      tools: {
        image: {
          enabled: true,
        },
        video: {
          enabled: true,
        },
        social: {
          enabled: true,
        },
      },
      tabs: {
        content: {
          enabled: true,
        },
        images: {
          enabled: false,
        },
      },
    }); */
  }

  saveDesign() {
    this.emailEditor.editor.saveDesign((data) => console.log('saveDesign', data));
  }

  exportHtml() {
    this.emailEditor.exportHtml((data) => console.log('exportHtml', data));
  }

  onClone() {
    this.loading = true;
    this.emailEditor.exportHtml((htmlObj) => {
      this.emailEditor.editor.saveDesign((definition) => {
        const formVals = this.form.value;
        const template = new ClinicEmailTemplate();
        template.definition = JSON.stringify(definition);
        template.html = (htmlObj as any).html;
        template.name = formVals['name'] + ' clone';
        template.description = formVals['description'];
        template.emailTemplateType = formVals['emailTemplateType'];
        this.addTemplate(template);
      });
    });
  }

  onSave() {
    this.loading = true;
    this.emailEditor.exportHtml((htmlObj) => {
      this.emailEditor.editor.saveDesign((definition) => {
        const formVals = this.form.value;
        const template = new ClinicEmailTemplate();
        template.definition = JSON.stringify(definition);
        template.html = (htmlObj as any).html;
        template.name = formVals['name'];
        template.description = formVals['description'];
        template.emailTemplateType = formVals['emailTemplateType'];
        if (this.selectedTemplate) {
          template.id = this.selectedTemplate.id;
          this.updateTemplate(template);
        } else {
          this.addTemplate(template);
        }
      });
    });
  }

  onDelete() {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.deleteTemplate(this.selectedTemplate);
        }
      });
  }

  onCancel() {
    this.showDetails = false;
    this.showList = true;
    this.showEditor = false;
    this.displayEmailTemplateType = '-';
    this.displayName = '-';
    this.displayDescription = '-';
    this.displayCreatedDate = '-';
    this.displayCreatedBy = '-';
    this.displayModifiedDate = '-';
    this.displayModifiedBy = '-';
    this.clickedIndex = -1;
  }

  onEdit() {
    this.showDetails = true;
    this.showList = false;
  }

  addTemplate(template: ClinicEmailTemplate) {
    this.clinicEmailTemplateService
      .addClinicEmailTemplate(template)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (savedTemplate) => {
          this.emailTemplates.push(savedTemplate);
          this.form.controls['template'].setValue(savedTemplate);
          this.loading = false;
          this.showSaveMessage = true;
          setTimeout(async () => {
            this.showSaveMessage = false;
          }, 5000);
        },
      });
  }

  updateTemplate(template: ClinicEmailTemplate) {
    this.clinicEmailTemplateService
      .updateClinicEmailTemplate(template)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (savedTemplate) => {
          const index = this.emailTemplates.findIndex((t) => t.id === savedTemplate.id);
          this.emailTemplates[index] = savedTemplate;
          this.form.controls['template'].setValue(savedTemplate);
          this.loading = false;
          this.showSaveMessage = true;
          setTimeout(async () => {
            this.showSaveMessage = false;
          }, 5000);
        },
      });
  }

  deleteTemplate(template: ClinicEmailTemplate) {
    this.clinicEmailTemplateService
      .deleteClinicEmailTemplate(template.id)
      .pipe(takeUntil(this.unsub))
      .subscribe({
        next: (deletedTemplate) => {
          const index = this.emailTemplates.findIndex((t) => t.id === deletedTemplate.id);
          this.emailTemplates.splice(index, 1);
          if (this.selectedTemplate.id == deletedTemplate.id)
            if (this.emailTemplates.length) {
              this.form.controls['template'].setValue(this.emailTemplates[0]);
            } else {
              this.form.controls['template'].setValue(null);
            }
          this.showDeleteMessage = true;
          this.loading = false;
          setTimeout(async () => {
            this.showDeleteMessage = false;
          }, 5000);
        },
      });
  }

  createTemplate() {
    console.log('Creating new Template');
    this.showEditor = true;
    this.showDetails = true;
    this.showList = false;
    this.selectedTemplate = null;
    //this.form.controls['template'].setValue(1);
    this.form.controls['emailTemplateType'].setValue(null);
    this.form.controls['name'].setValue(null);
    this.form.controls['description'].setValue(null);
    this.form.controls['createdDate'].setValue('-Not Saved-');
    this.form.controls['createdBy'].setValue('-Not Saved-');
    this.form.controls['modifiedDate'].setValue('-Not Saved-');
    this.form.controls['modifiedBy'].setValue('-Not Saved-');
    this.displayEmailTemplateType = '-Not Saved-';
    this.displayName = '-Not Saved-';
    this.displayDescription = '-Not Saved-';
    this.displayCreatedDate = '-Not Saved-';
    this.displayCreatedBy = '-Not Saved-';
    this.displayModifiedDate = '-Not Saved-';
    this.displayModifiedBy = '-Not Saved-';

    this.emailEditor.editor.loadDesign(emptyDesign);
    if (this.iPad) this.emailEditor.editor.showPreview('Mobile');
  }

  onImportJSON() {
    const modalRef = this.modalService.open(ImportJSONComponent, { windowClass: 'add-photo-modal', centered: true });
    modalRef.result.then((result: ClinicEmailTemplate) => {
      console.log('Importing template');
      this.showList = false;
      this.showDetails = true;
      this.selectedTemplate = null;
      //this.form.controls['template'].setValue(1);
      this.form.controls['emailTemplateType'].setValue(result.emailTemplateType);
      this.form.controls['name'].setValue(result.name);
      this.form.controls['description'].setValue(result.description);
      this.form.controls['createdDate'].setValue('-Not Saved-');
      this.form.controls['createdBy'].setValue('-Not Saved-');
      this.form.controls['modifiedDate'].setValue('-Not Saved-');
      this.form.controls['modifiedBy'].setValue('-Not Saved-');
      this.displayEmailTemplateType = '-Not Saved-';
      this.displayName = '-Not Saved-';
      this.displayDescription = '-Not Saved-';
      this.displayCreatedDate = '-Not Saved-';
      this.displayCreatedBy = '-Not Saved-';
      this.displayModifiedDate = '-Not Saved-';
      this.displayModifiedBy = '-Not Saved-';
      let definition = result.definition ? result.definition : emptyDesign;
      this.emailEditor.editor.loadDesign(definition);
      if (this.iPad) this.emailEditor.editor.showPreview('Mobile');
    });
  }

  selectTemplate(template: ClinicEmailTemplate, i) {
    this.showEditor = true;
    this.selectedTemplate = template;
    this.clickedIndex = i;
    this.loadEditor(template);
    this.displayEmailTemplateType = EmailTemplateType[template.emailTemplateType];
    this.displayName = template.name;
    this.displayDescription = template.description;
    this.displayCreatedDate = moment(template.createdDate).format('YYYY-MM-DD h:mm A');
    this.displayCreatedBy = template.createdByUser.userName;
    this.displayModifiedDate =
      template.modifiedDate.toString() == '0001-01-01T00:00:00'
        ? '-'
        : moment(template.modifiedDate).format('YYYY-MM-DD h:mm A');
    this.displayModifiedBy = template.modifiedByUser ? template.modifiedByUser.userName : '-';
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
