<div class="visits-container position-absolute d-block z-index-3 white-background">
  <ngx-loading [show]="loading"></ngx-loading>

  <!-- Links Container -->
  <div class="re-links-container" kendoTooltip tooltipClass="em-tooltip" position="top">
    <div class="re-links">
      <button class="btn em-btn" [attr.data-test-id]="'close-visit-panel'" (click)="closePanel()">
        <i class="far fa-times" aria-hidden="true" title="Close Panel"></i>
      </button>
      <button class="btn em-btn white-border-top" (click)="onChangePatient()">
        <i class="far fa-exchange" title="Change Patient"></i>
      </button>
      <button [disabled]="!patient" class="btn em-btn white-border-top" (click)="openNudgeModal()">
        <i class="far fa-bell-plus" title="Add Nudge"></i>
      </button>
    </div>
  </div>
  <ng-scrollbar class="h-100">
    <!-- Select Patient -->
    <div class="row" *ngIf="!patient || changingPatient">
      <div class="col">
        <div class="row">
          <div class="col m-3">
            <button class="btn em-btn em-btn-green" [disabled]="addPatientVisible" (click)="onNewPatientHandler()">
              Add New Patient
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col m-3">
            <app-patient-typehead
              date-test-id="visit-panel-patient-search"
              #patientSearch
              (typeaheadOnSelectEmitter)="typeaheadOnChange($event)"
            >
            </app-patient-typehead>
          </div>
        </div>
      </div>
    </div>

    <!-- Patient Info -->
    <app-visit-patient-info
      *ngIf="patient"
      [patient]="patient"
      [showActionButtons]="!changingPatient"
      (editPatientEvent)="onEditPatientHandler()"
    ></app-visit-patient-info>

    <app-edit-patient-content
      [currentPatient]="patient"
      [forceNew]="addOrEdit && addOrEdit == 'Add'"
      *ngIf="addPatientVisible"
      id="addPatient"
      (updatedPatientEvent)="handleUpdatedPatient($event)"
      (cancelEditEvent)="cancelPatientUpdate()"
    ></app-edit-patient-content>

    <!-- Patient Memberships -->
    <div *ngIf="patient" class="row pb-2">
      <div class="col">
        <h6 class="teal-background white-font uppercase p-2 mb-0">Memberships</h6>
        <app-patient-membership-overview class="d-block pt-2 px-2" [patientId]="patient.patientId">
        </app-patient-membership-overview>
      </div>
    </div>

    <div class="row teal-background">
      <div class="col">
        <h6 class="white-font uppercase p-2 mb-0">Services</h6>
      </div>
    </div>
    <!-- Visit Notes -->
    <div class="row mx-0" kendoTooltip tooltipClass="em-tooltip" position="top">
      <div class="col pt-0 mb-2 grey-two-background">
        <div class="d-flex align-items-center justify-content-start mt-2 mb-1">
          <h6 class="navy-font sub-heading my-1">Visit Notes</h6>
        </div>
        <div class="mb-3">
          <app-textarea-widget
            class=""
            [minRows]="1"
            [maxRows]="3"
            [textValue]="visit?.visitNotes"
            (emitText)="saveVisitNotes($event)"
          >
          </app-textarea-widget>
        </div>
      </div>
    </div>

    <!-- Scheduled Appointments -->
    <div class="row mx-0" kendoTooltip tooltipClass="em-tooltip" position="top">
      <div class="col px-2">
        <h6 class="navy-font sub-heading">Scheduled Appointments</h6>
      </div>
    </div>
    <app-visit-appointments [visit]="visit" [patient]="patient"></app-visit-appointments>

    <!-- Add Appointment -->
    <div class="row mx-0 mt-3">
      <div class="col px-2">
        <h6 class="sub-heading">Add Appointment</h6>
      </div>
    </div>
    <app-visit-edit-appointment
      [visit]="visit"
      [patient]="patient"
      [selectedStaff]="selectedStaff"
      [selectedTreatmentService]="selectedTreatmentService"
    ></app-visit-edit-appointment>

    <!-- eForms -->
    <ng-container *ngIf="visitServiceForms && visitServiceForms.length > 0">
      <div class="row">
        <div class="col">
          <h6 class="teal-background white-font uppercase p-2 mb-0">eForms</h6>
          <div class="px-2 py-3" kendoTooltip tooltipClass="em-tooltip" position="top">
            <div class="row mx-0">
              <ng-container *ngFor="let serviceForm of visitServiceForms; let index = index">
                <div class="col">
                  <mat-checkbox
                    (change)="
                      currentFormsSelectionModel.toggle(serviceForm?.clinicForm?.id);
                      onChangeCurrentFormsSelectionModel($event, serviceForm)
                    "
                    [checked]="currentFormsSelectionModel.isSelected(serviceForm?.clinicForm?.id)"
                    [disabled]="
                      currentFormsSelectionModel.isSelected(serviceForm?.clinicForm?.id) && isFormSigned(serviceForm)
                    "
                    >{{ serviceForm?.clinicForm?.name }}&nbsp;&nbsp;
                    <i
                      class="far bigger-icon form-icon"
                      [ngClass]="
                        isFormSigned(serviceForm) ? 'fa-check spring-green-font' : 'fa-times calendar-red-font'
                      "
                    ></i
                  ></mat-checkbox>
                </div>
                <div class="w-100" *ngIf="(index + 1) % 2 === 0"></div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Treatment Plan -->
    <div class="row">
      <div class="col">
        <h6 class="teal-background white-font uppercase p-2 mb-0">Treatment Plan</h6>
        <app-visits-treatment-plan
          class="d-block pt-2 px-2"
          [patientId]="patient?.patientId"
          [serviceTemplateIds]="selectedStaff?.authorizedServiceIds"
          (addServiceToSchedule)="onSelectPlannedTreatment($event)"
        >
        </app-visits-treatment-plan>
      </div>
    </div>

    <ng-container *ngIf="patient">
      <!-- Future Appointments -->
      <div class="row">
        <div class="col">
          <h6 class="teal-background white-font uppercase p-2 mb-0">Future Appointments</h6>
          <div class="pt-2 px-2" kendoTooltip tooltipClass="em-tooltip" position="top">
            <div
              class="d-flex grey-two-background mb-2 justify-content-center align-items-center"
              *ngFor="let item of futureAppointments"
            >
              <div
                class="service-chart-color align-self-stretch"
                [ngStyle]="{ background: item?.serviceIdColour }"
              ></div>
              <div class="py-2 px-2 navy-font">
                <span class="font-weight-bold">{{ item?.serviceName }}</span>
                <span class="mx-4 medium navy-font">
                  {{
                    moment(item?.date).add(moment.duration(item?.startTime)).toDate() | date : 'yyyy-MM-dd h:mma'
                  }}</span
                >
              </div>
              <div
                class="ml-auto panel-box-column px-2 bigger"
                [ngClass]="{
                  'calendar-green-font': item?.paymentStatus == PaymentStatus.Paid,
                  'calendar-red-font': item?.paymentStatus == PaymentStatus.Unpaid,
                  'purple-font': item?.paymentStatus == PaymentStatus.Refund
                }"
                [title]="
                  item?.paymentStatus == PaymentStatus.Paid
                    ? 'Paid'
                    : item?.paymentStatus == PaymentStatus.Unpaid
                    ? 'Unpaid'
                    : 'Refunded'
                "
              >
                <i
                  [class]="item?.paymentStatus == PaymentStatus.Refund ? 'fas fa-sad-tear' : 'fas fa-usd-circle'"
                  [title]="
                    item?.paymentStatus == PaymentStatus.Paid
                      ? 'Paid'
                      : item?.paymentStatus == PaymentStatus.Unpaid
                      ? 'Unpaid'
                      : 'Refunded'
                  "
                ></i>
              </div>
              <div
                *ngIf="item?.price"
                class="profile-currency pr-2 bigger font-weight-bold"
                [ngClass]="{
                  'calendar-green-font': item?.paymentStatus == PaymentStatus.Paid,
                  'calendar-red-font': item?.paymentStatus == PaymentStatus.Unpaid,
                  'purple-font': item?.paymentStatus == PaymentStatus.Refund
                }"
                [title]="
                  item?.paymentStatus == PaymentStatus.Paid
                    ? 'Paid'
                    : item?.paymentStatus == PaymentStatus.Unpaid
                    ? 'Unpaid'
                    : 'Refunded'
                "
              >
                {{ item?.price | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Previous Appointments -->
      <div class="row pb-2">
        <div class="col">
          <h6 class="teal-background white-font uppercase p-2 mb-0">Previous Appointments</h6>
          <div class="pt-2 px-2" kendoTooltip tooltipClass="em-tooltip" position="top">
            <div
              class="d-flex grey-two-background mb-2 justify-content-center align-items-center"
              *ngFor="let item of previousAppointments"
            >
              <div
                class="service-chart-color align-self-stretch"
                [ngStyle]="{ background: item?.serviceIdColour }"
              ></div>
              <div class="py-2 px-2 navy-font">
                <span class="font-weight-bold">{{ item?.serviceName }}</span>
                <span class="mx-4 medium navy-font">{{ item?.date | date : 'yyyy-MM-dd h:mma' }}</span>
              </div>
              <div
                class="ml-auto panel-box-column px-2 bigger"
                [ngClass]="{
                  'calendar-green-font': item?.paymentStatus == PaymentStatus.Paid,
                  'calendar-red-font': item?.paymentStatus == PaymentStatus.Unpaid,
                  'purple-font': item?.paymentStatus == PaymentStatus.Refund
                }"
              >
                <i
                  [class]="item?.paymentStatus == PaymentStatus.Refund ? 'fas fa-sad-tear' : 'fas fa-usd-circle'"
                  [title]="
                    item?.paymentStatus == PaymentStatus.Paid
                      ? 'Paid'
                      : item?.paymentStatus == PaymentStatus.Unpaid
                      ? 'Unpaid'
                      : 'Refunded'
                  "
                ></i>
              </div>
              <div
                *ngIf="item?.price"
                class="profile-currency pr-2 bigger font-weight-bold"
                [ngClass]="{
                  'calendar-green-font': item?.paymentStatus == PaymentStatus.Paid,
                  'calendar-red-font': item?.paymentStatus == PaymentStatus.Unpaid,
                  'purple-font': item?.paymentStatus == PaymentStatus.Refund
                }"
                [title]="
                  item?.paymentStatus == PaymentStatus.Paid
                    ? 'Paid'
                    : item?.paymentStatus == PaymentStatus.Unpaid
                    ? 'Unpaid'
                    : 'Refunded'
                "
              >
                {{ item?.price | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-scrollbar>
</div>
