<ngx-loading [show]="loading"></ngx-loading>

<div class="d-flex align-items-center px-3 mb-2">
  <div class="column-header">Invoice History:</div>
  <mat-slide-toggle class="ml-auto" *ngIf="nFutureInvoices && nFutureInvoices > 0" [(ngModel)]="showFutureInvoices"
    >Show Future Invoices ({{ nFutureInvoices }})</mat-slide-toggle
  >
  <button
    type="submit"
    class="btn em-btn-green white-font text-center px-2 py-1 align-self-center ml-2"
    (click)="toggleAll()"
  >
    <i [className]="isOpenAll ? 'fas fa-chevron-double-up' : 'fas fa-chevron-double-down'"></i>
  </button>
</div>

<mat-accordion [multi]="true" class="patient-invoice-history flex-grow-1 minh-0">
  <ng-scrollbar style="height: 100%">
    <div *ngFor="let invoice of invoices; trackBy: trackByFunction">
      <mat-expansion-panel
        *ngIf="showFutureInvoices || !isFutureInvoice(invoice)"
        #panelList="matExpansionPanel"
        [expanded]="invoice['isExpanded']"
      >
        <mat-expansion-panel-header
          class="pr-0"
          [collapsedHeight]="'auto'"
          [attr.data-test-id]="'invoice-' + invoice.id"
          [expandedHeight]="'auto'"
          #panelHeader
          (click)="panelHeader._toggle()"
        >
          <mat-panel-title>
            <div
              class="d-flex panel-box pr-0"
              [ngClass]="{
                'return-invoice-panel-box':
                  invoice.invoiceTypeId == InvoiceTypeEnum.Return || invoice.invoiceTypeId == InvoiceTypeEnum.Refund
              }"
            >
              <div class="panel-box-column panel-box-column--main d-flex flex-column">
                <div [ngClass]="{ lighter: invoice?.isPaid }" class="font-weight-normal">
                  {{ invoice?.invoiceDate | date : 'yyyy-MM-dd' }} {{ isFutureInvoice(invoice) ? ' (Future)' : '' }}
                </div>
                <div [ngClass]="{ lighter: invoice?.isPaid }" class="smaller">
                  {{ getInvoiceType(invoice) }}: {{ invoice?.invoiceNumber }}
                </div>
              </div>

              <div class="panel-box-column mr-3 mt-auto mb-auto d-flex" [ngClass]="{ lighter: invoice?.isPaid }">
                {{ invoice?.totalAmount | currency }}
              </div>
              <div
                kendoTooltip
                tooltipClass="em-tooltip"
                position="top"
                class="panel-box-column mt-auto mb-auto mr-3"
                [ngClass]="invoice?.isPaid ? 'calendar-green-font' : 'calendar-red-font'"
              >
                <i class="fas fa-usd-circle" [title]="invoice?.isPaid ? 'Paid' : 'Unpaid'"></i>
              </div>
            </div>
            <button
              kendoTooltip
              tooltipClass="em-tooltip"
              position="top"
              type="button"
              class="panel-box-button d-flex align-items-center justify-content-center"
              (click)="detailInvoice(invoice.id)"
              [attr.data-test-id]="'open-invoice-button'"
              [attr.data-test-value]="invoice.id"
              [attr.data-test]="invoice?.isPaid ? 'true' : 'false'"
            >
              <i class="far fa-arrow-right" title="Invoice Details"></i>
            </button>
            <button
              *ngIf="!invoice?.isPaid"
              type="button"
              class="panel-box-button d-flex align-items-center justify-content-center em-btn-green invoice-pay-button"
              (click)="selectedInvoice$.next(invoice); isOpenPaymentModal = !isOpenPaymentModal"
            >
              Pay
            </button>
            <button type="button" class="btn d-flex flex-column expand-btn p-0" (click)="panelHeader._toggle()">
              <mat-icon>{{ panelList.expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
            </button>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="px-2 pt-2 pb-0">
          <div class="panel-box-content mr-4">
            <div *ngFor="let invoiceLineItem of invoice.invoiceLineItems" class="content-row">
              <div>{{ invoiceLineItem.serviceType }}</div>
              <div>x {{ invoiceLineItem.quantity }}: {{ invoiceLineItem.unitPrice | currency }}</div>
              <div class="calendar-red-font">
                {{
                  invoiceLineItem.isPercentDiscount
                    ? invoiceLineItem.discountPrice + '%'
                    : (invoiceLineItem.discountPrice | currency)
                }}
              </div>
              <div>{{ invoiceLineItem.finalTotal | currency }}</div>
            </div>
          </div>
          <!-- Hiddin email and print button until needed again-->
          <!-- <div class="d-flex align-items-center justify-content-end pt-2 pr-4">
            <app-generic-send-email-popover
              buttonClass="panel-box-button-icon mr-3"
              iconClass="far fa-envelope navy-font fs-20 pointer"
              toolTipTitle="Email Invoice"
              [initialEmailAddress]="email"
              [sendEmailObservable]="emailInvoice(invoice)"
            ></app-generic-send-email-popover>
            <button type="button" class="panel-box-button-icon" (click)="createInvoicePDF(invoice)">
              <i class="far fa-print" title="Print Invoice"></i>
            </button>
          </div> -->
        </div>
      </mat-expansion-panel>
    </div>
  </ng-scrollbar>
</mat-accordion>

<div style="opacity: 0; position: absolute; width: 0; height: 0">
  <ng-template #messagecontainer></ng-template>
</div>

<app-panel [(isVisible)]="isOpenPaymentModal">
  <app-invoice-payment
    [patientId]="patientId"
    [selectedInvoice]="selectedInvoice$"
    [paymentType]=" getPaymentType()"
    (paymentComplete)="onInvoicePay()"
  ></app-invoice-payment>
</app-panel>
