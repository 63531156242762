import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { Clinic } from '@models/clinic';
import { MasterOverlayService } from '@services/actionpanel.service';
import { BlobService } from '@services/blob.service';
import { ClinicsService } from '@services/clinics.service';
import { NavStateService } from '@services/navstate.service';
import { NudgeService } from '@services/nudge.service';
import { PatientService } from '@services/patient.service';
import { ReviewService } from '@services/review.service';
import { UsersService } from '@services/users.service';
import { Subject } from 'rxjs';
import { filter, mergeMap, takeUntil } from 'rxjs/operators';
import { UserAccountDialogComponent } from './user-account-dialog/user-account-dialog.component';
import { TwilioConversationsService } from '@services/twilio-conversations.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.less'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  clinic: Clinic;
  adminOpen = false;
  sideNavExpanded = false;
  loggedInUserName = '';
  loggedInUserAvatar = '';
  nudgeCounter: number;
  reviewNotificationsCounter: number;
  communicationsPolicy = Policies.communications;
  galleryPolicy = Policies.gallery;
  managementPolicy = Policies.management;
  cataloguePolicy = Policies.managementCatalogue;
  formsPolicy = Policies.managementForms;
  managementOrganizationPolicy = Policies.managementOrganization;
  reviewsPolicy = Policies.reviews;
  patientMessaging = Policies.patientMessaging;
  devPolicy = Policies.developer;
  private unsub: Subject<void> = new Subject<void>();

  get patientMessageCount(): number {
    return this.twilioService.getTotalUnreadCount();
  }

  constructor(
    private authService: AuthService,
    private userService: UsersService,
    private clinicsService: ClinicsService,
    private blobService: BlobService,
    private navStateService: NavStateService,
    private masterOverlayService: MasterOverlayService,
    private patientService: PatientService,
    private router: Router,
    private reviewService: ReviewService,
    private nudgeService: NudgeService,
    private dialog: MatDialog,
    private twilioService: TwilioConversationsService
  ) {}

  ngOnInit() {
    this.clinicsService.clinicIdSelected$
      .pipe(
        takeUntil(this.unsub),
        filter((clinicId) => clinicId != null),
        mergeMap((clinicId) => this.clinicsService.getClinicById(clinicId))
      )
      .subscribe((clinic) => {
        if (clinic.logoUrl) {
          clinic.logoUrl += this.blobService.getReadOnlySAS();
        }
        this.clinic = clinic;
      });

    this.nudgeService.nudgeCountUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.nudgeService.getNudgesCount().subscribe((data) => {
        this.nudgeCounter = data;
      });
    });

    this.reviewService.reviewNotificationsCountUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.reviewService.getReviewNotificationsCount().subscribe((data) => {
        this.reviewNotificationsCounter = data;
      });
    });

    this.userService.loggedInUserUpdated$.pipe(takeUntil(this.unsub)).subscribe((u) => {
      if (u && u.id) {
        this.loggedInUserName = u.fullName;
        this.loggedInUserAvatar = u.avatar;

        this.nudgeService.getNudgesCount().subscribe((data) => {
          this.nudgeCounter = data;
        });

        this.reviewService.getReviewNotificationsCount().subscribe((data) => {
          this.reviewNotificationsCounter = data;
        });
      }
    });

    this.navStateService.sideNavExpanded.subscribe((isExpanded) => {
      this.sideNavExpanded = isExpanded;
    });
  }

  adminClick() {
    this.adminOpen = !this.adminOpen;
  }

  closeSubMenu() {
    this.adminOpen = false;
  }

  sideNavExpandClick() {
    this.sideNavExpanded = !this.sideNavExpanded;
    this.navStateService.sideNavExpandState(this.sideNavExpanded);
  }

  addPatient() {
    this.patientService.editPatientSourceURL = this.router.url;
    this.masterOverlayService.masterOverlayState(true);
    this.router.navigate([this.router.url, { outlets: { 'action-panel': ['edit-patient', '_'] } }]);
  }

  logout() {
    this.authService.logout();
  }

  userAccountClick() {
    this.dialog.open(UserAccountDialogComponent, {
      width: '480px',
      height: '800px',
      panelClass: 'custom-dialog-container',
      data: {},
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
