<ngx-loading [show]="loading"></ngx-loading>
<!-- Patient Demographic Info -->
<div *ngIf="!viewModeDefault" class="d-flex teal-background edit-patient-title">
  <div class="col">
    <h6 class="uppercase p-2 mb-0" style="color: white !important">{{ addOrEdit }} Patient</h6>
  </div>
</div>
<div *ngIf="patientFormGroup" [formGroup]="patientFormGroup">
  <div class="row panel-item-border mt-0 pt-2">
    <div class="col">
      <div class="row pb-2">
        <div *ngIf="showCardSwipe" class="col-12 mt-2 form-col">
          <mat-form-field floatLabel="always">
            <mat-label class="mb-0">Health Card Swipe</mat-label>
            <input
              matInput
              id="cardSwipe"
              aria-label="Health Card Swipe"
              (change)="onCardSwipe()"
              formControlName="mspCardSwipe"
              autofocus
            />
            <mat-error *ngIf="cardSwipeError">Error reading card swipe, please try again</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-10 col-xl-6 mt-2 first-control form-col">
              <mat-form-field floatLabel="always" class="edit-patient-control">
                <mat-label class="mb-0">Status</mat-label>
                <mat-select formControlName="patientStatus" id="patientStatus" aria-label="Patient Status">
                  <mat-option
                    *ngFor="let patientStatus of statusEnumKeys"
                    [id]="'status' + patientStatus"
                    [value]="patientStatus"
                  >
                    {{ patientStatusData[patientStatus] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 order-2 order-xl-1 col-xl-4 mt-2 form-col">
              <label class="d-block mb-0 teal-font fake-mat-label text-truncate">VIP Customer</label>
              <label class="switch align-text-top">
                <input
                  type="checkbox"
                  formControlName="isPreferred"
                  aria-label="VIP Customer"
                  id="isPreferred"
                  name="isPreferred"
                />
                <span
                  [ngStyle]="isEditForm === false && { 'pointer-events': null }"
                  class="slider round"
                  [ngClass]="{ 'disabled-slider': !isEditForm }"
                ></span>
              </label>
              <label for="isPreferred" class="pl-2 text-dark" [ngStyle]="{ cursor: isEditForm ? 'pointer' : null }">{{
                patientFormGroup.get('isPreferred').value ? 'Yes' : 'No'
              }}</label>
            </div>
            <div
              class="col-2 order-1 order-xl-2 form-col d-flex align-items-center justify-content-end"
              *ngIf="isShowEditButton"
            >
              <button
                class="btn btn-sm em-btn em-btn-green pull-right mt-2"
                [ngClass]="{ 'em-btn-green': !isEditForm, 'em-btn-red': isEditForm }"
                id="enterEditButton"
                aria-label="Toggle edit mode"
                style="width: 32px; height: 32px"
                (click)="isEditForm ? exitEditMode() : enterEditMode()"
                [title]="isEditForm ? 'Cancel' : 'Edit'"
              >
                <i class="fas fa-pencil" [ngClass]="{ 'fa-pencil': !isEditForm, 'fa-ban': isEditForm }"></i>
              </button>
            </div>
          </div>
        </div>
        <div
          class="col-12 col-xl-6 mt-3 form-col"
          [ngClass]="{ 'mb-4': patientFormGroup.controls['firstName']?.errors?.nameError }"
        >
          <mat-form-field floatLabel="always" [hideRequiredMarker]="!isEditForm" class="edit-patient-control">
            <mat-label class="mb-0">First Name</mat-label>
            <input
              matInput
              data-test-id="patient-firstName-input"
              id="firstName"
              aria-label="First Name"
              formControlName="firstName"
              placeholder="..."
              required
            />
            <mat-error *ngIf="patientFormGroup.controls['firstName']?.errors?.nameError"
              >Can only include letters, numbers, hyphens, or apostrophes</mat-error
            >
            <mat-error *ngIf="patientFormGroup.controls['firstName']?.errors?.required">Enter a first name</mat-error>
          </mat-form-field>
        </div>
        <div
          class="col-12 col-xl-6 mt-3 form-col"
          [ngClass]="{ 'mb-4': patientFormGroup.controls['lastName']?.errors?.nameError }"
        >
          <mat-form-field floatLabel="always" [hideRequiredMarker]="!isEditForm" class="edit-patient-control">
            <mat-label>Last Name</mat-label>
            <input
              matInput
              id="lastName"
              data-test-id="patient-lastName-input"
              aria-label="Last Name"
              formControlName="lastName"
              placeholder="..."
              required
            />
            <mat-error *ngIf="patientFormGroup.controls['lastName']?.errors?.nameError"
              >Can only include letters, hyphens, or apostrophes</mat-error
            >
            <mat-error *ngIf="patientFormGroup.controls['lastName']?.errors?.required">Enter a last name</mat-error>
          </mat-form-field>
        </div>
        <div
          class="col-12 col-xl-6 mt-3 form-col"
          [ngClass]="{ 'mb-4': patientFormGroup.controls['nickName']?.errors?.nameError }"
        >
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>Nickname</mat-label>
            <input
              matInput
              id="nickName"
              data-test-id="patient-Nickname-input"
              aria-label="Nickname"
              formControlName="nickName"
              placeholder="..."
            />
            <mat-error *ngIf="patientFormGroup.controls['nickName']?.errors?.nameError"
              >Can only include letters, hyphens, or apostrophes</mat-error
            >
          </mat-form-field>
        </div>
        <div class="col-12 col-xl-6 mt-3 form-col">
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>Gender</mat-label>
            <mat-select
              formControlName="gender"
              id="gender"
              data-test-id="patient-Gender-select"
              aria-label="Gender"
              [disabled]="!isEditForm"
            >
              <mat-option value="Male">Male</mat-option>
              <mat-option value="Female">Female</mat-option>
              <mat-option value="X">Other</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          class="col-12 col-xl-6 mt-3 form-col"
          [ngClass]="{ 'mb-3': patientFormGroup.controls['clientId']?.errors?.serverError && errorMessage }"
        >
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>
              Health Care Number
              <i
                *appAuthGuard="developerPolicy"
                (click)="generateValidPHN()"
                class="allow-phn-generation fas fa-dice"
              ></i>
            </mat-label>

            <input
              matInput
              id="clientId"
              data-test-id="patient-clientId-input"
              aria-label="Health Care Number"
              formControlName="clientId"
              placeholder="..."
            />
            <mat-error *ngIf="patientFormGroup.controls['clientId']?.errors?.mspInvalid"
              >Invalid British Columbia MSP #</mat-error
            >
            <mat-error *ngIf="patientFormGroup.controls['clientId']?.errors?.mspInvalidLength"
              >MSP # must be 10 digits</mat-error
            >
            <mat-error *ngIf="patientFormGroup.controls['clientId']?.errors?.serverError"
              >Existing patient
              <a style="color: #007bff; cursor: pointer" (click)="navigateToPatient(duplicateMspInfo.patientId)">{{
                duplicateMspInfo.patientName
              }}</a>
              has identical MSP number.</mat-error
            >
          </mat-form-field>
        </div>
        <div #bdaySection class="col-12 col-xl-6 mt-3 form-col">
          <mat-form-field floatLabel="always" class="birthdate-control edit-patient-control">
            <mat-label>Date of Birth</mat-label>
            <input
              matInput
              id="birthDate"
              defaultBirthdayCheck
              data-test-id="patient-birthDate-input"
              [birthDate]="selectedPatient?.birthDate"
              [min]="minBirthdate"
              [max]="maxBirthdate"
              [satDatepicker]="picker"
              aria-label="Birth Date"
              formControlName="birthDate"
            />
            <sat-datepicker-toggle matSuffix [disabled]="!isEditForm" [for]="picker"></sat-datepicker-toggle>
            <sat-datepicker #picker></sat-datepicker>
            <mat-error>Invalid date. YYYY-MM-DD</mat-error>
          </mat-form-field>
        </div>
        <!-- <div class="d-flex mx-2 edit-patient-form-group">
    <mat-form-field class="birthday-input" >
      <label class="m-0 birthday-label"  ><span>Birthday *</span></label>
      <input matInput style="display:none"/>
      <kendo-datepicker
        class="emr-datepicker w-100 mt-1"
        placeholder="Birth Date"
        #birthdateInput
        defaultBirthdayCheck
        [defaultBirthdayCheckClass]="['k-input']"
        [birthDate]="birthdateInput.value"
        [format]="'yyyy-MM-dd'"
        formControlName="birthDate">
      </kendo-datepicker>
      <mat-error *ngIf="patientFormGroup.controls['birthDate']?.invalid">Not a Valid Date</mat-error>
      </mat-form-field>
  </div> -->
      </div>
    </div>
  </div>
  <div class="row panel-item-border grey-two-background">
    <div class="col">
      <div class="row mt-3 pb-3" formGroupName="address">
        <div class="col-12 col-xl-6 form-col">
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>Address 1</mat-label>
            <input
              matInput
              id="address1"
              data-test-id="patient-address1-input"
              aria-label="Address 1"
              formControlName="address1"
              placeholder="..."
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-xl-6 form-col">
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>Address 2</mat-label>
            <input
              matInput
              id="address2"
              aria-label="Address 2"
              data-test-id="patient-address2-input"
              formControlName="address2"
              placeholder="..."
            />
          </mat-form-field>
        </div>
        <div class="col-12 col-xl-6 mt-3 form-col">
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>City</mat-label>
            <input
              matInput
              id="city"
              formControlName="city"
              data-test-id="patient-city-input"
              aria-label="City"
              placeholder="..."
            />
            <mat-error>Enter a city</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-xl-6 mt-3 form-col">
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>{{
              patientFormGroup.get('address.country').value?.slug == 'CA'
                ? 'Province / Territory'
                : patientFormGroup.get('address.country').value?.slug == 'US'
                ? 'State'
                : patientFormGroup.get('address.country').value?.slug == 'AU'
                ? 'State / Territory'
                : 'Region'
            }}</mat-label>
            <mat-select
              formControlName="province"
              id="province"
              aria-label="Province"
              data-test-id="patient-province-select"
              [disabled]="!isEditForm"
            >
              <mat-option *ngFor="let region of selectedCountryRegions" [value]="region">
                {{ region.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-xl-6 mt-3 form-col">
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>{{
              patientFormGroup.get('address.country').value?.slug == 'US' ? 'Zip Code' : 'Postal Code'
            }}</mat-label>
            <input
              matInput
              data-test-id="patient-postalCode-input"
              id="postalCode"
              aria-label="Postal Code"
              formControlName="postalCode"
            />
            <mat-error>
              {{
                patientFormGroup.get('address.country').value?.slug == 'US'
                  ? 'Not a valid Zip Code'
                  : 'Not a valid Postal Code'
              }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-xl-6 mt-3 form-col">
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>Country</mat-label>
            <mat-select
              name="country"
              id="country"
              data-test-id="patient-country-select"
              aria-label="Country"
              formControlName="country"
              [disabled]="!isEditForm"
            >
              <mat-option *ngFor="let country of countries" [value]="country">
                {{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="row panel-item-border my-0">
    <div class="col">
      <div class="row pb-2">
        <div class="col-12 col-xl-6 mt-3 form-col">
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>Mobile Phone</mat-label>
            <input
              matInput
              id="mobileNumber"
              aria-label="Mobile Phone Number"
              data-test-id="patient-mobileNumber-input"
              formControlName="mobileNumber"
              placeholder="..."
            />
            <mat-error>{{ 'Format: ' + getExampleNumber() + ' or Int.' }}</mat-error>
          </mat-form-field>
        </div>
        <!-- <div class="col-12 col-xl-6 mt-2 form-col">
          <mat-form-field
            floatLabel="always"
            class="edit-patient-control"
          >
            <mat-label>Landline</mat-label>
            <input
              matInput
              id="homeNumber"
              mask="000-000-0000"
              aria-label="Landline Phone Number"
              data-test-id="patient-homeNumber-input"
              formControlName="homeNumber"
              placeholder="..."
            />
            <mat-error>Format: 555-555-5555</mat-error>
          </mat-form-field>
        </div> -->
        <div
          class="col-12 col-xl-6 mt-3 form-col"
          [ngClass]="{ 'mb-3': patientFormGroup.controls['email']?.errors?.serverError }"
        >
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label>Email Address</mat-label>
            <input
              matInput
              formControlName="email"
              data-test-id="patient-email-input"
              aria-label="Email"
              id="email"
              placeholder="..."
            />
            <mat-error *ngIf="patientFormGroup.controls['email']?.errors?.required">
              Cannot be empty with Patient Portal
            </mat-error>
            <mat-error *ngIf="patientFormGroup.controls['email']?.errors?.pattern">Invalid email</mat-error>
            <mat-error *ngIf="patientFormGroup.controls['email']?.errors?.serverError">
              Existing patient
              <a style="color: #007bff; cursor: pointer" (click)="navigateToPatient(duplicateEmailInfo.patientId)">{{
                duplicateEmailInfo.name
              }}</a>
              has identical Email.
            </mat-error>
            <mat-error *ngIf="patientFormGroup.controls['email']?.errors?.notUnique">
              Existing user
              <a style="color: #007bff">{{ duplicateEmailInfo.name }}</a>
              has identical Email.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-xl-6 mt-3 form-col">
          <mat-form-field floatLabel="always" class="edit-patient-control">
            <mat-label class="text-truncate">Communication Preference</mat-label>
            <mat-select
              formControlName="communicationPreference"
              id="communicationPreferences"
              data-test-id="patient-communicationPreferences-select"
              name="communicationPreference"
              aria-label="Communication Preference"
              [disabled]="!isEditForm"
            >
              <mat-option *ngFor="let commPref of communicationPreferences" [value]="commPref">
                {{ commPref }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row pb-3 grey-two-background">
        <div class="col-12 col-xl-6 mt-3 form-col">
          <label class="d-block mb-0 teal-font fake-mat-label text-truncate">SMS Appt. Reminders</label>
          <label class="switch align-text-top">
            <input
              type="checkbox"
              name="sendAppointmentSMSNotifications"
              data-test-id="patient-sendAppointmentSMSNotifications-input"
              id="sendAppointmentSMSNotifications"
              aria-label="Send Appointment SMS Notification Toggle"
              formControlName="sendAppointmentSMSNotifications"
            />
            <span
              [ngStyle]="isEditForm === false && { 'pointer-events': 'none' }"
              class="slider round"
              [ngClass]="{ 'disabled-slider': !isEditForm }"
            ></span>
          </label>
          <label
            for="sendAppointmentSMSNotifications"
            class="pl-2 text-dark"
            [ngStyle]="{ cursor: isEditForm ? 'pointer' : null }"
            >{{ patientFormGroup?.get('sendAppointmentSMSNotifications').value ? 'Send' : "Don't send" }}</label
          >
        </div>
        <div class="col-12 col-xl-6 mt-3 form-col">
          <label class="d-block mb-0 teal-font fake-mat-label text-truncate">Email Appt. Reminders</label>
          <label class="switch align-text-top">
            <input
              type="checkbox"
              formControlName="sendAppointmentEmailNotifications"
              data-test-id="patient-sendAppointmentEmailNotifications-input"
              name="sendAppointmentEmailNotifications"
              aria-label="Send Appointment Email Notification Toggle"
              id="sendAppointmentEmailNotifications"
            />
            <span
              [ngStyle]="isEditForm === false && { 'pointer-events': 'none' }"
              class="slider round"
              [ngClass]="{ 'disabled-slider': !isEditForm }"
            ></span>
          </label>
          <label
            for="sendAppointmentEmailNotifications"
            class="pl-2 text-dark"
            [ngStyle]="{ cursor: isEditForm ? 'pointer' : null }"
            >{{ patientFormGroup?.get('sendAppointmentEmailNotifications').value ? 'Send' : "Don't send" }}</label
          >
        </div>
        <div class="col-12 col-xl-6 mt-3 form-col">
          <label class="d-block mb-0 teal-font fake-mat-label text-truncate">Reviews</label>
          <label class="switch align-text-top mb-0">
            <input
              type="checkbox"
              name="sendReviews"
              formControlName="sendReviews"
              data-test-id="patient-sendReviews-input"
              aria-label="Send Reviews"
              id="sendReviews"
            />
            <span
              [ngStyle]="isEditForm === false && { 'pointer-events': 'none' }"
              class="slider round"
              [ngClass]="{ 'disabled-slider': !isEditForm }"
            ></span>
          </label>
          <label for="sendReviews" class="pl-2 text-dark mb-0" [ngStyle]="{ cursor: isEditForm ? 'pointer' : null }">{{
            patientFormGroup?.get('sendReviews').value ? 'Send' : "Don't send"
          }}</label>
          <label *ngIf="selectedPatient.unsubscribedDateReviews != null" class="d-block mb-2 unsubscribed-text"
            >Unsubscribed {{ formatDate(selectedPatient.unsubscribedDateReviews) }}</label
          >
        </div>
        <div class="col-12 col-xl-6 mt-3 form-col">
          <label class="d-block mb-0 teal-font fake-mat-label text-truncate">Retention Messages</label>
          <label class="switch align-text-top mb-0">
            <input
              type="checkbox"
              formControlName="sendRetentionEmails"
              name="sendRetentionEmails"
              data-test-id="patient-sendRetentionEmails-input"
              aria-label="Send Retention Email Toggle"
              id="sendRetentionEmails"
            />
            <span
              [ngStyle]="isEditForm === false && { 'pointer-events': 'none' }"
              class="slider round"
              [ngClass]="{ 'disabled-slider': !isEditForm }"
            ></span>
          </label>
          <label
            for="sendRetentionEmails"
            class="pl-2 text-dark mb-0"
            [ngStyle]="{ cursor: isEditForm ? 'pointer' : null }"
            >{{ patientFormGroup?.get('sendRetentionEmails').value ? 'Send' : "Don't send" }}</label
          >
          <label
            *ngIf="selectedPatient.unsubscribedDateRetentionCampaigns != null"
            class="d-block mb-2 unsubscribed-text"
            >Unsubscribed {{ formatDate(selectedPatient.unsubscribedDateRetentionCampaigns) }}</label
          >
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-xl-6 mt-3 form-col">
          <ng-container formGroupName="familyPhysician">
            <mat-form-field floatLabel="always" class="" class="family-physician edit-patient-control">
              <mat-label>Family Physician</mat-label>
              <mat-select
                name="doctors"
                formControlName="doctorId"
                aria-label="Family Physician"
                data-test-id="patient-doctorId-select"
                id="doctorId"
                [disabled]="!isEditForm"
                (selectionChange)="familyPhysicianChanged($event)"
              >
                <mat-option [value]="0"> Quick Add </mat-option>
                <mat-option [value]="-1"> None </mat-option>
                <mat-option *ngFor="let doctor of theDoctors" [value]="doctor.doctorId">
                  {{ doctor.proTitle }} {{ doctor.lastName }}, {{ doctor.firstName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
        <div class="col-12 col-xl-6 mt-3 form-col">
          <ng-container formGroupName="preferredPharmacy">
            <mat-form-field floatLabel="always" class="" class="edit-patient-control">
              <mat-label>Preferred Pharmacy</mat-label>
              <mat-select
                formControlName="pharmacyId"
                id="pharmacyId"
                data-test-id="patient-pharmacyId-select"
                aria-label="Preferred Pharmacy"
                [disabled]="!isEditForm"
                (selectionChange)="preferredPharmacyChanged($event)"
                name="pharmacies"
              >
                <mat-option [value]="0"> Quick Add </mat-option>
                <mat-option [value]="-1"> None </mat-option>
                <mat-option *ngFor="let pharmacy of thePharmacies" [value]="pharmacy.pharmacyId">
                  {{ pharmacy.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isEditForm" class="row panel-item-border border-top-0 mt-0">
    <div class="col d-flex flex-wrap justify-content-around my-3">
      <button
        class="btn em-btn em-btn-green my-1 text-nowrap"
        id="submitEditPatient"
        data-test-id="patient-add-button"
        aria-label="Submit Patient Form"
        (click)="savePatientInfo()"
        [disabled]="!patientFormGroup.dirty || !patientFormGroup.valid"
      >
        {{ addOrEdit === 'Add' ? 'Add Patient' : 'Update Patient' }}
      </button>
      <button
        *ngIf="!showCardSwipe"
        class="btn em-btn em-btn-green text-nowrap my-1"
        id="showCardSwipe"
        aria-label="Show Health Card Swipe Field"
        data-test-id="patient-swipe-card-button"
        (click)="clickShowCardSwipe()"
      >
        Swipe HC
      </button>
      <button
        class="btn em-btn em-btn-green my-1"
        id="cancelEditPatient"
        data-test-id="patient-cancel-edit-button"
        aria-label="Cancel Patient Form"
        (click)="exitEditMode()"
      >
        Cancel
      </button>
    </div>
  </div>
  <div class="w-100 mb-2"></div>
</div>
