<div
  [attr.data-test-id]="'schedule-is-loaded'"
  [attr.data-test-value]="!loading"
  class="d-flex w-100 h-100"
  [ngClass]="{ 'date-picker-visible': datePickerVisible }"
  #scheduleContainer
>
  <app-calnav
    *ngIf="displayCalendar()"
    [ngClass]="{ hidden: !datePickerVisible }"
    #calendarContainer
    (reloadMainSchedule)="this.changeSelectedClinic()"
  >
  </app-calnav>

  <mat-sidenav-container hasBackdrop="false">
    <mat-sidenav
      [(opened)]="actionPanelOpened"
      [ngStyle]="{ width: '38em' }"
      class="dummy-mat-sidenav"
      mode="side"
      position="end"
      #actionPanel
    >
    </mat-sidenav>

    <mat-sidenav-content>
      <perfect-scrollbar
        class="scroll-container"
        [ngStyle]="{
          'max-width':
            resources?.length >= maxCountProviders && eventsService.scheduleMode == ScheduleMode.AgendaDay
              ? singleTileWidth * resources?.length + 'px'
              : '100%'
        }"
        [config]="config"
        [scrollIndicators]="false"
        (psScrollY)="onScrollEvent($event)"
      >
        <div
          kendoTooltip
          filter="i[title]"
          position="top"
          tooltipClass="em-tooltip"
          class="schedule-wrapper"
          [ngStyle]="{
            width:
              resources?.length >= maxCountProviders && eventsService.scheduleMode == ScheduleMode.AgendaDay
                ? singleTileWidth * resources?.length + 'px'
                : '100%'
          }"
        >
          <ngx-loading [show]="loading"></ngx-loading>
          <div *ngIf="loading" class="loading-info">
            <h3 class="px-4 py-1">{{ loadingMessage }}</h3>
          </div>
          <ng-container *ngIf="configLoaded">
            <p-schedule
              (touchstart)="onTouchStart($event)"
              [editable]="appointmentsPolicySatisfied && isScheduleEditable"
              name="visitsSchedule"
              id="thecalendar"
              height="auto"
              [ngClass]="{ shadowed: actionPanelOpened, 'cal-loading': loading }"
              [options]="optionsConfig"
              [events]="appointmentService.activeAppointments"
              [eventRender]="eventRender"
              (onEventClick)="eventClick($event)"
              (onEventMouseover)="eventHover($event)"
              (onEventMouseout)="eventMouseout($event)"
              (contextmenu)="onRightClick($event)"
              #visitsSchedule
            >
            </p-schedule>
          </ng-container>
        </div>
      </perfect-scrollbar>

      <context-menu #contextMenu *ngIf="isMainSchedule() && !eventsService?.blockedScheduleMode">
        <ng-template contextMenuItem>
          <div *ngIf="!rightClickEvent.cancelled" class="context-menu-btn" (click)="createNudge()">Create Nudge</div>
        </ng-template>
        <ng-template *ngIf="eventsService?.movingAppointment" contextMenuItem>
          <div class="context-menu-btn" (click)="cancelMove()">Cancel Move</div>
        </ng-template>
        <ng-template *appAuthGuard="appointmentsPolicy" contextMenuItem>
          <div class="context-menu-btn" (click)="cancelAppointment()">Cancel This Appointment</div>
        </ng-template>
        <ng-template
          *appAuthGuard="appointmentsPolicy"
          contextMenuItem
          [enabled]="eventsService.scheduleMode !== ScheduleMode.AgendaWeek"
        >
          <div class="context-menu-btn" (click)="cancelVisit()">Cancel All Appointments</div>
        </ng-template>
        <!-- <ng-template
          *ngIf="manageAppointmentPermissions ? authService.userHasPermission(manageAppointmentPermissions) : false"
          contextMenuItem
        >
          <div
            class="context-menu-btn"
            (click)="noShowAppointment()"
            (mouseenter)="processContextMenuMouseEnterEvent()"
            (mouseout)="processContextMenuMouseOutEvent()"
          >
            No Show
          </div>
        </ng-template>
        <ng-template
          *ngIf="manageAppointmentPermissions ? authService.userHasPermission(manageAppointmentPermissions) : false"
          contextMenuItem
        >
          <div
            class="context-menu-btn"
            (click)="undoNoShowAppointment()"
            (mouseenter)="processContextMenuMouseEnterEvent()"
            (mouseout)="processContextMenuMouseOutEvent()"
          >
            Undo No Show
          </div>
        </ng-template> -->

        <ng-template *appAuthGuard="appointmentsPolicy" contextMenuItem>
          <div class="context-menu-btn" (click)="markAppointmentForMove()">Move This Appointment</div>
        </ng-template>

        <ng-template contesxtMenuItem [enabled]="eventsService.scheduleMode !== ScheduleMode.AgendaWeek">
          <div class="context-menu-btn" (click)="markVisitForMove()">Move All Appointments</div>
        </ng-template>
        <ng-template contextMenuItem [disabled]="!rightClickEvent?.service?.requireCardOnFile">
          <div class="context-menu-btn" (click)="requestCreditCard()">Request Credit Card</div>
        </ng-template>
        <!-- Commenting out until we have this functionality in place
        <ng-template contextMenuItem>
          <div (click)="appointmentNudge()" (mouseenter)="processContextMenuMouseEnterEvent()"
            (mouseout)="processContextMenuMouseOutEvent()">
            Nudge
          </div>
        </ng-template> -->
        <!-- Commenting out until we have this functionality in place
        <ng-template contextMenuItem>
          <div (click)="appointmentNotification()" (mouseenter)="processContextMenuMouseEnterEvent()"
            (mouseout)="processContextMenuMouseOutEvent()">
            SMS/Email
          </div>
        </ng-template> -->
        <!-- Commenting out until we have this functionality in place
        <ng-template contextMenuItem>
          <div (click)="appointmentPrintLable()" (mouseenter)="processContextMenuMouseEnterEvent()"
            (mouseout)="processContextMenuMouseOutEvent()">
            Print Label
          </div>
        </ng-template> -->
      </context-menu>

      <context-menu #contextMenu *ngIf="isStaffBlockedSchedule() || eventsService.blockedScheduleMode">
        <ng-template contextMenuItem>
          <div class="context-menu-btn" (click)="deleteAppointment()">Delete Schedule</div>
        </ng-template>
      </context-menu>

      <context-menu #contextMenu *ngIf="isCancelled()">
        <ng-template contextMenuItem>
          <div class="context-menu-btn" (click)="reactiveAppointment()">Reactivate Appointment</div>
        </ng-template>
      </context-menu>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div #providersFilterPanel id="providersFilterPanel" class="">
  <div class="box">
    <div class="top-bar d-flex justify-content-end py-1">
      <button
        class="d-flex justify-content-center align-items-center px-0 py-0 btn pointer"
        (click)="closeProvidersPanel()"
      >
        <i class="fal fa-times"></i>
      </button>
    </div>
    <div class="container pb-0" cdkDropList (cdkDropListDropped)="onProviderDropped($event)">
      <ngx-loading [show]="providerListLoading"></ngx-loading>
      <div class="row grey-four-border-bottom" *ngFor="let provider of serviceProvidersList; let i = index" cdkDrag>
        <div class="col-sm-10 col1 d-flex align-items-center">
          <div
            class="d-flex align-items-center justify-content-center item-number mr-2"
            (click)="providerSelected(serviceProviders[i])"
            [ngClass]="{ selected: serviceProviders[i].visible }"
          ></div>
          {{ serviceProviders[i].title }}
          &nbsp;<i
            class="far fa-clock smaller"
            *ngIf="appointmentService.isProviderScheduled(serviceProviders[i].id)"
          ></i>
        </div>
        <div class="col-sm-2 col2 text-right d-flex align-items-center justify-items-center">
          <i
            class="fas fa-check-square selected provider-checkbox"
            *ngIf="serviceProviders[i].visible"
            (click)="providerSelected(serviceProviders[i])"
          ></i>
          <i
            class="fal fa-square provider-checkbox"
            *ngIf="!serviceProviders[i].visible"
            (click)="providerSelected(serviceProviders[i])"
          ></i>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center">
        <div class="row align-items-center">
          Working Only
          <mat-slide-toggle
            *ngIf="userService?.loggedInUser"
            (change)="userService.toggleUserProviderSetting().toPromise(); changeSelectedClinic()"
            [(ngModel)]="userService.loggedInUser.showOnlyWorkingProvidersOnSchedule"
          >
          </mat-slide-toggle>
        </div>
        <div class="clear-all m-0 mr-2" (click)="selectAllVisibleProviders()">Select All</div>
        <div class="clear-all m-0 mr-2" (click)="clearAllVisibleProviders()">Clear All</div>
      </div>
    </div>
  </div>
</div>

<div #hoverPanel id="hoverPanel">
  <div class="hover-box" *ngIf="hoveredEvent && eventsService.scheduleView !== 'StaffSchedules'">
    <div
      class="hover-top-bar d-flex justify-content-end"
      [ngStyle]="{ 'background-color': hoveredEvent?.service?.serviceTemplate.serviceIDColour }"
    >
      <button
        class="d-flex justify-content-center align-items-center px-0 py-0 btn pointer"
        (click)="closeHoverPanel()"
      >
        <i class="fal fa-times"></i>
      </button>
    </div>
    <div
      class="hover-info-box d-flex flex-column"
      kendoTooltip
      tooltipClass="kendo-tooltip"
      position="top"
      [ngStyle]="{ background: hexToTranslucentRgbA(hoveredEvent?.service?.serviceTemplate.serviceIDColour, '0.1') }"
    >
      <div class="hover-bar hover-title align-items-center justify-content-between font-weight-bold">
        <div class="title-bar-patient-name align-items-center">
          {{ hoveredEvent?.patient?.firstName + ' ' + hoveredEvent?.patient?.lastName | titlecase }}
        </div>
        <div class="title-bar-patient-gender align-items-center px-2">
          {{ '(' + getGender(hoveredEvent?.patient) + ')' }}
        </div>
        <div *ngIf="!isCancelled()" class="title-bar-icons d-flex justify-content-end">
          <button
            *appAuthGuard="patientProfilePolicy"
            id="patientprofiletab"
            class="btn patient-btn px-1 no-btn-shadow"
            (click)="hoverPatientChartClick('patientprofiletab')"
          >
            <i class="fal fa-user" title="Patient Profile"></i>
          </button>
          <button
            *appAuthGuard="patientChartPolicy"
            id="patientcharttab"
            class="btn patient-btn px-1 no-btn-shadow"
            (click)="hoverPatientChartClick('patientcharttab')"
          >
            <i class="fal fa-notes-medical" title="Patient Chart"></i>
          </button>
          <button
            *appAuthGuard="patientAccountPolicy"
            id="patientaccounttab"
            class="btn patient-btn px-1 no-btn-shadow"
            (click)="hoverPatientChartClick('patientaccounttab')"
          >
            <i class="fal fa-file-invoice-dollar" title="Patient Invoices"></i>
          </button>
          <ng-container *appAuthGuard="appointmentsPolicy">
            <button
              *ngIf="getVisitStatus(hoveredEvent) === 'uncomfirmed'"
              id="checkin"
              class="btn patient-btn px-1 no-btn-shadow"
              (click)="hoverCheckInClick()"
            >
              <i class="fal fa-calendar-minus" title="Check-in Unconfirmed"></i>
            </button>
            <button
              *ngIf="getVisitStatus(hoveredEvent) === 'confirmed'"
              id="checkin"
              class="btn patient-btn btn-blue px-1 no-btn-shadow"
              (click)="hoverCheckInClick()"
            >
              <i class="fal fa-calendar-check" title="Check-in Confirmed"></i>
            </button>
            <button
              *ngIf="getVisitStatus(hoveredEvent) === 'checkedIn'"
              id="checkin"
              class="btn patient-btn px-1 btn-green no-btn-shadow"
              (click)="hoverCheckInClick()"
            >
              <i class="fal fa-calendar-check" title="Checked in"></i>
            </button>
            <button
              *ngIf="getVisitStatus(hoveredEvent) === 'noShow'"
              id="checkin"
              class="btn patient-btn px-1 btn-red no-btn-shadow"
              (click)="hoverCheckInClick()"
            >
              <i class="fal fa-calendar-exclamation" title="No Show"></i>
            </button>
          </ng-container>
        </div>
      </div>
      <div class="hover-bar d-flex align-items-center mt-0 text-uppercase">
        <div
          class="appt-info"
          [innerHTML]="
            (hoveredEvent?.roomName ? 'ROOM ' + hoveredEvent?.roomName + '&nbsp;&ndash;&nbsp;' : '') +
            (hoveredEvent ? appointmentService.getStartTime(hoveredEvent) : 'N/A') +
            ':&nbsp;' +
            hoveredEvent?.service?.serviceName
          "
        ></div>
      </div>
      <div
        class="hover-bar hover-vip-bar d-flex align-items-center font-weight-bold mt-0"
        *ngIf="hoveredEvent?.patient?.isPreferred"
      >
        <i class="hover-icon fas fa-star" title="VIP"></i>
        <div class="vip-bar-info">VIP Customer</div>
      </div>
      <div
        *ngIf="hoveredEvent?.patient?.address?.city || hoveredEvent?.patient?.address?.province"
        class="hover-bar d-flex align-items-center"
      >
        <i class="hover-icon fas fa-map-marker-alt" title="Location"></i>
        &nbsp;{{ hoveredEvent?.patient?.address?.city
        }}<ng-container
          *ngIf="
            hoveredEvent?.patient?.address?.city?.length > 0 && hoveredEvent?.patient?.address?.province?.length > 0
          "
          >, </ng-container
        >{{ hoveredEvent?.patient?.address?.province }}
      </div>
      <div *ngIf="hoveredEvent?.patient?.homeNumber" class="hover-bar d-flex align-items-center">
        <i class="hover-icon fas fa-phone" title="Phone Number"></i>
        &nbsp;{{ hoveredEvent?.patient?.homeNumber }}
      </div>
      <div *ngIf="hoveredEvent?.patient?.mobileNumber" class="hover-bar d-flex align-items-center">
        <i class="hover-icon fas fa-mobile" title="Mobile Number"></i>
        &nbsp;{{ hoveredEvent?.patient?.mobileNumber }}
      </div>
      <div
        *ngIf="hoveredEvent?.patient"
        class="hover-bar d-flex align-items-center"
        defaultBirthdayCheck
        [birthDate]="hoveredEvent?.patient?.birthDate"
      >
        <i class="hover-icon fas fa-gift" title="Birth Date"></i>
        &nbsp;{{ getBirthday(hoveredEvent.patient) }}
      </div>
      <!-- <div *ngIf="hoveredEvent?.patient?.gender" class="hover-bar d-flex align-items-center">
        <i class="hover-icon fas fa-info-circle" title="Gender"></i>
        &nbsp;{{ getGender(hoveredEvent.patient) }}
      </div> -->
      <div *ngIf="hoveredEvent?.visit?.confirmedStatus" class="hover-bar d-flex align-items-center">
        <i class="hover-icon fas fa-calendar-check" title="Confirmation Status"></i>
        &nbsp;{{ getConfirmationStatus(hoveredEvent) }}
      </div>
      <div
        *ngIf="!hoveredEvent?.visit?.confirmedStatus && hoveredEvent?.visit?.smsReminderSent"
        class="hover-bar d-flex align-items-center"
      >
        SMS Reminder Sent: {{ hoveredEvent?.visit?.smsReminderSent | date : 'yyyy-MM-dd h:mm a' }}
      </div>
      <div
        *ngIf="!hoveredEvent?.visit?.confirmedStatus && hoveredEvent?.visit?.emailReminderSent"
        class="hover-bar d-flex align-items-center"
      >
        Email Reminder Sent: {{ hoveredEvent?.visit?.emailReminderSent | date : 'yyyy-MM-dd h:mm a' }}
      </div>
    </div>

    <div class="hover-appt-visit-notes-box d-flex flex-column">
      <div class="hover-appt-visit-notes-title-bar font-weight-bold">Visit Notes</div>
      <div class="hover-appt-visit-notes-content">{{ getHoverPanelVisitNoteContent() }}</div>
      <div class="hover-appt-visit-notes-controls-bar d-none">
        <button class="btn px-0 py-0">
          <i class="hover-icon far fa-arrow-left"></i>
        </button>
        <button class="btn px-0 py-0">
          <i class="hover-icon far fa-arrow-right"></i>
        </button>
      </div>
    </div>
    <div
      class="hover-appt-visit-notes-box d-flex flex-column"
      *ngIf="hoveredEvent?.service?.plannedTreatmentNotes"
      [ngStyle]="{ background: hexToTranslucentRgbA(hoveredEvent?.service?.serviceTemplate.serviceIDColour, '0.1') }"
    >
      <div class="hover-appt-visit-notes-title-bar font-weight-bold">Planned Treatment Notes</div>
      <div class="hover-appt-visit-notes-content mw-100">{{ hoveredEvent.service.plannedTreatmentNotes }}</div>
      <div class="hover-appt-visit-notes-controls-bar d-none">
        <button class="btn px-0 py-0">
          <i class="hover-icon far fa-arrow-left"></i>
        </button>
        <button class="btn px-0 py-0">
          <i class="hover-icon far fa-arrow-right"></i>
        </button>
      </div>
    </div>
    <div
      *ngIf="hoveredEvent?.appointmentForms"
      class="hover-appt-visit-notes-box d-flex flex-column"
      [ngStyle]="{ background: hexToTranslucentRgbA(hoveredEvent?.service?.serviceTemplate?.serviceIDColour, '0.3') }"
    >
      <div class="hover-appt-visit-notes-title-bar font-weight-bold">eForms</div>
      <div *ngIf="hoveredEvent?.appointmentForms?.length > 0">
        <div *ngFor="let appointmentForm of hoveredEvent?.appointmentForms" class="hover-appt-visit-notes-form">
          <i
            class="far bigger-icon form-icon"
            [ngClass]="appointmentForm?.isSigned ? 'fa-check spring-green-font' : 'fa-times calendar-red-font'"
          ></i
          >{{ appointmentForm?.clinicForm?.name }}
        </div>
      </div>
      <div *ngIf="hoveredEvent?.appointmentForms?.length == 0" class="hover-appt-visit-notes-content">
        No eForms for this appointment.
      </div>
    </div>
    <div
      class="hover-appt-visit-notes-box d-flex flex-column"
      [ngStyle]="{ background: hexToTranslucentRgbA(hoveredEvent?.service?.serviceTemplate.serviceIDColour, '0.5') }"
    >
      <div class="hover-appt-visit-notes-title-bar font-weight-bold">
        <span *ngIf="hoveredEvent?.patient?.rushPatientNote" class="important-color"
          ><i class="far fa-exclamation-circle important-animation"></i> IMPORTANT
        </span>
        Permanent Notes/Alerts
      </div>
      <div class="hover-appt-visit-notes-content">{{ getHoverPatientNoteContent() }}</div>
      <div class="hover-appt-visit-notes-controls-bar d-none">
        <button class="btn px-0 py-0">
          <i class="hover-icon far fa-arrow-left"></i>
        </button>
        <button class="btn px-0 py-0">
          <i class="hover-icon far fa-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<app-actionpanel></app-actionpanel>
