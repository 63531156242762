import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { APP_BASE_HREF, Location, PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class BaseUrlService {
  static getHomeRoute(): string {
    return window['base-local'];
  }
  static homeRoute: string = localStorage.getItem('home');

  constructor(
    @Inject(APP_BASE_HREF) private baseHref: string,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    if (localStorage.getItem('home') == null || localStorage.getItem('home') == '') {
      localStorage.setItem('home', this.getClinicFolderName());
    }
    BaseUrlService;
  }

  //Changes the basee url IF the response from the server is different from the browser address
  setBaseUrl(home: string, path: string) {
    const url = '/' + home;
    if (path.indexOf('?') !== -1) {
      path = path.split('?')[0];
    }
    const location = window.location.pathname.replace(/\/+$/, '');
    if (location !== url + path) {
      localStorage.setItem('home', url);
      window['base-local'] = url;
      const localPath = url + path == '//' ? '/' : url + path;
      window.location.pathname = localPath;
    }
  }

  //Changes the URL etc IF you swap clinic through the drop-down
  swapClinicHomeUrl(clinicHomeUrl: string) {
    let replace = localStorage.getItem('home');
    if (replace == null) {
      replace = this.getClinicFolderName();
    }
    localStorage.setItem('home', '/' + clinicHomeUrl);
    BaseUrlService.homeRoute = clinicHomeUrl;
    window['base-local'] = clinicHomeUrl;
    this.baseHref = clinicHomeUrl;
    console.log('Clinic folder: ' + clinicHomeUrl);
    const path = replace == '/' ? window.location.pathname : window.location.pathname.replace(replace, '');
    console.log('New Path: ' + path);
    const url = ('/' + (clinicHomeUrl == '/' ? '' : clinicHomeUrl) + (path == '/' ? '' : path)).replace('//', '/');
    console.log('New Url: ' + url);
    //this.router.navigate([url]);
    //this.router.navigate([url], { relativeTo: null });
    //this.router.navigateByUrl(url);
    //this.location.go(url);
    //history.replaceState({}, null, url);
    //history.pushState({}, '', url);
    window.location.pathname = url;
  }

  private getClinicFolderName(): string {
    const path: string[] = window['base-local'].split('/');
    if (path[path.length - 1] === 'login') {
      path.pop();
    }
    let location = '';
    if (path.length >= 2) {
      location = '/' + path[1];
    }
    return location;
  }

  getHomeRouteForBlob() {
    var homeRoute = BaseUrlService.homeRoute;
    // Home route will be "/" or "/17ave"

    // /17ave case
    if (!homeRoute.endsWith('/')) {
      homeRoute = homeRoute + '/';
    }
    // Remove the starting slash
    if (homeRoute.startsWith('/')) {
      homeRoute = homeRoute.substring(1, homeRoute.length);
    }
    return homeRoute;
  }
}
