import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { ConfirmCancelWithReasonDialogComponent } from '@app/management/dialogs/confirm-cancel-with-reason/confirm-cancel-with-reason.component';
import { CreateNudgesComponent } from '@app/patients/patient-tabs/patient-nudges-tab/create-nudges/create-nudges.component';
import { isNullOrUndefined } from '@app/shared/helpers';
import { AppointmentViewModel } from '@models/appintment-viewmodel';
import { NudgeReferenceType } from '@models/nudges/reference-type';
import { Patient } from '@models/patient';
import { CancellationType } from '@models/payments/cancellation-type.enum';
import { ResourceType } from '@models/resource-type';
import { Appointment, AppointmentType } from '@models/scheduler/event';
import { PaymentStatus } from '@models/scheduler/payment-status';
import { ServiceProvider } from '@models/service-provider';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { Visit } from '@models/visit';
import { VisitConfirmedStatus } from '@models/visit-confirm-status';
import { ContextMenuComponent, ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { MasterOverlayService } from '@services/actionpanel.service';
import { AppointmentSignalrService } from '@services/appointment-signalr.service';
import { AppointmentService } from '@services/appointments.service';
import { ClinicsService } from '@services/clinics.service';
import { CurrentDataService } from '@services/currentData.service';
import { EventsService, ScheduleMode, ScheduleView } from '@services/events.service';
import { FinanceService } from '@services/finance.service';
import { NavStateService } from '@services/navstate.service';
import { PatientFormService } from '@services/patient-form.service';
import { PatientService } from '@services/patient.service';
import { ResourcesService } from '@services/resources.service';
import { ServiceProviderService } from '@services/service-provider.service';
import { SquareService } from '@services/square.service';
import { UsersService } from '@services/users.service';
import { VisitService } from '@services/visit.service';
import * as moment from 'moment';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {
  EMPTY,
  MonoTypeOperatorFunction,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  defer,
  forkJoin,
  from,
  zip,
} from 'rxjs';
import { filter, finalize, mergeMap, take, takeUntil } from 'rxjs/operators';
import { Schedule } from '../../../../primeng-schedule/src/app/components/schedule/schedule';
import { getPosition } from '../../lib/fun';
import { ConfirmDeleteDialogComponent } from '../../management/dialogs/confirm-delete/confirm-delete.component';
import { GenericDialogComponent } from '../../management/dialogs/generic-confirm/generic-confirm.component';
import { MoveAppointmentDialogComponent } from '../../management/dialogs/move-appointment/move-appointment.component';
import { AppointmentComponent } from './appointment/appointment.component';

@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.less'],
})
export class AppointmentsComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('visitsSchedule') visitSchedule: Schedule;
  @ViewChild('hoverPanel', { static: true }) hoverPanel;
  @ViewChild('scheduleContainer', { static: true }) scheduleContainer;
  @ViewChild('calendarContainer') calendarContainer;
  @ViewChild('scrolledContainer') scrolledContainer;
  @ViewChild('contextMenu') contextMenu: ContextMenuComponent<AppointmentsComponent>;

  @ViewChild(TooltipDirective)
  public tooltipDir: TooltipDirective;

  @Output() cancellationMessage = new EventEmitter();

  @ViewChild(PerfectScrollbarComponent, { static: true }) componentRef?: PerfectScrollbarComponent;
  public config: PerfectScrollbarConfigInterface = {
    suppressScrollX: false,
  };

  private mouseIsDown = false;
  private scrollTop = 0;
  selectedStaff: ServiceProvider;

  get isIpad() {
    var isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
    if (!isNullOrUndefined(isIpad)) return isIpad;
    else return true;
  }

  private viewChecked = false;
  private clickEvent: AppointmentViewModel;
  private baseHoveredEvent: any;
  private patientId: number;
  private touches: any[] = [];
  private subs: Array<Subscription> = [];
  private calendarDate: Date;
  private unsub = new Subject<any>();
  private timer;
  private currentDate: Date = new Date();
  private appointmentType = AppointmentType;
  private selectedProviderId: string = null;

  private selectedTimeSlotStartTime: any;
  private selectedTimeSlotEndTime: any;
  private selectedTimeSlotResourceId: any;
  currentView: 'agendaWeekFive' | 'agendaDay' = 'agendaWeekFive';

  private minTimeString = '';
  private maxTimeString = '';
  // make a list of days when the clinic is closed
  private daysClosed: number[] = [];
  private daysOpen: number;
  private visitChangingStatus: Visit;
  private displayHoverTimer;
  private forceHideHover = false;
  private countScheduleColumns: number;
  private isScrolledToCurrentTimeIndicator = false;
  private results: any[] = [];
  private nowIndicatorTimeoutID: any;
  private nowIndicatorIntervalID: any;
  private allowEditMode = false;
  private sideNavExpanded: boolean;
  private appTimeDiff: number;
  private serviceDetailTemplate = ServiceDetailTemplate;

  public rightClickEvent: any;
  public hoveredEvent: any;
  public optionsConfig: any;
  public resources: ServiceProvider[];
  public shiftPanelOpen = false;
  public datePickerVisible = true;
  public actionPanelOpened = false;
  public providerListLoading: boolean = false;
  public configLoaded = false;
  public singleTileWidth: number;
  public maxCountProviders = 5;
  public serviceProviders: ServiceProvider[] = [];
  get serviceProvidersList(): string[] {
    return this.serviceProviders.map((s) => s.title);
  }
  public isScheduleEditable = true;
  public ScheduleMode = ScheduleMode;

  public loading = false;
  public loadingMessage = '';

  ResourceType = ResourceType;

  @HostListener('window:resize', ['$event'])
  onScreenResize() {
    const calendarWidth: number = this.getCalendarWidth();
    if (this.scheduleContainer && this.scheduleContainer.nativeElement.offsetWidth !== 0) {
      this.singleTileWidth =
        (this.scheduleContainer.nativeElement.offsetWidth - calendarWidth) / this.maxCountProviders;
    }
  }
  public showTooltip(eventTarget: any): void {
    // 4. Use the public API
    this.tooltipDir.toggle(eventTarget);
  }

  @HostListener('document:mouseover', ['$event'])
  onMouseOver(e) {
    if (!isNullOrUndefined(e.fromElement) && !isNullOrUndefined(e.fromElement.id)) {
      if (
        (!isNullOrUndefined(e.target.offsetParent) &&
          e.target.offsetParent.id !== 'appointment' &&
          e.target.offsetParent.id !== 'hoverPanel' &&
          e.target.id !== 'birthday-balloons' &&
          !String(e.target.className).includes('fc-now-indicator')) ||
        (String(e.target.className).includes('staff') && !this.eventsService.blockedScheduleMode)
      ) {
        this.fullHoverHide();
      }
    }
  }

  @HostListener('document:mousedown', ['$event'])
  onMouseDown(e) {
    this.mouseIsDown = true;
    clearTimeout(this.displayHoverTimer);
    if (
      e.target.className !== 'box' &&
      !$(e.target).parents('.box').length &&
      !$(e.target).parents('.fc-axis.ui-widget-header').length
    ) {
      this.closeProvidersPanel();
    }
  }

  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.mouseIsDown = false;
  }

  appointmentsPolicy = Policies.appointments;
  patientAccountPolicy = Policies.patientAccount;
  patientChartPolicy = Policies.patientChart;
  patientProfilePolicy = Policies.patientProfile;
  patientPanelPolicy = Policies.patientPanel;
  patientPanelPolicySatisfied = false;
  appointmentsPolicySatisfied = false;

  constructor(
    private masterOverlayService: MasterOverlayService,
    private resolver: ComponentFactoryResolver,
    private financeService: FinanceService,
    private injector: Injector,
    private router: Router,
    private visitService: VisitService,
    public appointmentService: AppointmentService,
    public patientService: PatientService,
    private deleteDialog: MatDialog,
    private providerService: ServiceProviderService,
    private dialog: MatDialog,
    private navStateService: NavStateService,
    public eventsService: EventsService,
    public appointmentSignalrService: AppointmentSignalrService,
    public authService: AuthService,
    public currentDataService: CurrentDataService,
    public clinicsService: ClinicsService,
    public userService: UsersService,
    private resourcesService: ResourcesService,
    private patientFormService: PatientFormService,
    private contextMenuService: ContextMenuService<AppointmentsComponent>,
    private squareService: SquareService
  ) {
    this.resources = [];
    this.isScheduleEditable = !this.isMobileDevice();
    this.eventsService.closeSidePanel$.subscribe(() => {
      this.onSidePanelClose();
      if (this.visitSchedule && this.visitSchedule.schedule && this.visitSchedule.schedule.fullCalendar) {
        this.visitSchedule.schedule.fullCalendar('unselect');
      }
    });
    this.eventsService.movingAppointment$.subscribe(() => {
      if (!this.eventsService.movingAppointment) {
        this.clearAllApptMarksForMove();
        this.reRenderSchedule();
      }
    });
    this.eventsService.planningMode = false;
    // open the action panel when directly navigating to action-panel routes
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const { url } = val;
        if (url.includes('action-panel')) {
          if (!url.includes('edit-patient') && !url.includes('patient')) {
            this.actionPanelOpened = true;
          }
        }
        if (url.includes('action-panel:edit-patient')) {
          this.actionPanelOpened = false;
          if (!isNullOrUndefined(this.visitSchedule)) {
            if (
              !isNullOrUndefined(this.visitSchedule.schedule) &&
              !isNullOrUndefined(this.visitSchedule.schedule.fullCalendar)
            ) {
              this.visitSchedule.schedule.fullCalendar('unselect');
            }
          }
        }
      }
    });
  }

  // angular lifecycle section
  ngOnInit() {
    this.init();
  }

  init() {
    this.patientPanelPolicySatisfied = this.authService.userSatisfiesPolicy(this.patientPanelPolicy);
    this.appointmentsPolicySatisfied = this.authService.userSatisfiesPolicy(this.appointmentsPolicy);

    this.navStateService.sideNavExpanded.pipe(takeUntil(this.unsub)).subscribe((exp) => {
      this.sideNavExpanded = exp;
      if (!this.sideNavExpanded) {
        const calendarWidth: number = this.getCalendarWidth();
        if (this.scheduleContainer && this.scheduleContainer.nativeElement.offsetWidth !== 0) {
          this.singleTileWidth =
            (this.scheduleContainer.nativeElement.offsetWidth - calendarWidth + 160) / this.maxCountProviders;
        }
      }
    });

    this.eventsService.scheduleViewChanged$.subscribe((type) => {
      if (this.eventsService.blockedScheduleMode || type !== 'Appointments') {
        this.appointmentService.apptsSelected.clear();
        this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);
        this.eventsService.closePanel();
      }
      //This on occasion fails wth fullCalendar is not defined
      if (
        !isNullOrUndefined(this.visitSchedule.schedule) &&
        !isNullOrUndefined(this.visitSchedule.schedule.fullCalendar)
      ) {
        this.visitSchedule.changeView('agendaDay');
        this.selectedProviderId = null;
        this.updateScheduleView().then(() => {
          this.visitSchedule.refetchResources();
        });
      }
    });

    this.scrollTop = 0;
    this.currentDataService.fetchCurrentData();

    this.eventsService.appointmentAddedListener.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.updateScheduleView();
    });

    this.eventsService.appointmentRemovedListener.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.updateScheduleView();
    });

    this.eventsService.closeSidePanel$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.reRenderSchedule();
    });

    this.appointmentService.allApptsUpdated$.pipe(takeUntil(this.unsub)).subscribe((aa) => {
      this.reRenderSchedule();
    });

    this.appointmentSignalrService.apptAdded$.pipe(takeUntil(this.unsub)).subscribe((appointment: Appointment) => {
      this.appointmentUpdate(appointment);
    });

    this.appointmentSignalrService.apptDeleted$.pipe(takeUntil(this.unsub)).subscribe((appointment: Appointment) => {
      this.appointmentUpdate(appointment);
    });

    this.appointmentSignalrService.apptUpdated$.pipe(takeUntil(this.unsub)).subscribe((appointment: Appointment) => {
      this.appointmentUpdate(appointment);
    });

    this.eventsService.actionPanelOpenedListener.pipe(takeUntil(this.unsub)).subscribe((event) => {
      //Need to wait here - as this.visitSchedule.schedule & this.visitSchedule.schedule.fullCalendar
      //can be 'undefined' for a short moment
      let wait = setInterval(() => {
        if (
          !isNullOrUndefined(this.visitSchedule.schedule) &&
          !isNullOrUndefined(this.visitSchedule.schedule.fullCalendar)
        ) {
          clearInterval(wait);
          wait = undefined;
          const events = this.visitSchedule.getEventSources();
          events[0].eventDefs.forEach((e) => {
            if (e.miscProps.visitId === event.visitId) {
              if (this.appointmentService.apptsSelected.has(e.appointmentId)) {
                this.appointmentService.apptsSelected.delete(e.appointmentId);
              }
            } else {
              if (!this.appointmentService.apptsSelected.has(e.appointmentId)) {
                this.appointmentService.apptsSelected.set(e.appointmentId, e.appointmentId);
              }
            }
          });
        }
      }, 20);
    });

    this.patientFormService.patientFormSubmitted$
      .pipe(
        takeUntil(this.unsub),
        filter((form) => form.appointmentId != null),
        mergeMap((form) => this.appointmentService.getAppointmentById(form.appointmentId))
      )
      .subscribe((appointment) => {
        this.appointmentUpdate(appointment);
      });

    this.viewChecked = false;

    this.appointmentService.apptsAdded$.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (!isNullOrUndefined(this.visitSchedule)) {
        if (
          !isNullOrUndefined(this.visitSchedule.schedule) &&
          !isNullOrUndefined(this.visitSchedule.schedule.fullCalendar)
        ) {
          this.visitSchedule.schedule.fullCalendar('unselect');
        }
      }
    });

    this.initServiceProviders();
    this.startNowIndicator();
    this.initInvoicePaidSubscription();

    this.clinicsService.getClinics();
    this.currentDataService.currentDataUpdated$.subscribe(() => {
      if (this.configLoaded === false) {
        // If we haven't yet loaded 'a' clinic - need to wait to setup Scheduler
        let wait = setInterval(() => {
          if (this.clinicsService.clinic) {
            clearInterval(wait);
            wait = undefined;
            this.updateScheduleView();
            this.scheduleSetup();
          }
        }, 20);
      }
    });
  }

  private loadingOperator<T>(loadingMessage: string): MonoTypeOperatorFunction<T> {
    return ((source$: Observable<T>) => {
      let counter = 0;
      return defer(() => {
        counter++;
        this.onCounterUpdate(counter, loadingMessage);
        return source$;
      }).pipe(
        finalize(() => {
          counter--;
          this.onCounterUpdate(counter, loadingMessage);
        })
      );
    }).bind(this);
  }

  private onCounterUpdate(counter: number, message: string) {
    if (counter > 0) {
      this.loading = true;
      this.loadingMessage = message;
    } else {
      this.loading = false;
      this.loadingMessage = '';
    }
  }

  private checkIfUpdatesAffectCurrentScheduleView(appointment: Appointment) {
    if (
      this.serviceProviders.filter((sp) => sp.visible == true).findIndex((appt) => appt.id == appointment.staffId) != -1
    ) {
      //if provider isnt showing then dont update calendar
      if (this.visitSchedule.getView().name == 'agendaDay') {
        //One day being shown on calendar
        if (appointment && moment(appointment.date).isSame(moment(this.currentDate), 'day')) {
          return true;
        }
      } else {
        //showing weekly mode starting from currentDate + 5 days and matches the provider
        if (
          appointment &&
          appointment.staffId == this.selectedProviderId &&
          moment(appointment.date).isBetween(moment(this.currentDate), moment(this.currentDate).add(5, 'days'))
        ) {
          return true;
        }
      }
    }
    return false;
  }

  private appointmentUpdate(appointment: Appointment) {
    if (this.checkIfUpdatesAffectCurrentScheduleView(appointment)) {
      let isDailyView = this.visitSchedule.getView().name == 'agendaDay';
      this.appointmentService.onAllApptsUpdated(
        isDailyView ? undefined : moment(this.currentDate).startOf('week').toDate(),
        isDailyView ? undefined : appointment.staffId
      );
    }
  }

  changeSelectedClinic() {
    //Clear-up, close down and reload with new clinic
    this.configLoaded = false;
    this.appointmentService.closeActionPanel();
    if (this.nowIndicatorIntervalID) {
      clearInterval(this.nowIndicatorIntervalID);
    }
    this.unsub.next();
    this.unsub.complete();
    this.init();
  }

  private async updateScheduleView() {
    const updateApptsFunc =
      this.eventsService.scheduleMode === ScheduleMode.AgendaDay
        ? this.appointmentService.updateActiveAppointments(null, this.currentDate, this.currentDate)
        : this.appointmentService.updateActiveAppointments(
            moment(this.currentDate).startOf('week').toDate(),
            null,
            null,
            this.selectedProviderId
          );
    const apptPromise = updateApptsFunc.pipe(this.loadingOperator('Loading Appointments')).toPromise();
    const providerPromise = this.providerService
      .getServiceProviderByDate(this.currentDate)
      .pipe(this.loadingOperator('Loading Appointments'), take(1))
      .toPromise();
  }

  private refreshDayHours() {
    const currentDayHours = this.clinicsService.hoursOfOperation?.hoursOfOperationDays[this.currentDate.getDay()];

    if (currentDayHours && !currentDayHours.closed) {
      this.minTimeString = currentDayHours.openTime.subtract(currentDayHours.openTime.minutes(), 'minutes').format();
      this.maxTimeString = currentDayHours.closeTime.format();
    } else {
      this.minTimeString = '07:00:00';
      this.maxTimeString = '17:00:00';
    }
  }

  private getCalendarWidth(): number {
    return this.calendarContainer && this.calendarContainer.nativeElement
      ? this.calendarContainer.nativeElement.offsetWidth
      : 180;
  }

  private async getAppointmentsForWeek(weekStart: Date, providerId: string) {
    if (!this.loading) {
      await this.appointmentService
        .updateActiveAppointments(weekStart, null, null, providerId)
        .pipe(this.loadingOperator('Loading Appointments'))
        .toPromise();
    }
  }

  public scheduleSetup() {
    const slotDurationString = '00:' + this.clinicsService.minimumDuration.toString() + ':00';
    const showWorkingOnly =
      this.userService.loggedInUser && this.userService.loggedInUser.showOnlyWorkingProvidersOnSchedule;
    this.refreshDayHours();

    // primeng schedule is basically just a wrapper for fullCalendar, this is
    // where we can reach in to full calendar and leverage features that aren't implemented in p-schedule
    // resources is one example, as is select
    this.optionsConfig = {
      editable: this.appointmentsPolicySatisfied,
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
      defaultView: 'agendaDay',
      timeZone: 'UTC',
      slotDuration: slotDurationString,
      slotLabelFormat: 'h(:mm) A',
      slotLabelInterval: '01:00:00',
      defaultTimedEventDuration: '00:00:00',
      slotEventOverlap: false,
      nowIndicator: true,
      groupByDateAndResource: true,
      minTime: this.minTimeString,
      maxTime: this.maxTimeString,
      selectable: true,
      unselectAuto: false,
      allDaySlot: false,
      longPressDelay: 100,
      header: {
        left: 'prev next',
        center: '',
        right: '',
      },
      views: {
        agendaWeekFive: {
          type: 'agenda',
          duration: { weeks: 1 },
          hiddenDays: this.daysClosed,
        },
      },
      navLinks: true,
      navLinkDayClick: (date) => {
        this.visitSchedule.refetchResources();
        this.visitSchedule.changeView('agendaDay');
        this.eventsService.scheduleModeChanged(ScheduleMode.AgendaDay);
        this.selectedProviderId = null;
        this.eventsService.selectedDate.next(moment(date).local().toDate());
        this.updateNowIndicator();
      },
      resources: (callback: (resources: ServiceProvider[]) => void) => {
        switch (this.eventsService.scheduleView) {
          case 'Appointments':
            let providerMethod = showWorkingOnly
              ? this.providerService.getServiceProviderByDate(this.currentDate)
              : this.providerService.getServiceProviders();
            providerMethod.pipe(take(1)).subscribe((serviceProviders: ServiceProvider[]) => {
              let resources = serviceProviders.filter((provider) => provider.visible);
              if (resources.length === 0) {
                resources.push(<ServiceProvider>{
                  id: 'none',
                  title: showWorkingOnly
                    ? 'No working staff selected, use button on the left to choose visible staff'
                    : 'No staff selected, use button on the left to choose visible staff',
                });
              }
              this.resources = resources;
              this.addProvidersFilterToggleButton();
              callback(this.resources);
            });
            break;
          default:
            this.providerService
              .getServiceProviders()
              .pipe(take(1))
              .subscribe((serviceProviders: ServiceProvider[]) => {
                this.resources = [...serviceProviders];
                callback(this.resources);
              });
        }
      },
      select: (start, end, ev, view, resourceObj) => {
        if (ev == null) {
          return;
        }
        // We are clicking on an empty spot in the calendar here ...
        // use settimeout, because need to wait while schedule width will be recalculated
        setTimeout(() => {
          if (
            !isNullOrUndefined(ev.target) &&
            !isNullOrUndefined(ev.target.offsetParent) &&
            !isNullOrUndefined(ev.target.offsetParent.offsetParent) &&
            !isNullOrUndefined(ev.target.offsetParent.offsetParent.offsetLeft) &&
            !isNullOrUndefined(this.componentRef) &&
            !isNullOrUndefined(this.componentRef.directiveRef)
          ) {
            this.componentRef.directiveRef.scrollToX(ev.target.offsetParent.offsetParent.offsetLeft - 60, 500);
          }
        }, 100);
        if (this.eventsService.scheduleView !== ScheduleView.StaffSchedules && !this.appointmentsPolicySatisfied) {
          return;
        }
        // Because we are clicking on an empty slot, we will clear the visitService.clickedVisitAppt
        this.visitService.clickedVisitAppt = null;
        this.selectedStaff = resourceObj;

        if (this.eventsService.movingAppointment) {
          this.verifyMovedAppointment(start, resourceObj);
        } else {
          this.clickEvent = { isSelection: true, start, end, resource: resourceObj };
          this.eventsService.setTempEvent(this.clickEvent);
          if (!this.actionPanelOpened) {
            this.toggleCreateVisitPanel(start, end, resourceObj.id);
            this.toggleVisitPanel('_');
          }
        }

        const isSameDate = moment(this.currentDate).isSame(start, 'day');
        // If there is already a reservation, we will move it to the new calendar location
        if (this.visitService.reservation && isSameDate) {
          // Reservations are now handled entirely within the visit
          // this.moveAppointmentReservations();
          return;
        }

        //If there isn't a reservation and we had a patient selected, then we will create a new reservation for that patient
        if (
          !this.visitService.reservation &&
          !isNullOrUndefined(this.visitService.lastVisit) &&
          !isNullOrUndefined(this.patientService.reservationPatient) //this is switched to editPatient from patientPanelPatien
        ) {
          // Create a reservation for the patient
          // Reservations are now handled entirely within the visit component
          // this.addAppointmentReservation(this.visitService.lastVisit);
          return;
        }
      },
      dayClick: () => {},
      eventResize: async (event, delta, revertFunc: () => void) => {
        const appointment = event as Appointment;
        var date = this.appointmentService.stripDateFromUtcDate(new Date(appointment.date));
        const appointmentStartTime = moment(date).startOf('day').add(moment.duration(appointment.startTime)).toDate();
        const isLocked = await this.checkAppointmentIsLocked(appointment, appointmentStartTime, true);
        if (isLocked) {
          revertFunc();
          return;
        }
        event.start = this.visitService.fixFullCalendarDate(event.start);
        event.end = this.visitService.fixFullCalendarDate(event.end);
        this.updateEventInUI(event, true, revertFunc);
        event.endTime = moment.duration(moment(event.end).format('HH:mm'));
        this.appointmentService.appointmentUpdated.next(event);
      },
      eventDrop: async (event: any, delta: any, revertFunc: () => void) => {
        const appointment = event as Appointment;
        var date = this.appointmentService.stripDateFromUtcDate(new Date(appointment.date));
        const appointmentStartTime = moment(date).startOf('day').add(moment.duration(appointment.startTime)).toDate();
        const isLocked = await this.checkAppointmentIsLocked(appointment, appointmentStartTime);
        if (isLocked) {
          revertFunc();
          return;
        }

        event.start = this.visitService.fixFullCalendarDate(event.start);
        event.end = this.visitService.fixFullCalendarDate(event.end);
        const targetDate = moment(event.start).format('YYYY-MM-DDT00:00:00');
        // Drag event on daily view - moving within the same day
        if (event.date == targetDate) {
          this.updateEventInUI(event, true, revertFunc);
        }
        // Drag event on weekly view - moving to a different day
        else {
          this.appointmentService.apptsMarkedForMove.clear();
          this.appointmentService.apptsMarkedForMove.set(event.appointmentId, event.appointmentId);
          await this.moveAppointmentsBetweenDays(event.start, this.selectedProviderId, event.visitId);
        }
        event.startTime = moment.duration(moment(event.start).format('HH:mm'));
        event.endTime = moment.duration(moment(event.end).format('HH:mm'));
        this.appointmentService.appointmentUpdated.next(event);
      },
      eventDragStart: (info) => {
        this.forceHideHover = true;
        this.hideHoverPanel();
      },
      resourceRender: (resourceObj, labelTds, bodyTds, view) => {
        this.currentView = view.name;
        if (view.name !== 'agendaWeekFive') {
          this.countScheduleColumns = this.resources.length;
        }
        const calendarWidth: number = this.getCalendarWidth();
        if (this.scheduleContainer && this.scheduleContainer.nativeElement.offsetWidth !== 0) {
          this.singleTileWidth =
            (this.scheduleContainer.nativeElement.offsetWidth - calendarWidth) / this.maxCountProviders;
        } else {
          this.singleTileWidth = view.el[0].clientWidth / this.maxCountProviders;
        }
        labelTds.on('click', (evt) => {
          if (view.name !== 'agendaWeekFive') {
            this.optionsConfig.resources((resources: { id: string; title: string }[]) => {
              resources.forEach((resource) => {
                if (resource.id !== resourceObj.id) {
                  this.visitSchedule.removeResource(resource);
                }
              });
              this.daysClosed = [];
              for (let i = 0; i < 7; i++) {
                const thisSunday = moment(this.currentDate).startOf('week');
                if (this.clinicsService.hoursOfOperation.hoursOfOperationDays[thisSunday.add(i, 'days').day()].closed) {
                  this.daysClosed.push(i);
                }
                this.getAppointmentsForWeek(thisSunday.toDate(), resourceObj.id).then(() => {
                  this.daysOpen = 7 - this.daysClosed.length;
                  this.countScheduleColumns = this.daysOpen;
                  this.visitSchedule.options.views.agendaWeekFive.hiddenDays = this.daysClosed;
                  this.visitSchedule.changeView(ScheduleMode.AgendaWeek);
                  this.eventsService.scheduleModeChanged(ScheduleMode.AgendaWeek);
                  this.selectedProviderId = resourceObj.id;
                });
              }
            });

            this.createTimeOverlay();
            this.updateNowIndicator();
          }
        });
        if (view.name === 'agendaWeekFive') {
          const slotRow = document.querySelectorAll('tr[data-time]');
          if (slotRow) {
            if (slotRow[0].children.length <= this.daysOpen) {
              const timeSlot = document.createElement('td');
              timeSlot.classList.add('ui-widget-content');
              for (let i = 0; i < slotRow.length; i++) {
                let node = timeSlot.cloneNode(true);
                slotRow[i].appendChild(node);
              }
            }
          }
          this.createTimeOverlay();
          this.updateNowIndicator();
        } else {
          this.optionsConfig.resources((resources: { id: string; title: string }[]) => {
            const slotRow = document.querySelectorAll('tr[data-time]');
            if (slotRow) {
              if (slotRow[0].children.length <= resources.length) {
                const timeSlot = document.createElement('td');
                timeSlot.classList.add('ui-widget-content');
                for (let i = 0; i < slotRow.length; i++) {
                  slotRow[i].appendChild(timeSlot.cloneNode(true));
                }
              }
              this.createTimeOverlay();
              this.updateNowIndicator();
            }
          });
        }
        setTimeout(() => {
          this.providerListLoading = false;

          setTimeout(() => {
            // this.loading = false;
          }, 250); //just gives it a nicer cascading effect as it loads
        });
      },
      eventAfterRender: (event, element, view) => {
        if (view.name === 'agendaWeekFive') this.addWeekViewForwardBackButtons();
        if (element[0].className.indexOf('fc-time-grid-event') >= 0) {
          const singleElWidth = 100 - +element[0].style.left.slice(0, -1) - +element[0].style.right.slice(0, -1);
          const elementsInRow = 100 / singleElWidth;
          const shrinkSizeValue = 20 / elementsInRow;

          if (element[0].style.left === '0%') {
            element[0].style.right = `calc(${element[0].style.right} + ${shrinkSizeValue}px)`;
            return;
          }

          if (element[0].style.left !== '0%' && element[0].style.right !== '0%') {
            const elementPos = +element[0].style.left.slice(0, -1) / singleElWidth + 1;

            element[0].style.right = `calc(${element[0].style.right} + ${shrinkSizeValue * elementPos}px)`;
            element[0].style.left = `calc(${element[0].style.left} - ${shrinkSizeValue * (elementPos - 1)}px)`;
            return;
          }

          if (element[0].style.right === '0%') {
            element[0].style.right = `calc(${element[0].style.right} + 20px)`;
            element[0].style.left = `calc(${element[0].style.left} - ${20 - shrinkSizeValue}px)`;
            return;
          }
        }
      },
    };
    this.configLoaded = true;
  }

  ngAfterViewChecked() {
    const timeIndicator = <HTMLElement>(
      document.querySelectorAll('.fc-content-skeleton:last-of-type .fc-now-indicator-arrow:last-of-type')[0]
    );
    if (!this.isScrolledToCurrentTimeIndicator && !isNullOrUndefined(timeIndicator)) {
      this.scrollToTimeIndicator();
      this.updateNowIndicator();
      this.isScrolledToCurrentTimeIndicator = true;
    }

    if (this.configLoaded && !this.viewChecked) {
      this.viewChecked = true;
      // Every time the date gets updated, update the schedule with the new date
      this.eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe((value) => {
        this.allowEditMode = false;
        if (this.calendarDate !== value) {
          if (
            this.visitSchedule &&
            this.visitSchedule.schedule &&
            this.eventsService.scheduleMode !== ScheduleMode.AgendaWeek
          ) {
            // this.initServiceProviders();
            this.calendarDate = new Date(value);
            this.currentDate = new Date(value);
            this.currentDataService.currentDate = new Date(value);
            if (this.calendarDate) {
              this.appointmentService
                .updateActiveAppointments(null, this.currentDate, this.currentDate)
                .pipe(this.loadingOperator('Loading Appointments'))
                .subscribe();
            }
            this.visitSchedule.changeView('agendaDay');
            this.selectedProviderId = null;
            this.refreshDayHours();
            const view = this.visitSchedule.getView();
            view.options.minTime = this.minTimeString;
            view.options.maxTime = this.maxTimeString;
            this.visitSchedule.gotoDate(this.currentDataService.currentDate);
            this.visitSchedule.refetchResources();
            this.removeStickyTime();
            this.slotLabel();
          }
          this.scrollToTimeIndicator();
          this.updateNowIndicator();
        }
      });
    }
  }

  verifyMovedAppointment(start, resourceObj) {
    const getAppointmentCalls = [];

    this.appointmentService.apptsMarkedForMove.forEach((value: number, key: number) => {
      getAppointmentCalls.push(this.appointmentService.getAppointmentById(key));
    });

    forkJoin(getAppointmentCalls).subscribe(async (appointmentsToMove: Appointment[]) => {
      // Sort appointments by start time.
      appointmentsToMove.sort((a, b) => (a.startTime > b.startTime ? 1 : a.startTime < b.startTime ? -1 : 0));
      // Check if the first appointment's provider is being changed.
      // If it is being changed, check if they can do the Service.
      // If is is not being changed, we can check if the other ones are scheduled.
      var firstAppointment = appointmentsToMove[0];
      if (firstAppointment.staffId !== resourceObj.id) {
        if (!this.appointmentService.verifyMovedAppointmentForProvider(firstAppointment, resourceObj)) {
          // Determine which Services the provider can't perform.
          var serviceProvider = this.appointmentService.workingServiceProviders.filter((s) => s.id === resourceObj.id);
          var message = `<strong>${serviceProvider[0].title}</strong> cannot perform the <strong>${firstAppointment.service.serviceName}</strong> service.`;

          this.dialog.open(GenericDialogComponent, {
            width: '300px',
            data: {
              title: 'Error - Not Authorized',
              content: message,
              confirmButtonText: 'Ok',
              showCancel: false,
            },
          });
          return;
        }
      }

      var withinSchedule = true;
      var serviceIsLocked = false;

      var index = 0;
      var appTimeDiff = 0;
      for (const appointment of appointmentsToMove) {
        if (!index) {
          appTimeDiff =
            moment.duration(moment(start).format('HH:mm')).asMilliseconds() -
            moment.duration(appointment.startTime).asMilliseconds();
        }

        var startTime = moment.duration(appTimeDiff + moment.duration(appointment.startTime).asMilliseconds());
        var endTime = moment.duration(appTimeDiff + moment.duration(appointment.endTime).asMilliseconds());

        const calculatedDuration = moment.duration(endTime).asMinutes() - moment.duration(startTime).asMinutes();
        var date = this.appointmentService.stripDateFromUtcDate(start.toDate());
        const appointmentStartTime = moment(date).startOf('day').add(moment.duration(startTime)).toDate();

        var dt = this.appointmentService.getStartEndTime(appointmentStartTime, calculatedDuration);

        if (!(await this.appointmentService.isWithinStaffSchedule(appointment.staffId, dt))) {
          withinSchedule = false;
          var serviceProvider = this.appointmentService.workingServiceProviders.filter(
            (s) => s.id === appointment.staffId
          );
          var message = `<strong>${appointment.service.serviceName}</strong> at <strong>${moment(
            appointmentStartTime
          ).format('h:mm A')}</strong> will be outside of <strong>${serviceProvider[0].title}'s</strong> schedule.`;
          const dialogRef = this.dialog.open(GenericDialogComponent, {
            width: '300px',
            data: {
              title: 'Error - Outside Schedule',
              content: message,
              confirmButtonText: 'Ok',
              showCancel: false,
            },
          });
          const dialogResult = await dialogRef.afterClosed().toPromise();
        }

        if (await this.checkAppointmentIsLocked(appointment, appointmentStartTime)) {
          serviceIsLocked = true;
        }

        index++;
      }

      if (!withinSchedule || serviceIsLocked) {
        this.eventsService.movingAppointment = false;
        this.appointmentService.apptsMarkedForMove.clear();
        return false;
      }

      this.moveAppointment(start, resourceObj.id);
    });
  }

  private async checkAppointmentIsLocked(
    appointment: Appointment,
    startTime: Date,
    isResize = false
  ): Promise<boolean> {
    // Check if Appointment is locked
    if (appointment.service.isLocked) {
      var serviceProvider = this.appointmentService.workingServiceProviders.filter((s) => s.id === appointment.staffId);
      var message = `<strong>${appointment.service.serviceName}</strong> at <strong>${moment(startTime).format(
        'h:mm A'
      )}</strong> by <strong>${serviceProvider[0].title}</strong> is locked and ${
        isResize ? 'the duration cannot be changed' : 'cannot be moved.'
      }`;
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '300px',
        data: {
          title: 'Error - Locked Service',
          content: message,
          confirmButtonText: 'Ok',
          showCancel: false,
        },
      });
      const dialogResult = await dialogRef.afterClosed().toPromise();
      return true;
    }
    return false;
  }

  private scrollToTimeIndicator() {
    const timeIndicator = <HTMLElement>(
      document.querySelectorAll('.fc-content-skeleton:last-of-type .fc-now-indicator-arrow:last-of-type')[0]
    );
    if (
      !isNullOrUndefined(timeIndicator) &&
      !isNullOrUndefined(this.componentRef) &&
      !isNullOrUndefined(this.componentRef.directiveRef)
    ) {
      const top = parseInt(timeIndicator.style.top, 10);
      const positionY = top - ((window.innerHeight || document.documentElement.clientHeight) - 52) / 2;
      if (this.componentRef.directiveRef.ps() != null) {
        this.componentRef.directiveRef.scrollToY(positionY, 500);
      }
    }
  }

  // event/appointment section
  public eventClick(e) {
    const appointmentType = this.eventsService.blockedScheduleMode
      ? this.appointmentType.Regular
      : this.appointmentType.Blocked;
    if (
      (e.calEvent.appointmentType === appointmentType &&
        this.eventsService.scheduleView === ScheduleView.Appointments) ||
      this.eventsService.scheduleView === ScheduleView.NoShowAppointments ||
      e.calEvent.isPlaceholder
    ) {
      e.jsEvent.stopPropagation();
      return;
    }

    this.forceHideHover = true;
    this.hideHoverPanel();
    if (e.jsEvent.target.parentElement.id === 'checkin') {
      e.jsEvent.stopPropagation();
      this.updateVisitStatus(e.calEvent.visit.visitId, e.calEvent);
    } else {
      this.visitChangingStatus = null;
      // the line below has been replaced for now by the line below it so that clicking on the patient name doesn't navigate to patient profile tab of patient panel
      if (
        e.jsEvent.target.id === 'patientprofiletab' ||
        e.jsEvent.target.id === 'patientcharttab' ||
        e.jsEvent.target.id === 'patientservicedetail' ||
        e.jsEvent.target.id === 'patientaccounttab'
      ) {
        // now go to the patient panel, passing in the patient id
        this.patientService.getPatientById(e.calEvent.patientId).subscribe((pat) => {
          if (!isNullOrUndefined(pat)) {
            this.patientService.patientPanelPatient = pat;
            this.masterOverlayService.masterOverlayState(true);
            this.navigateFromAppointment(e.jsEvent.target.id, pat, e.calEvent.service);
          }
        });
      } else if (this.appointmentsPolicySatisfied) {
        // use settimeout, because need to wait while schedule width will be recalculated
        setTimeout(() => {
          if (!isNullOrUndefined(e.jsEvent.target.offsetParent)) {
            this.componentRef.directiveRef.scrollToX(
              e.jsEvent.target.offsetParent.offsetParent.offsetParent.offsetParent.offsetLeft - 60,
              500
            );
          } else {
            this.componentRef.directiveRef.scrollToX(e.jsEvent.originalEvent.path[4].offsetLeft - 60, 500);
          }
        });
        this.appointmentSelected(e.calEvent);
        this.openVisitPanel(e);
      }
    }
  }

  public hoverCheckInClick() {
    this.updateVisitStatus(this.hoveredEvent.visit.visitId, this.hoveredEvent);
  }

  private updateVisitStatus(visitId, parentAppointment) {
    this.visitService.getVisitById(visitId).subscribe((visit) => {
      this.visitChangingStatus = visit;
      this.visitStatusClicked(parentAppointment.visit);
      parentAppointment.visit = { ...this.visitChangingStatus };
      parentAppointment.source = null;
      parentAppointment.className = '';
      this.visitChangingStatus = null;
      // We don't need to update the appointment on a visit status change right? -MM
      //this.appointmentService.updateAppointment(parentAppointment).subscribe(() => {});
    });
  }

  public hoverPatientChartClick(tabName) {
    // now go to the patient panel, passing in the patient id
    this.patientService.getPatientById(this.hoveredEvent.patientId).subscribe((pat) => {
      if (!isNullOrUndefined(pat)) {
        this.patientService.patientPanelPatient = pat;
        this.masterOverlayService.masterOverlayState(true);
        this.navigateFromAppointment(tabName, pat, this.hoveredEvent.service);
      }
    });
  }

  public hoverPatientAccountClick(tabName) {
    // now go to the patient invoice, passing in the patient id
    this.patientService.getPatientById(this.hoveredEvent.patientId).subscribe((pat) => {
      if (!isNullOrUndefined(pat)) {
        this.patientService.patientPanelPatient = pat;
        this.masterOverlayService.masterOverlayState(true);
        this.navigateFromAppointment(tabName, pat, this.hoveredEvent.service);
      }
    });
  }

  private navigateFromAppointment(tabName: string, patient: Patient, service: Service) {
    this.actionPanelOpened = false;
    if (tabName !== 'patientservicedetail') {
      if (tabName === 'patientcharttab') {
        this.router.navigate([
          '/schedule',
          {
            outlets: {
              'action-panel': [
                'patient',
                patient.patientId + '_patientcharttab',
                'patienttabs',
                'patientcharttab',
                'overview',
              ],
            },
          },
        ]);
      } else if (tabName === 'patientaccounttab') {
        this.router.navigate([
          '/schedule',
          {
            outlets: {
              'action-panel': [
                'patient',
                patient.patientId + '_patientaccounttab',
                'patienttabs',
                'patientaccounttab',
                'overview',
              ],
            },
          },
        ]);
      } else {
        this.router.navigate([
          '/schedule',
          { outlets: { 'action-panel': ['patient', patient.patientId + '_' + tabName] } },
        ]);
      }
    } else {
      if (
        service.serviceDetailTemplateId === this.serviceDetailTemplate.Injections ||
        service.serviceDetailTemplateId === this.serviceDetailTemplate.Coolsculpting
      ) {
        this.router.navigate([
          '/schedule',
          {
            outlets: {
              'action-panel': [
                'patient',
                patient.patientId + '_patientcharttab',
                'patienttabs',
                'patientcharttab',
                'detail',
                service.serviceId,
                service.isLocked,
                false,
              ],
            },
          },
        ]);
      } else if (service.serviceDetailTemplateId === this.serviceDetailTemplate.TreatmentPlan) {
        this.router.navigate([
          '/schedule',
          {
            outlets: {
              'action-panel': [
                'patient',
                patient.patientId + '_patientcharttab',
                'patienttabs',
                'patientcharttab',
                'detail',
                'treatmentplan',
                true,
                true,
              ],
            },
          },
        ]);
      } else {
        this.router.navigate(
          [
            '/schedule',
            {
              outlets: {
                'action-panel': [
                  'patient',
                  patient.patientId + '_patientcharttab',
                  'patienttabs',
                  'patientcharttab',
                  'overview',
                ],
              },
            },
          ],
          { queryParams: { toServiceId: service.serviceId } }
        );
      }
    }
  }

  private fullHoverHide() {
    clearTimeout(this.timer);
    this.forceHideHover = false;
    this.hideHoverPanel();
  }

  private hideHoverPanel() {
    document.getElementById('hoverPanel').className = '';
    this.hoveredEvent = null;
    clearTimeout(this.displayHoverTimer);
  }

  private appointmentSelected(appointment) {
    this.eventsService.movingAppointment = false;
    const siblingAppointments: Appointment[] = [];
    this.appointmentService.getRegularScheduleAppointmentsByDate(this.currentDate, null).subscribe((todaysAppts) => {
      const todaysApptsForThisVisit: Appointment[] = todaysAppts.filter((a) => a.visitId === appointment.visitId);
      if (todaysApptsForThisVisit.length > 0) {
        if (!this.appointmentService.apptsSelected.has(todaysApptsForThisVisit[0].appointmentId)) {
          this.appointmentService.apptsSelected.clear();
        }
      }
      todaysApptsForThisVisit.forEach((a) => {
        siblingAppointments.push(a);
        const targetAppt: Appointment = this.appointmentService.activeAppointments.find(
          (appt) => appt.appointmentId === a.appointmentId
        );
        if (!this.appointmentService.apptsSelected.has(a.appointmentId)) {
          this.appointmentService.apptsSelected.set(targetAppt.appointmentId, targetAppt.appointmentId);
        }
        this.clearAllApptMarksForMove();
        this.visitSchedule.rerenderEvents();
      });
      this.visitService.clickedVisitAppt = { id: appointment.appointmentId, visitId: appointment.visitId };
      if (this.allowEditMode) {
        this.visitService.activeVisitApptsUpdated.next(appointment);
      }
      this.allowEditMode = true;
    });
  }

  private openVisitPanel(e) {
    this.eventsService.actionPanelOpened.next(e.calEvent);
    // Get the resource
    this.providerService.getServiceProviderById(e.calEvent.resourceId).subscribe((provider: ServiceProvider) => {
      this.clickEvent = {
        isSelection: false,
        start: e.calEvent.start,
        end: e.calEvent.end,
        resource: provider,
      };
      this.eventsService.setTempEvent(this.clickEvent);
      this.patientId = e.calEvent.patientId;
      this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);

      const clicked = e.jsEvent.target.parentElement.id;
      if (clicked === 'history') {
        this.patientHistoryClicked();
      } else if (clicked === 'checkin') {
        this.patientCheckInClick();
      } else {
        const event = e.calEvent;
        if (this.eventsService.scheduleView !== ScheduleView.StaffSchedules) {
          if (!this.eventsService.blockedScheduleMode) {
            this.toggleVisitPanel(event.visitId);
          } else {
            this.toggleVisitPanel(event.appointmentId + '_B');
          }
        } else {
          if (event.appointmentType === AppointmentType.Blocked) {
            this.toggleVisitPanel(event.appointmentId + '_B');
          } else {
            this.toggleVisitPanel(event.appointmentId + '_S');
          }
        }
      }
    });
  }

  public eventRender = (event, element) => {
    return this.loadAppointmentComponent(event, element);
  };

  private loadAppointmentComponent(event, element): any {
    if (!isNullOrUndefined(event) && !isNullOrUndefined(element)) {
      const factory = this.resolver.resolveComponentFactory(AppointmentComponent);
      const component = factory.create(this.injector);
      // const component = AppointmentComponent.
      component.instance.actionPanelOpened = this.actionPanelOpened;
      component.instance.isWeeklyMode = this.currentView == 'agendaWeekFive';
      component.instance.isOverlappingEvent = !isNullOrUndefined(this.isOverlappingEvent(event));
      component.instance.appointmentTile.classList = element[0].classList.value;
      component.instance.appointmentTile.start = moment(event.start).format('h:mm a');
      component.instance.appointmentTile.end = moment(event.end).format('h:mm a');
      component.instance.appointmentTile.backColor = !isNullOrUndefined(event.backgroundColor)
        ? event.backgroundColor
        : (event.service as Service).serviceTemplate.serviceIDColour;
      component.instance.appointmentTile.id = event.appointmentId;
      component.instance.appointmentTile.altid = event.altAppointmentId;
      component.instance.appointmentTile.resourceId = event.resourceId;
      component.instance.appointmentTile.source = event.source;
      component.instance.appointmentTile.title = event.title;
      component.instance.appointmentTile.notes = event.notes;
      component.instance.appointmentTile.visitIdString = event.visitIdString;
      component.instance.appointmentTile.rendering = event.rendering;
      component.instance.appointmentTile.apptType = event.appointmentType;
      component.instance.appointmentTile.isPreferred = false;
      component.instance.appointmentTile.paymentStatus = event.paymentStatus;
      component.instance.appointmentTile.isPlaceholder = event.isPlaceholder;
      component.instance.appointmentTile.createdDate = event.createdDate;
      if (event.isPlaceholder && event.onlineBooked)
        this.appointmentService.startReservationTimer(
          event,
          this.clinicsService.clinic.onlineBookingReservationDurationMinutes
        );

      component.instance.appointmentTile.onlineBooked = event.onlineBooked ?? false;
      component.instance.appointmentTile.daysUntlBirthday = this.patientService.getDaysUntilBirthday(
        event.patient,
        moment(this.currentDate)
      );
      component.instance.appointmentTile.isMinistry = event.isMinistry;
      if (event.service) {
        component.instance.appointmentTile.serviceName = event.service.serviceName;
        component.instance.appointmentTile.isLocked = event.service.isLocked;
        component.instance.appointmentTile.serviceIcon = event.service.templateIcon;
        if (
          !component.instance.appointmentTile.notes &&
          event.service.plannedTreatmentNotes &&
          event.service.plannedTreatmentNotes.length > 0
        ) {
          component.instance.appointmentTile.notes = event.service.plannedTreatmentNotes;
        }
        component.instance.appointmentTile.roomName = event.roomName;
      }

      // Try to get the colour bar colour
      if (event.appointmentType === 1) {
        component.instance.appointmentTile.color = this.appointmentService.staffUnavailabilityColor;
      } else if (event.appointmentType === 2) {
        component.instance.appointmentTile.color = this.appointmentService.blockedUnavailabilityColor;
      } else {
        component.instance.appointmentTile.color = !isNullOrUndefined(event.color)
          ? event.color
          : (event.service as Service).serviceTemplate.serviceIDColour;
        component.instance.appointmentTile.className = !isNullOrUndefined(this.isOverlappingEvent(event))
          ? 'appointmentCard--smaller'
          : '';
      }

      if (this.eventsService.scheduleView !== ScheduleView.StaffSchedules) {
        if (!isNullOrUndefined(event.patient)) {
          component.instance.appointmentTile.patientName =
            event.patient.firstName +
            (event.patient.nickName ? ' "' + event.patient.nickName + '"' : '') +
            ' ' +
            event.patient.lastName;
          component.instance.appointmentTile.patientBirthday = moment(event.patient.birthDate).format('MM-DD');
        } else {
          component.instance.appointmentTile.patientName = '';
        }

        if (!isNullOrUndefined(event.patient)) {
          component.instance.appointmentTile.isPreferred = event.patient.isPreferred;
        }
        component.instance.appointmentTile.visitStatus = this.getVisitStatus(event);
      }
      component.instance.appointmentTile.reasonCancellationNotCharged = event.reasonCancellationNotCharged;
      component.instance.appointmentTile.convergePaymentId = event.convergePaymentId;
      component.instance.appointmentTile.squarePaymentId = event.squarePaymentId;
      component.instance.appointmentTile.requestCreditCard = event.requestCreditCard;
      // component.instance.dow = event.dow;
      component.changeDetectorRef.detectChanges();
      element[0].innerHTML = component.location.nativeElement.innerHTML;
      element[0].ontouchstart = (evt) => {
        this.touches.push(evt);
      };
      element[0].ontouchend = (evt) => {
        if (this.touches.length === 2) {
          this.hoveredEvent = event;
        } else if (this.touches.length === 3) {
          this.hoverPanel.show(evt);
        }
        this.touches = [];
      };
      component.destroy();
      return element;
    }
  }

  private isOverlappingEvent(event: Appointment) {
    return this.appointmentService.activeAppointments.find((appointment: Appointment) => {
      return (
        appointment.appointmentType === 0 &&
        appointment.staffId === event.staffId &&
        appointment.appointmentId !== event.appointmentId &&
        moment(appointment.start).isBefore(moment(event.end).format()) &&
        moment(appointment.end).isAfter(moment(event.start).format())
      );
    });
  }

  private updateEventInUI(event, updateInDb?: boolean, revertAppointmentMove?: () => void) {
    if (event.appointmentId === 0) {
      return;
    }
    this.appointmentService.setAppointmentTileLoading(event.appointmentId, true);
    zip(
      this.appointmentService.getAppointmentById(event.appointmentId),
      this.providerService.getServiceProviderById(event.resourceId)
    ).subscribe(async ([apptMoving, apptToMoveProvider]) => {
      const apptToMove = Object.assign({}, apptMoving);

      // If we are moving appointments across providers verify that the new provider has the correct user category to perform the service
      if (
        event.appointmentType === AppointmentType.Regular &&
        apptToMove.staffId !== apptToMoveProvider.id &&
        apptToMoveProvider.authorizedServiceIds.findIndex(
          (authServiceId) => authServiceId === apptToMove.service.serviceTemplate.id
        ) < 0
      ) {
        // User doesn't have required category to perform the service
        this.dialog.open(GenericDialogComponent, {
          width: '300px',
          data: {
            title: 'Error',
            content: "This provider can't perform the booked service!",
            confirmButtonText: 'Ok',
            showCancel: false,
          },
        });
        if (!isNullOrUndefined(revertAppointmentMove)) {
          revertAppointmentMove();
        }
      } else {
        let updateConfirmationStatus = true;
        if (event.start && event.end) {
          // Determine if appointment is only changing in duration
          const newStartTime = moment.duration(moment(event.start).format('HH:mm'));
          const newEndTime = moment.duration(moment(event.end).format('HH:mm'));
          if (
            event.visitId == apptToMove.visitId && // Same day
            newStartTime.hours() == apptToMove.startTime.hours() &&
            newStartTime.minutes() == apptToMove.startTime.minutes() && // Same start time
            apptToMoveProvider.id == apptToMove.staffId
          ) {
            // Same provider
            updateConfirmationStatus = false;
          }
          apptToMove.startTime = newStartTime;
          apptToMove.endTime = newEndTime;
        }
        apptToMove.cancellationDate = moment(event.cancellationDate).toDate();
        apptToMove.allDay = event.allDay;
        apptToMove.cancellationReason = event.cancellationReason;
        apptToMove.cancellationMessage = event.cancellationMessage;
        apptToMove.cancelled = event.cancelled;
        apptToMove.isCancellationAlert = event.isCancellationAlert;
        apptToMove.resourceId = event.resourceId;
        apptToMove.service = event.service;
        apptToMove.serviceId = event.serviceId;
        apptToMove.staffId = event.resourceId;
        apptToMove.title = event.title;
        apptToMove.visitId = event.visitId;
        apptToMove.visitIdString = event.visitIdString;
        if (updateInDb) {
          if (event.appointmentType === AppointmentType.Staff) {
            if (event.resource) {
              apptToMove.title = event.resource;
            } else {
              this.providerService.getServiceProviderById(event.resourceId).subscribe((sp) => {
                apptToMove.title = sp.title;
                apptToMove.staffId = sp.id;
                this.appointmentService.updateAppointment(apptToMove).subscribe(() => {});
              });
            }
          } else if (event.appointmentType === AppointmentType.Blocked) {
            this.providerService.getServiceProviderById(event.resourceId).subscribe((sp) => {
              apptToMove.staffId = sp.id;
              this.appointmentService.updateAppointment(apptToMove).subscribe(() => {});
            });
          } else {
            this.validateResourcesAndAppointment(
              apptToMove,
              event,
              apptToMoveProvider,
              updateConfirmationStatus,
              revertAppointmentMove
            );
          }
        }
      }
    });
  }

  private validateResourcesAndAppointment(
    apptToMove: Appointment,
    event: any,
    apptToMoveProvider: ServiceProvider,
    updateConfirmationStatus: boolean,
    revertAppointmentMove: () => void
  ) {
    const durationMinutes = apptToMove.endTime.asMinutes() - apptToMove.startTime.asMinutes();
    // Check resources allocated for the Appointment
    combineLatest([
      this.resourcesService.getResourcesAllocated(
        undefined,
        apptToMove.date,
        apptToMove.startTime,
        apptToMove.endTime,
        apptToMove.appointmentId
      ),
      this.visitService.getVisitById(apptToMove.visitId),
    ]).subscribe(([resources, visit]) => {
      if (resources.length > 0) {
        let appointmentResourceIds = apptToMove.service?.serviceResources?.map((b) => b.resourceId);
        let matchingResources = resources.filter((r) => appointmentResourceIds.find((ar) => ar == r.resourceId));

        if (matchingResources.length > 0) {
          let resourceMessage = this.appointmentService.getResourceConflictMessage(matchingResources);
          this.appointmentService.validateResourceConflict(
            resourceMessage,
            () => {
              this.appointmentService.validateAppointment(
                moment(event.start).toDate(),
                durationMinutes,
                apptToMoveProvider.id,
                () => {
                  this.appointmentService.updateAppointment(apptToMove).subscribe(async (appt) => {
                    if (updateConfirmationStatus) await this.visitService.updateAutoConfirmation(appt, visit, true);
                  });
                },
                () => {
                  if (!isNullOrUndefined(revertAppointmentMove)) {
                    revertAppointmentMove();
                  }
                }
              );
            },
            () => {
              if (!isNullOrUndefined(revertAppointmentMove)) {
                revertAppointmentMove();
              }
            },
            apptToMove.staffId
          );
        } else {
          this.appointmentService.validateAppointment(
            moment(event.start).toDate(),
            durationMinutes,
            apptToMoveProvider.id,
            () => {
              this.appointmentService.updateAppointment(apptToMove).subscribe(async (appt) => {
                if (updateConfirmationStatus) await this.visitService.updateAutoConfirmation(appt, visit, true);
              });
            },
            () => {
              if (!isNullOrUndefined(revertAppointmentMove)) {
                revertAppointmentMove();
              }
            }
          );
        }
      } else {
        this.appointmentService.validateAppointment(
          moment(event.start).toDate(),
          durationMinutes,
          apptToMoveProvider.id,
          () => {
            this.appointmentService.updateAppointment(apptToMove).subscribe(async (appt) => {
              if (updateConfirmationStatus) await this.visitService.updateAutoConfirmation(appt, visit, true);
            });
          },
          () => {
            if (!isNullOrUndefined(revertAppointmentMove)) {
              revertAppointmentMove();
            }
          }
        );
      }
    });
  }

  public eventHover(event) {
    const appointmentType = this.eventsService.blockedScheduleMode
      ? this.appointmentType.Regular
      : this.appointmentType.Blocked;
    if (
      (event.calEvent.appointmentType === appointmentType && this.eventsService.scheduleView === 'Appointments') ||
      event.calEvent.isPlaceholder
    ) {
      event.jsEvent.stopPropagation();
      return;
    }
    this.hoveredEvent = event.calEvent;
    if (
      !this.forceHideHover &&
      this.eventsService.scheduleView !== 'StaffSchedules' &&
      !this.eventsService.blockedScheduleMode
    ) {
      this.displayHoverTimer = setTimeout(() => {
        this.baseHoveredEvent = event;
        document.getElementById('hoverPanel').className = 'event-hovered-transition';
        if (this.hoveredEvent) {
          if (!this.appointmentService.apptsHovered.has(this.hoveredEvent.appointmentId)) {
            this.appointmentService.apptsHovered.set(this.hoveredEvent, this.hoveredEvent);
          }
          document.getElementById('hoverPanel').className = 'show-panel';
          this.setHoverPanelPosition();
        }
      }, 0);
    }
  }

  public onScrollEvent(event) {
    const hoverPanelObj = document.getElementById('hoverPanel');
    const eventCoords = getPosition(hoverPanelObj);
    hoverPanelObj.style.top = eventCoords.y - (event.target.scrollTop - this.scrollTop) + 'px';
    this.scrollTop = event.target.scrollTop;
  }

  private setHoverPanelPosition() {
    const hoverPanelObj = document.getElementById('hoverPanel');
    const eventBounding = this.baseHoveredEvent.jsEvent.currentTarget.getBoundingClientRect();
    if (!isNullOrUndefined(hoverPanelObj)) {
      if (eventBounding.top !== 0 && eventBounding.left !== 0) {
        const scrollLeft = window.pageXOffset;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const eventCoords = { top: eventBounding.top + scrollTop, left: eventBounding.left + scrollLeft };

        // put the hover panel in the default position
        hoverPanelObj.style.top = eventCoords.top + 'px';
        hoverPanelObj.style.left = eventCoords.left + this.baseHoveredEvent.jsEvent.currentTarget.offsetWidth + 'px';
        const bounding = hoverPanelObj.getBoundingClientRect();
        const windowWidth = this.actionPanelOpened
          ? (window.innerWidth || document.documentElement.clientWidth) - 420
          : window.innerWidth || document.documentElement.clientWidth;

        // the right side of the hover panel is beyond the viewport, so shift it to the secondary position
        if (bounding.right > windowWidth) {
          hoverPanelObj.style.left = eventCoords.left - bounding.width + 25 + 'px';
        }

        // now if anything is out of bounds it will be the bottom, so pull it up til it is all visible
        if (bounding.bottom > (window.innerHeight || document.documentElement.clientHeight)) {
          hoverPanelObj.style.top =
            (window.innerHeight || document.documentElement.clientHeight) - bounding.height + 'px';
        }
      } else {
        hoverPanelObj.style.top = 'auto';
        hoverPanelObj.style.left = 'auto';
      }
    }
  }

  public eventMouseout(event) {
    if (event.jsEvent.type === 'mouseleave' && !this.mouseIsDown) {
      if (this.appointmentService.apptsHovered.has(event.calEvent.appointmentId)) {
        this.appointmentService.apptsHovered.delete(event.calEvent.appointmentId);
      }
    }
  }

  public isMainSchedule() {
    return this.eventsService.scheduleView === ScheduleView.Appointments;
  }

  public isStaffBlockedSchedule() {
    return this.eventsService.scheduleView === ScheduleView.StaffSchedules;
  }

  public isCancelled() {
    // We will not allow cancelled appointments re-activations right now.
    // return this.eventsService.scheduleView === ScheduleView.NoShowAppointments;
    return false;
  }

  public onRightClick(event: MouseEvent) {
    this.forceHideHover = true;
    if (this.hoveredEvent && this.appointmentsPolicySatisfied) {
      this.hoveredEvent.start = this.appointmentService.fixFullCalendarEventDate(this.hoveredEvent.start);
      this.hoveredEvent.end = this.appointmentService.fixFullCalendarEventDate(this.hoveredEvent.end);
      const tempEvent = this.hoveredEvent;
      this.rightClickEvent = tempEvent;
      this.contextMenuService.show(this.contextMenu, { x: event.x, y: event.y });
      event.stopPropagation();
    }
    event.preventDefault();
  }

  patientHistoryClicked() {}

  patientCheckInClick() {}

  private toggleVisitPanel(id) {
    if (this.eventsService.scheduleView !== ScheduleView.StaffSchedules && !this.eventsService.blockedScheduleMode) {
      this.actionPanelOpened = true;
      this.router.navigate([
        '/schedule',
        { outlets: { 'action-panel': ['visit-details', id, this.patientId ? this.patientId : '_'] } },
      ]);
    } else {
      this.actionPanelOpened = true;
      this.shiftPanelOpen = true;
      this.router.navigate(['schedule', { outlets: { 'action-panel': ['create-shift', id] } }]);
    }
  }

  private onSidePanelClose() {
    this.actionPanelOpened = false;
    this.shiftPanelOpen = false;
    this.patientId = null;
  }

  private slotLabel() {
    const data: NodeListOf<Element> = document.querySelectorAll('.fc-axis span');
    let dataItem = '';
    let result: string[] = [];
    this.results = [];

    for (let i = 0; i < data.length; i++) {
      dataItem = data[i].innerHTML;
      result = dataItem.split(' ');
      this.results.push(result);
      data[i].innerHTML = `${result[0]} ${result[1]}`;
    }

    const scheduleTimeslotRows: NodeListOf<Element> = document.querySelectorAll('.fc-slats table tbody tr');
    // figure out how many timeslots per hour
    const timeslotsPerHour = 60 / this.clinicsService.minimumDuration;
    this.results.map((e, i) => {
      if (i === this.results.length - 1) {
        e.rows = scheduleTimeslotRows.length % timeslotsPerHour;
      } else {
        e.rows = Math.floor(scheduleTimeslotRows.length / timeslotsPerHour);
      }
      e.timeslot = timeslotsPerHour;
      return e;
    });

    this.createTimeOverlay();
    this.updateNowIndicator();

    if (scheduleTimeslotRows.length !== 0) {
      for (let i = 0; i < scheduleTimeslotRows.length; i++) {
        if (!(i % timeslotsPerHour)) {
          const scheduleTimeslotCells: NodeListOf<Element> =
            scheduleTimeslotRows[i].querySelectorAll('.ui-widget-content');
          for (let k = 0; k < scheduleTimeslotCells.length; k++) {
            if (k) {
              (scheduleTimeslotCells[k] as HTMLElement).classList.add('timeslot-top-border');
            }
          }
        }
      }
    }
  }

  public async cancelAppointment() {
    const appointment = new Appointment(this.rightClickEvent);
    const cancelled = await from(this.appointmentService.handleCancellation(appointment))
      .pipe(this.loadingOperator('Cancelling Appointment'))
      .toPromise();
    if (cancelled) {
      this.eventsService.closePanel();
      this.visitSchedule.rerenderEvents();
      this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);
    }
  }

  public async requestCreditCard() {
    await this.squareService.requestCreditCardModal(this.rightClickEvent.patientId, this.rightClickEvent.appointmentId);
  }

  public reactiveAppointment() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Reactivate confirmation',
        content: 'Are you sure?',
        confirmButtonText: 'Reactivate',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          const el = this.appointmentService.activeAppointments.find(
            (item) => item.appointmentId === this.rightClickEvent.appointmentId
          );
          el.source = null;
          el.cancelled = false;
          this.appointmentService.updateAppointment(el).subscribe(
            () => {},
            () => {},
            () => {
              this.visitSchedule.refetchResources();
            }
          );
        }
      });
  }

  public cancelVisit() {
    this.visitService.getVisitById(this.rightClickEvent.visitId).subscribe((visit) => {
      if (this.canVisitBeCanceled(visit)) {
        const dialogRef = this.deleteDialog.open(ConfirmCancelWithReasonDialogComponent, {
          width: '400px',
          data: {
            title: 'Cancel the Entire Visit?',
            result: '',
            selectedCancelReason: '',
            customCancelReason: '',
            visitId: this.rightClickEvent.visitId,
          },
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsub))
          .subscribe((result) => {
            if (result.event === 'confirm') {
              const dialogConfirmCancelData = dialogRef.componentInstance.data;
              // Check for cancellation charge
              var visitId = this.rightClickEvent.visitId;
              var appointmentId = this.rightClickEvent.appointmentId;
              this.appointmentService.handleCancellationCharge(
                dialogConfirmCancelData,
                CancellationType.Visit,
                visitId,
                appointmentId
              );
              // Record the no charge for cancellation reason
              if (dialogConfirmCancelData.isCancelChargeable) {
                if (!dialogConfirmCancelData.chargeCancelAppointment) {
                  visit.reasonCancellationNotCharged = dialogConfirmCancelData.reasonCancellationNotCharged;
                }
              }
              visit.cancelledByUserId = this.userService.loggedInUser.id;
              visit.cancellationDate = new Date();
              visit.cancelled = true;
              visit.cancellationReason = dialogConfirmCancelData.selectedCancelReason;
              visit.cancellationMessage = dialogConfirmCancelData.customCancelReason;
              this.visitService
                .updateVisit(visit)
                .pipe(this.loadingOperator('Cancelling Appointments'))
                .subscribe((visit) => {
                  // Need to update all appointments because they should be removed from the view
                  this.appointmentService.onAllApptsUpdated();
                  this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);
                });

              this.eventsService.closePanel();
            }
          });
      } else {
        const dialogRef = this.deleteDialog.open(GenericDialogComponent, {
          width: '330px',
          data: {
            title: 'Cannot Cancel Visit',
            content:
              "One or more of this patient's appointments have already been paid and/or their chart entry is locked. Cancel individually.",
            confirmButtonText: 'OK',
            showCancel: false,
          },
        });

        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsub))
          .subscribe((result) => {});
      }
    });
  }

  private canVisitBeCanceled(visit: Visit) {
    let canCancel = true;

    //iterate through each appointment
    visit.appointments.forEach((appointment) => {
      if (
        appointment &&
        appointment.service && //if an appointment is locked or paid then do not allow visit canceelling
        (appointment.service.isLocked ||
          (appointment.paymentStatus == PaymentStatus.Paid && !appointment.service.isPrepaid))
      ) {
        //if an appt is paid
        canCancel = false;
      }
    });

    return canCancel;
  }

  updateShowStatus(appointment, show: boolean) {
    this.visitService.getVisitById(appointment.visitId).subscribe((visit) => {
      const parentAppointment = { ...appointment };
      this.visitChangingStatus = visit;
      this.visitChangingStatus.confirmedStatus = VisitConfirmedStatus.Unconfirmed;
      this.visitChangingStatus.checkedIn = false;
      this.visitChangingStatus.noShow = show;
      this.visitService.updateVisit(this.visitChangingStatus).subscribe(() => {});
      parentAppointment.visit = { ...this.visitChangingStatus };
      parentAppointment.source = null;
      parentAppointment.className = '';
      this.visitChangingStatus = null;
      this.appointmentService.updateAppointment(parentAppointment).subscribe(() => {});
    });
  }

  public noShowAppointment() {
    this.updateShowStatus(this.rightClickEvent, true);
  }

  public undoNoShowAppointment() {
    this.updateShowStatus(this.rightClickEvent, false);
  }

  public markAppointmentForMove() {
    this.clearAllApptMarksForMove();
    this.clearAllApptSelections();

    // Check if there's more than one appointment to move
    // Get all the appointments for this visit
    this.appointmentService.getAppointmentsByVisitId(this.rightClickEvent.visitId).subscribe((visitAppts) => {
      if (visitAppts.length > 1) {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          width: '300px',
          data: {
            title: 'Warning: Multiple Appointments for Patient',
            content:
              "There's more than one appointment for this patient. Are you sure you want to move just the single selected appointment?",
            confirmButtonText: 'Confirm',
            showCancel: true,
          },
        });
        dialogRef
          .afterClosed()
          .pipe(takeUntil(this.unsub))
          .subscribe(async (dialogResponse) => {
            if (dialogResponse === 'confirm') {
              this.appointmentService.apptsMarkedForMove.set(
                this.rightClickEvent.appointmentId,
                this.rightClickEvent.appointmentId
              );
              this.visitSchedule.rerenderEvents();
              this.eventsService.movingAppointment = true;
            } else {
              if (dialogResponse === 'cancel') {
                this.clearAllApptMarksForMove();
                this.visitSchedule.rerenderEvents();
              }
            }
          });
      } else {
        this.appointmentService.apptsMarkedForMove.set(
          this.rightClickEvent.appointmentId,
          this.rightClickEvent.appointmentId
        );
        this.visitSchedule.rerenderEvents();
        this.eventsService.movingAppointment = true;
      }
    });
  }

  public markVisitForMove() {
    this.clearAllApptMarksForMove();
    this.clearAllApptSelections();
    // get all the appointments for this visit
    this.appointmentService.getAppointmentsByVisitId(this.rightClickEvent.visitId).subscribe((visitAppts) => {
      visitAppts.forEach((va) => {
        if (!va.cancelled && va.startTime.asMilliseconds() !== va.endTime.asMilliseconds()) {
          /* Ignore cancelled and chart appointments when marking for move */
          this.appointmentService.apptsMarkedForMove.set(va.appointmentId, va.appointmentId);
        }
      });
      this.visitSchedule.rerenderEvents();
    });
    this.eventsService.movingAppointment = true;
    this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Moving All Appointments',
        content:
          "Click a time slot to move all of today's appointments for this patient at once, relative to the selected appointment.",
        confirmButtonText: 'Ok',
        showCancel: false,
      },
    });
  }

  public cancelMove() {
    this.eventsService.cancelMoveAppointment();
  }

  /**
   * This is me trying to describe simply the sequence that the previous devs imlpemented for Moving appointments
   * 1. Prompt to confirm move
   * 2. Get visit of selcted appointment
   * 3. Clone the visit
   * 4. Remove all appointments from newly cloned visit
   * 5a. If target date of move is the same date as today then:
   *    a) get the visit for the target patient on selected target day, if one exists then set cloned visit id as that, otherwise set as 0 to create a new one
   * 5b. If target date is same date then use clone visit
   * 6. Loop through appointments marked for move and get their details including lock status
   * 7. Check each appointment for lock status and deny moving if any are locked
   * 8. Update all appointments ?
   * 9. Create new appointments based on visit from 5 and appointments returned from update in step 8
   * 10. Remove all appointments from step 7
   * 11. Get original visit from step 2 and if it has no appointments then delete it
   */
  private moveAppointment(targetDate: moment.Moment, staffId: string) {
    this.eventsService.movingAppointment = false;

    const dialogRef = this.deleteDialog.open(MoveAppointmentDialogComponent, {
      width: '250px',
      data: {
        targetDate,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe(async (dialogResponse) => {
        if (dialogResponse === 'confirm') {
          const fromVisitId = this.rightClickEvent.visitId;
          await this.moveAppointmentsBetweenDays(targetDate, staffId, fromVisitId);
        } else {
          if (dialogResponse === 'cancel') {
            this.clearAllApptMarksForMove();
            this.visitSchedule.rerenderEvents();
          }
        }
        this.visitSchedule.rerenderEvents();
      });
  }

  private async moveAppointmentsBetweenDays(targetDate: moment.Moment, staffId: string, fromVisitId: number) {
    const toVisit = await from(this.visitService.getVisitForAppointmentMove(fromVisitId, targetDate))
      .pipe(this.loadingOperator('Moving Appointments'))
      .toPromise();
    const didMove = await from(
      this.appointmentService.moveAppointments(toVisit, fromVisitId, targetDate, staffId, this.serviceProviders)
    )
      .pipe(this.loadingOperator('Moving Appointments'))
      .toPromise();
    const isNewVisit = toVisit.visitId != fromVisitId;
    if (didMove === false && isNewVisit === true) {
      this.visitService.removeVisit(toVisit);
    }
  }

  private clearAllApptMarksForMove() {
    this.appointmentService.apptsMarkedForMove.clear();
  }

  private clearAllApptSelections() {
    this.appointmentService.apptsSelected.clear();
  }

  appointmentNudge() {}

  appointmentNotification() {}

  appointmentPrintLable() {}

  private areMomentsBetweenSelectedStartEnd(start, end, resourceId) {
    const isStartEqualOrAfter = moment(this.selectedTimeSlotStartTime).isSameOrAfter(start);
    const isEndEqualOrBefore = moment(this.selectedTimeSlotEndTime).isSameOrBefore(end);
    const isResourceEqual = this.selectedTimeSlotResourceId === resourceId ? true : false;

    if (isStartEqualOrAfter && isEndEqualOrBefore && isResourceEqual) {
      return true;
    } else {
      return false;
    }
  }

  private toggleCreateVisitPanel(start, end, resourceId) {
    if (!this.actionPanelOpened) {
      this.actionPanelOpened = true;
      this.selectedTimeSlotStartTime = start;
      this.selectedTimeSlotEndTime = end;
      this.selectedTimeSlotResourceId = resourceId;
    } else {
      if (this.areMomentsBetweenSelectedStartEnd(start, end, resourceId)) {
        this.selectedTimeSlotStartTime = null;
        this.selectedTimeSlotEndTime = null;
        this.selectedTimeSlotResourceId = null;
        this.router.navigate(['/schedule', { outlets: { 'action-panel': null } }]);
        this.eventsService.closeCreateVisitPanel.next();
      } else {
        this.actionPanelOpened = true;
        this.selectedTimeSlotStartTime = start;
        this.selectedTimeSlotEndTime = end;
        this.selectedTimeSlotResourceId = resourceId;
      }
    }
  }

  private initInvoicePaidSubscription() {
    this.financeService.invoicePaid$
      .pipe(
        takeUntil(this.unsub),
        mergeMap(() => this.appointmentService.updateActiveAppointments(null, this.currentDate, this.currentDate))
      )
      .subscribe();
  }

  public displayCalendar() {
    if (this.clinicsService.clinic) {
      return true;
    } else {
      return false;
    }
  }

  private visitStatusClicked(visit: Visit) {
    if (
      //if unconfirmed then confirm
      (isNullOrUndefined(this.visitChangingStatus.noShow) || this.visitChangingStatus.noShow === false) &&
      (isNullOrUndefined(this.visitChangingStatus.confirmedStatus) ||
        this.visitChangingStatus.confirmedStatus === VisitConfirmedStatus.Unconfirmed) &&
      (isNullOrUndefined(this.visitChangingStatus.checkedIn) || this.visitChangingStatus.checkedIn === false)
    ) {
      this.visitChangingStatus.noShow = false;
      this.visitChangingStatus.confirmedStatus = VisitConfirmedStatus.StaffConfirmed;
      this.visitChangingStatus.confirmedTime = new Date();
      this.visitChangingStatus.checkedIn = false;
      this.visitChangingStatus.manuallyConfirmedByUser = this.userService.loggedInUser;
    } else if (
      //if confirm then check in
      (isNullOrUndefined(this.visitChangingStatus.noShow) || this.visitChangingStatus.noShow === false) &&
      !isNullOrUndefined(this.visitChangingStatus.confirmedStatus) &&
      (this.visitChangingStatus.confirmedStatus === VisitConfirmedStatus.StaffConfirmed ||
        this.visitChangingStatus.confirmedStatus === VisitConfirmedStatus.EmailConfirmed ||
        this.visitChangingStatus.confirmedStatus === VisitConfirmedStatus.SMSConfirmed) &&
      (isNullOrUndefined(this.visitChangingStatus.checkedIn) || this.visitChangingStatus.checkedIn === false)
    ) {
      this.visitChangingStatus.noShow = false;
      this.visitChangingStatus.checkedIn = true;
      if (visit.manuallyConfirmedByUser !== null) {
        this.visitChangingStatus.manuallyConfirmedByUser = visit.manuallyConfirmedByUser;
      }
    } else if (
      //if checked in then reset to unconfirmed
      (isNullOrUndefined(this.visitChangingStatus.noShow) || this.visitChangingStatus.noShow === false) &&
      !isNullOrUndefined(this.visitChangingStatus.confirmedStatus) &&
      (this.visitChangingStatus.confirmedStatus === VisitConfirmedStatus.StaffConfirmed ||
        this.visitChangingStatus.confirmedStatus === VisitConfirmedStatus.EmailConfirmed ||
        this.visitChangingStatus.confirmedStatus === VisitConfirmedStatus.SMSConfirmed) &&
      !isNullOrUndefined(this.visitChangingStatus.checkedIn) &&
      this.visitChangingStatus.checkedIn === true
    ) {
      this.visitChangingStatus.noShow = false;
      this.visitChangingStatus.confirmedStatus = VisitConfirmedStatus.Unconfirmed;
      this.visitChangingStatus.checkedIn = false;
      delete this.visitChangingStatus.manuallyConfirmedByUser;
      delete this.visitChangingStatus.confirmedTime;
    }
    this.visitService.updateVisit(this.visitChangingStatus).subscribe(() => {});
  }

  public updateVisit() {
    this.visitService.updateVisit(this.visitChangingStatus).subscribe(() => {
      this.updateScheduleView();
    });
  }

  public closeHoverPanel() {
    this.hoveredEvent = null;
  }

  private reRenderSchedule() {
    if (!isNullOrUndefined(this.visitSchedule)) {
      if (!isNullOrUndefined(this.visitSchedule.schedule)) {
        this.visitSchedule.rerenderEvents();
      }
    }
  }

  public getBirthday(thePatient) {
    if (!isNullOrUndefined(thePatient)) {
      if (thePatient.birthDate !== '') {
        return moment(thePatient.birthDate).format('MMMM Do, YYYY');
      } else {
        return 'N/A';
      }
    } else {
      return 'N/A';
    }
  }

  public getGender(thePatient) {
    if (!isNullOrUndefined(thePatient)) {
      if (thePatient.gender === 'Female' || thePatient.gender === 'F') {
        return 'F';
      } else if (thePatient.gender === 'Male' || thePatient.gender === 'M') {
        return 'M';
      } else {
        return 'X';
      }
    } else {
      return 'U';
    }
  }

  public getHoverPanelVisitNoteDate() {
    if (!isNullOrUndefined(this.hoveredEvent.visit) && !isNullOrUndefined(this.hoveredEvent.visit.date)) {
      return moment(this.hoveredEvent.visit.date).format('dddd, MMMM, YYYY');
    } else {
      return 'N/A';
    }
  }

  public getHoverPanelVisitNoteCreatedBy() {
    if (!isNullOrUndefined(this.hoveredEvent.visit)) {
      return '(Entered by ' + this.hoveredEvent.visit.createdBy + ')';
    } else {
      return '(Entered by )';
    }
  }

  public getHoverPanelVisitNoteContent() {
    if (!isNullOrUndefined(this.hoveredEvent.visit)) {
      if (this.hoveredEvent.visit.visitNotes !== '') {
        return this.hoveredEvent.visit.visitNotes;
      } else {
        return 'No notes for this visit.';
      }
    } else {
      return 'No Notes for this visit.';
    }
  }

  public getHoverPatientNoteContent() {
    if (!isNullOrUndefined(this.hoveredEvent.patient)) {
      if (
        this.hoveredEvent.patient.notesAndAlerts &&
        this.hoveredEvent.patient.notesAndAlerts.replace(/\s/g, '').length
      ) {
        return this.hoveredEvent.patient.notesAndAlerts;
      } else {
        return 'No notes for this patient.';
      }
    } else {
      return 'No notes for this patient.';
    }
  }

  public getHoverPlannedTreatmentNoteContent() {
    if (
      this.hoveredEvent &&
      !isNullOrUndefined(this.hoveredEvent.service) &&
      !isNullOrUndefined(this.hoveredEvent.service.plannedTreatmentNotes) &&
      this.hoveredEvent.service.plannedTreatmentNotes.length > 0
    ) {
      if (this.hoveredEvent.service.serviceNotes[this.hoveredEvent.service.serviceNotes.length - 1].entryText !== '') {
        return this.hoveredEvent.service.plannedTreatmentNotes;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  public hexToTranslucentRgbA(hex: string, opacity: string): string {
    if (isNullOrUndefined(hex)) {
      return '';
    }
    let color: string[] | string = hex.substring(1).split('');
    if (color.length === 3) {
      color = [color[0], color[0], color[1], color[1], color[2], color[2]];
    }
    color = '0x' + color.join('');
    // tslint:disable-next-line: no-bitwise
    return 'rgba(' + [(+color >> 16) & 255, (+color >> 8) & 255, +color & 255].join(',') + ',' + opacity + ')';
  }

  private initServiceProviders() {
    this.providerService.getServiceProviders().subscribe((serviceProviders: ServiceProvider[]) => {
      if (!this.providerListLoading) {
        this.serviceProviders = serviceProviders;
      }
    });
  }

  private addProvidersFilterToggleButton() {
    const axisHeader = document.getElementsByClassName('fc-axis ui-widget-header');
    if (axisHeader.length) {
      const el = axisHeader[0];
      if (el.childNodes.length === 0) {
        const div = document.createElement('div');
        div.classList.add('users-btn');
        const i = document.createElement('i');
        i.classList.add('fal');
        i.classList.add('fa-users');
        div.appendChild(i);
        el.appendChild(div);
        div.onclick = (e) => this.showProvidersFilterPanel(e);
      }
    }
  }

  /**
   * Adds the forward and back buttons to the week view
   */
  private addWeekViewForwardBackButtons() {
    const axisHeader = document.getElementsByClassName('fc-axis ui-widget-header');
    if (axisHeader.length) {
      const el = axisHeader[0];
      if (el.childNodes.length === 0) {
        const div = document.createElement('div');
        div.id = 'weekViewBtns';
        div.classList.add('d-flex', 'justify-content-center');
        const leftDiv = document.createElement('div');
        leftDiv.classList.add('btn', 'em-btn-green');
        leftDiv.style.cssText = 'padding: .2rem .4rem;';
        const rightDiv = document.createElement('div');
        rightDiv.classList.add('btn', 'em-btn-green');
        rightDiv.style.cssText = 'padding: .2rem .4rem; margin-left: 0.1rem;';
        const left = document.createElement('i');
        left.classList.add('fal', 'fa-chevron-left', 'white-font');
        const right = document.createElement('i');
        right.classList.add('fal', 'fa-chevron-right', 'white-font');
        leftDiv.onclick = () => this.weekViewBack();
        rightDiv.onclick = () => this.weekViewForward();
        leftDiv.appendChild(left);
        rightDiv.appendChild(right);
        div.appendChild(leftDiv);
        div.appendChild(rightDiv);
        el.appendChild(div);
      }
    }
  }

  /**
   * Moves the week view forward or back one week
   * @param isForward
   */
  private weekViewMove(isForward: boolean) {
    const newDate = new Date(this.currentDate);
    newDate.setDate(newDate.getDate() + 7 * (isForward ? 1 : -1));
    this.calendarDate = new Date(newDate);
    this.currentDate = new Date(newDate);
    this.currentDataService.currentDate = new Date(newDate);
    this.eventsService.setSelectedDate(new Date(newDate));
    const thisSunday = moment(this.currentDate).startOf('week');
    this.getAppointmentsForWeek(thisSunday.toDate(), this.selectedProviderId).then(() => {
      this.daysOpen = 7 - this.daysClosed.length;
      this.countScheduleColumns = this.daysOpen;
      this.visitSchedule.options.views.agendaWeekFive.hiddenDays = this.daysClosed;
      this.visitSchedule.changeView(ScheduleMode.AgendaWeek);
      this.eventsService.scheduleModeChanged(ScheduleMode.AgendaWeek);
      this.optionsConfig.resources((resources: { id: string; title: string }[]) => {
        resources.forEach((resource) => {
          if (resource.id !== this.selectedProviderId) {
            this.visitSchedule.removeResource(resource);
          }
        });
      });
      this.selectedProviderId = this.selectedProviderId;
    });
    this.refreshDayHours();
    this.visitSchedule.changeView('agendaWeekFive');
    this.visitSchedule.gotoDate(this.currentDataService.currentDate);
    this.removeStickyTime();
    this.slotLabel();
    this.scrollToTimeIndicator();
    this.updateNowIndicator();
  }

  private weekViewForward() {
    this.weekViewMove(true);
  }
  private weekViewBack() {
    this.weekViewMove(false);
  }

  private showProvidersFilterPanel(e) {
    const panelObj = document.getElementById('providersFilterPanel');
    if (panelObj.className === 'show-panel') {
      this.closeProvidersPanel();
    } else {
      panelObj.className = 'show-panel';
      const eb = e.target.parentElement.getBoundingClientRect();
      panelObj.style.left = eb.left + 'px';
      panelObj.style.top = eb.bottom + 'px';
    }
  }

  public closeProvidersPanel() {
    const panelObj = document.getElementById('providersFilterPanel');
    panelObj.className = '';
  }

  public async providerSelected(provider: ServiceProvider) {
    provider.visible = !provider.visible;
    this.providerListLoading = true;
    if (provider.visible) {
      await this.providerService
        .addServiceProviderVisibleOnSchedule(provider.id)
        .pipe(this.loadingOperator('Adding Provider'))
        .toPromise();
    } else {
      await this.providerService
        .removeServiceProviderVisibleOnSchedule(provider.id)
        .pipe(this.loadingOperator('Adding Provider'))
        .toPromise();
    }
    this.eventsService.scheduleViewChanged(this.visitSchedule);
  }

  public async onProviderDropped(event: CdkDragDrop<ServiceProvider[]>) {
    this.providerListLoading = true;
    moveItemInArray(this.serviceProviders, event.previousIndex, event.currentIndex);
    await this.providerService
      .updateServiceProvidersOrder(this.serviceProviders.map((_) => _.id))
      .pipe(this.loadingOperator('Removing Provider'))
      .toPromise();
    this.eventsService.scheduleViewChanged(this.visitSchedule);
  }

  public clearAllVisibleProviders() {
    this.providerListLoading = true;
    this.serviceProviders.forEach((p) => {
      p.visible = false;
    });
    this.providerService
      .removeAllServiceProvidersVisibleOnSchedule()
      .pipe(this.loadingOperator('Removing Providers'))
      .subscribe(() => {
        this.eventsService.scheduleViewChanged(this.visitSchedule);
      });
  }

  public selectAllVisibleProviders() {
    this.providerListLoading = true;
    let reqs = [];
    this.serviceProviders.forEach((p) => {
      p.visible = true;
      reqs.push(this.providerService.addServiceProviderVisibleOnSchedule(p.id));
    });

    forkJoin(reqs)
      .pipe(this.loadingOperator('Adding Providers'))
      .subscribe(() => {
        this.eventsService.scheduleViewChanged(this.visitSchedule);
      });
  }

  private isMobileDevice() {
    return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
  }

  private createTimeOverlay() {
    const stickyTimeContainer = document.getElementsByClassName('sticky-time-wrapper');
    if (stickyTimeContainer.length) {
      return;
    }
    const calendarTimeWrapper = document.getElementsByClassName('fc-time-grid');
    const elementBefore = document.getElementsByClassName('fc-slats');
    const timeWrapper = document.createElement('div');
    timeWrapper.classList.add('sticky-time-wrapper');
    const timeContainer = document.createElement('div');
    timeContainer.classList.add('sticky-time-container');
    const rowsWrapper = document.createElement('div');
    rowsWrapper.classList.add('sticky-rows-wrapper');

    this.results.map((e, i) => {
      const timeSlot = document.createElement('div');
      timeSlot.className = 'results';
      timeSlot.innerHTML = e[0] + ' ' + e[1];
      timeSlot.classList.add('sticky-time');

      if (i === this.results.length - 1) {
        timeSlot.style.cssText = e.rows ? `height: ${e.rows * 42}px` : `height: ${e.timeslot * 42}px`;
      } else {
        timeSlot.style.cssText = `height: ${e.timeslot * 42}px`;
      }
      rowsWrapper.appendChild(timeSlot);
    });
    timeContainer.appendChild(rowsWrapper);
    timeWrapper.appendChild(timeContainer);
    calendarTimeWrapper[0].insertBefore(timeWrapper, elementBefore[0]);
  }

  private removeStickyTime() {
    const wrapper = document.querySelector('.sticky-time-wrapper');
    if (wrapper) {
      wrapper.remove();
    }
  }

  private startNowIndicator() {
    const update = this.updateNowIndicator.bind(this);
    const miliseconds = (60 - new Date().getSeconds()) * 1000;

    this.nowIndicatorTimeoutID = setTimeout(() => {
      const delay = 1000 * 60;
      this.nowIndicatorTimeoutID = null;
      update();

      this.nowIndicatorIntervalID = setInterval(update, delay); // update every interval
    }, miliseconds);
  }

  private updateNowIndicator() {
    const timeIndicator = <HTMLElement>(
      document.querySelectorAll('.fc-content-skeleton:last-of-type .fc-now-indicator-arrow:last-of-type')[0]
    );
    if (!isNullOrUndefined(timeIndicator)) {
      const top = parseInt(timeIndicator.style.top, 10);
      this.createGreenTimeIndicator(top);
    }
  }

  private createGreenTimeIndicator(top) {
    const isLineEl = document.getElementsByClassName('custom-time-indicator')[0];
    if (isLineEl) {
      isLineEl.remove();
    }
    const lineEl = document.createElement('div');
    lineEl.classList.add('custom-time-indicator');
    lineEl.style.top = top - 5 + 'px';

    const stickyTimeContainer = document.getElementsByClassName('sticky-rows-wrapper');
    if (stickyTimeContainer && stickyTimeContainer.length > 0) stickyTimeContainer[0].appendChild(lineEl);
  }

  public createNudge() {
    const dialogRef = this.dialog.open(CreateNudgesComponent, {
      panelClass: 'custom-dialog-container',
      width: '550px',
      data: {
        patientId: this.rightClickEvent.patient.patientId,
        referenceId: +this.rightClickEvent.service.serviceId,
        referenceType: NudgeReferenceType.Service,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  public deleteAppointment() {
    const dialogRef = this.deleteDialog.open(ConfirmDeleteDialogComponent, {
      width: '400px',
      data: {
        result: '',
        selectedCancelReason: '',
        customCancelReason: '',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsub),
        mergeMap((result) => {
          if (result === 'delete') {
            return this.appointmentService.removeAppointment(this.rightClickEvent.appointmentId);
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  onTouchStart(event) {
    if (event.target.classList.contains('touch-fix')) {
      event.target.closest('.fc-event').focus();
      event.target.click();
      event.preventDefault();
    }
  }

  getVisitStatus(event) {
    const visit = this.appointmentService.activeAppointmentVisits.get(event.visitId)
    return this.visitService.getVisitStatus(visit);
  }

  getConfirmationStatus(event) {
    const visit = this.appointmentService.activeAppointmentVisits.get(event.visitId)
    return this.appointmentService.getConfirmationStatus(visit, event);
  }

  ngOnDestroy() {
    if (this.nowIndicatorIntervalID) {
      clearInterval(this.nowIndicatorIntervalID);
    }
    this.subs.forEach((sub) => {
      sub.unsubscribe();
    });
    this.unsub.next();
    this.unsub.complete();
  }
}
