import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { EventsService, ScheduleMode, ScheduleView } from '@services/events.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalnavnoheaderComponent } from './calnavnoheader.component';

@Component({
  selector: 'app-calnav',
  templateUrl: './calnav.component.html',
  styleUrls: ['./calnav.component.less'],
})
export class CalnavComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('calendar1') calendar1: MatCalendar<Date>;
  @ViewChild('calendar2') calendar2: CalnavnoheaderComponent;
  @ViewChild('calendar3') calendar3: CalnavnoheaderComponent;
  @ViewChild('calendar4') calendar4: CalnavnoheaderComponent;
  @Output() reloadMainSchedule = new EventEmitter();
  private unsub: Subject<void> = new Subject<void>();
  scheduleViewType = ScheduleView;
  selectedScheduleView: ScheduleView;
  selectedScheduleMode: ScheduleMode;
  selectedDate: Date;
  isMobileDevice: boolean;
  ScheduleMode = ScheduleMode;

  private get firstDate(): Date {
    const activeDate = this.calendar1?.monthView.activeDate;
    return activeDate ? moment(activeDate).startOf('month').toDate() : null;
  }

  private get lastDate(): Date {
    const activeDate = this.calendar4
      ? this.calendar4.matCalendar.monthView.activeDate
      : this.calendar3?.matCalendar.monthView.activeDate;
    return activeDate ? moment(activeDate).endOf('month').toDate() : null;
  }

  private get activeDay(): Date {
    return this.calendar1.monthView.activeDate;
  }

  private set activeDay(date: Date) {
    this.calendar1.monthView.activeDate = date;
    this.calendar1.stateChanges.next();
    this.intiYearAppend();
  }

  get weekStart() {
    return moment(this.selectedDate).startOf('week');
  }

  get weekEnd() {
    return moment(this.selectedDate).endOf('week');
  }

  patientPanelPolicy = Policies.patientPanel;
  staffSchedulePolicy = Policies.staffSchedule;

  constructor(public _eventsService: EventsService, public authService: AuthService) {}

  ngOnInit() {
    this.isMobileDevice = this.checkDevice();
    this._eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe((value) => {
      this.checkDateBounds(value);
      this.selectedDate = value;
    });

    this.selectedScheduleView = this._eventsService.scheduleView;
    this._eventsService.scheduleViewChanged$.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.selectedScheduleView = this._eventsService.scheduleView;
    });

    this.selectedScheduleMode = this._eventsService.scheduleMode;
    this._eventsService.scheduleModeChangedListener.pipe(takeUntil(this.unsub)).subscribe((mode) => {
      this.selectedScheduleMode = mode;
    });
  }

  ngAfterViewInit() {
    this._eventsService.setSelectedDate(new Date(Date.now()));
    this.intiYearAppend();
  }

  intiYearAppend() {
    this.calendar1.stateChanges.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.appendYear(this.calendar1);
    });
    this.calendar2.matCalendar.stateChanges.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.appendYear(this.calendar2.matCalendar);
    });
    this.calendar3.matCalendar.stateChanges.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.appendYear(this.calendar3.matCalendar);
    });
    this.appendYear(this.calendar1);
    this.appendYear(this.calendar2.matCalendar);
    this.appendYear(this.calendar3.matCalendar);

    if (this.calendar4) {
      this.calendar4.matCalendar.stateChanges.pipe(takeUntil(this.unsub)).subscribe(() => {
        this.appendYear(this.calendar4.matCalendar);
      });
      this.appendYear(this.calendar4.matCalendar);
    }
  }

  appendYear(calendar: MatCalendar<Date>) {
    const calViewYear = moment(calendar.monthView.activeDate).year();
    const currentYear = moment().year();
    const yearStr = calViewYear.toString().substring(2);
    if (calViewYear != currentYear && !calendar.monthView._monthLabel.includes(yearStr)) {
      calendar.monthView._monthLabel += ` '${yearStr}`;
    }
  }

  selectedChange = (event) => {
    this.clearSelections();
    this._eventsService.scheduleMode = ScheduleMode.AgendaDay;
    this._eventsService.scheduleModeChanged(ScheduleMode.AgendaDay);
    const date = event.toDate();
    this._eventsService.setSelectedDate(date);
  };

  private checkDateBounds(inDate: Date = null) {
    const date = inDate ? inDate : this.selectedDate;
    if (moment(date).isBefore(this.firstDate) || moment(date).isAfter(this.lastDate)) {
      this.activeDay = date;
    }
  }

  gotoToday() {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    this._eventsService.scheduleMode = ScheduleMode.AgendaDay;
    this._eventsService.scheduleModeChanged(ScheduleMode.AgendaDay);
    this.activeDay = todayDate;
    this._eventsService.setSelectedDate(todayDate);
  }

  goBackOneDay() {
    this.clearSelections();
    const oneDayBack = moment(this.selectedDate).subtract(1, 'days').toDate();
    this._eventsService.scheduleMode = ScheduleMode.AgendaDay;
    this._eventsService.scheduleModeChanged(ScheduleMode.AgendaDay);
    this._eventsService.setSelectedDate(oneDayBack);
  }

  goForwardOneDay() {
    this.clearSelections();
    const oneDayForward = moment(this.selectedDate).add(1, 'days').toDate();
    this._eventsService.scheduleMode = ScheduleMode.AgendaDay;
    this._eventsService.scheduleModeChanged(ScheduleMode.AgendaDay);
    this._eventsService.setSelectedDate(oneDayForward);
  }

  goForwardOneMonth() {
    this.clearSelections();
    const oneMonthForward = moment(this.activeDay).add(1, 'months').toDate();
    this.activeDay = oneMonthForward;
  }

  goBackOneMonth() {
    this.clearSelections();
    const oneMonthBack = moment(this.activeDay).subtract(1, 'months').toDate();
    this.activeDay = oneMonthBack;
  }

  scheduleView() {
    if (this._eventsService.scheduleView !== ScheduleView.Appointments || this._eventsService.blockedScheduleMode) {
      this._eventsService.blockedScheduleMode = false;
      this._eventsService.scheduleView = ScheduleView.Appointments;
      this._eventsService.scheduleViewChanged(this._eventsService.scheduleView);
    }
  }

  cancelledAndNoShowView() {
    if (this._eventsService.scheduleView !== ScheduleView.NoShowAppointments) {
      this._eventsService.blockedScheduleMode = false;
      this._eventsService.scheduleView = ScheduleView.NoShowAppointments;
      this._eventsService.scheduleViewChanged(this._eventsService.scheduleView);
    }
  }

  staffScheduleView() {
    if (this._eventsService.scheduleView !== ScheduleView.StaffSchedules) {
      this._eventsService.blockedScheduleMode = false;
      this._eventsService.scheduleView = ScheduleView.StaffSchedules;
      this._eventsService.scheduleViewChanged(this._eventsService.scheduleView);
    }
  }

  private clearSelections() {
    const calendarNodes = document.querySelectorAll('.mat-calendar-body-selected');
    const calendarList = Array.from(calendarNodes);
    calendarList.forEach((e) => {
      e.classList.remove('mat-calendar-body-selected');
    });
  }

  blockedScheduleModeChange() {
    if (this._eventsService.scheduleView !== ScheduleView.Appointments || !this._eventsService.blockedScheduleMode) {
      this._eventsService.blockedScheduleMode = !this._eventsService.blockedScheduleMode;
      this._eventsService.scheduleView = ScheduleView.Appointments;
      this._eventsService.scheduleViewChanged(this._eventsService.scheduleView);
    }
  }

  cancelMove() {
    this._eventsService.cancelMoveAppointment();
  }

  private checkDevice() {
    return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
