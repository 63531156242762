import { GeographyService } from './../../../../services/geography.service';
import { Component, OnDestroy, OnInit, Input, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { PatientDocumentService } from '@services/patient-documents.service';
import { PatientFormService } from '@services/patient-form.service';
import { PatientDocument } from '@models/document/patient-document';
import { PatientForm } from '@models/forms/patient-form';
import { PatientService } from '@services/patient.service';
import { UsersService } from '@services/users.service';
import { ClinicsService } from '@services/clinics.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CreateNudgesComponent } from '@app/patients/patient-tabs/patient-nudges-tab/create-nudges/create-nudges.component';
import { NudgeReferenceType } from '@models/nudges/reference-type';
import { Utils } from 'formiojs';
import moment from 'moment';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { PasscodeComponent } from '../../patient-profile-tab/patient-portal-overview/passcode/passcode.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface Dict {
  [key: string]: any;
}

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.less'],
})
export class DocumentDetailsComponent implements OnInit, OnDestroy {
  @Input() patientDocument?: PatientDocument;
  @Input() patientForm?: PatientForm;

  isIpad: boolean;
  isDirty: boolean;
  isUnassigned: boolean;
  patientId: number;
  patientEmail: string;
  loading = true;
  modalView = false;
  unsub: Subject<void> = new Subject<void>();
  kioskMode: boolean;

  // Legacy forms
  docFormDefinition: Dict = {};
  docFormData: string;

  constructor(
    private patientDocumentService: PatientDocumentService,
    private patientFormService: PatientFormService,
    private patientService: PatientService,
    private usersService: UsersService,
    private clinicService: ClinicsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private geoService: GeographyService,
    private dialogService: MatDialog,
    private modalService: NgbModal,
    @Optional() private dialogRef: MatDialogRef<DocumentDetailsComponent>
  ) {
    var kioskMode = this.route.snapshot.params.kioskMode;
    if (kioskMode && kioskMode.toLocaleLowerCase() === 'true') {
      this.kioskMode = JSON.parse(kioskMode);
    }
  }

  ngOnInit() {
    this.loading = true;
    if (!isNaN(+this.route.snapshot.params.patId?.split('_')[0]))
      this.patientId = +this.route.snapshot.params.patId?.split('_')[0];
    else this.patientId = this.patientService.patientPanelPatient?.patientId;
    this.patientEmail = this.patientService.patientPanelPatient?.email;
    this.isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;

    if (this.dialogRef) {
      this.modalView = true;
    }

    this.patientFormService.formDirty$.pipe(takeUntil(this.unsub)).subscribe((dirty: boolean) => {
      this.isDirty = dirty;
    });

    this.patientDocumentService.documentSelected.pipe(takeUntil(this.unsub)).subscribe((doc: PatientDocument) => {
      this.patientForm = null;
      this.patientDocument = doc;
      this.preFillLegacyForm();
      this.loading = false;
    });

    this.patientFormService.patientFormSelected$.pipe(takeUntil(this.unsub)).subscribe((form: PatientForm) => {
      this.patientDocument = null;
      this.patientForm = form;
      this.preFillForm();
      this.isUnassigned = !form?.id;
      this.loading = false;
      if (form?.isSigned) {
        this.patientFormService.formDirty$.next(false);
      }
    });

    this.patientDocumentService.documentSigned.pipe(takeUntil(this.unsub)).subscribe((signature: string) => {
      this.preFillLegacyForm(signature);
    });

    this.patientDocumentService.documentsTabInitialized$.next(true);
  }

  private preFillForm() {
    let presetFields = { dateTime: moment().format() };
    this.setDataFields(presetFields);
  }

  private preFillLegacyForm(signature: string = null) {
    this.loading = true;
    this.docFormDefinition = {};
    this.docFormData = '';

    if (this.patientDocument?.eFormDefinition) {
      this.docFormDefinition = JSON.parse(this.patientDocument.eFormDefinition);
    }

    if (this.patientDocument?.eFormData) {
      this.docFormData = this.patientDocument.eFormData;
    }

    if (this.patientDocument?.pdfToHtmlUrl) {
      let zoomLevel = this.isIpad ? '1' : '-40';
      this.docFormDefinition.settings.pdf.src = this.patientDocument.pdfToHtmlUrl + `&zoom=${zoomLevel}`;
    }

    let presetFields = {
      ...this.generatePatientFields(),
      ...this.generateClinicFields(),
      ...this.generateUserFields(),
      dateTime: moment().format(),
    };
    if (signature) {
      presetFields['signature'] = signature;
    }
    this.setDataFields(presetFields);
  }

  private generatePatientFields(): Dict {
    let patient = this.patientService.patientPanelPatient;
    let patientFields = {};
    if (patient?.patientId !== 0) {
      patientFields = {
        firstName: patient.firstName,
        lastName: patient.lastName,
        fullPatientName: patient.firstName + ' ' + patient.lastName,
        fullName: patient.firstName + ' ' + patient.lastName, //retroactive support for old doc definitions
        name: patient.firstName + ' ' + patient.lastName, //retroactive support for old doc definitions
        genderString: patient.gender,
        genderMale: patient.gender === 'Male',
        genderFemale: patient.gender === 'Female',
        birthMonth: new Date(patient.birthDate).toISOString(),
        birthDay: new Date(patient.birthDate).toISOString(),
        birthYear: new Date(patient.birthDate).toISOString(),
        healthCareNumber: patient.clientId,
        homePhone: patient.homeNumber,
        mobilePhone: patient.mobileNumber,
        email: patient.email,
      };
      if (patient.address) {
        patientFields = {
          ...patientFields,
          address1: patient.address.address1,
          address2: patient.address.address2,
          city: patient.address.city,
          country: patient.address.country,
          postalCode: patient.address.postalCode,
          province: this.geoService.getProvinceCode(patient.address.province),
        };
      }
    }
    return patientFields;
  }

  private generateClinicFields(): Dict {
    let clinic = this.clinicService.clinic;
    let clinicFields = {};
    if (clinic) {
      clinicFields = {
        clinicPhoneNumber: clinic.phoneNumber,
        clinicFaxNumber: clinic.faxNumber,
        clinicEmail: clinic.email,
      };
      if (clinic.address) {
        clinicFields = {
          ...clinicFields,
          clinicAddress1: clinic.address.address1,
          clinicAddress2: clinic.address.address2,
          clinicCity: clinic.address.city,
          clinicCountry: clinic.address.country,
          clinicPostalCode: clinic.address.postalCode,
          clinicProvince: this.geoService.getProvinceCode(clinic.address.province),
        };
      }
    }
    return clinicFields;
  }

  private generateUserFields(): Dict {
    let user = this.usersService.loggedInUser;
    let userFields = {};
    if (user) {
      userFields = {
        mspNumber: user.provincialBillingNumber,
        mspBillingNumber: user.provincialBillingNumber, //retroactive support for old doc definitions
        cpsbc: user.medicalLicenseNumber,
        physicianFirstName: user.firstName,
        physicianLastName: user.lastName,
        fullPhysicianName: user.firstName + ' ' + user.lastName,
        physicianName: user.firstName + ' ' + user.lastName, //retroactive support for old doc definitions
        userFirstName: user.firstName,
        userLastName: user.lastName,
        userFullName: user.firstName + ' ' + user.lastName,
      };
    }
    return userFields;
  }

  private setDataFields(data: { [key: string]: any }) {
    if (this.patientForm?.clinicForm.formDefinition) {
      let patientFormEntry = { data: {}, metadata: {} };
      if (this.patientForm.formEntry) {
        patientFormEntry = JSON.parse(this.patientForm.formEntry);
        if (!patientFormEntry.data) {
          patientFormEntry = { data: patientFormEntry, metadata: {} };
        }
      }

      let formDef = JSON.parse(this.patientForm.clinicForm.formDefinition);
      let formComponents = Utils.flattenComponents(formDef.components, false);
      for (const key in data) {
        if (formComponents[key]) {
          patientFormEntry.data[key] = data[key];
        }
      }

      patientFormEntry.metadata['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.patientForm.formEntry = JSON.stringify(patientFormEntry);
    }

    if (this.patientDocument && this.docFormDefinition?.components) {
      let formComponents = Utils.flattenComponents(this.docFormDefinition.components, false);
      for (const key in data) {
        if (formComponents[key]) {
          const docFormData = JSON.parse(this.docFormData);
          docFormData[key] = data[key];
          this.docFormData = JSON.stringify(docFormData);
          Utils.getComponent(this.docFormDefinition.components, key, false).disabled = true;
        }
      }
    }
  }

  private async confirmLeaveForm(): Promise<boolean> {
    const dialogRef = this.dialogService.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Warning',
        content: 'You have unsaved changes to this form. Are you sure you want to proceed?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancel: true,
      },
    });
    let proceed = await dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .map((result) => result === 'confirm')
      .toPromise();
    return proceed;
  }

  onSubmit(event) {
    if (event?.state == 'submitted') {
      this.patientDocument.eFormData = JSON.stringify(event.data);
      this.patientDocumentService.updatePatientClinicDocument(this.patientDocument).toPromise();
    }
  }

  onFormSubmit(data) {
    this.loading = true;
    this.patientFormService
      .submitPatientForm(data)
      .pipe(first())
      .subscribe(() => (this.loading = false));
    if (this.modalView) {
      this.closeDialog(true);
    }
  }

  onFormChange(data) {
    let signedOrModified = data.changed?.component.key === 'signature' || data.isModified == true;
    this.patientFormService.formDirty$.next(signedOrModified);
    this.patientFormService.formEntry$.next(data);
  }

  pageRendered(event) {
    this.loading = false;
  }

  async closeDialog(isSigned = false) {
    if ((this.isDirty || this.isUnassigned) && !isSigned && this.patientForm) {
      let proceed = await this.confirmLeaveForm();
      if (!proceed) {
        return;
      }
    }
    if (this.patientDocument) {
      this.patientDocumentService.documentSelected.next(null);
    }
    if (this.patientForm) {
      this.patientFormService.patientFormSelected$.next(null);
    }
    this.dialogRef.close();
  }

  openNudgeModal() {
    const dialogRef = this.dialog.open(CreateNudgesComponent, {
      panelClass: 'custom-dialog-container',
      width: '550px',
      data: {
        patientId: this.patientId,
        referenceId: this.patientDocument ? this.patientDocument.id : this.patientForm.id,
        referenceType: this.patientForm
          ? NudgeReferenceType.PatientForm
          : this.patientDocument?.isClinicDocument
          ? NudgeReferenceType.PatientClinicDocument
          : NudgeReferenceType.PatientDocument,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  saveForm() {
    this.loading = true;
    this.patientFormService.savePatientForm().subscribe(() => {
      this.dialogRef.close();
      this.loading = false;
    });
  }

  async downloadDoc() {
    let patient = this.patientService.patientPanelPatient;
    let patientName = patient.firstName + patient.lastName;
    let dateString = moment().format('YYYY-MM-DD-HH-mm');

    if (this.patientForm) {
      this.loading = true;

      if (this.isUnassigned) {
        await this.patientFormService.savePatientForm().toPromise();
        this.isUnassigned = false;
      }

      let formName = this.patientForm.clinicForm.name.replace(/\s/g, '');
      let fileName = `${patientName}-${formName}-${dateString}.pdf`;

      this.patientFormService
        .createFileUrl()
        .pipe(takeUntil(this.unsub))
        .subscribe((fileUrl) => {
          this.downloadURL(fileUrl, fileName);
          this.loading = false;
          URL.revokeObjectURL(fileUrl);
        });
    } else if (this.patientDocument) {
      let fileName = `${patientName}-${this.patientDocument.name}-${dateString}.pdf`;
      if (this.patientDocument?.pdfToHtmlUrl) {
        this.downloadURL(this.patientDocument.pdfToHtmlUrl, fileName);
      } else {
        this.downloadURL(this.patientDocument.filePath, fileName);
      }
    }
  }

  private downloadURL(url: string, fileName: string) {
    let a = document.createElement('a');
    a.style.display = 'none';
    a.target = '_blank';
    a.rel = 'noreferrer noopener';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  async printDoc() {
    this.loading = true;
    if (this.patientForm) {
      if (this.isUnassigned) {
        await this.patientFormService.savePatientForm().toPromise();
        this.isUnassigned = false;
      }

      this.patientFormService
        .createFileUrl()
        .pipe(takeUntil(this.unsub))
        .subscribe((fileUrl) => {
          this.printURL(fileUrl);
          URL.revokeObjectURL(fileUrl);
          this.loading = false;
        });
    } else if (this.patientDocument) {
      this.printFrame();
      this.loading = false;
    }
  }

  async emailDocument() {
    this.loading = true;

    if (this.isUnassigned) {
      await this.patientFormService.savePatientForm().toPromise();
      this.isUnassigned = false;
    }

    this.patientService.sendDocumentsToComplete(this.patientId).subscribe(
      () => {
        this.loading = false;
        this.dialog.open(GenericDialogComponent, {
          data: {
            content: 'Document sent to Patient',
            confirmButtonText: 'Ok',
            showCancel: false,
          },
        });
      },
      (error) => {
        this.loading = false;
        if (error.error && error.error.errors) {
          const errors = error.error.errors;
          var errorMessage = errors[0].fieldErrors[0];
          this.dialog.open(GenericDialogComponent, {
            data: {
              title: 'Failed to Send',
              content: '<div class="text-danger">' + errorMessage + '</div>',
              confirmButtonText: 'Ok',
              showCancel: false,
            },
          });
        }
      }
    );
  }

  private printFrame() {
    let iframe = <HTMLIFrameElement>document.getElementsByClassName('formio-iframe')[0];
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
  }

  private printURL(url: string) {
    let iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
  }

  exitKiosk() {
    let passcodeModal = this.modalService.open(PasscodeComponent, {
      centered: true,
    });
    passcodeModal.componentInstance.patientId = this.patientId;
  }

  ngOnDestroy() {
    this.patientDocumentService.documentSelected.next(null);
    this.patientDocumentService.documentsTabInitialized$.next(false);
    this.patientFormService.patientFormSelected$.next(null);
    this.patientFormService.formDirty$.next(false);
    this.unsub.next();
    this.unsub.complete();
  }
}
