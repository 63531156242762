import { Appointment } from '@models/scheduler/event';
import { Pipe, PipeTransform } from '@angular/core'; 
import * as moment from 'moment';

//For converting appointment date + start time to a local date object with time data for display purposes
@Pipe({ name: 'txDate' })
export class AppointmentDatePipe implements PipeTransform {
  constructor( ) {}

  transform(appointment: Appointment) { 

    return appointment.dateTime ? appointment.dateTime : moment(appointment.date).add(appointment.startTime).toDate();
  }
}
