import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { isNullOrUndefined } from '@app/shared/helpers';
import { ColourVariables } from '@models/constants/colour-variables';
import { ObrPlottingEventType } from '@models/observation/obr-plotting-event-type';
import { Observation, ObservationTypes } from '@models/observation/observation';
import { Patient } from '@models/patient';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { TreatmentType } from '@models/treatment-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService } from '@services/actionpanel.service';
import { PatientService } from '@services/patient.service';
import { PlottingEventService } from '@services/plotting-event.service';
import { CoolsculptingFormService } from '@services/service-detail/coolsculpting-form.service';
import { CoolToneFormService } from '@services/service-detail/cooltone-form.service';
import { InjectionFormService } from '@services/service-detail/injection-form.service';
import { ToolbarEventService } from '@services/service-detail/toolbar-event.service';
import { ServicesService } from '@services/services.service';
import 'beautifymarker';
import * as L from 'leaflet';
import {
  CRS,
  Control,
  FeatureGroup,
  LatLngBounds,
  Layer,
  LayerGroup,
  featureGroup,
  icon,
  imageOverlay,
  latLngBounds,
  layerGroup,
  marker
} from 'leaflet';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddPhotoComponent } from '../../../../patient-photos-tab/add-photo/add-photo.component';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  service: Service;

  // Map properties
  map: L.Map;
  mapOptions: L.MapOptions;
  markerGroup: LayerGroup;
  imgBounds: LatLngBounds = latLngBounds([
    [0, 0],
    [800, 750],
  ]);
  drawLayer: FeatureGroup;
  drawControl: Control.Draw;
  drawOptions: any;
  showDrawToolbar: boolean;
  pinDropActive: boolean;
  markerColours: string[];
  colourIndex: number;
  showTooltips: boolean;
  previousTooltips: boolean;
  colourVariables = new ColourVariables();
  private imageOverlay: L.ImageOverlay;

  // Observation properties
  currentObr: Observation;
  obrToSend: Map<number, Observation>;
  annotationToSend = new Map<number, any>();
  isCoolsculpting: boolean;
  isCoolTone: boolean;
  isUndoActive: boolean;
  serviceDetailTemplate = ServiceDetailTemplate;

  // Plotted points
  treatmentMap: Map<string, Map<number, Observation>> = new Map();
  fillerColourIndex: number;
  fillerColourMap: Map<string, string> = new Map();
  unsub: Subject<void> = new Subject<void>();

  get patient(): Patient {
    return this.patientService.patientPanelPatient;
  }
  set patient(value: Patient) {
    this.patientService.patientPanelPatient = value;
  }

  constructor(
    private plottingEventService: PlottingEventService,
    private injectionFormService: InjectionFormService,
    private servicesService: ServicesService,
    private modalService: NgbModal,
    private toolbarEventService: ToolbarEventService,
    private coolsculptingFormService: CoolsculptingFormService,
    private coolToneFormService: CoolToneFormService,
    private patientService: PatientService,
    private actionPanelService: ActionPanelService
  ) {
    this.pinDropActive = false;
    this.showTooltips = true;

    this.injectionFormService.initObservationUnits(null);

    this.plottingEventService
      .getObservationSource()
      .pipe(takeUntil(this.unsub))
      .subscribe((response) => {
        switch (response.event) {
          case ObrPlottingEventType.StartPinDrop: {
            this.markerGroup.eachLayer((layer: Layer) => {
              // @ts-ignore
              layer.dragging.disable();
            });
            this.obrToSend = new Map<number, Observation>();
            this.currentObr = response.observation;
            this.isCoolsculpting = this.currentObr.name === TreatmentType.Coolsculpting;
            this.isCoolTone = this.currentObr.name === TreatmentType.CoolTone;
            if (this.isCoolsculpting) {
              // Place the marker if there isn't already a marker for the current obr details
              const key = this.coolsculptingFormService.onGetMapKey(this.currentObr);
              this.onAddCoolsculptMarker(isNullOrUndefined(this.treatmentMap.get(key)));
              this.onHandleCoolsculptingTooltip(this.currentObr, key);
            } else if (this.isCoolTone) {
              const key = this.coolToneFormService.getObsKey(this.currentObr);
              this.onAddCoolToneMarker(isNullOrUndefined(this.treatmentMap.get(key)));
            } else {
              this.pinDropActive = true;
              this.previousTooltips = this.showTooltips;
              if (this.previousTooltips) {
                this.onToggleTooltips();
              }
            }
            break;
          }
          case ObrPlottingEventType.StopPinDrop: {
            this.pinDropActive = false;
            this.markerGroup.eachLayer((layer: Layer) => {
              // @ts-ignore
              layer.dragging.enable();
            });
            // Increase the colour index so that the next time we are dropping points it will be a different colour
            if (this.currentObr.name !== TreatmentType.Toxins) {
              ++this.colourIndex;
            }
            if (this.previousTooltips) {
              this.onToggleTooltips();
            }
            this.plottingEventService.sendPlotDetails(this.obrToSend);
            break;
          }
          case ObrPlottingEventType.PlotObservations: {
            this.obrToSend = new Map<number, Observation>();
            if (this.service.isLocked) {
              this.map.dragging.disable();
            }
            this.initializeFillerColoursFromExistingObservations(response.observations);
            this.onPlotObservation(response.observations);
            break;
          }
          case ObrPlottingEventType.DeletePoints: {
            this.onDeletePoints(response.leafletIds);
            if (!isNullOrUndefined(this.treatmentMap.get(response.mapKey))) {
              this.treatmentMap.delete(response.mapKey);
            }
            break;
          }
          default: {
            break;
          }
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.map.invalidateSize();
    this.fitImage();
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.service.servicePhotoPath)) {
      this.isUndoActive = !this.service.servicePhotoPath.includes('../../');
    }
    this.setIconColours();
    this.setDrawOptions();
    this.setMapOptions();
    this.servicesService.servicePhotoUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (this.service.serviceId === this.servicesService.servicePhotoService.serviceId) {
        this.service.servicePhotoPath = this.servicesService.servicePhotoFilePath;
        this.setMapImage(this.service.servicePhotoPath);
      }
    });
    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.patient = patient;
    });
  }

  ngAfterViewInit() {
    const actionPanelOpened$ = this.actionPanelService.actionPanelVisible().pipe(takeUntil(this.unsub));
    actionPanelOpened$.subscribe(() => {
      setTimeout(() => {
        if (this.map) this.map.invalidateSize(); //if this is never reached it means the panel opened fully before onInit completed so we don't need to wait until this completes to invalidate the size
      });
    });

    if (this.service.serviceDetailTemplateId === this.serviceDetailTemplate.Injections) {
      if (this.colourIndex === 0) {
        // Increase colourIndex because the first colour is reserved for fillers
        this.colourIndex = 1;
      }
    }

    this.configureMap();

    this.servicesService
      .getServiceById(this.service.serviceId)
      .pipe(takeUntil(this.unsub))
      .subscribe((service: Service) => {
        this.service = service;
        this.setMapImage(service.servicePhotoPath);
      });
  }

  private async setMapImage(imgSrc: string) {
    if (imgSrc) {
      if (this.service.serviceDetailTemplateId === this.serviceDetailTemplate.CoolTone) {
        const [width, height] = await this.loadImageDimensions(imgSrc);
        this.imgBounds = latLngBounds([
          [0, 0],
          [height, width],
        ]);
      }
      this.isUndoActive = !imgSrc.includes('../../');
      if (this.map.hasLayer(this.imageOverlay)) {
        this.map.removeLayer(this.imageOverlay);
      }
      this.imageOverlay = imageOverlay(imgSrc, this.imgBounds);
      this.map.addLayer(this.imageOverlay);
      this.fitImage();
    }
  }

  private loadImageDimensions(src: string): Promise<[number, number]> {
    return new Promise((resolve, reject) => {
      const imageElement = new Image();
      imageElement.onload = () => {
        resolve([imageElement.width, imageElement.height]);
        imageElement.remove();
      };
      imageElement.onerror = reject;
      imageElement.src = src;
    });
  }

  private setMapOptions() {
    let isIpad = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;

    const imageLayer = imageOverlay('', this.imgBounds);
    this.mapOptions = {
      layers: [imageLayer],
      minZoom: -50,
      maxZoom: 50,
      zoomSnap: 0,
      scrollWheelZoom: false,
      zoomControl: false,
      tap: !isIpad, // ref https://github.com/Leaflet/Leaflet/issues/7255
      touchZoom: false,
      doubleClickZoom: false,
      attributionControl: false,
      crs: CRS.Simple,
      dragging: false,
    };
  }

  private setDrawOptions() {
    const annotationColour = this.colourVariables.teal;
    // By default don't show the draw tools
    this.showDrawToolbar = false;
    this.drawLayer = featureGroup();

    this.drawOptions = {
      position: 'bottomright',
      draw: {
        marker: {
          icon: L.BeautifyIcon.icon({
            iconShape: 'circle-dot',
            borderWidth: 7,
            borderColor: annotationColour,
          }),
          repeatMode: true,
        },
        polyline: {
          shapeOptions: {
            color: annotationColour,
          },
        },
        circle: {
          shapeOptions: {
            color: annotationColour,
          },
          repeatMode: true,
        },
        polygon: false,
        rectangle: false,
        circlemarker: false,
      },
      edit: {
        featureGroup: this.drawLayer,
      },
    };
  }

  onDrawReady(control: Control.Draw) {
    this.drawControl = control;
    // Hide the draw toolbar by default
    this.map.removeControl(this.drawControl);
    this.drawLayer.removeFrom(this.map);
  }

  private onPlotObservation(observations: Observation[]) {
    observations.forEach((obr) => {
      this.currentObr = obr;
      if (obr.name === 'Annotation') {
        this.onPlotAnnotation(obr);
      } else {
        if (obr.name === ObservationTypes.Coolsculpting) {
          this.onPlotOval(obr);
          const key = this.coolsculptingFormService.onGetMapKey(obr);
          this.onHandleCoolsculptingTooltip(obr, key);
        } else if (obr.name === ObservationTypes.CoolTone) {
          this.onPlotCoolToneMarker(obr);
        } else {
          this.onPlotPoint(obr);
        }
      }
    });
    if (this.obrToSend.size > 0) {
      this.plottingEventService.sendPlotDetails(this.obrToSend);
    }
    if (this.annotationToSend.size > 0) {
      this.plottingEventService.sendAnnotations(this.annotationToSend);
    }
  }

  private onHandleCoolsculptingTooltip(obr: Observation, key: string) {
    const plotObr = isNullOrUndefined(this.treatmentMap.get(key));
    if (plotObr) {
      this.treatmentMap.set(key, new Map());
    }
    this.treatmentMap.get(key).set(obr.details.plotDetails.leafletId, obr);
    if (!plotObr) {
      // We need to update the "original" obr's tooltip to reflect the additional cycles
      this.markerGroup.eachLayer((layer: Layer) => {
        // @ts-ignore
        const layerKey = this.coolsculptingFormService.onGetMapKey(layer.options.observation);
        if (layerKey === key) {
          let value = parseInt(layer.getTooltip().getContent().toString(), 10);
          value += parseInt(this.currentObr.value, 10);
          layer.setTooltipContent(value.toString());
        }
      });
    }
  }

  private onPlotAnnotation(obr: Observation) {
    // Plot the annotation based on it's type
    const layerType = obr.details.type;
    let leafletId = 0;
    if (layerType === 'circle') {
      const circle = L.circle(obr.details.coords, { ...obr.details.options, obr: obr }).addTo(this.drawLayer);
      // @ts-ignore
      leafletId = circle._leaflet_id;
      obr.details.leafletId = leafletId;
    }
    if (layerType === 'marker') {
      const markerObr = marker(obr.details.coords, {
        icon: L.BeautifyIcon.icon({
          iconShape: 'circle-dot',
          borderWidth: 7,
          borderColor: this.colourVariables.teal,
        }),
        // @ts-ignore
        obr: obr,
      }).addTo(this.drawLayer);
      // @ts-ignore
      leafletId = markerObr._leaflet_id;
      obr.details.leafletId = leafletId;
    }
    if (layerType === 'polyline') {
      const polyline = L.polyline(obr.details.coords, { ...obr.details.options, obr: obr }).addTo(this.drawLayer);
      // @ts-ignore
      leafletId = polyline._leaflet_id;
      obr.details.leafletId = leafletId;
    }
    this.annotationToSend.set(leafletId, obr);
  }

  private onPlotPoint(obr: Observation) {
    const obrMarker = marker([obr.details.plotDetails.coords.lat, obr.details.plotDetails.coords.lng], {
      icon: L.BeautifyIcon.icon({
        iconShape: 'circle-dot',
        borderWidth: 7,
        borderColor: obr.details.plotDetails.pointColor,
      }),
      draggable: !this.service.isLocked,
      // @ts-ignore
      observation: this.currentObr,
    }).addTo(this.markerGroup);

    obrMarker.on('moveend', (e: L.LeafletEvent) => {
      this.onMoveEnd(e.target);
    });

    obrMarker
      .bindTooltip(this.getIconTitle(), { permanent: true, className: 'marker-tooltip', direction: 'top' })
      .openTooltip();
    //@ts-ignore
    obr.details.plotDetails.leafletId = obrMarker._leaflet_id;
    //@ts-ignore
    this.obrToSend.set(obrMarker._leaflet_id, obr);

    if (obr.name !== TreatmentType.Toxins) {
      ++this.colourIndex;
    }
  }

  private onPlotOval(obs: Observation) {
    const leafletId = obs.details.plotDetails.leafletId;
    if (!isNullOrUndefined(leafletId) && leafletId > 0) {
      let colourIndex = !isNullOrUndefined(obs.details.plotDetails.pointColor)
        ? this.getIndexOfColour(obs.details.plotDetails.pointColor)
        : this.colourIndex;

      if (colourIndex >= this.markerColours.length) {
        colourIndex = 0;
      }

      const lat = obs.details.plotDetails.coords.lat;
      const lng = obs.details.plotDetails.coords.lng;
      const obsMarker = this.createObsMarker(lat, lng, obs);

      // Update the point details of the currentObr
      //@ts-ignore
      obs.details.plotDetails.leafletId = (obsMarker as any)._leaflet_id;
      //@ts-ignore
      this.obrToSend.set(obsMarker._leaflet_id, obs);

      if (obs.name !== TreatmentType.Toxins) {
        ++this.colourIndex;
      }
      //@ts-ignore
      this.obrToSend.set(obsMarker._leaflet_id, obs);
      //++this.colourIndex;
    } else {
      this.obrToSend.set(this.onGenerateLeafletId(), obs);
    }
  }

  private onPlotCoolToneMarker(obs: Observation) {
    const obsMarker = this.createCoolToneMarker(obs);
    //@ts-ignore
    obs.details.plotDetails.leafletId = obsMarker._leaflet_id;
    //@ts-ignore
    this.obrToSend.set(obsMarker._leaflet_id, obs);
  }

  private onDeletePoints(leafletIds: number[]) {
    try {
      leafletIds.forEach((leafletId) => {
        this.markerGroup.removeLayer(leafletId);
      });
    } catch {
      // Do nothing, the marker didn't exist on the map
    }
  }

  onMapReady(map: L.Map) {
    this.map = map;
    this.markerGroup = layerGroup().addTo(this.map);
  }

  private configureMap() {
    this.drawLayer.addTo(this.map);
    this.fitImage();
  }

  private fitImage() {
    this.map.fitBounds(this.imgBounds, { maxZoom: 50, animate: false });
  }

  onSetPhoto() {
    const modalRef = this.modalService.open(AddPhotoComponent, { windowClass: 'add-photo-modal', centered: true });
    (modalRef.componentInstance as AddPhotoComponent).isServicePhoto = true;
    (modalRef.componentInstance as AddPhotoComponent).service = this.service;
  }

  onAnnotate() {
    this.showDrawToolbar = !this.showDrawToolbar;
    if (this.showDrawToolbar) {
      this.drawLayer.addTo(this.map);
      this.map.addControl(this.drawControl);
    } else {
      this.map.removeControl(this.drawControl);
      this.drawLayer.removeFrom(this.map);
    }
  }

  onUndo() {
    let filePathPart: string;
    if (this.service.serviceDetailTemplateId === this.serviceDetailTemplate.Injections) {
      filePathPart = this.patient.gender.toLowerCase() === 'female' ? 'FemaleInjectables' : 'MaleInjectables';
    } else if (this.service.serviceDetailTemplateId === this.serviceDetailTemplate.Coolsculpting) {
      filePathPart = this.patient.gender.toLowerCase() === 'female' ? 'FemaleCoolsculpting' : 'MaleCoolsculpting';
    } else if (this.service.serviceDetailTemplateId === this.serviceDetailTemplate.CoolTone) {
      filePathPart = this.patient.gender.toLowerCase() === 'female' ? 'FemaleCoolTone' : 'MaleCoolTone';
    } else if (this.service.serviceDetailTemplateId === this.serviceDetailTemplate.Deoxycholate) {
      filePathPart = 'Deoxycholate';
    }
    this.servicesService.servicePhotoFilePath = '../../../../assets/service-detail/' + filePathPart + '.jpg';
    this.service.servicePhotoPath = '../../../../assets/service-detail/' + filePathPart + '.jpg';
    this.servicesService.servicePhotoIsUpdated(this.service);
  }

  onToggleTooltips() {
    this.showTooltips = !this.showTooltips;
    this.map.eachLayer((l) => {
      if (!this.showTooltips) {
        const toolTip = l.getTooltip();
        if (toolTip) {
          this.map.closeTooltip(toolTip);
        }
      } else {
        const toolTip = l.getTooltip();
        if (toolTip) {
          this.map.addLayer(toolTip);
        }
      }
    });
  }

  onMapClick(event: any) {
    if (this.pinDropActive) {
      const iconColour = !isNullOrUndefined(this.currentObr.details.plotDetails.pointColor)
        ? this.currentObr.details.plotDetails.pointColor
        : this.getIconColour(this.currentObr);

      const obrMarker = marker([event.latlng.lat, event.latlng.lng], {
        icon: L.BeautifyIcon.icon({
          iconShape: 'circle-dot',
          borderWidth: 7,
          borderColor: iconColour,
        }),
        draggable: false,
        // @ts-ignore
        observation: this.currentObr,
      }).addTo(this.markerGroup);

      obrMarker.on('moveend', (e: L.LeafletEvent) => {
        this.onMoveEnd(e.target);
      });

      obrMarker
        .bindTooltip(this.getIconTitle(), { permanent: true, className: 'marker-tooltip', direction: 'top' })
        .closeTooltip();

      // Update the point details of the currentObr
      const newObr = JSON.parse(JSON.stringify(this.currentObr));
      newObr.details.plotDetails = {
        //@ts-ignore
        leafletId: obrMarker._leaflet_id,
        coords: obrMarker.getLatLng(),
        pointColor: iconColour,
      };

      this.obrToSend.set(newObr.details.plotDetails.leafletId, newObr);
      this.plottingEventService.updatedPinCount(this.obrToSend.size);
    }
  }

  private onAddCoolsculptMarker(plot: boolean) {
    const colourIndex = this.getColourIndex();
    let lat = 400;
    let lng = 375;
    if (this.currentObr.details.plotDetails.coords) {
      lat = this.currentObr.details.plotDetails.coords.lat;
      lng = this.currentObr.details.plotDetails.coords.lng;
    }
    const newObr = JSON.parse(JSON.stringify(this.currentObr));
    const obrMarker = plot ? this.createObsMarker(lat, lng, newObr) : null;
    newObr.details.plotDetails = {
      leafletId: obrMarker ? (obrMarker as any)._leaflet_id : this.onGenerateLeafletId(),
      coords: obrMarker ? obrMarker.getLatLng() : null,
      pointColor: this.markerColours[colourIndex],
    };

    this.obrToSend.set(newObr.details.plotDetails.leafletId, newObr);
    // Trigger a "TooglePinDrop" because we are only allowing users to drop one pin (currently)
    // This must be in a timeout because we need to give the inital StartPinDrop a chance to bubble through to the services
    setTimeout(() => this.toolbarEventService.togglePinDrop(this.service), 300);
  }

  private getColourIndex() {
    // If there is already a marker with the same mapKey within the marker group we don't want to add
    let colourIndex = !isNullOrUndefined(this.currentObr.details.plotDetails.pointColor)
      ? this.getIndexOfColour(this.currentObr.details.plotDetails.pointColor)
      : this.getIndexOfColour(this.getIconColour(this.currentObr));

    if (colourIndex >= this.markerColours.length) {
      colourIndex = 0;
    }

    if (this.colourIndex >= this.markerColours.length) {
      this.colourIndex = 0;
    }
    return colourIndex;
  }

  private createObsMarker(lat: number, lng: number, observation: Observation) {
    const colourIndex = this.getColourIndex();
    const angle = parseInt(observation.details.angle, 10);
    const obrMarker = marker([lat, lng], {
      icon: icon({
        iconUrl: '../../../../assets/service-detail/coolsculpting-icons/' + colourIndex + '-r' + angle + '.png',
        iconSize: this.getIconSize(angle),
      }),
      draggable: !this.service.isLocked,
      // @ts-ignore
      observation: observation,
    }).addTo(this.markerGroup);
    obrMarker.on('moveend', (e: L.LeafletEvent) => {
      this.onMoveEnd(e.target);
    });
    obrMarker
      .bindTooltip(observation.value.toString(), {
        permanent: true,
        direction: 'center',
        className: 'oval-tooltip',
      })
      .openTooltip();
    this.map.on('zoomend', (event) => {
      const icon = obrMarker.getIcon() as L.Icon;
      icon.options.iconSize = this.getIconSize(angle);
      obrMarker.setIcon(icon);
    });
    return obrMarker;
  }

  private onAddCoolToneMarker(plot: boolean) {
    let obrMarker = null;
    if (plot) {
      obrMarker = this.createCoolToneMarker(this.currentObr);
    }

    const newObr = JSON.parse(JSON.stringify(this.currentObr));
    newObr.details.plotDetails.leafletId = obrMarker ? obrMarker._leaflet_id : this.onGenerateLeafletId();
    newObr.details.plotDetails.coords = obrMarker ? obrMarker.getLatLng() : null;

    this.obrToSend.set(newObr.details.plotDetails.leafletId, newObr);
    // Trigger a "TogglePinDrop" because we are only allowing users to drop one pin (currently)
    // This must be in a timeout because we need to give the inital StartPinDrop a chance to bubble through to the services
    setTimeout(() => this.toolbarEventService.togglePinDrop(this.service), 300);
  }

  private createCoolToneMarker(obs: Observation): L.Marker {
    const areaKey = this.coolToneFormService.getAreaFromObs(obs).name;
    const positionKey = this.coolToneFormService.getPositionAndLocation(obs);
    obs.details.plotDetails.pointColor = this.getIconColour(obs);

    let pos: L.LatLng;
    if (!isNullOrUndefined(obs.details.plotDetails.coords)) {
      pos = new L.LatLng(obs.details.plotDetails.coords.lat, obs.details.plotDetails.coords.lng);
    } else if (this.service.servicePhotoPath.includes('Male')) {
      pos = this.coolToneFormService.maleMarkerPositionMap.get(`${areaKey}/${positionKey}`);
    } else if (this.service.servicePhotoPath.includes('Female')) {
      pos = this.coolToneFormService.femaleMarkerPositionMap.get(`${areaKey}/${positionKey}`);
    } else {
      pos = this.imgBounds.getCenter();
    }

    const obrMarker = marker(pos, {
      icon: L.BeautifyIcon.icon({
        iconSize: [30, 30],
        borderWidth: 6,
        borderColor: obs.details.plotDetails.pointColor,
        isAlphaNumericIcon: true,
        text: positionKey,
        textColor: 'black',
        innerIconStyle: 'margin-top:0;',
      }),
      draggable: !this.service.isLocked,
      // @ts-ignore
      observation: obs,
    }).addTo(this.markerGroup);

    obrMarker.on('moveend', (e: L.LeafletEvent) => {
      this.onMoveEnd(e.target);
    });

    return obrMarker;
  }

  private getIconAnchor(angle: number): L.PointExpression {
    switch (angle) {
      case 0:
        return [13.5, 6];
      case 45:
        return [13.5, 12.5];
      case 135:
        return [12.5, 13.5];
      case 90:
        return [6, 13.5];
    }
  }

  private getIconSize(angle: number): L.PointExpression {
    const zoomScale = this.map.getZoomScale(this.map.getZoom(), 0);
    const scaleFactor = 0.5 + zoomScale;
    switch (angle) {
      case 0:
        return [27 * scaleFactor, 12 * scaleFactor];
      case 45:
        return [27 * scaleFactor, 25 * scaleFactor];
      case 135:
        return [25 * scaleFactor, 27 * scaleFactor];
      case 90:
        return [12 * scaleFactor, 27 * scaleFactor];
    }
  }

  private onMoveEnd(obrMarker: any) {
    this.obrToSend = new Map();
    // @ts-ignore
    const obr = obrMarker.options.observation;
    obr.details.plotDetails.leafletId = obrMarker._leaflet_id;
    obr.details.plotDetails.coords = obrMarker.getLatLng();
    this.obrToSend.set(obrMarker._leaflet_id, obr);
    this.plottingEventService.sendPlotDetails(this.obrToSend);
  }

  onDrawCreated(event: any) {
    const layerType = event.layerType;
    const layer = event.layer;
    this.drawLayer.addLayer(event.layer);
    const options: any = layer.options;
    let coords = layer._latlngs;
    if (isNullOrUndefined(coords)) {
      coords = layer._latlng;
    }
    this.annotationToSend.set(layer._leaflet_id, {
      leafletId: layer._leaflet_id,
      coords: coords,
      options: options,
      type: layerType,
    });
    this.plottingEventService.sendAnnotations(this.annotationToSend);
  }

  onDrawDeleted(event: any) {
    this.plottingEventService.deleteAnnotations([parseInt(Object.keys(event.layers._layers)[0], 10)]);
  }

  setIconColours() {
    this.markerColours = this.colourVariables.getColourList();
    this.colourIndex = 0;
  }

  getIconColour(obr: Observation): string {
    if (obr.name === TreatmentType.Toxins) {
      return this.markerColours[0];
    }

    if (obr.name === TreatmentType.Deoxycholate) {
      return this.markerColours[1];
    }

    if (obr.name === TreatmentType.Fillers) {
      return this.getFillerIconColor(obr);
    }

    if (!isNullOrUndefined(obr.details.applicator) && obr.name === TreatmentType.Coolsculpting) {
      if (
        !isNullOrUndefined(this.coolsculptingFormService.observationListItemsMap.get(obr.details.applicator).colour)
      ) {
        return this.coolsculptingFormService.observationListItemsMap.get(obr.details.applicator).colour;
      }
    }

    if (!isNullOrUndefined(obr.details.area) && obr.name === TreatmentType.CoolTone) {
      const areaColour = this.coolToneFormService.observationListStaticMap.get(obr.details.area).colour;
      if (areaColour) {
        return areaColour;
      }
    }

    if (this.colourIndex >= this.markerColours.length) {
      if (
        this.service.serviceDetailTemplateId === this.serviceDetailTemplate.Injections ||
        this.service.serviceDetailTemplateId === this.serviceDetailTemplate.Deoxycholate
      ) {
        this.colourIndex = 1;
      } else {
        this.colourIndex = 0;
      }
    }

    return this.markerColours[this.colourIndex];
  }

  initializeFillerColoursFromExistingObservations(observations: Observation[]): void {
    if (observations && observations.length) {
      this.resetFillerColourMap();
      observations.forEach((o) => {
        if (o.name === TreatmentType.Fillers) {
          const key = this.getFillerIconColourMapKey(o);
          const currentColour = o.details.plotDetails.pointColor;

          if (!this.fillerColourMap.has(key)) {
            this.fillerColourMap.set(key, currentColour);
          }
        }
      });
    }
  }

  getFillerIconColourMapKey(observation: Observation): string {
    if (observation) {
      const area = observation.details.area;
      const injected = observation.details.injected;
      return area + '' + injected;
    }
  }

  getFillerIconColor(observation: Observation): string {
    const key = this.getFillerIconColourMapKey(observation);

    if (this.fillerColourMap.has(key)) {
      return this.fillerColourMap.get(key);
    } else {
      const fillerColour = this.markerColours[this.colourIndex];
      this.fillerColourMap.set(key, fillerColour);
      ++this.fillerColourIndex;
      if (this.fillerColourIndex >= this.markerColours.length) {
        this.fillerColourIndex = 1;
      }
      return fillerColour;
    }
  }

  resetFillerColourMap(): void {
    this.fillerColourMap = new Map();
    this.fillerColourIndex = 1;
  }

  getIndexOfColour(colour: string): number {
    return this.markerColours.findIndex((c) => c === colour);
  }

  getIconTitle(): string {
    if (this.isCoolsculpting) {
      return this.currentObr.value;
    } else if (this.isCoolTone) {
      return this.coolToneFormService.getPositionAndLocation(this.currentObr);
    } else {
      if (!isNullOrUndefined(this.currentObr.unit)) {
        return this.currentObr.value + this.currentObr.unit.name;
      }
      return this.currentObr.value + this.injectionFormService.observationUnitsMap.get(this.currentObr.unitId)?.name;
    }
  }

  onGenerateLeafletId(): number {
    return Math.floor((Math.random() * 110 + 10) * 100) * -1;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
