import { KeyValue } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { authPermissions } from '@app/auth/auth-permissions';
import { AuthRoleClaimsService } from '@app/auth/auth-role-claims.service';
import { AuthService } from '@app/auth/auth.service';
import { Claim, Role, User } from '@models/user';
import { ClinicsService } from '@services/clinics.service';
import { UsersService } from '@services/users.service';
import { EMPTY, Observable, Subject, concat, forkJoin } from 'rxjs';
import { catchError, map, mergeAll, mergeMap, takeUntil, tap, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-org-user-permissions',
  templateUrl: './org-user-permissions.component.html',
  styleUrls: ['./org-user-permissions.component.less'],
})
export class OrgUserPermissionsComponent implements OnInit {
  users: User[] = [];
  copyUserId: string = null;
  roles: Map<string, string> = new Map();
  roleClaims: Map<string, Claim[]> = new Map();
  roleExpanded: Map<string, boolean> = new Map();
  userRoles: Map<string, string[]> = new Map();
  userClaims: Map<string, string[]> = new Map();
  errorMessage: string = null;
  loading = false;
  unsub: Subject<void> = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private authRoleClaimsService: AuthRoleClaimsService,
    private authService: AuthService,
    private clinicsService: ClinicsService
  ) {}

  ngOnInit(): void {
    this.usersService.refreshRequired$.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (this.usersService.refreshRequired$) {
        this.loadData();
      }
    });

    this.clinicsService.clinicIdSelected$
      .asObservable()
      .pipe(takeUntil(this.unsub))
      .subscribe(() => {
        this.loadData();
      });
  }

  get selectedClinicId() {
    return this.clinicsService.clinicIdSelected$.getValue();
  }

  loadData() {
    this.loading = true;
    this.roles.clear();
    this.roleExpanded.clear();
    this.roleClaims.clear();
    this.userRoles.clear();

    const getRolesRequest = this.authRoleClaimsService.getRoles().pipe(
      mergeAll(),
      mergeMap((role) =>
        this.authRoleClaimsService
          .getRoleClaims(role.id)
          .pipe(map<Claim[], [Role, Claim[]]>((claims) => [role, claims]))
      ),
      toArray()
    );

    const userRequestMethod =
      this.selectedClinicId === 0 ? this.usersService.getDevUsers() : this.usersService.getUsers(true);
    const getUsersRequest = userRequestMethod.pipe(
      tap((users) => (this.users = users)),
      mergeAll(),
      mergeMap((user) =>
        forkJoin([
          this.authRoleClaimsService.getUserRoles(user.id),
          this.authRoleClaimsService.getUserClaims(user.id),
        ]).pipe(map<[string[], Claim[]], [User, [string[], Claim[]]]>((rolesAndClaims) => [user, rolesAndClaims]))
      ),
      toArray()
    );

    forkJoin([getRolesRequest, getUsersRequest]).subscribe(([roleClaims, userRolesAndClaims]) => {
      roleClaims.forEach((roleClaim) => {
        const [role, claims] = roleClaim;
        this.roles.set(role.id, role.name);
        this.roleClaims.set(role.id, claims);
        this.roleExpanded.set(role.id, false);
      });
      userRolesAndClaims.forEach((userRoleClaim) => {
        const [user, [userRoleStrings, userClaims]] = userRoleClaim;
        const userRoleIds = roleClaims
          .map((roleClaim) => roleClaim[0])
          .filter((role) => userRoleStrings.includes(role.name))
          .map((role) => role.id);
        const userClaimValues = userClaims.map((claim) => claim.value);
        this.userRoles.set(user.id, userRoleIds);
        this.userClaims.set(user.id, userClaimValues);
      });
      this.loading = false;
    });
  }

  userHasRole(userId: string, roleId: string) {
    return this.userRoles.get(userId)?.includes(roleId);
  }

  toggleUserRole(user: User, roleId: string) {
    const roleClaims = this.roleClaims.get(roleId);
    const updateRequests = [];
    if (this.userHasRole(user.id, roleId)) {
      updateRequests.push(this.removeRole(user, roleId));
      for (const claim of roleClaims) {
        if (this.userHasClaim(user.id, claim.value)) {
          updateRequests.push(this.removeClaim(user, claim.value));
        }
      }
    } else {
      updateRequests.push(this.setRole(user, roleId));
      for (const claim of roleClaims) {
        if (!this.userHasClaim(user.id, claim.value)) {
          updateRequests.push(this.setClaim(user, claim.value));
        }
      }
    }
    concat(...updateRequests)
      .pipe(
        mergeMap(() => {
          return this.usersService.loggedInUser.id === user.id
            ? this.authService.refreshTokenRequest('emilyEMRActiveUserRefresh')
            : EMPTY;
        })
      )
      .subscribe();
  }

  private setRole(user: User, roleId: string): Observable<void> {
    let userRoleIds = this.userRoles.get(user.id);
    userRoleIds = userRoleIds.concat(roleId);
    this.userRoles.set(user.id, userRoleIds);
    return this.authRoleClaimsService
      .updateUserRoles(
        user,
        userRoleIds.map((roleId) => this.roles.get(roleId))
      )
      .pipe(
        catchError((err) => {
          userRoleIds = userRoleIds.filter((userRole) => userRole !== roleId);
          this.userRoles.set(user.id, userRoleIds);
          this.errorMessage = `Could not set <b>${this.roles.get(roleId)}</b> role for <b>${
            user.firstName + ' ' + user.lastName
          }</b>.`;
          return EMPTY;
        })
      );
  }

  private removeRole(user: User, roleId: string): Observable<void> {
    let userRoleIds = this.userRoles.get(user.id);
    userRoleIds = userRoleIds.filter((userRole) => userRole !== roleId);
    this.userRoles.set(user.id, userRoleIds);
    return this.authRoleClaimsService
      .updateUserRoles(
        user,
        userRoleIds.map((roleId) => this.roles.get(roleId))
      )
      .pipe(
        catchError((err) => {
          userRoleIds = userRoleIds.concat(roleId);
          this.userRoles.set(user.id, userRoleIds);
          this.errorMessage = `Could not remove <b>${this.roles.get(roleId)}</b> role for <b>${
            user.firstName + ' ' + user.lastName
          }</b>.`;
          return EMPTY;
        })
      );
  }

  userHasClaim(userId: string, claimValue: string) {
    return this.userClaims.get(userId).some((claim) => claim === claimValue);
  }

  toggleRoleClaim(user: User, roleId: string, claimValue: string) {
    let apiCalls = [];
    if (this.userHasClaim(user.id, claimValue)) {
      apiCalls.push(this.removeClaim(user, claimValue));
    } else {
      apiCalls.push(this.setClaim(user, claimValue));
    }
    if (this.userHasRole(user.id, roleId) && this.userRoleHasNoClaims(user.id, roleId)) {
      apiCalls.push(this.removeRole(user, roleId));
    }
    if (!this.userHasRole(user.id, roleId) && this.userRoleHasPartialClaims(user.id, roleId)) {
      apiCalls.push(this.setRole(user, roleId));
    }
    concat(...apiCalls)
      .pipe(
        mergeMap(() => {
          return this.usersService.loggedInUser.id === user.id
            ? this.authService.refreshTokenRequest('emilyEMRActiveUserRefresh')
            : EMPTY;
        })
      )
      .subscribe();
  }

  private setClaim(user: User, claimValue: string): Observable<void> {
    const currentUserClaims = this.userClaims.get(user.id);
    this.userClaims.set(user.id, currentUserClaims.concat(claimValue));
    let setCall = this.authRoleClaimsService.setUserClaims(user.id, [claimValue]).pipe(map(() => {}));
    if (claimValue === authPermissions.Developer) {
      setCall = setCall.pipe(
        mergeMap(() => {
          user.clinicId = null;
          return this.usersService.updateUser(user, null);
        })
      );
    }
    return setCall.pipe(
      catchError((err) => {
        this.userClaims.set(
          user.id,
          currentUserClaims.filter((userClaim) => userClaim !== claimValue)
        );
        this.errorMessage = `Could not set <b>${claimValue}</b> permission for <b>${
          user.firstName + ' ' + user.lastName
        }</b>.`;
        return EMPTY;
      })
    );
  }

  private removeClaim(user: User, claimValue: string): Observable<any> {
    const currentUserClaims = this.userClaims.get(user.id);
    this.userClaims.set(
      user.id,
      currentUserClaims.filter((userClaim) => userClaim !== claimValue)
    );
    let setCall = this.authRoleClaimsService.removeUserClaim(user.id, claimValue).pipe(map(() => {}));
    if (claimValue === authPermissions.Developer) {
      setCall = setCall.pipe(
        mergeMap(() => {
          user.clinicId = this.usersService.loggedInUserClinic;
          return this.usersService.updateUser(user, null);
        })
      );
    }
    return setCall.pipe(
      catchError((err) => {
        this.userClaims.set(user.id, currentUserClaims.concat(claimValue));
        this.errorMessage = `Could not remove <b>${claimValue}</b> permission for <b>${
          user.firstName + ' ' + user.lastName
        }</b>.`;
        return EMPTY;
      })
    );
  }

  setExpansion(roleId: string, expanded: boolean) {
    this.roleExpanded.set(roleId, expanded);
  }

  userRoleHasPartialClaims(userId: string, roleId: string) {
    return this.roleClaims
      .get(roleId)
      .some((roleClaim) => this.userClaims.get(userId).some((userClaim) => userClaim == roleClaim.value));
  }

  userRoleHasAllClaims(userId: string, roleId: string) {
    return this.roleClaims
      .get(roleId)
      .every((roleClaim) => this.userClaims.get(userId).some((userClaim) => userClaim == roleClaim.value));
  }

  userRoleHasNoClaims(userId: string, roleId: string) {
    return this.roleClaims
      .get(roleId)
      .every((roleClaim) => !this.userClaims.get(userId).some((userClaim) => userClaim == roleClaim.value));
  }

  valueAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return a.value.localeCompare(b.value);
  };

  onCopyClick(user: User) {
    if (this.copyUserId === user.id) {
      this.copyUserId = null;
    } else if (this.copyUserId) {
      const fromUserRoles = this.userRoles.get(this.copyUserId);
      this.userRoles.set(user.id, fromUserRoles);
      const roleUpdate = this.authRoleClaimsService.updateUserRoles(
        user,
        fromUserRoles.map((roleId) => this.roles.get(roleId))
      );
      const toUserClaims = this.userClaims.get(user.id);
      const fromUserClaims = this.userClaims.get(this.copyUserId);
      const claimsToRemove = toUserClaims
        .filter((toClaim) => !fromUserClaims.includes(toClaim))
        .map((claim) => this.removeClaim(user, claim));
      const claimsToAdd = fromUserClaims
        .filter((fromClaim) => !toUserClaims.includes(fromClaim))
        .map((claim) => this.setClaim(user, claim));

      concat(...claimsToRemove, ...claimsToAdd, roleUpdate)
        .pipe(
          mergeMap(() => {
            return this.usersService.loggedInUser.id === user.id
              ? this.authService.refreshTokenRequest('emilyEMRActiveUserRefresh')
              : EMPTY;
          })
        )
        .subscribe();
      this.copyUserId = null;
    } else {
      this.copyUserId = user.id;
    }
  }
}
