import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppointmentViewModel } from '@models/appintment-viewmodel';
import * as moment from 'moment';

export enum ScheduleView {
  Appointments = 'Appointments',
  NoShowAppointments = 'NoShowAppointments',
  StaffSchedules = 'StaffSchedules',
}

export enum ScheduleMode {
  AgendaDay = 'agendaDay',
  AgendaWeek = 'agendaWeekFive',
}

@Injectable()
export class EventsService {
  scheduleView = ScheduleView.Appointments;
  scheduleMode = ScheduleMode.AgendaDay;
  planningMode: boolean;
  movingAppointment: boolean;
  selectedPref: string;
  selectedIcon;
  preferredAppoints: any[] = [];
  blockedScheduleMode = false;

  // Observable string sources
  private componentMethodCallSource = new Subject<any>();
  private closePanelCallSource = new Subject<any>();
  private updateCreateVisitTimeProviderSource = new BehaviorSubject<any>(null);
  private removeAppointmentCallSource = new Subject<any>();
  private movingAppointmentCallSource = new Subject<any>();
  private scheduleViewCallSource = new Subject<any>();
  private scheduleModeChangedSource = new Subject<ScheduleMode>();

  appointmentUpdated = new Subject<any>();
  appointmentAdded = new Subject<any>();
  appointmentRemoved = new Subject<any>();
  closeCreateVisitPanel = new Subject<any>();
  public actionPanelOpened = new Subject<any>();

  appointmentUpdatedListener = this.appointmentUpdated.asObservable();
  appointmentAddedListener = this.appointmentAdded.asObservable();
  appointmentRemovedListener = this.appointmentRemoved.asObservable();
  closeCreateVisitPanelListener = this.closeCreateVisitPanel.asObservable();
  actionPanelOpenedListener = this.actionPanelOpened.asObservable();
  scheduleModeChangedListener = this.scheduleModeChangedSource.asObservable();

  private patientListUpdatedSource = new Subject<any>();

  // Observable string streams
  closeSidePanel$ = this.closePanelCallSource.asObservable();
  updateCreateVisitTimeProvider$ = this.updateCreateVisitTimeProviderSource.asObservable();
  removeAppointment$ = this.removeAppointmentCallSource.asObservable();
  movingAppointment$ = this.movingAppointmentCallSource.asObservable();
  patientListChanged$ = this.patientListUpdatedSource.asObservable();
  scheduleViewChanged$ = this.scheduleViewCallSource.asObservable();

  now = moment();
  selectedDate = new BehaviorSubject(new Date(Date.now()));
  currentDate = this.selectedDate.asObservable();
  tempEvent: AppointmentViewModel = {
    start: moment().startOf('day'),
    end: moment().startOf('day'),
    resource: null,
    isSelection: false,
  };

  constructor() {}

  setTempEvent(event: AppointmentViewModel) {
    this.tempEvent = event;
    this.updateCreateVisitTimeAndProvider(event.start, event.end, event.resource);
  }

  getTempEvent(): AppointmentViewModel {
    return this.tempEvent;
  }

  setSelectedDate(date: Date) {
    this.selectedDate.next(date);
  }

  patientListUpdated() {
    this.patientListUpdatedSource.next();
  }
  // Service message commands
  callAppointmentListenerMethod() {
    this.componentMethodCallSource.next();
  }
  closePanel(event?: any) {
    this.closePanelCallSource.next(event);
  }
  updateCreateVisitTimeAndProvider(start, end, provider) {
    this.updateCreateVisitTimeProviderSource.next({ start, end, provider });
  }
  callRemoveAppointmentMethod(currentAppointmentId) {
    this.removeAppointmentCallSource.next(currentAppointmentId);
  }
  callMoveAppointmentChangeMethod() {
    this.movingAppointmentCallSource.next(null);
  }
  cancelMoveAppointment() {
    this.movingAppointment = false;
    this.movingAppointmentCallSource.next(null);
  }
  scheduleViewChanged(scheduleView) {
    this.scheduleViewCallSource.next(scheduleView);
  }
  scheduleModeChanged(scheduleMode: ScheduleMode) {
    this.scheduleMode = scheduleMode;
    this.scheduleModeChangedSource.next(scheduleMode);
  }
}
