<div class="h-100 content d-flex flex-column">
  <div class="container-fluid">
    <div class="row py-4 px-2 navy-background">
      <div class="col-xl-6 col-lg-6 col-md-12 d-flex align-items-center py-1">
        <h5 class="white-font text-uppercase m-0 mr-2">Communication</h5>
      </div>
    </div>
  </div>

  <div class="p-4 flex-grow-1 minh-0 d-flex flex-column">
    <div class="d-flex">
      <div class="d-flex">
        <button
          *appAuthGuard="communicationsSummaryPolicy"
          class="btn text-uppercase em-btn-nav-tab mr-2 position-relative"
          [class.selected]="'summary' === selectedBtn"
          (click)="goToSummary()"
        >
          Summary
        </button>
        <button
          *appAuthGuard="communicationsAppointmentsPolicy"
          class="btn text-uppercase em-btn-nav-tab mr-2 position-relative"
          [class.selected]="'Appointments' === selectedBtn"
          (click)="appointments()"
        >
          Appointments
        </button>
        <button
          *appAuthGuard="communicationsRetentionCampaignsPolicy"
          class="btn text-uppercase em-btn-nav-tab mr-2 position-relative"
          [class.selected]="'Retention Campaigns' === selectedBtn"
          (click)="retentionCampaigns()"
        >
          Retention Campaigns
        </button>
        <button
          *appAuthGuard="developerPolicy"
          class="btn text-uppercase em-btn-nav-tab mr-2 position-relative"
          [class.selected]="'Email Templates' === selectedBtn"
          (click)="emailTemplates()"
        >
          Email Templates
        </button>
      </div>
    </div>
    <div class="header-line mb-2"></div>
    <div class="mt-1 content flex-grow-1 minh-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
