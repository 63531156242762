import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddProductComponent } from '@app/management/dialogs/add-product/add-product.component';
import { CategoryType } from '@models/category-type';
import { ClinicProduct } from '@models/clinic-product';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { CatalogueUpdatesService } from '@services/catalogueupdates.service';
import { ClinicProductsService } from '@services/clinic-products.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ConfirmDeleteDialogComponent } from '../../dialogs/confirm-delete/confirm-delete.component';
import { ManageCategoriesDialogComponent } from '../../dialogs/manage-categories/manage-categories.component';

@Component({
  selector: 'app-catalogue-products',
  templateUrl: './catalogue-products.component.html',
  styleUrls: ['./catalogue-products.component.less'],
})
export class CatalogueProductsComponent implements OnInit, OnDestroy {
  searchValue = '';
  unsub: Subject<void> = new Subject<void>();
  disableGrid = false;
  loading = false;
  products: ClinicProduct[] = [];
  searchCtrl: FormControl;
  filteredProducts: Observable<ClinicProduct[]>;

  gridView: GridDataResult;
  gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: [],
    },
  };

  formGroup: FormGroup;
  editedRowIndex: number;
  editedDataItem: ClinicProduct;

  constructor(
    private clinicProductsService: ClinicProductsService,
    private catalogueUpdatesService: CatalogueUpdatesService,
    private dialogService: MatDialog,
    private router: Router
  ) {
    this.searchCtrl = new FormControl();
    this.filteredProducts = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map((product) => this.filterProducts(product))
    );
  }

  ngOnInit() {
    this.gridView = {
      data: [],
      total: 0,
    };
    this.loading = true;
    this.catalogueUpdatesService.catalogueUpdated.pipe(takeUntil(this.unsub)).subscribe(() => {
      this.disableGrid = false;
      if (this.catalogueUpdatesService.refreshRequired) {
        this.catalogueUpdatesService.refreshRequired = false;
        this.refreshData();
      }
    });
    this.refreshData();
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }

  filterProducts(name: string) {
    let filterResults: ClinicProduct[] = [];

    if (name !== '') {
      this.gridView = {
        data: this.products.filter((product) => product.displayName.toLowerCase().includes(name.toLowerCase())),
        total: this.products.filter((product) => product.displayName.toLowerCase().includes(name.toLowerCase())).length,
      };
      filterResults = this.products.filter((product) => product.displayName.toLowerCase().includes(name.toLowerCase()));
    } else {
      this.gridView = {
        data: this.products,
        total: this.products.length,
      };
      filterResults = [];
    }
    return filterResults;
  }

  refreshData() {
    this.loading = true;
    this.products = [];
    this.clinicProductsService.getProducts().subscribe((products) => {
      this.products = products;
      this.loadItems();
      this.loading = false;
    });
  }

  onAddClick() {
    this.disableGrid = true;
    const dialogRef = this.dialogService.open(AddProductComponent, {
      width: '450px',
      panelClass: 'add-product-dialog',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const productsToAdd = result.map((prodId) => new ClinicProduct({ productId: prodId }));
        const addProductRequests = productsToAdd.map((prod) => this.clinicProductsService.addProduct(prod));
        forkJoin(addProductRequests).subscribe(() => {
          this.refreshData();
          this.disableGrid = false;
        });
      } else {
        this.disableGrid = false;
      }
    });
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.disableGrid = true;
    this.router.navigate([
      '/management/catalogue/products',
      { outlets: { 'action-panel': ['edit-product', dataItem.category?.name, dataItem.id] } },
    ]);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }) {
    const product: ClinicProduct = formGroup.value;
    if (isNew) {
      this.clinicProductsService.addProduct(product).subscribe(() => {
        this.refreshData();
      });
    } else {
      this.clinicProductsService.updateProduct(product).subscribe(() => {
        this.refreshData();
      });
    }
    sender.closeRow(rowIndex);
  }

  openManageCategoryDialog(): void {
    const dialogRef = this.dialogService.open(ManageCategoriesDialogComponent, {
      width: '600px',
      data: CategoryType.Product,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.refreshData();
    });
  }

  public removeHandler({ dataItem }) {
    const dialogRef = this.dialogService.open(ConfirmDeleteDialogComponent, {
      width: '250px',
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'delete') {
          this.clinicProductsService.removeProduct(dataItem).subscribe(() => {
            this.refreshData();
          });
        }
      });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  loadItems() {
    this.gridView = {
      data: this.products,
      total: this.products.length,
    };
  }
}
