import { PaymentHelper } from '@services/helpers/payment-helper';
import { Component, Input, OnInit } from '@angular/core';
import { SquareCard } from '@models/square/square-card';

@Component({
  selector: 'app-square-card-on-file',
  templateUrl: './square-card-on-file.component.html',
  styleUrls: ['./square-card-on-file.component.less'],
})
export class SquareCardOnFileComponent implements OnInit {
  @Input() card: SquareCard;
  @Input() showSmallBrandLogo: boolean = false;

  PaymentHelper = PaymentHelper;

  constructor() {}

  ngOnInit(): void {}

  getCreditCardString(card: SquareCard) {
    const brand = card.card_brand;
    let result = 'fa-credit-card';
    switch (brand) {
      case 'VISA':
        result = 'Visa';
        break;
      case 'MASTERCARD':
        result = 'Mastercard';
        break;
      case 'AMERICAN_EXPRESS':
        result = 'American Express';
        break;
      case 'DISCOVER':
      case 'DISCOVER_DINERS':
        result = 'Discover';
        break;
      case 'JCB':
        result = 'JCB';
        break;
      case 'INTERAC':
        result = 'Interac';
        break;
    }
    return result;
  }
}
