import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@app/auth/auth.service';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { Address } from '@models/address';
import { Doctor } from '@models/doctor';
import { Patient } from '@models/patient';
import { PatientStatus } from '@models/patient-status.enum';
import { Pharmacy } from '@models/pharmacy';
import { PreviousTreatment } from '@models/treatment-planning/previous-treatment';
import { CommunicationsService } from '@services/communications.service';
import { FormatterService } from '@services/formatter.service';
import { PatientService } from '@services/patient.service';
import { ReviewService } from '@services/review.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditPatientContentComponent } from '../actionpanel/edit-patient-content/edit-patient-content.component';
import { Policies } from '@app/auth/auth-policies';

@Component({
  selector: 'app-patient-profile-detail',
  templateUrl: './patient-profile-detail.component.html',
  styleUrls: ['./patient-profile-detail.component.less'],
})
export class PatientProfileDetailComponent implements OnInit, OnDestroy {
  @Input() patientId: number = 0;
  @Input() showNotes: boolean = true;
  @Input() allowEdit: boolean = true;
  @ViewChild(EditPatientContentComponent) patientContentComponent: EditPatientContentComponent;
  public get patientStatuses(): typeof PatientStatus {
    return PatientStatus;
  }

  thePatient: Patient;
  editPatient: FormGroup;
  patientIdParam = '0';
  addOrEdit = 'Add';
  patientPanelVisible = false;
  loading = false;
  disableGrid = false;
  showNewSocialHistoryField = false;
  editIsValid = false;
  isEditMode = false;
  patientInfoEditable = false;

  selectedPatient: Patient;
  editedPatient: Patient;
  selectedAddress: Address;
  editedAddress: Address;

  boolChoices: string[] = ['Yes', 'No'];
  communicationPreferences: string[] = ['Email', 'Phone', 'Text', 'None'];
  genders: string[] = ['Male', 'Female', 'X'];

  editIsEnabled = false;
  thePharmacies: Pharmacy[] = [];
  theDoctors: Doctor[] = [];

  unsub: Subject<void> = new Subject<void>();

  previousAppointments: PreviousTreatment[];
  futureAppointments: PreviousTreatment[];
  errorMessage: '';
  cardSwipeError;
  reviewManuallyRequested = false;
  clinicHasReviewsEnabled = false;
  latestReviewRequestMessage: string;
  _latestReviewRequestDate: Date;
  set latestReviewRequestDate(date: Date) {
    this._latestReviewRequestDate = date;
    if (date == null) this.latestReviewRequestMessage = 'Patient has not yet received a review request.';
    else {
      this.latestReviewRequestMessage = `Last sent ${moment(date).format('YYYY-MM-DD[, ]h:mma')}`;
    }
  }
  get latestReviewRequestDate(): Date {
    return this._latestReviewRequestDate;
  }

  reviewsRequestPolicy = Policies.reviewsRequest;

  constructor(
    public patientService: PatientService,
    public formatterService: FormatterService,
    private dialog: MatDialog,
    private reviewService: ReviewService,
    public authService: AuthService,
    private communicationService: CommunicationsService
  ) {}

  ngOnInit() {
    this.thePatient = this.patientService.patientPanelPatient;
    this.patientService.editedPatient = this.patientService.patientPanelPatient;
    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient: Patient) => {
      if (patient && patient.patientId == this.patientId) {
        this.thePatient = patient;
        this.patientService.editedPatient = this.thePatient;
      }
    });
    this.getPatientProfile(this.patientId);
    this.reviewService.getClinicReviewSettings(parseInt(localStorage.getItem('clinicId'))).subscribe({
      next: (settings) => {
        this.clinicHasReviewsEnabled = settings.googleReviewsOn;
      },
    });
  }

  ngOnChanges() {
    this.getPatientProfile(this.patientId);
    this.getLatestReview(this.patientId);
  }

  showRequestReviewPopup() {
    let content =
      'Are you sure you want to send a Google Review request to ' +
      this.thePatient.firstName +
      ' ' +
      this.thePatient.lastName +
      '?';
    if (this.latestReviewRequestDate)
      content +=
        '<br />Patient last received a request on ' +
        moment(this.latestReviewRequestDate).format('YYYY-MM-DD[ at ]h:ma') +
        '.';
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '350px',
      data: {
        title: 'Confirm - Google Review Request',
        content: content,
        confirmButtonText: 'Send',
        showCancel: true,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') this.requestReview();
      });
  }

  requestReview() {
    this.reviewManuallyRequested = true;
    this.latestReviewRequestDate = new Date();
    this.loading = true;
    this.reviewService.manuallyRequestPatientReview(this.patientId).subscribe(
      (createdHistories) => {
        this.loading = false;
        if (createdHistories.some((history) => history.status == 'Fail')) {
          this.dialog.open(GenericDialogComponent, {
            width: '350px',
            data: {
              title: 'Review Request Failed',
              content: "One or more of the request methods failed. See the patient's communication tab for details.",
              confirmButtonText: 'OK',
              showCancel: false,
            },
          });
        }
      },
      () => (this.loading = false)
    );
  }

  requestReviewButtonDisabled(): boolean {
    return (
      !this.clinicHasReviewsEnabled ||
      !this.thePatient?.sendReviews ||
      this.reviewManuallyRequested ||
      this.thePatient == null ||
      ((this.thePatient?.email == null || this.thePatient?.email == '') &&
        (this.thePatient?.mobileNumber == null || this.thePatient?.mobileNumber == ''))
    );
  }

  getPatientProfile(patientId) {
    if (patientId > 0) {
      this.patientService
        .getPatientById(patientId)
        .pipe(takeUntil(this.unsub))
        .subscribe((patient) => {
          this.thePatient = patient;
        });
    }
  }

  getLatestReview(patientId) {
    if (patientId > 0)
      this.communicationService.getLatestReviewRequest(this.patientId).subscribe({
        next: (latestRequest) => (this.latestReviewRequestDate = latestRequest ? latestRequest.date : null),
      });
  }

  public getBirthday() {
    return moment(this.thePatient.birthDate).format('YYYY-MM-DD');
  }

  public getAge() {
    const today = new Date();
    const birthDate = new Date(this.thePatient.birthDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age.toString();
  }

  public getGender() {
    if (this.thePatient.gender === 'Female' || this.thePatient.gender === 'F') {
      return 'F';
    } else if (this.thePatient.gender === 'Male' || this.thePatient.gender === 'M') {
      return 'M';
    }
    return this.thePatient.gender;
  }

  public formatVisitDate(treatment: PreviousTreatment) {
    return moment(treatment.date).add(moment.duration(treatment.startTime)).format('YYYY-MM-DD h:mma');
  }

  public savePatientNotes(event: string) {
    this.thePatient.notesAndAlerts = event;
    this.patientService
      .updatePatientNotesAlerts({
        patientId: this.thePatient.patientId,
        notesAndAlerts: event,
      })
      .subscribe();

    this.editIsEnabled = false;
  }

  togglePatientRushNote(toggle: boolean) {
    this.patientService.togglePatientRushNote(this.thePatient.patientId, toggle).subscribe(() => {
      this.thePatient.rushPatientNote = toggle;
    });
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
