<mat-select [disabled]="disabled" name="photoTags" placeholder="Tag">
  <mat-select-trigger>Tags</mat-select-trigger>
  <mat-option disabled>Please select one or more tags.</mat-option>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeToggle matTreeNodePadding>
      <button mat-icon-button></button>
      <mat-checkbox
        class="tree-node-checkbox"
        [checked]="tagSelection.isSelected(node)"
        (change)="leafSelectionToggle(node)"
      >
        {{ node.title }}
      </mat-checkbox>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.title">
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <mat-checkbox
        class="tree-node-checkbox"
        [checked]="descendantsAllSelected(node)"
        [indeterminate]="descendantsPartiallySelected(node)"
        (change)="parentSelectionToggle(node)"
      >
        {{ node.title }}
      </mat-checkbox>
    </mat-tree-node>
  </mat-tree>
</mat-select>
