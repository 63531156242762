<ngx-loading [show]="loading"></ngx-loading>

<div class="component-wrapper">
  <mat-card class="example-container">
    <img mat-card-lg-image [src]="clinicLogoUrl ? clinicLogoUrl : '/assets/svg/emily-logo-final.svg'" />
    <mat-card-subtitle>Sign in to continue.</mat-card-subtitle>

    <mat-card-content>
      <form class="example-form">
        <mat-form-field class="example-full-width">
          <input matInput placeholder="User Name" [formControl]="userNameFormControl" [errorStateMatcher]="matcher" />
          <mat-hint>Enter the user name associated with your account.</mat-hint>
          <mat-error *ngIf="userNameFormControl.hasError('required')">
            User Name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="example-full-width password">
          <input
            matInput
            type="password"
            placeholder="Password"
            [formControl]="passwordFormControl"
            [errorStateMatcher]="matcher"
          />
          <mat-hint>Enter your password.</mat-hint>
          <mat-error *ngIf="passwordFormControl.hasError('required')">
            Password is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-error class="errorBox" [hidden]="!validationError">
          {{ validationErrorMessage }}
        </mat-error>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button (click)="login()">Login</button>
    </mat-card-actions>
  </mat-card>
</div>
