import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Service } from '@models/service/service';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { isNullOrUndefined } from '@app/shared/helpers';
import { PlannedTreatmentMultiple } from '@models/treatment-planning/planned-treatment-multiple';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Invoice } from '@models/invoice/invoice';
import { InvoicesService } from '@services/invoices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceType } from '@models/invoice/invoice-type';
import { PaymentStatus } from '@models/scheduler/payment-status';

export const DATE_FORMAT = {
  parse: {
    dateInput: 'yyyy-MM-dd',
  },
  display: {
    dateInput: 'yyyy-MM-dd',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-planned-treatment-multiple',
  templateUrl: './planned-treatment-multiple.component.html',
  styleUrls: ['./planned-treatment-multiple.component.less'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class PlannedTreatmentMultipleComponent implements OnInit {
  @Input() plannedTreatments: PlannedTreatment[];
  plannedTreatmentsForm: FormGroup;
  serviceList: Service[];
  treatmentState = TreatmentState;
  PaymentStatus = PaymentStatus;
  unsub = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    private invoicesService: InvoicesService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.plannedTreatmentsForm = this.fb.group({
      plannedTreatments: this.fb.array([]),
    });
    this.initFormPlannedTreatments();
  }

  private initFormPlannedTreatments() {
    this.serviceList = [];
    const formArray = <FormArray>this.plannedTreatmentsForm.get('plannedTreatments');
    if (!isNullOrUndefined(this.plannedTreatments)) {
      this.plannedTreatments.forEach((pt) => {
        this.serviceList.push(pt.service);
        formArray.push(
          this.fb.group({
            id: [pt.id],
            rank: [pt.rank, Validators.required],
            service: this.fb.group(pt.service),
            concern: [pt.concern],
            notes: [pt.notes],
            createdDate: [pt.createdDate],
            updatedDate: [pt.updatedDate],
            plannedDate: [pt.treatmentAppointments.length > 0 ? pt.treatmentAppointments[0].dateTime : ''],
            scheduledServiceId: [pt.scheduledServiceId],
            dueDate: [pt.dueDate],
            treatmentState: [pt.treatmentState],
            price: [
              pt.service.governmentBilling
                ? 0
                : pt.service.chargeAmount
                ? pt.service.chargeAmount
                : pt.service.defaultPrice
                ? pt.service.defaultPrice
                : 0,
              Validators.required,
            ],
            paymentStatus: [pt.paymentStatus],
            plannedTreatmentMultipleId: [pt.plannedTreatmentMultipleId],
          })
        );
      });
    }
  }
  public getPlannedTreatmentControls() {
    return (<FormArray>this.plannedTreatmentsForm.get('plannedTreatments')).controls;
  }

  onGoToReturnInvoice(plannedTreatmentMultipleId: number) {
    this.invoicesService
      .getInvoiceByPlannedTreatmentMultipleId(plannedTreatmentMultipleId, InvoiceType.Refund)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        (invoice: Invoice) => {
          // Goto the Invoice
          this.router.navigate(['patientaccounttab/invoice', invoice.id], { relativeTo: this.route.parent.parent });
        },
        (errorResponse: HttpErrorResponse) => {
          console.log(errorResponse.error.errors);
        }
      );
  }
}
