<li (click)="patientCheckInClicked()">
  <span
    class="menu-icon"
    [ngClass]="{ 'menu-icon-expanded': sideNavExpanded }"
    kendoTooltip
    tooltipClass="em-tooltip"
    position="right"
    ><i class="fal fa-clock fa-lg" aria-hidden="true" title="Patient Check In"></i></span
  ><span [ngClass]="{ inactive: !sideNavExpanded }">Patient Check In</span>
</li>
