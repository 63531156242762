import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { isNullOrUndefined } from '@app/shared/helpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PatientService } from '@services/patient.service';
import { EventsService } from '@services/events.service';
import { CurrentDataService } from '@services/currentData.service';
import { ObservationService } from '@services/observation.service';
import { ServicesService } from '@services/services.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { AppointmentService } from '@services/appointments.service';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { Service } from '@models/service/service';
import { Patient } from '@models/patient';
import { ConfirmUnsavedChangesDialogComponent } from '@app/management/dialogs/confirm-unsaved-changes/confirm-unsaved-changes.component';
import { ApptListDialogComponent } from '@app/management/dialogs/appt-list-dialog/appt-list-dialog.component';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';

@Component({
  selector: 'app-service-detail',
  templateUrl: './service-detail.component.html',
  styleUrls: ['./service-detail.component.less'],
})
export class ServiceDetailComponent implements OnInit, OnDestroy {
  public serviceDetailTemplate = ServiceDetailTemplate;

  service: Service;
  isLocked: boolean;
  serviceSubType: string;
  currentDate: Date;
  patient: Patient;
  isConsult: boolean;
  isTreatmentPlanning: boolean;
  unsub: Subject<void> = new Subject<void>();

  constructor(
    public patientService: PatientService,
    private eventsService: EventsService,
    public currentDataService: CurrentDataService,
    private observationsService: ObservationService,
    private servicesService: ServicesService,
    private serviceTemplatesService: ServiceTemplatesService,
    private confirmDialog: MatDialog,
    private dialog: MatDialog,
    private appointmentsService: AppointmentService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private treatmentPlanFormService: TreatmentPlanFormService,
    private treatmentPlanService: TreatmentPlanService
  ) {}

  ngOnInit() {
    this.isTreatmentPlanning = this.route.snapshot.params.treatmentPlanning === 'true';
    // strange place
    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      let id: string = params['patId'];
      let forceLock = params['forceLock'] && params['forceLock'] == 'true';
      if (id && id !== '_') {
        if (id.includes('_')) {
          this.currentDataService.patientPanelActiveTab = id.split('_')[1];
          id = id.split('_')[0];
        }
        this.patientService
          .getPatientById(id)
          .pipe(takeUntil(this.unsub))
          .subscribe((patient) => {
            // patientPanelPatient is usually set by event that calls patient profile dialog
            // but once you are in the dialog and press F5, page refreshes but patientPanelPatient is not being re-set
            this.patientService.patientPanelPatient = patient;

            this.patient = this.patientService.patientPanelPatient;
            if (isNullOrUndefined(patient.address)) {
              patient.address = {
                address1: '',
                address2: '',
                city: '',
                country: 'Canada',
                postalCode: '',
                province: 'British Columbia',
              };
            }
            // Get the service
            this.isConsult = false;
            this.service = null;
            if (!isNaN(+params.id)) {
              this.servicesService.getServiceById(+params.id).subscribe((service: Service) => {
                this.service = service;
                if (forceLock) this.service.isLocked = true;
                this.isLocked = service.isLocked;

                if (isNullOrUndefined(this.service.servicePhotoPath) || this.service.servicePhotoPath === '') {
                  let filePathPart: string;
                  if (this.service.serviceDetailTemplateId === this.serviceDetailTemplate.Injections) {
                    filePathPart =
                      this.patient.gender.toLowerCase() === 'female' ? 'FemaleInjectables' : 'MaleInjectables';
                  } else if (this.service.serviceDetailTemplateId === this.serviceDetailTemplate.Coolsculpting) {
                    filePathPart =
                      this.patient.gender.toLowerCase() === 'female' ? 'FemaleCoolsculpting' : 'MaleCoolsculpting';
                  } else if (this.service.serviceDetailTemplateId === this.serviceDetailTemplate.CoolTone) {
                    filePathPart = this.patient.gender.toLowerCase() === 'female' ? 'FemaleCoolTone' : 'MaleCoolTone';
                  } else if (this.service.serviceDetailTemplateId === this.serviceDetailTemplate.Deoxycholate) {
                    filePathPart = 'Deoxycholate';
                  }
                  this.servicesService.servicePhotoFilePath =
                    '../../../../assets/service-detail/' + filePathPart + '.jpg';
                  this.service.servicePhotoPath = this.servicesService.servicePhotoFilePath;
                  this.servicesService.servicePhotoIsUpdated(this.service);
                }

                if (this.isTreatmentPlanning && this.service) {
                  this.treatmentPlanService.getAssociatedTreatmentByServiceId(+params.id).subscribe((pt) => {
                    if (pt && pt.scheduledService && pt.scheduledService.isLocked) {
                      this.isLocked = true;
                      this.service.isLocked = true;
                    }
                  });
                }
              });
            } else {
              if (isNullOrUndefined(params.id) || params.id === 'treatmentplan') {
                this.isConsult = true;
              }
            }
          });
      }
    });

    this.patient = this.patientService.patientPanelPatient;
    this.eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe((date) => (this.currentDate = date));
    this.servicesService.servicePhotoUpdated$.pipe(takeUntil(this.unsub)).subscribe(() => {
      if (!isNullOrUndefined(this.service)) {
        this.service.servicePhotoPath = this.servicesService.servicePhotoFilePath;
      }
    });
  }

  public onToggleView() {
    if (!this.currentDataService.treatmentIsDirty || !this.isLocked) {
      this.router.navigateByUrl(
        `/schedule/(action-panel:patient/${this.patient.patientId}_patientprofiletab/patienttabs/patientcharttab/overview)`
      );
    } else {
      const dialogRef = this.confirmDialog.open(ConfirmUnsavedChangesDialogComponent, {
        width: '250px',
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.unsub))
        .subscribe((result) => {
          if (result === 'confirm') {
            this.treatmentPlanFormService.clearUnsavedTreatmentPlan();
            this.location.back();
          }
        });
    }
  }

  // private convertConsultToAppt() {
  //   const dialogRef = this.dialog.open(ApptListDialogComponent, {
  //     width: '330px',
  //     data: {
  //       date: this.currentDate,
  //       patientId: this.patient.patientId,
  //       serviceType: this.serviceSubType,
  //       selectedApptId: null,
  //     },
  //   });

  //   dialogRef
  //     .afterClosed()
  //     .pipe(takeUntil(this.unsub))
  //     .subscribe((result) => {
  //       if (!isNullOrUndefined(result.selectedApptId)) {
  //         if (result.selectedApptId === -1) {
  //           this.makeNewAppointmentFromConsult();
  //         } else {
  //           this.mergeConsultWithSelectedAppt(result.selectedApptId);
  //         }
  //       }
  //     });
  // }

  // private makeNewAppointmentFromConsult() {}

  // private mergeConsultWithSelectedAppt(apptId: number) {
  //   let matchFound = false;
  //   let targetService = null;

  //   this.appointmentsService
  //     .getAppointmentById(apptId)
  //     .pipe(takeUntil(this.unsub))
  //     .subscribe((a) => {
  //       targetService = a;
  //       const currentService = this.service;
  //       if (!isNullOrUndefined(currentService.observations)) {
  //         if (!isNullOrUndefined(targetService.observations)) {
  //           currentService.observations.forEach((currentObs) => {
  //             targetService.observations.forEach((targetObs) => {
  //               if (!matchFound) {
  //                 switch (targetObs.name.toLowerCase()) {
  //                   case 'injectables':
  //                     if (
  //                       targetObs.value === currentObs.value &&
  //                       targetObs.unitId === currentObs.unitId &&
  //                       targetObs.details.area === currentObs.details.area &&
  //                       targetObs.details.toxin === currentObs.details.toxin
  //                     ) {
  //                       matchFound = true;
  //                     }
  //                     break;
  //                   case 'coolsculpting':
  //                     break;
  //                 }
  //               }
  //             });
  //             if (!matchFound) {
  //               const addObs = { ...currentObs };
  //               addObs.serviceId = targetService.serviceId;
  //               addObs.id = 0;
  //               targetService.observations.push(addObs);
  //             }
  //           });
  //         } else {
  //           targetService.observations = [];
  //           currentService.observations.forEach((o) => {
  //             const addObs = { ...o };
  //             addObs.serviceId = targetService.serviceId;
  //             addObs.id = 0;
  //             targetService.observations.push(addObs);
  //           });
  //         }
  //         this.observationsService.addObservations(targetService.observations).subscribe((obs) => {
  //           obs.forEach((o) => {
  //             this.patient.observations.push(o);
  //           });
  //           this.servicesService.updateService(targetService).subscribe(() => {});
  //         });
  //       }
  //       this.onToggleView();
  //     });
  // }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
