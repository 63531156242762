<div class="row panel-container h-100" #panelContainer>
  <!-- Left Layout Column -->
  <div class="h-100 col-xl-3 col-6 d-flex flex-column px-2 left-chart-col">
    <!-- Treatment Plan Overview -->
    <app-treatment-plan-overview
      [isLocked]="false"
      [showProducts]="true"
      [typeOfTab]="TabType.Chart"
      class="flex-1 minh-0 border"
    ></app-treatment-plan-overview>
    <div class="mb-3"></div>

    <!-- Future Treatments -->
    <!-- <app-patient-appointments-list
      [startDate]="todaysDate"
      [endDate]="'3000-01-01' | date"
      [panelHeight]="panelHeight / 4 - 12.5"
    ></app-patient-appointments-list> -->

    <!-- Previous Treatments -->
    <app-patient-appointments-list class="flex-1 minh-0 border"></app-patient-appointments-list>
    <div class="mb-3"></div>

    <!-- Purchased Products -->
    <app-product-purschase-history [isInvoice]="false" class="flex-1 minh-0 border"></app-product-purschase-history>
  </div>

  <!-- Middle Layout Column -->
  <div class="h-100 col-xl-6 col-12 d-flex main-column flex-column px-2 center-chart-col">
    <app-service-charts class="h-100" (chartEdited)="chartEdited($event)"></app-service-charts>
  </div>

  <!-- Right Layout Bar -->
  <div class="h-100 col-xl-3 col-6 d-flex flex-column px-2 right-chart-col">
    <!-- Social History -->
    <app-social-history [panelHeight]="panelHeight / 5 - 12.5"></app-social-history>

    <!-- Prescriptions -->
    <app-prescriptions-widget class="border mt-3" [panelHeight]="panelHeight / 5 - 12.5"></app-prescriptions-widget>

    <!-- External Prescriptions -->
    <app-external-prescriptions [panelHeight]="panelHeight / 5 - 12.5"></app-external-prescriptions>

    <!-- Documents -->
    <app-document-patient-note
      [panelHeight]="panelHeight / 5 - 12.5"
      [documentTypesNames]="['PMHx', 'PSH', 'FMH']"
    ></app-document-patient-note>

    <app-document-filter-list class="border mt-3" style="flex: 1; min-height: 0"></app-document-filter-list>
  </div>
</div>
