import { Component, OnInit, OnDestroy, ViewChild, NgZone, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

import { Subject, Observable, merge } from 'rxjs';
import { takeUntil, take, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { isNullOrUndefined } from '@app/shared/helpers';

import { TreatmentPlan } from '@models/treatment-planning/treatment-plan';
import { ClinicServiceTemplate } from '@models/service/clinic-service-template';
import { TreatmentState } from '@models/treatment-planning/treatment-state';
import { ObservationTypes } from '@models/observation/observation';
import { ServiceListType } from '@models/service/service-list-type';
import { Service } from '@models/service/service';
import { PlannedTreatment } from '@models/treatment-planning/planned-treatment';
import { ServiceNote } from '@models/service/service-note';
import { PatientService } from '@services/patient.service';
import { ServiceTemplatesService } from '@services/service-templates.service';
import { ServicesService } from '@services/services.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { TreatmentPlanFormService } from '@services/treatment-planning/treatment-plan-form.service';
import { ConfirmUnsavedChangesDialogComponent } from '@app/management/dialogs/confirm-unsaved-changes/confirm-unsaved-changes.component';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CreateTreatmentPlanPackageComponent } from '../../../create-treatment-plan-package/create-treatment-plan-package.component';
import { GridComponent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { DataSourceRequestState } from '@progress/kendo-data-query';
import { PlannedTreatmentMultiple } from '@models/treatment-planning/planned-treatment-multiple';
import { InvoicesService } from '@services/invoices.service';
import { InvoiceLineItem } from '@models/invoice/invoice-line-item';
import { Invoice } from '@models/invoice/invoice';
import { UsersService } from '@services/users.service';
import { User } from '@models/user';
import { HttpErrorResponse } from '@angular/common/http';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { CurrentDataService } from '@services/currentData.service';
import { InvoiceType } from '@models/invoice/invoice-type';
import { PaymentStatus } from '@models/scheduler/payment-status';

export const DATE_FORMAT = {
  parse: {
    dateInput: 'yyyy-MM-dd',
  },
  display: {
    dateInput: 'yyyy-MM-dd',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-planned-treatments-list',
  templateUrl: './planned-treatments-list.component.html',
  styleUrls: ['./planned-treatments-list.component.less'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class PlannedTreatmentsListComponent implements OnInit, OnDestroy {
  treatmentPlan: TreatmentPlan;
  unsub = new Subject<any>();
  treatmentPlanForm: FormGroup;
  serviceTemplates: ClinicServiceTemplate[];
  serviceTemplateMultiples: ClinicServiceTemplate[];
  treatmentState = TreatmentState;
  plannedTreatmentsToDelete: number[];
  completedTreatments: PlannedTreatment[];
  observationTypes = ObservationTypes;
  serviceListType = ServiceListType;
  serviceList: Service[];
  loading = false;
  formLoaded = false;
  patientId: number;
  public serviceDetailTemplate = ServiceDetailTemplate;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @ViewChild('instanceMultiples', { static: true }) instanceMultiples: NgbTypeahead;
  @ViewChild('multiplesTypeahead') multiplesInputRef: ElementRef<HTMLInputElement>;
  focus$ = new Subject<string>();
  focusMultiples$ = new Subject<string>();

  @ViewChild('grid') grid;
  plannedTreatmentMultiples: PlannedTreatmentMultiple[];

  gridView: GridDataResult;
  gridState: DataSourceRequestState;
  errors: any[] = [];

  PaymentStatus = PaymentStatus;

  constructor(
    private patientService: PatientService,
    private fb: FormBuilder,
    private serviceTemplatesService: ServiceTemplatesService,
    private servicesService: ServicesService,
    private treatmentPlanService: TreatmentPlanService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmDialog: MatDialog,
    public treatmentPlanFormService: TreatmentPlanFormService,
    private _ngZone: NgZone,
    private createPackageDialog: MatDialog,
    private invoicesService: InvoicesService,
    private usersService: UsersService,
    private currentDataService: CurrentDataService
  ) {
    this.serviceTemplates = [];
    this.serviceTemplateMultiples = [];
    this.plannedTreatmentsToDelete = [];
    this.serviceList = [];
  }

  ngOnInit() {
    this.loading = true;
    this.patientId = +this.route.snapshot.params.patId.split('_')[0];
    this.completedTreatments = [];

    this.patientService.thePatientUpdated$.pipe(takeUntil(this.unsub)).subscribe((patient) => {
      this.getTreatment();
    });

    this.treatmentPlanService.treatmentPlanUpdated$.pipe(takeUntil(this.unsub)).subscribe((tp) => {
      this.treatmentPlan = tp;
      this.plannedTreatmentsToDelete = [];
      this.initForm();
      this.loading = false;
    });

    this.serviceTemplatesService.getServiceTemplateList().subscribe((st) => {
      this.serviceTemplates = st;
    });

    this.serviceTemplatesService.getServiceTemplateMultiples().subscribe((st) => {
      this.serviceTemplateMultiples = st;
    });

    this.getTreatment();
    this.initForm();
  }

  private getTreatment() {
    this.loading = true;
    this.treatmentPlanService.getTreatmentPlanByPatientId(this.patientId).subscribe((tp) => {
      this.treatmentPlan = tp;
      this.initForm();
      this.loading = false;
    });
  }

  private initForm() {
    if (!isNullOrUndefined(this.treatmentPlan)) {
      this.treatmentPlanForm = this.fb.group({
        notes: [this.treatmentPlan.notes],
        plannedTreatments: this.fb.array([]),
      });
      this.initFormPlannedTreatments();
      this.onFormChange();
      this.formLoaded = true;
    }
  }

  private initFormPlannedTreatments() {
    this.serviceList = [];
    const formArray = <FormArray>this.treatmentPlanForm.get('plannedTreatments');
    if (!isNullOrUndefined(this.treatmentPlan.plannedTreatments)) {
      this.treatmentPlan.plannedTreatments.forEach((pt) => {
        // Ignore PlannedTreatmentMultiples
        if (isNullOrUndefined(pt.plannedTreatmentMultipleId)) {
          if (pt.treatmentState === this.treatmentState.Completed) {
            if (!this.completedTreatments.find((ct) => ct.id === pt.id)) {
              this.completedTreatments.push(pt);
            }
          } else {
            if (isNullOrUndefined(pt.service.observations)) {
              pt.service.observations = [];
            }
            this.serviceList.push(pt.service);
            const isRefunded = pt.paymentStatus === PaymentStatus.Refund;
            formArray.push(
              this.fb.group({
                id: [pt.id],
                rank: [pt.rank, Validators.required],
                service: this.fb.group(pt.service),
                concern: [pt.concern],
                notes: [{ value: pt.notes, disabled: isRefunded }],
                createdDate: [pt.createdDate],
                updatedDate: [pt.updatedDate],
                plannedDate: [pt.plannedDate],
                scheduledServiceId: [pt.scheduledServiceId],
                dueDate: [{ value: pt.dueDate, disabled: isRefunded }],
                treatmentState: [pt.treatmentState],
                treatmentAppointments: [pt.treatmentAppointments],
                treatmentPlanId: [this.treatmentPlan.id],
                price: [
                  {
                    value: pt.service.governmentBilling
                      ? 0
                      : pt.service.chargeAmount != null
                      ? pt.service.chargeAmount
                      : pt.service.overrideDefaultPrice != null
                      ? pt.service.overrideDefaultPrice
                      : pt.service.defaultPrice != null
                      ? pt.service.defaultPrice
                      : 0,
                    disabled: isRefunded,
                  },
                  Validators.required,
                ],
                paymentStatus: [pt.paymentStatus],
              })
            );
          }
        }
      });
    } else {
      this.treatmentPlan.plannedTreatments = [];
    }
    this.plannedTreatmentMultiples = this.treatmentPlan.plannedTreatmentMultiples;
    this.plannedTreatmentMultiples.forEach(
      (ptm) => (ptm['partialRefund'] = ptm.plannedTreatments.some((pt) => pt.paymentStatus == PaymentStatus.Refund))
    );
    this.loading = false;
  }

  private onFormChange() {
    this.treatmentPlanForm.valueChanges.subscribe(() => {
      const plannedTreatments: PlannedTreatment[] = [];
      this.getPlannedTreatmentControls().forEach((pt, idx) => {
        const plannedTreatment = new PlannedTreatment(pt.value);
        plannedTreatment.service = this.serviceList[idx];
        if (plannedTreatment.service.serviceDetailTemplateId === this.serviceDetailTemplate.None) {
          plannedTreatment.service.defaultPrice = pt.value.price;
        }
        if (isNullOrUndefined(pt.value.price)) {
          pt.value.price = 0;
        }
        plannedTreatments.push(new PlannedTreatment(plannedTreatment));
      });

      this.treatmentPlan.plannedTreatments = plannedTreatments;
      this.treatmentPlan.notes = this.treatmentPlanForm.value.notes;

      this.treatmentPlanFormService.updateTreatmentsofInterest(
        this.treatmentPlan,
        this.plannedTreatmentsToDelete,
        this.treatmentPlanForm.valid
      );
    });
  }

  public getPlannedTreatmentControls() {
    if (isNullOrUndefined(this.treatmentPlan)) {
      return this.fb.array([]).controls;
    }
    return (<FormArray>this.treatmentPlanForm.get('plannedTreatments')).controls;
  }

  public async onAddService(partialServiceTemplate: ClinicServiceTemplate) {
    const serviceTemplate = await this.serviceTemplatesService
      .getServiceTemplateById(partialServiceTemplate.id)
      .toPromise();
    const newService = this.servicesService.mapServiceFromTemplate(
      serviceTemplate,
      this.patientService.patientPanelPatient
    );
    if (isNullOrUndefined(newService.observations)) {
      newService.observations = [];
    }
    // Save the new service
    let plannedTreatment = await this.treatmentPlanService
      .createPlannedTreatment(this.patientId, newService)
      .toPromise();
    this.serviceList.push(plannedTreatment.service);
    this.treatmentPlanFormService.treatmentPlanUpdated(this.treatmentPlan);
  }

  // private onAddServiceGroup(service: Service) {
  //   (<FormArray>this.treatmentPlanForm.get('plannedTreatments')).push(this.fb.group({
  //     id: [0],
  //     rank: [(<FormArray>this.treatmentPlanForm.get('plannedTreatments')).length + this.completedTreatments.length + 1, Validators.required],
  //     service: this.fb.group(service),
  //     concern: [],
  //     notes: [],
  //     createdDate: [new Date()],
  //     updatedDate: [new Date()],
  //     plannedDate: [null],
  //     scheduledServiceId: [null],
  //     dueDate: [null],
  //     treatmentState: [TreatmentState.Unplanned],
  //     price: [service.governmentBilling ? 0 : service.chargeAmount ? service.chargeAmount : service.defaultPrice ? service.defaultPrice : 0, Validators.required],
  //     isPaid: [false]
  //   }));
  // }

  priceUpdated(plannedTreatment: PlannedTreatment, price: number) {
    if (plannedTreatment && plannedTreatment.scheduledServiceId) {
      let index = this.treatmentPlanFormService.scheduledServicePriceUpdate.findIndex(
        (s) => s.serviceId == plannedTreatment.scheduledServiceId
      );
      if (index != -1) {
        this.treatmentPlanFormService.scheduledServicePriceUpdate[index].price = price;
      } else {
        this.treatmentPlanFormService.scheduledServicePriceUpdate.push({
          appointmentId: 0,
          serviceId: plannedTreatment.scheduledServiceId,
          price,
        });
      }
    }
  }

  async updatePlannedTreatment(plannedTreatment: PlannedTreatment, priceInput = null) {
    this.loading = true;

    this.currentDataService.setPreventUserFromNavigation(true);
    if (plannedTreatment.scheduledService && plannedTreatment.scheduledService.observations)
      delete plannedTreatment.scheduledService.observations;
    if (plannedTreatment.service && plannedTreatment.service.observations) delete plannedTreatment.service.observations; //the underlying service is only updated if it has observations - so its done elsewhere

    if (priceInput && priceInput.value) {
      plannedTreatment.service.overrideDefaultPrice = priceInput.value;
    }
    await this.treatmentPlanService.updatePlannedTreatment(plannedTreatment).toPromise();
    this.currentDataService.setPreventUserFromNavigation(false);
    this.loading = false;
  }

  async saveNotes(event) {
    let notes: string = event && event.target && event.target.value ? event.target.value : '';
    await this.treatmentPlanService.updatePatientTreatmentPlanNotes(notes, this.patientId).toPromise();
  }

  public async onDeleteService(index: number, plannedTreatment: PlannedTreatment) {
    if (plannedTreatment.id > 0 && (await this.verifyRemovalOfPlannedTreatment().toPromise())) {
      // The service has been saved in the database so we must remove it from the database as well
      // this.treatmentPlanFormService.treatmentPlanDirty = true;
      // this.plannedTreatmentsToDelete.push(plannedTreatment.id)
      await this.treatmentPlanService.deletePlannedTreatments([plannedTreatment.id]).toPromise();

      (<FormArray>this.treatmentPlanForm.get('plannedTreatments')).removeAt(index);
      this.serviceList.splice(index, 1);
    }
  }

  private verifyRemovalOfPlannedTreatment(): Observable<boolean> {
    const dialogRef = this.createPackageDialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        title: 'Warning',
        content: 'Are you sure you want to remove this planned treatment?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        showCancel: true,
      },
    });

    return dialogRef.afterClosed().pipe(
      takeUntil(this.unsub),
      map((result) => {
        if (result === 'confirm') {
          return true;
        }
        return false;
      })
    );
  }

  onGetServiceNotes(index: number): ServiceNote[] {
    return this.serviceList[index].serviceNotes ? this.serviceList[index].serviceNotes : [];
  }

  onViewServiceDetail(pt: PlannedTreatment) {
    let service = pt.service;
    const treatmentFromToday = true;
    const treatmentPlanning = true;
    this.router.navigate(
      [
        'detail',
        service.serviceId,
        treatmentFromToday,
        treatmentPlanning,
        service.isLocked || pt.paymentStatus != PaymentStatus.Unpaid,
      ],
      {
        relativeTo: this.route.parent,
      }
    );
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  public serviceTemplatesInputFormatter = (x: { serviceName: string }) => null;

  public serviceTemplatesResultFormatter = (x: { serviceName: string }) => x.serviceName;

  public searchServiceTemplates = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        term === ''
          ? this.serviceTemplates
          : this.serviceTemplates.filter((v) => v.serviceName.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );
  };

  public searchServiceTemplateMultiples = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocusMultiples$ = this.focusMultiples$;
    return merge(debouncedText$, inputFocusMultiples$).pipe(
      map((term) =>
        term === ''
          ? this.serviceTemplateMultiples
          : this.serviceTemplateMultiples.filter((v) => v.serviceName.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );
  };

  public onCreatePackage(serviceTemplate: ClinicServiceTemplate) {
    this.multiplesInputRef.nativeElement.blur();
    const dialogRef = this.createPackageDialog.open(CreateTreatmentPlanPackageComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: {
        patientId: this.patientId,
        serviceTemplateMultiples: this.serviceTemplateMultiples,
        serviceTemplate: serviceTemplate,
        treatmentPlanId: this.treatmentPlan.id,
        treatmentPlan: this.treatmentPlan,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.treatmentPlanService.treatmentPlanUpdated$.next(this.treatmentPlan);
      }
    });
  }

  onDeleteMultiple(plannedTreatmentMultiple: PlannedTreatmentMultiple) {
    // TODO: Can't delete those that have been scheduled or Paid
    const dialogRef = this.confirmDialog.open(GenericDialogComponent, {
      width: '330px',
      data: {
        title: 'Delete this package?',
        content: 'Are you sure you want to delete this package of multiples?',
        confirmButtonText: 'Yes',
        showCancel: true,
        plannedTreatmentMultipleId: plannedTreatmentMultiple.id,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsub))
      .subscribe((result) => {
        if (result === 'confirm') {
          this.loading = true;
          this.treatmentPlanService
            .deletePlannedTreatmentMultiple(plannedTreatmentMultiple)
            .pipe(takeUntil(this.unsub))
            .subscribe(
              (res) => {
                const indexToRemove = this.treatmentPlan.plannedTreatmentMultiples.findIndex(
                  (p) => p.id === plannedTreatmentMultiple.id
                );
                this.treatmentPlan.plannedTreatmentMultiples.splice(indexToRemove, 1);
                this.treatmentPlanService.treatmentPlanUpdated$.next(this.treatmentPlan);
                this.loading = false;
              },
              (errorResponse: HttpErrorResponse) => {
                this.errors = errorResponse.error.errors;
                this.loading = false;
              }
            );
        }
      });
  }

  canMultipleBeDeleted(plannedTreatmentMultiple: PlannedTreatmentMultiple) {
    //do not allow deleting pt multiple just because theinvoice was unlocked - make sure none of the tx plans have been used
    return (
      plannedTreatmentMultiple.paymentStatus === PaymentStatus.Unpaid &&
      plannedTreatmentMultiple.plannedTreatments.find((pt) => pt.treatmentState != TreatmentState.Unplanned) == null
    );
  }

  onPayForMultiple(plannedTreatmentMultiple: PlannedTreatmentMultiple) {
    this.onGoToInvoice(plannedTreatmentMultiple);
  }

  onGoToInvoice(plannedTreatmentMultiple: PlannedTreatmentMultiple) {
    this.loading = true;
    // The Service creates an Invoice if it doesn't exist
    this.invoicesService
      .getInvoiceByPlannedTreatmentMultipleId(plannedTreatmentMultiple.id, InvoiceType.Regular)
      .pipe(takeUntil(this.unsub))
      .subscribe(
        (invoice: Invoice) => {
          this.loading = false;
          // Goto the Invoice
          this.router.navigate(['patientaccounttab/invoice', invoice.id], { relativeTo: this.route.parent.parent });
        },
        (errorResponse: HttpErrorResponse) => {
          this.errors = errorResponse.error.errors;
          this.loading = false;
        }
      );
  }

  public getQuantityAvailable(plannedTreatmentMultiple: PlannedTreatmentMultiple) {
    if (isNullOrUndefined(plannedTreatmentMultiple)) {
      return '';
    }
    // How many scheduled ones do we have already?
    const totalPlannedTreatmentsCount = plannedTreatmentMultiple.plannedTreatments.length;
    if (totalPlannedTreatmentsCount > 0) {
      const availablePlannedTreatments = plannedTreatmentMultiple.plannedTreatments.filter((pt) => {
        return pt.treatmentState == TreatmentState.Unplanned;
      });
      const usage = availablePlannedTreatments.length + ' / ' + totalPlannedTreatmentsCount;
      return usage;
    }
  }

  onEditMultiple(plannedTreatmentMultiple: PlannedTreatmentMultiple) {
    var serviceTemplate = this.serviceTemplateMultiples.filter(
      (v) => v.id == plannedTreatmentMultiple.serviceTemplateId
    )[0];

    const dialogRef = this.createPackageDialog.open(CreateTreatmentPlanPackageComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: {
        isNew: false,
        patientId: this.patientId,
        serviceTemplateMultiples: this.serviceTemplateMultiples,
        serviceTemplate: serviceTemplate,
        treatmentPlanId: this.treatmentPlan.id,
        treatmentPlan: this.treatmentPlan,
        plannedTreatmentMultiple: plannedTreatmentMultiple,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.treatmentPlanService.treatmentPlanUpdated$.next(this.treatmentPlan);
      }
    });
  }

  isEditable(plannedTreatmentMultiple: PlannedTreatmentMultiple) {
    return plannedTreatmentMultiple.paymentStatus === PaymentStatus.Unpaid;
  }

  onFocus(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.focus$.next(value);
  }

  onFocusMultiples(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this.focusMultiples$.next(value);
  }


  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
