import { IChargeableAsset } from '@interfaces/IChargeableAsset';
import { ITransactionTargetItem } from '@interfaces/ITransactionTargetItem';
import { ChargeableItem } from '../billing/chargeable-item';
import { ServiceChargeableAsset } from '../billing/service-chargeable-asset';
import { Equipment } from '../equipment';
import { Observation } from '../observation/observation';
import { Package } from '../package';
import { Room } from '../room';
import { Tax } from '../tax';
import { UserCategory } from '../user-category';
import { ClinicServiceTemplate } from './clinic-service-template';
import { ServiceBillingCode } from './service-billing-code';
import { ServiceCategory } from './service-category';
import { ServiceDetailTemplate } from './service-detail-template';
import { ServiceNote } from './service-note';
import { ServiceResource } from './service-resource';
import { ServiceTemplateResource } from './service-template-resource';

export class Service implements ITransactionTargetItem, IChargeableAsset {
  public serviceId: number;
  public serviceName: string;
  public quantity: number;
  public serviceCategoryId: number;
  public category: ServiceCategory;
  public serviceRecProductsString: string;
  public templateId: number;
  public serviceTemplate: ClinicServiceTemplate;
  public subType?: string;
  public status?: boolean;
  public serviceAltName?: string;
  public defaultDurationMinutes?: number;
  public diagnosticCode?: string;
  public templateIcon?: string;
  public defaultPrice?: number;
  public overrideDefaultPrice?: number;
  public chargeAmount?: number;
  public attachedForms?: string[];
  public governmentBilling?: boolean;
  public serviceTaxes?: ServiceTax[];
  public userCategories?: UserCategoryService[];
  public room?: Room[];
  public serviceNotes?: ServiceNote[];
  public plannedTreatmentNotes?: string;
  public servicePhotoPath?: string;
  public observations?: Observation[];
  public signedTime?: Date;
  public signedById?: string;
  public isLocked?: boolean;
  public isPrepaid?: boolean;
  public equipment?: Equipment[];
  public serviceBillingCodes: ServiceBillingCode[];
  public serviceDetailTemplateId: ServiceDetailTemplate;
  public serviceTemplateResources?: ServiceTemplateResource[];
  public resourcesString: string;
  public serviceResources?: ServiceResource[];
  public requireCardOnFile?: boolean;

  constructor(init?: Partial<Service>) {
    this.serviceResources = [];

    Object.assign(this, init);
  }

  getColor(): string {
    return '#555555'; // Davy Grey
  }

  isRoutable(): boolean {
    return true;
  }

  public getBaseAndChildrenChargeableItems(): ChargeableItem[] {
    const chargeableItems: ChargeableItem[] = [];

    if (this.defaultPrice || this.overrideDefaultPrice) {
      chargeableItems.push(
        new ChargeableItem({ defaultPrice: this.defaultPrice, overridePrice: this.overrideDefaultPrice })
      );
    }

    if (this.observations != null && this.observations.length > 0) {
      for (let obr of this.observations) {
        obr = new Observation(obr);

        chargeableItems.push(...obr.getChargeableItems());
      }
    }
    return chargeableItems;
  }

  public getChargeableItems(): ChargeableItem[] {
    let chargeableItems: ChargeableItem[] = [];

    if (this.chargeAmount) {
      chargeableItems.push(new ChargeableItem({ defaultPrice: this.chargeAmount }));
    } else {
      chargeableItems = this.getBaseAndChildrenChargeableItems();
    }

    return chargeableItems;
  }

  public getChargeAmount() {
    const serviceAsset = new ServiceChargeableAsset(this);
    return serviceAsset.getChargeAmount();
  }

  setFullObject(
    serviceId: number,
    serviceName: string,
    quantity: number,
    serviceCategoryId: number,
    category: ServiceCategory,
    serviceRecProductsString: string,
    resourcesString: string,
    templateId: number,
    serviceTemplate: ClinicServiceTemplate,
    serviceDetailTemplateId: number,
    status?: boolean,
    serviceAltName?: string,
    defaultDurationMinutes?: number,
    diagnosticCode?: string,
    templateIcon?: string,
    defaultPrice?: number,
    overrideDefaultPrice?: number,
    attachedForms?: string[],
    governmentBilling?: boolean,
    serviceTaxes?: ServiceTax[],
    userCategories?: UserCategoryService[],
    room?: Room[],
    serviceNotes?: ServiceNote[],
    plannedTreatmentNotes?: string,
    servicePhotoPath?: string,
    observations?: Observation[],
    signedTime?: Date,
    signedBy?: string,
    equipment?: Equipment[],
    isLocked?: boolean,
    serviceBillingCodes?: ServiceBillingCode[],
    serviceTemplateResources?: ServiceTemplateResource[],
    requireCardOnFile?: boolean
  ) {
    this.serviceId = serviceId;
    this.serviceName = serviceName;
    this.quantity = quantity;
    this.serviceCategoryId = serviceCategoryId;
    this.category = category;
    this.serviceRecProductsString = serviceRecProductsString;
    this.resourcesString = resourcesString;
    this.templateId = templateId;
    this.serviceTemplate = serviceTemplate;
    this.status = status;
    this.serviceAltName = serviceAltName;
    this.defaultDurationMinutes = defaultDurationMinutes;
    this.diagnosticCode = diagnosticCode;
    this.templateIcon = templateIcon;
    this.defaultPrice = defaultPrice;
    this.overrideDefaultPrice = overrideDefaultPrice;
    this.attachedForms = attachedForms;
    this.governmentBilling = governmentBilling;
    this.serviceTaxes = serviceTaxes;
    this.userCategories = userCategories;
    this.room = room;
    this.serviceNotes = serviceNotes;
    this.servicePhotoPath = servicePhotoPath;
    this.observations = observations;
    this.signedTime = signedTime;
    this.signedById = signedBy;
    this.equipment = equipment;
    this.serviceTemplate = serviceTemplate;
    this.plannedTreatmentNotes = plannedTreatmentNotes;
    this.isLocked = isLocked;
    this.serviceBillingCodes = serviceBillingCodes;
    this.serviceDetailTemplateId = serviceDetailTemplateId;
    this.serviceTemplateResources = serviceTemplateResources;
    this.requireCardOnFile = requireCardOnFile;
  }
}

export interface PackageService {
  serviceId: number;
  service: Service;
  serviceQuantity: number;
  packageId: number;
  package: Package;
}

export class UserCategoryService {
  userCategoryId: number;
  userCategory: UserCategory;
  serviceTemplateId: number;
  serviceTemplate: ClinicServiceTemplate;

  constructor(init?: Partial<UserCategoryService>) {
    Object.assign(this, init);
  }
}

export interface ServiceTax {
  serviceId: number;
  service: Service;
  taxId: number;
  tax: Tax;
}
