import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmCancelWithReasonDialogComponent } from '@app/management/dialogs/confirm-cancel-with-reason/confirm-cancel-with-reason.component';
import { GenericDialogComponent } from '@app/management/dialogs/generic-confirm/generic-confirm.component';
import { FinanceTransactionType } from '@models/finance/finance-transaction-type';
import { InvoicePayment } from '@models/finance/invoice-payment';
import { InvoiceLineItemTax } from '@models/invoice/invoice-line-item-tax';
import { Patient } from '@models/patient';
import { CancellationType } from '@models/payments/cancellation-type.enum';
import { Appointment } from '@models/scheduler/event';
import { PaymentStatus } from '@models/scheduler/payment-status';
import { ServiceProvider } from '@models/service-provider';
import { Service } from '@models/service/service';
import { ServiceDetailTemplate } from '@models/service/service-detail-template';
import { Visit } from '@models/visit';
import { AppointmentService } from '@services/appointments.service';
import { EventsService } from '@services/events.service';
import { FinanceService } from '@services/finance.service';
import { InvoicesService } from '@services/invoices.service';
import { TreatmentPlanService } from '@services/treatment-planning/treatment-plan.service';
import { UsersService } from '@services/users.service';
import { VisitService } from '@services/visit.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-visit-appointments',
  templateUrl: './visit-appointments.component.html',
  styleUrls: ['./visit-appointments.component.less'],
})
export class VisitAppointmentsComponent implements OnInit, OnDestroy {
  private _visit;
  get visit() {
    return this._visit;
  }
  @Input() set visit(newVisit: Visit) {
    this._visit = newVisit;
    this.onVisitUpdate();
  }
  @Input() patient: Patient;
  ServiceDetailTemplate = ServiceDetailTemplate;
  visitAppointments: Appointment[] = [];
  showCreate = false;
  showCancelAppointment = false;
  date: FormControl;
  private currentDate: Date;
  private unsub: Subject<void> = new Subject<void>();

  constructor(
    private appointmentService: AppointmentService,
    private dialog: MatDialog,
    private invoiceService: InvoicesService,
    private financeService: FinanceService,
    private visitService: VisitService,
    private treatmentPlanService: TreatmentPlanService,
    private eventsService: EventsService,
    private userService: UsersService
  ) {
    this.date = new FormControl(new Date());
  }

  ngOnInit(): void {
    this.visitService.activeVisitApptsUpdatedListener$.pipe(takeUntil(this.unsub)).subscribe((selectedAppointment) => {
      this.checkAppointmentSelection();
    });

    this.appointmentService.appointmentUpdated$.pipe(takeUntil(this.unsub)).subscribe((appointment: Appointment) => {
      const visitAppointment = this.visitAppointments.find((va) => va.appointmentId === appointment.appointmentId);
      if (visitAppointment) {
        if (visitAppointment.startTime !== appointment.startTime) {
          visitAppointment.startTime = appointment.startTime;
        }
        if (visitAppointment.endTime !== appointment.endTime) {
          visitAppointment.endTime = appointment.endTime;
        }
        visitAppointment.reasonCancellationNotCharged = appointment.reasonCancellationNotCharged;
        visitAppointment.patientTokenId = appointment.patientTokenId;
        visitAppointment.squareCardId = appointment.squareCardId;
      }
    });

    this.appointmentService.allApptsUpdated$.pipe(takeUntil(this.unsub)).subscribe((aa) => {
      this.refreshVisitAppointments();
    });

    this.eventsService.currentDate.pipe(takeUntil(this.unsub)).subscribe((date) => {
      this.currentDate = date;
      this.date.setValue(this.currentDate);
    });

    this.eventsService.appointmentUpdated.pipe(takeUntil(this.unsub)).subscribe((appointment) => {
      this.updateAppointmentEventHandler(appointment);
    });
  }

  private onVisitUpdate() {
    if (this.visit && this.visit.appointments && this.visit.appointments.length > 0) {
      this.visitAppointments = this.formatAppointments(this.visit.appointments);
    } else {
      this.visitAppointments = [];
    }
    this.checkAppointmentSelection();
  }

  private checkAppointmentSelection() {
    if (this.visit && this.visit.appointments.length > 0 && this.visitService.clickedVisitAppt) {
      this.onEditAppointment(this.visitService.clickedVisitAppt.id);
    }
  }

  private formatAppointments(appointments: Appointment[]) {
    const mappedApps = appointments.map((appointment) => {
      const cloneApp = JSON.parse(JSON.stringify(appointment));
      cloneApp.editing = false;
      cloneApp.color = cloneApp.service.serviceTemplate.serviceIDColour;
      cloneApp.roomName = this.appointmentService.getRoomName(cloneApp);
      return cloneApp;
    });
    return mappedApps;
  }

  private refreshVisitAppointments() {
    if (this.visit) {
      const newVisitAppointments = this.appointmentService.activeAppointments.filter(
        (a) => a.visitId === this.visit.visitId && !a.isPlaceholder
      );
      this.visitAppointments = this.formatAppointments(newVisitAppointments);
    }
  }

  private updateAppointmentEventHandler(event: Appointment) {
    const appointmentToUpdate = this.visitAppointments.find((a) => a.appointmentId === event.appointmentId);
    if (appointmentToUpdate) {
      appointmentToUpdate.startTime = event.startTime;
      appointmentToUpdate.endTime = event.endTime;
      if (event.cancellationDate) {
        appointmentToUpdate.cancellationDate = event.cancellationDate;
      }
      appointmentToUpdate.allDay = event.allDay;
      appointmentToUpdate.cancellationReason = event.cancellationReason;
      appointmentToUpdate.cancellationMessage = event.cancellationMessage;
      appointmentToUpdate.cancelled = event.cancelled;
      appointmentToUpdate.className = event.className;
      appointmentToUpdate.isCancellationAlert = event.isCancellationAlert;
      appointmentToUpdate.resourceId = event.resourceId;
      appointmentToUpdate.service = event.service;
      appointmentToUpdate.serviceId = event.serviceId;
      appointmentToUpdate.title = event.title;
      appointmentToUpdate.roomName = event.roomName;
      appointmentToUpdate.reasonCancellationNotCharged = event.reasonCancellationNotCharged;
      appointmentToUpdate.patientTokenId = event.patientTokenId;
      appointmentToUpdate.squareCardId = event.squareCardId;
      this.treatmentPlanService.treatmentPlanScheduled = null;
    }
  }

  getTime(date: Date, time: moment.Duration) {
    return moment(date).startOf('day').add(moment.duration(time)).format('h:mm a');
  }

  async cancelAppointment(appointment: Appointment, index: number) {
    const cancelled = await this.appointmentService.handleCancellation(appointment);
    if (cancelled) {
      this.visitAppointments.splice(index, 1);
      if (this.visitAppointments.length === 0) {
        this.visitService.closePanel();
      }
    }
  }

  hexToTranslucentRgbA(hex): string {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      // tslint:disable-next-line:no-bitwise
      const rgba = 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ', 0.1)';
      return rgba;
    } else if (hex === 'red') {
      return this.hexToTranslucentRgbA('#FF3F3F');
    } else if (hex === 'blue') {
      return this.hexToTranslucentRgbA('#3F3FFF');
    } else if (hex === 'green') {
      return this.hexToTranslucentRgbA('#3F9F3F');
    } else {
      return 'rgba(0,0,0,0)';
    }
  }

  onEditAppointment(selectedAppId: number) {
    this.visitAppointments.forEach((app) => (app.editing = app.appointmentId === selectedAppId));
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
}
