import { Address } from './address';
import { Doctor } from './doctor';
import { Pharmacy } from './pharmacy';
import { Appointment } from './scheduler/event';
import { Observation } from './observation/observation';
import { PhotoMetaData } from './photo/photo-meta-data';
import { TreatmentPlan } from './treatment-planning/treatment-plan';
import { PatientStatus } from './patient-status.enum';
import { PhotoDrawing } from './photo/photo-drawing';

export class Patient {
  patientId: number;
  clientId: string;
  firstName: string;
  lastName: string;
  nickName?: string;
  birthDate: Date;
  avatar?: string;
  gender: string;
  address?: Address;
  addressId?: number;
  email?: string;
  homeNumber: string;
  mobileNumber: string;
  familyPhysician?: Doctor;
  doctorId?: number;
  preferredPharmacy?: Pharmacy;
  pharmacyId?: number;
  communicationPreference: string;
  sendAppointmentEmailNotifications: boolean;
  sendAppointmentSMSNotifications: boolean;
  sendRetentionEmails: boolean;
  isPreferred?: boolean;
  rushPatientNote?: boolean;
  socialHistory?: PatientSocialHistoryEntry[];
  notesAndAlerts: string;
  medicalHistoryNotes?: string;
  socialHistoryNotes?: string;
  familyHistoryNotes?: string;
  signature: string;
  serviceAppointments: Appointment[];
  patientNotes?: PatientNote[];
  observations?: Observation[];
  photos?: PhotoMetaData[];
  creditAmount?: number;
  patientStatus: PatientStatus;
  sendReviews?: boolean;
  unsubscribedDateRetentionCampaigns?: Date;
  unsubscribedDateReviews?: Date;
  unsubscribedDateAppointments?: Date;

  constructor(init?: Partial<Patient>) {
    Object.assign(this, init);
  }
}

export class PatientSocialHistoryEntry {
  id: number;
  entryDate: Date;
  enteredByUserId: string;
  entryText: string;
  patientId: number;
}

export class PatientHistoryNotes {
  patientId: number;
  rushPatientNote?: boolean;
  notesAndAlerts?: string;
  medicalHistoryNotes?: string;
  socialHistoryNotes?: string;
  familyHistoryNotes?: string;
}

export interface PatientNote {
  patientNoteId: number;
  entryDate: Date | string; //see #410 for details , need to pass date string to process patient notes correctly on back end for now
  enteredBy: string;
  entryText: string;
  draftText: string;
  patientId?: number;
  photoDrawings: PhotoDrawing[];
}
