import { Component, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateNudgesComponent } from '@app/patients/patient-tabs/patient-nudges-tab/create-nudges/create-nudges.component';
import { DocumentPreviewComponent } from '@app/patients/patient-tabs/patient-nudges-tab/document-preview/document-preview.component';
import { DocumentDetailsComponent } from '@app/patients/patient-tabs/patients-documents-tab/document-details/document-details.component';
import { Nudge } from '@models/nudges/nudge';
import { NudgePriority, NudgePriorityTitle } from '@models/nudges/priority';
import { NudgeReferenceType } from '@models/nudges/reference-type';
import { NudgeStatuses, NudgeStatusesTitle } from '@models/nudges/status';
import { NudgeTypes, NudgeTypesTitle } from '@models/nudges/type';
import { User } from '@models/user';
import { DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, State } from '@progress/kendo-data-query';
import { NudgeService } from '@services/nudge.service';
import { PatientDocumentService } from '@services/patient-documents.service';
import { PatientFormService } from '@services/patient-form.service';
import { UsersService } from '@services/users.service';
import * as moment from 'moment';
import { Subject, forkJoin } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-nudges',
  templateUrl: './nudges.component.html',
  styleUrls: ['./nudges.component.less'],
})
export class NudgesComponent implements OnInit {
  public users: User[];
  public selectedBtn: string;

  public Status = NudgeStatuses;
  public Type = NudgeTypes;
  public Priority = NudgePriority;
  public btnList = ['All', 'Dictations/letters', 'Follow-ups', 'Investigations', 'New referrals', 'Inventory', 'Other'];
  public patientId: number;

  public state: State = {
    skip: 0,
    take: 10,

    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [
        {
          filters: [
            {
              field: 'status',
              operator: 'eq',
              value: this.Status.Active,
            },
            {
              field: 'status',
              operator: 'eq',
              value: this.Status.Overdue,
            },
          ],
          logic: 'or',
        },
      ],
    },
    sort: [
      {
        field: 'deferredUntil',
        dir: 'asc',
      },
    ],
  };

  public gridView: any;
  public allowUnsort = true;
  public buttonCount = 5;
  public info = true;
  public pageSizes = true;
  public previousNext = true;

  public pageSize = 10;
  public skip = 0;

  public filterByUser = true;
  public status: any;
  public priority: any;
  public types: any;
  public myNudgesCounters: any = {};
  public loading = false;

  private additionalQueries: any;
  private gridQueries: any = {};
  private currentUser: User;

  private unsub: Subject<void> = new Subject<void>();

  constructor(
    private userService: UsersService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private nudgeService: NudgeService,
    private router: Router,
    private documentService: PatientDocumentService,
    private formService: PatientFormService,
    @Optional() private dialogRef: MatDialogRef<DocumentDetailsComponent>
  ) {}

  ngOnInit() {
    this.currentUser = this.userService.loggedInUser;
    this.state.filter.filters.push({
      filters: [
        {
          field: 'assignedTo',
          operator: 'eq',
          value: this.userService.loggedInUser.id,
        },
      ],
      logic: 'or',
    });

    this.status = Object.keys(NudgeStatusesTitle).map((e) => ({
      title: NudgeStatusesTitle[e],
      value: NudgeStatuses[e],
    }));
    this.priority = Object.keys(NudgePriorityTitle).map((e) => ({
      title: NudgePriorityTitle[e],
      value: NudgePriority[e],
    }));
    this.types = Object.keys(NudgeTypesTitle).map((e) => ({ title: NudgeTypesTitle[e], value: NudgeTypes[e] }));

    this.patientId = this.route.snapshot.params.patId && +this.route.snapshot.params.patId.split('_')[0];
    if (this.patientId) {
      this.additionalQueries = { patientId: this.patientId };
    }
    this.createQuery(this.state);
    this.types.unshift({ title: 'all', value: 0 });
    this.selectedBtn = this.types[0].title;
    this.userService.getUsers().subscribe((users: User[]) => {
      this.users = users;
    });
    this.gridQueries.limit = 10;
    this.gridQueries.page = 0;
    this.loadNudges(this.gridQueries, this.additionalQueries);
  }

  public isGreaterThanOneWeekOverdue(dueDate: Date) {
    return moment().diff(moment(dueDate), 'days') > 7;
  }

  public selectBtn(item: any) {
    this.selectedBtn = item.title;
    if (item.value) {
      this.additionalQueries = { nudgeType: item.value };
    } else {
      delete this.additionalQueries.nudgeType;
    }

    this.skip = 0;
    this.gridQueries.page = 0;
    this.loadNudges(this.gridQueries, this.additionalQueries);
  }

  private loadNudges(state, addState): void {
    this.loading = true;
    this.nudgeService.getNudges({ state, addState }).subscribe((res) => {
      const result = res.results.map((e) => {
        e['completed'] = e.status === this.Status.Completed;
        return e;
      });
      this.gridView = {
        data: result,
        total: res.total || result.length,
      };

      this.loading = false;
    });
    this.getCounters();
  }

  public dataStateChange(event: DataStateChangeEvent) {
    this.createQuery(event);
    if (event.filter.filters.length) {
      this.checkMyFilterStatus();
    }

    this.gridQueries.limit = event.take;
    this.gridQueries.page = Math.floor((event.skip || 0) / event.take);
    this.skip = event.skip;
    this.pageSize = event.take;

    this.loadNudges(this.gridQueries, this.additionalQueries);
    this.state = event;
  }

  private createQuery(event) {
    const filterQueries = event.filter.filters.map((e) => {
      if (e.filters) {
        const arrayOfFilters = e.filters.map((fil) => {
          return { [fil.field]: fil.value };
        });

        return arrayOfFilters;
      }
    });

    this.gridQueries = filterQueries.reduce((obj, e) => {
      return Object.assign(
        obj,
        e.reduce((r, o) => {
          Object.entries(o).forEach(([k, v]) => (r[k] = r[k] || []).push(v));
          return r;
        }, {})
      );
    }, {});

    if (event.sort.length) {
      let sortQuery: any;
      if (event.sort[0].dir) {
        sortQuery = {
          SortBy: event.sort[0].field,
          SortDirection: event.sort[0].dir,
        };
      }

      this.gridQueries = Object.assign({}, this.gridQueries, sortQuery);
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
  }

  public openNudgeModal({ sender, rowIndex, dataItem }) {
    const dialogRef = this.dialog.open(CreateNudgesComponent, {
      panelClass: 'custom-dialog-container',
      width: '550px',
      data: dataItem,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.loadNudges(this.gridQueries, this.additionalQueries);
      }
    });
  }

  public clearFilters() {
    this.state = {
      skip: 0,
      take: 10,

      // Initial filter descriptor
      filter: {
        logic: 'and',
        filters: [
          {
            filters: [
              {
                field: 'status',
                operator: 'eq',
                value: this.Status.Active,
              },
              {
                field: 'status',
                operator: 'eq',
                value: this.Status.Overdue,
              },
            ],
            logic: 'or',
          },
        ],
      },
      sort: [
        {
          field: 'deferredUntil',
          dir: 'asc',
        },
      ],
    };

    this.filterByUser = true;
    this.createQuery(this.state);
    this.gridQueries.page = 0;
    this.gridQueries.limit = this.pageSize;

    this.loadNudges(this.gridQueries, this.additionalQueries);
  }

  public returnDateTimeUTC(date: string | moment.Moment) {
    return moment(date).format('YYYY-MM-DD hh:mma');
  }

  public filterNudges() {
    if (this.filterByUser) {
      const myFilter: CompositeFilterDescriptor = {
        filters: [
          {
            field: 'assignedTo',
            operator: 'eq',
            value: this.currentUser.id,
          },
        ],
        logic: 'or',
      };

      this.skip = 0;
      const indexAssignedToFilters = this.state.filter.filters.findIndex((e: CompositeFilterDescriptor) => {
        return e.filters[0]['field'] === 'assignedTo';
      });

      if (indexAssignedToFilters >= 0 && this.state.filter.filters[indexAssignedToFilters]['filters'].length > 1) {
        this.state.filter.filters.splice(indexAssignedToFilters, 1);
      }

      this.state.filter.filters.push(myFilter);

      this.createQuery(this.state);
      this.gridQueries.page = 0;
      this.gridQueries.limit = this.pageSize;
      this.loadNudges(this.gridQueries, this.additionalQueries);
    } else {
      this.removeMyFilter();
      this.skip = 0;
      this.createQuery(this.state);
      this.gridQueries.page = 0;
      this.gridQueries.limit = this.pageSize;
      this.loadNudges(this.gridQueries, this.additionalQueries);
    }
  }

  private removeMyFilter() {
    const indexAssignedToFilters = this.state.filter.filters.findIndex((e: CompositeFilterDescriptor) => {
      return e.filters[0]['field'] === 'assignedTo';
    });

    const currentUserFilter = this.state.filter.filters[indexAssignedToFilters]['filters'].findIndex(
      (e: FilterDescriptor) => {
        return e.value === this.currentUser.id;
      }
    );

    this.state.filter.filters[indexAssignedToFilters]['filters'].splice(currentUserFilter, 1);
    if (!this.state.filter.filters[indexAssignedToFilters]['filters'].length) {
      this.state.filter.filters.splice(indexAssignedToFilters, 1);
    }
  }

  private getCounters() {
    forkJoin([
      this.nudgeService.getNudgesCount(),
      this.nudgeService.getNudgesCount(this.Type['Dictation/Letter']),
      this.nudgeService.getNudgesCount(this.Type['Follow-Up']),
      this.nudgeService.getNudgesCount(this.Type.Investigation),
      this.nudgeService.getNudgesCount(this.Type.Referral),
      this.nudgeService.getNudgesCount(this.Type.Other),
      this.nudgeService.getNudgesCount(this.Type.Inventory),
    ]).subscribe(([res1, res2, res3, res4, res5, res6, res7]) => {
      this.myNudgesCounters = {
        0: res1,
        1: res2,
        2: res3,
        3: res4,
        4: res5,
        5: res6,
        6: res7,
      };
    });
  }

  public sortIconClass(column: any) {
    return this.state.sort.length && this.state.sort[0].field === column.field && this.state.sort[0].dir === 'asc'
      ? 'fad fa-sort-up'
      : this.state.sort.length && this.state.sort[0].field === column.field && this.state.sort[0].dir === 'desc'
      ? 'fad fa-sort-down'
      : 'fas fa-sort';
  }

  private checkMyFilterStatus() {
    const indexAssignedToFilters = this.state.filter.filters.findIndex((e: CompositeFilterDescriptor) => {
      return e.filters[0]['field'] === 'assignedTo';
    });

    if (indexAssignedToFilters >= 0 && this.state.filter.filters[indexAssignedToFilters]['filters'].length > 1) {
      this.filterByUser = false;
    }
  }

  public navigateToPatientChart(id: number): void {
    this.router.navigate([
      '/nudges',
      {
        outlets: { 'action-panel': ['patient', id + '_patientcharttab', 'patienttabs', 'patientcharttab', 'overview'] },
      },
    ]);
  }

  public navigateToReferenceLink(item: Nudge) {
    this.router.navigateByUrl(item.referenceLink);
  }

  public previewPatientDocument(item: Nudge) {
    this.dialogRef = this.dialog.open(DocumentDetailsComponent, {
      panelClass: 'document-view-modal',
      disableClose: true,
    });
    this.dialogRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => {
        this.documentService
          .getDocument(item.referenceId)
          .pipe(first())
          .subscribe((res) => {
            this.documentService.documentSelected.next(res);
          });
      });
  }

  public previewPatientClinicDocument(item: Nudge) {
    var dialogRef = this.dialog.open(DocumentDetailsComponent, {
      panelClass: 'document-view-modal',
      disableClose: true,
    });
    dialogRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => {
        this.documentService
          .getPatientClinicDocument(item.referenceId)
          .pipe(first())
          .subscribe((res) => {
            this.documentService.documentSelected.next(res);
          });
      });
  }

  public previewPatientForm(item: Nudge) {
    var dialogRef = this.dialog.open(DocumentDetailsComponent, {
      panelClass: 'document-view-modal',
      disableClose: true,
    });
    dialogRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => {
        this.formService.getPatientFormById(item.referenceId).subscribe((res) => {
          this.formService.patientFormSelected$.next(res);
        });
      });
  }

  public updateStatus(item, event) {
    const data = Object.assign({}, item);
    data.completed = event.target.checked;
    data.assignedTo = item.assignedTo.id;
    this.nudgeService.updateNudge(data, item.nudgeId).subscribe((res) => {
      this.loadNudges(this.gridQueries, this.additionalQueries);
      this.nudgeService.updateNudgeCount();
    });
  }

  public isServiceReferenceType(item): boolean {
    return item && item.referenceType == NudgeReferenceType.Service;
  }

  public isPatientDocumentReferenceType(item): boolean {
    return item && item.referenceType == NudgeReferenceType.PatientDocument;
  }

  public isPatientClinicDocumentReferenceType(item): boolean {
    return item && item.referenceType == NudgeReferenceType.PatientClinicDocument;
  }

  public isPatientFormReferenceType(item): boolean {
    return item && item.referenceType == NudgeReferenceType.PatientForm;
  }
  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
    this.dialog.closeAll();
  }
}
