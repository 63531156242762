import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';

@Component({
  selector: 'app-communication-wrapper',
  templateUrl: './communication-wrapper.component.html',
  styleUrls: ['./communication-wrapper.component.less'],
})
export class CommunicationWrapperComponent implements OnInit {
  developerPolicy = Policies.developer;
  communicationsAppointmentsPolicy = Policies.communicationsAppointments;
  communicationsEmailTemplatesPolicy = Policies.communicationsEmailTemplates;
  communicationsRetentionCampaignsPolicy = Policies.communicationsRetentionCampaigns;
  communicationsSummaryPolicy = Policies.communicationsSummary;

  constructor(private _router: Router, private authService: AuthService) {}

  selectedBtn: string;

  ngOnInit() {
    const path = this._router.url.split('/').pop();
    switch (path) {
      case 'summary':
        this.selectedBtn = 'summary';
        break;

      case 'appointments':
        this.selectedBtn = 'Appointments';
        break;

      case 'retention-campaigns':
        this.selectedBtn = 'Retention Campaigns';
        break;
      case 'email-templates':
        this.selectedBtn = 'Email Templates';
        break;
    }
  }

  appointments() {
    this.selectedBtn = 'Appointments';
    this._router.navigateByUrl('/management/communication/appointments');
  }

  goToSummary() {
    this.selectedBtn = 'summary';
    this._router.navigateByUrl('/management/communication/summary');
  }

  retentionCampaigns() {
    this.selectedBtn = 'Retention Campaigns';
    this._router.navigateByUrl('/management/communication/retention-campaigns');
  }

  emailTemplates() {
    this.selectedBtn = 'Email Templates';
    this._router.navigateByUrl('/management/communication/email-templates');
  }
}
