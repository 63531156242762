import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { formatHoursOfOperation } from '@app/shared/helpers';
import { environment } from '@environments/environment';
import { Clinic } from '@models/clinic';
import { HoursOfOperation, defaultHoursOfOperation } from '@models/hoursofoperation';
import { UsersService } from '@services/users.service';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { SquareService } from './square.service';
import { BlobService } from './blob.service';

@Injectable()
export class ClinicsService {
  constructor(
    private http: HttpClient,
    private userService: UsersService,
    private authService: AuthService,
    private squareService: SquareService,
    private blobService: BlobService
  ) {
    userService.loggedInUserUpdated$.subscribe((user) => {
      if (user.id) this.setClinicScheduler();
    });
  }

  minimumDuration: number;
  hoursOfOperation: HoursOfOperation;
  clinicHomeUrl: string;
  isSquareLinked: boolean;

  clinic: Clinic;
  clinics: Subject<Clinic[]> = new Subject<Clinic[]>();
  clinicIdSelected$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  clinicUpdated: Subject<Clinic> = new Subject<Clinic>();
  clinicUpdated$: Observable<Clinic> = this.clinicUpdated.asObservable();
  unsub: Subject<any> = new Subject<any>();

  addClinic(clinic: Clinic) {
    return this.http.post<Clinic>(environment.baseUrl + 'api/Clinics', clinic);
  }

  getLoginClinicLogo(): Observable<string> {
    return this.http.get(environment.baseUrl + 'api/Clinics/logo', { responseType: 'text' });
  }

  getClinics(): Observable<Clinic[]> {
    const companyId =
      this.userService.loggedInUserCompany == null
        ? parseInt(localStorage.getItem('companyId'))
        : this.userService.loggedInUserCompany;
    const userId = this.authService.userId;
    const clinicId =
      this.userService.loggedInUser.clinicId == null
        ? parseInt(localStorage.getItem('clinicId'))
        : this.userService.loggedInUser.clinicId;
    const organizationPolicySatisfied = this.authService.userSatisfiesPolicy(Policies.organization);
    if (companyId != null && !Number.isNaN(companyId) && userId) {
      this.getCompanyClinics(companyId, userId)
        .map((clinics) =>
          clinics.filter((clinic) => clinic.clinicId === (organizationPolicySatisfied ? clinic.clinicId : clinicId))
        )
        .subscribe((clinics) => {
          this.clinics.next(clinics);
        });
    }
    return this.clinics.asObservable().pipe(take(1));
  }

  getClinicsForCompany(): Observable<Clinic[]> {
    const companyId =
      this.userService.loggedInUserCompany == null
        ? parseInt(localStorage.getItem('companyId'))
        : this.userService.loggedInUserCompany;
    const userId = this.authService.userId;
    if (companyId != null && !Number.isNaN(companyId)) {
      var clinics = this.http.get<Clinic[]>(environment.baseUrl + 'api/Clinics/ForCompany/' + companyId + '/' + userId);
      return clinics.pipe(take(1));
    }
  }

  setClinicScheduler(): void {
    //I'm using the 'loginUser' clinicId - as a way to swap clinics
    this.getClinics().subscribe((clinics) => {
      if (clinics != null && clinics.length > 0) {
        this.clinic = clinics.find((c) => c.clinicId === this.userService.loggedInUser.clinicId);
        if (this.clinic.logoUrl && !this.clinic.logoUrl.includes('?sv')) {
          this.clinic.logoUrl = this.clinic.logoUrl.trim() + this.blobService.getReadOnlySAS();
        }
        this.hoursOfOperation = this.clinic.hoursOfOperation;
        formatHoursOfOperation(this.hoursOfOperation?.hoursOfOperationDays);
        this.minimumDuration = this.clinic.minimumDuration;
        this.clinicHomeUrl = this.clinic.url == null ? '' : this.clinic.url;
        this.clinicIdSelected$.next(this.clinic.clinicId);
        this.getIsSquareLinked();
      }
    });
  }

  getIsSquareLinked() {
    this.squareService
      .getIsSquareLinked()
      .pipe(takeUntil(this.unsub))
      .subscribe((isSquareLinked) => {
        this.isSquareLinked = isSquareLinked;
      });
  }

  getCompanyClinics(company: number, user: string): Observable<Clinic[]> {
    const clinics = this.http.get<Clinic[]>(environment.baseUrl + 'api/Clinics/' + company + '/' + user);
    return clinics;
  }

  getClinicById(clinicId) {
    const userId = this.authService.userId;
    return this.http.get<Clinic>(environment.baseUrl + 'api/Clinics/Clinic/' + clinicId + '/' + userId).pipe(
      catchError((err: HttpErrorResponse) => {
        // possibly log something here ?
        return this.clinic ? of(this.clinic) : this.notFoundClinic();
      })
    );
  }

  updateClinic(clinic: Clinic) {
    return this.http.put<void>(environment.baseUrl + 'api/Clinics/' + clinic.clinicId, clinic);
  }

  updateClinicBookingSettings(clinic: Clinic) {
    return this.http.put<void>(environment.baseUrl + 'api/Clinics/PutClinicBookingSettings/' + clinic.clinicId, clinic);
  }

  updateClinicMerchantDevicesSettings(clinic: Clinic) {
    return this.http.put<void>(
      environment.baseUrl + 'api/Clinics/PutClinicMerchantDevicesSettings/' + clinic.clinicId,
      clinic
    );
  }

  removeClinic(clinic: Clinic) {
    return this.http.delete(environment.baseUrl + 'api/Clinics/' + clinic.clinicId);
  }

  private notFoundClinic() {
    const clinic: Clinic = {
      clinicId: 0,
      name: 'Not Found',
      blobContainerName: '',
      address: {
        addressId: 0,
        address1: '',
        address2: '',
        city: '',
        country: '',
        postalCode: '',
        province: '',
      },
      clinicRooms: [],
      clinicTaxes: [],
      companyId: 0,
      email: '',
      website: '',
      srFaxId: null,
      srFaxPassword: '',
      hoursOfOperation: defaultHoursOfOperation,
      timezone: this.userService.loggedInUserTimezone,
      minimumDuration: 5,
      loyaltyPointsToDollarsRate: null,
      customInvoiceText: '',
      sendPatientPortalEmail: false,
    };
    return new BehaviorSubject<Clinic>(clinic).asObservable();
  }
}
