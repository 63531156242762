<perfect-scrollbar height="100%">
  <div class="calnav">
    <div class="header">
      <div
        *appAuthGuard="patientPanelPolicy"
        class="px-2 py-3 navy-background"
        [ngClass]="{ isMovingAppt: _eventsService?.movingAppointment }"
      >
        <app-search-patient [sideNavExpanded]="true" [alternativeLayout]="true"> </app-search-patient>
      </div>

      <app-current-date></app-current-date>

      <!-- Calendar Nav Buttons -->
      <div class="btn-group w-100">
        <!-- Back 1 Month(start of month) -->
        <div
          class="btn em-btn-green border-right px-1"
          (click)="goBackOneMonth()"
          [attr.data-test-id]="'calnav-backward-one-month'"
        >
          <i class="fal fa-chevron-double-left white-font small"></i>
        </div>

        <!-- Back 1 Day -->
        <div
          class="btn em-btn-green border-right"
          (click)="goBackOneDay()"
          [attr.data-test-id]="'calnav-backward-one-day'"
        >
          <i class="fal fa-chevron-left white-font small"></i>
        </div>

        <!-- Go To Today -->
        <div class="btn em-btn-green border-right" (click)="gotoToday()">
          <span class="small white-font">TODAY</span>
        </div>

        <!-- Forward 1 Day -->
        <div
          class="btn em-btn-green border-right"
          (click)="goForwardOneDay()"
          [attr.data-test-id]="'calnav-forward-one-day'"
        >
          <i class="fal fa-chevron-right white-font small"></i>
        </div>

        <!-- Forward 1 Month(start of month) -->
        <div
          class="btn em-btn-green border-right px-1"
          [attr.data-test-id]="'calnav-forward-one-month'"
          (click)="goForwardOneMonth()"
        >
          <i class="fal fa-chevron-double-right white-font small"></i>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <mat-calendar
        [selected]="selectedDate"
        [comparisonStart]="selectedScheduleMode == ScheduleMode.AgendaWeek ? weekStart : null"
        [comparisonEnd]="selectedScheduleMode == ScheduleMode.AgendaWeek ? weekEnd : null"
        (selectedChange)="selectedChange($event)"
        #calendar1
      >
      </mat-calendar>

      <app-calnav-noheader
        [calendarNumber]="2"
        [previousCalendar]="calendar1"
        [weekStart]="selectedScheduleMode == ScheduleMode.AgendaWeek ? weekStart : null"
        [weekEnd]="selectedScheduleMode == ScheduleMode.AgendaWeek ? weekEnd : null"
        (changeDate)="selectedChange($event)"
        #calendar2
      >
      </app-calnav-noheader>

      <app-calnav-noheader
        [calendarNumber]="3"
        [previousCalendar]="calendar2.matCalendar"
        [weekStart]="selectedScheduleMode == ScheduleMode.AgendaWeek ? weekStart : null"
        [weekEnd]="selectedScheduleMode == ScheduleMode.AgendaWeek ? weekEnd : null"
        (changeDate)="selectedChange($event)"
        #calendar3
      >
      </app-calnav-noheader>

      <app-calnav-noheader
        *ngIf="!isMobileDevice"
        [calendarNumber]="4"
        [previousCalendar]="calendar3.matCalendar"
        [weekStart]="selectedScheduleMode == ScheduleMode.AgendaWeek ? weekStart : null"
        [weekEnd]="selectedScheduleMode == ScheduleMode.AgendaWeek ? weekEnd : null"
        (changeDate)="selectedChange($event)"
        #calendar4
      >
      </app-calnav-noheader>
    </div>

    <div *ngIf="_eventsService?.movingAppointment" class="row mt-auto">
      <div class="col text-center">
        <button class="btn btn-small em-btn em-btn-green" (click)="cancelMove()">Cancel Move</button>
      </div>
    </div>
    <span class="search-box">
      <app-select-clinic></app-select-clinic>
    </span>
    <div class="d-flex flex-column mt-auto" [ngClass]="{ isMovingAppt: _eventsService?.movingAppointment }">
      <div class="mt-auto pb-2">
        <!-- Appointments -->
        <div
          class="row pointer schedule-type-buttons m-0 py-1"
          [ngClass]="{
            'schedule-active':
              selectedScheduleView === scheduleViewType.Appointments && !_eventsService.blockedScheduleMode
          }"
          (click)="scheduleView()"
        >
          <div class="schedule-type mt-auto mb-auto col-1">
            <i class="fal fa-stethoscope"></i>
          </div>
          <div class="col">
            <p class="text-uppercase mt-auto mb-auto">Appointments</p>
          </div>
        </div>
        <!-- Move & Cancel List -->
        <div
          class="row pointer schedule-type-buttons m-0 py-1"
          [ngClass]="{ 'schedule-active': selectedScheduleView === scheduleViewType.NoShowAppointments }"
          (click)="cancelledAndNoShowView()"
        >
          <div class="schedule-type mt-auto mb-auto col-1">
            <i class="fal fa-ban"></i>
          </div>
          <div class="col">
            <p class="text-uppercase mt-auto mb-auto">Cancellations</p>
          </div>
        </div>

        <!-- Breaks List -->
        <div
          *appAuthGuard="staffSchedulePolicy"
          class="row pointer schedule-type-buttons m-0 py-1"
          [ngClass]="{ 'schedule-active': _eventsService.blockedScheduleMode }"
          (click)="blockedScheduleModeChange()"
        >
          <div class="schedule-type mt-auto mb-auto col-1">
            <i class="fal fa-bell-slash"></i>
          </div>
          <div class="col">
            <p class="text-uppercase mt-auto mb-auto">Breaks</p>
          </div>
        </div>

        <!-- Staff Schedules -->
        <div
          *appAuthGuard="staffSchedulePolicy"
          class="row pointer schedule-type-buttons m-0 py-1"
          [ngClass]="{ 'schedule-active': selectedScheduleView === scheduleViewType.StaffSchedules }"
          (click)="staffScheduleView()"
        >
          <div class="schedule-type mt-auto mb-auto col-1">
            <i class="far fa-table"></i>
          </div>
          <div class="col">
            <p class="text-uppercase mt-auto mb-auto">Staff Schedules</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</perfect-scrollbar>
