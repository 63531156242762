import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { authRoles } from '@app/auth/auth-permissions';
import { Policies } from '@app/auth/auth-policies';
import { ChatChannelsService } from '@app/messaging/services/chat-channels.service';
import { NavStateService } from '@services/navstate.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.less'],
})
export class QuickLinksComponent implements OnInit, AfterViewInit, OnDestroy {
  unsub: Subject<void> = new Subject<void>();
  sideNavExpanded: boolean;
  searchPatientClicked: boolean;
  hasClinic = true;
  messagingNotificationsCounter: number;
  addressBookPolicy = Policies.addressBook;
  appointmentPolicy = Policies.appointments;
  patientListPolicy = Policies.patientList;
  patientMergePolicy = Policies.patientMerge;
  reportsPolicy = Policies.reports;
  powerReportsPolicy = Policies.reportsPower;

  constructor(
    private navStateService: NavStateService,
    private router: Router,
    private authService: AuthService,
    private chatChannelService: ChatChannelsService
  ) {}

  ngOnInit() {
    this.navStateService.sideNavExpanded.pipe(takeUntil(this.unsub)).subscribe((exp) => {
      this.sideNavExpanded = exp;
    });
    this.hasClinic = parseInt(this.authService.activeClinicId) != -1;
  }

  ngAfterViewInit() {
    this.chatChannelService.onNotificationsCountChange$.subscribe((c) => (this.messagingNotificationsCounter = c));
  }

  searchPatient() {
    if (!this.sideNavExpanded) {
      this.navStateService.sideNavExpandState(true);
      this.searchPatientClicked = true;
      setTimeout(() => (this.searchPatientClicked = false), 0);
    }
  }

  goToAddressBook() {
    this.router.navigate(['/management/address-book/doctors']);
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
