import { Service } from '@models/service/service';
import { PlannedTreatment } from './planned-treatment';
import { PaymentStatus } from '@models/scheduler/payment-status';

export class PlannedTreatmentMultiple {
  id: number;
  patientId: number;
  treatmentPlanId: number;
  service: Service;
  serviceName: string;
  serviceId: number;
  serviceTemplateId: number;
  price: number;
  quantity: number;
  totalAmount: number;
  notes: string;
  createdDate: Date;
  updatedDate: Date;
  paymentStatus: PaymentStatus;
  plannedTreatments: PlannedTreatment[];
  chargeAmount: number;

  public constructor(init?: Partial<PlannedTreatmentMultiple>) {
    Object.assign(this, init);
  }
}
