import { Injectable } from '@angular/core';
import { Policies } from '@app/auth/auth-policies';
import { AuthService } from '@app/auth/auth.service';
import { Subject } from 'rxjs';
import { PhotoMetaData } from './../models/photo/photo-meta-data';
import { SignalrBase } from './signalr-base';
import { SignalrHttpClientService } from './signalr-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class PhotoSignalrService extends SignalrBase<any> {
  private photosChanged = new Subject<PhotoMetaData>();
  photosChanged$ = this.photosChanged.asObservable();

  private cameraNotFound = new Subject<string>();
  public cameraNotFound$ = this.cameraNotFound.asObservable();

  private cameraError = new Subject<string>();
  public cameraError$ = this.cameraError.asObservable();

  private cameraConnected = new Subject<string>();
  public cameraConnected$ = this.cameraConnected.asObservable();

  private cameraDisconnected = new Subject<string>();
  public cameraDisconnected$ = this.cameraDisconnected.asObservable();

  private cameraShutDown = new Subject<string>();
  public cameraShutDown$ = this.cameraShutDown.asObservable();

  constructor(authService: AuthService, private signalrHttpClientService: SignalrHttpClientService) {
    // Permissions to have this connect
    super('Photos', Policies.appointments, authService, signalrHttpClientService);
    this.registerServerEvents();
  }

  private registerServerEvents(): void {
    super.registerServerEvent('AddPhoto', (photo: PhotoMetaData) => {
      this.photosChanged.next(photo);
    });

    super.registerServerEvent('UpdatePhoto', (photo: PhotoMetaData) => {
      this.photosChanged.next(photo);
    });

    super.registerServerEvent('DeletePhoto', (photo: PhotoMetaData) => {
      this.photosChanged.next(photo);
    });

    super.registerServerEvent('CameraNotFound', (message: string) => {
      this.cameraNotFound.next(message);
    });

    super.registerServerEvent('CameraError', (message: string) => {
      this.cameraError.next(message);
    });

    super.registerServerEvent('CameraConnected', (message: string) => {
      this.cameraConnected.next(message);
    });

    super.registerServerEvent('CameraDisconnected', (message: string) => {
      this.cameraDisconnected.next(message);
    });

    super.registerServerEvent('CameraShutDown', (message: string) => {
      this.cameraShutDown.next(message);
    });
  }

  // updatePhoto(photo: PhotoMetaData){
  //   this._hubConnection.invoke('UpdatePhotoFromClient', photo);
  // }

  // addPhoto(photo: PhotoMetaData){
  //   this._hubConnection.invoke('AddPhotoFromClient', photo);
  // }

  // deletePhoto(photo: PhotoMetaData){
  //   this._hubConnection.invoke('DeletePhotoFromClient', photo);
  // }
}
