import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullOrUndefined } from '@app/shared/helpers';
import { Address } from '@models/address';
import { MedicalCollege } from '@models/medical-college';
import { User } from '@models/user';
import { UserCategory } from '@models/user-category';
import { DoctorsService } from '@services/doctors.service';
import { FormatterService } from '@services/formatter.service';
import { GeographyService } from '@services/geography.service';
import { UsersService } from '@services/users.service';
import { ValidationService } from '@services/validation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.less'],
})
export class EditUserComponent implements OnInit, OnDestroy {
  selectedFile = null;
  editUserPanelVisible = false;
  addOrEdit = null;
  boolChoices: string[] = ['Yes', 'No'];
  userIdParam: string;
  userCategoryParam: string;
  userName: FormControl;
  firstName: FormControl;
  lastName: FormControl;
  password: FormControl;
  passwordConfirm: FormControl;
  addressAddress1: FormControl;
  addressAddress2: FormControl;
  addressCity: FormControl;
  addressCountry: FormControl;
  addressPostalCode: FormControl;
  addressProvince: FormControl;
  phoneNumber: FormControl;
  provincialBillingNumber: FormControl;
  payeeNumber: FormControl;
  email: FormControl;
  disableEmail = false;
  serviceProvider: FormControl;
  medicalLicenseNumber: FormControl;
  isNew: boolean;
  editedUser: User;
  editedAddress: Address;
  countriesOfTheWorld: string[] = [];
  provincesAndStates: string[] = [];
  userCategories: UserCategory[] = [{ userCategoryId: 0, categoryName: '' }];
  submitButtonDisabledState = false;
  unsub: Subject<void> = new Subject<void>();
  serverValidationError: '';
  doctorUserCategoryId: number;
  medicalColleges: MedicalCollege[];
  hidePassword = true;
  hideConfirmPassword = true;

  defaultAddress = {
    address1: '',
    address2: '',
    city: '',
    country: 'Canada',
    postalCode: '',
    province: 'British Columbia',
  };

  constructor(
    private usersService: UsersService,
    private geographyService: GeographyService,
    private validationService: ValidationService,
    public formatterService: FormatterService,
    private route: ActivatedRoute,
    private router: Router,
    private doctorService: DoctorsService,
  ) {
    this.userName = new FormControl();
    this.firstName = new FormControl();
    this.lastName = new FormControl();
    this.addressAddress1 = new FormControl();
    this.addressAddress2 = new FormControl();
    this.addressCity = new FormControl();
    this.addressCountry = new FormControl();
    this.addressPostalCode = new FormControl('', null);
    this.addressProvince = new FormControl();
    this.phoneNumber = new FormControl('', this.validationService.validatePhoneNumber);
    this.email = new FormControl('', [Validators.email]);
    this.serviceProvider = new FormControl();
    this.password = new FormControl();
    this.passwordConfirm = new FormControl();
    this.provincialBillingNumber = new FormControl(null, Validators.pattern('^[0-9]*$'));
    this.payeeNumber = new FormControl(null, Validators.pattern('^[0-9]*$'));
    this.medicalLicenseNumber = new FormControl();
  }

  ngOnInit() {
    for (const key in this.geographyService.countriesByName()) {
      if (key) {
        this.countriesOfTheWorld.push(key);
      }
    }

    this.doctorService
      .getMedicalColleges()
      .pipe(takeUntil(this.unsub))
      .subscribe((colleges) => {
        this.medicalColleges = colleges;
      });

    this.usersService.getUserCategories().subscribe((userCategories: UserCategory[]) => {
      this.userCategories = userCategories;
      this.doctorUserCategoryId = userCategories.find((i) => i.categoryName === 'Doctor').userCategoryId;
    });

    this.editedUser = this.initUser(this.editedUser);

    this.isNew = true;

    // TODO: Need to fill with actual Clinic country info
    const clinicCountry = 'Canada';

    this.route.params.pipe(takeUntil(this.unsub)).subscribe((params) => {
      this.userIdParam = params['userid'];
      if (this.userIdParam !== '_' && this.userIdParam != null) {
        this.usersService.getUserById(this.userIdParam).subscribe((user: User) => {
          if (isNullOrUndefined(user.address)) {
            user.address = this.defaultAddress;
          }
          if (user.isPortalUser) {
            this.email.setValidators([Validators.email, Validators.required]);
            this.email.updateValueAndValidity();
          }

          this.editedUser = user;

          this.isNew = false;
          this.addOrEdit = 'Edit';
          this.updateProvincesStates();
          this.updateSubmitButtonState();
        });
      } else {
        this.addOrEdit = 'Add';
        if (this.countriesOfTheWorld.includes(clinicCountry)) {
          this.editedUser.address.country = this.countriesOfTheWorld[this.countriesOfTheWorld.indexOf(clinicCountry)];
        } else {
          this.editedUser.address.country = 'Canada';
        }
        this.updateProvincesStates();
        this.submitButtonDisabledState = true;
      }
    });
  }

  onChangeCountry() {
    this.updateProvincesStates();
    this.addressPostalCode.setValue('');
  }

  updateProvincesStates() {
    this.provincesAndStates = this.geographyService.updateProvinceStateList(this.editedUser.address.country);
    if (this.editedUser.address.country.toLowerCase() === 'canada') {
      this.addressPostalCode.validator = this.validationService.validatePostalCode;
    } else if (this.editedUser.address.country.toLowerCase() === 'united states') {
      this.addressPostalCode.validator = this.validationService.validateZipCode;
    } else {
      this.addressPostalCode.validator = null;
    }
  }

  onMedicalCollegeSwitched(medicalCollegeId: number) {
    this.editedUser.medicalCollege = this.medicalColleges.find((mc) => mc.id == medicalCollegeId);
  }

  confirmPasswordMatch() {
    if (
      (isNullOrUndefined(this.password.value) && isNullOrUndefined(this.passwordConfirm.value)) ||
      (isNullOrUndefined(this.password.value) && this.passwordConfirm.value === '') ||
      (this.password.value === '' && isNullOrUndefined(this.passwordConfirm.value)) ||
      this.password.value === this.passwordConfirm.value
    ) {
      //Check for validity
      if (this.validate(this.password.value) && this.password.value.length >= 8) {
        this.password.setErrors(null);
        this.passwordConfirm.setErrors(null);
      } else if (this.password.value.length < 8) {
        this.password.setErrors({ passwordToSmallError: true });
        this.passwordConfirm.setErrors({ passwordToSmallError: true });
      } else {
        this.password.setErrors({ passwordWrongFormatError: true });
        this.passwordConfirm.setErrors({ passwordWrongFormatError: true });
      }
    } else {
      this.password.setErrors({ passwordMatchError: true });
      this.passwordConfirm.setErrors({ passwordMatchError: true });
    }
  }

  validate(password) {
    const minMaxLength = /^[\s\S]{8,32}$/,
      upper = /[A-Z]/,
      lower = /[a-z]/,
      number = /[0-9]/,
      special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

    if (
      minMaxLength.test(password) &&
      upper.test(password) &&
      lower.test(password) &&
      number.test(password) &&
      special.test(password)
    ) {
      return true;
    }

    return false;
  }

  updateSubmitButtonState() {
    this.serverValidationError = '';
    if (
      !this.userName.value ||
      this.password.hasError('passwordMatchError') ||
      this.passwordConfirm.hasError('passwordMatchError') ||
      this.email.hasError('email') ||
      this.email.hasError('required') ||
      (this.provincialBillingNumber.value && this.provincialBillingNumber.hasError('pattern')) ||
      (this.payeeNumber.value && this.payeeNumber.hasError('pattern')) ||
      (this.phoneNumber.value && this.phoneNumber.hasError('phoneError')) ||
      (this.addressPostalCode.value && this.addressPostalCode.hasError('postalCodeError')) ||
      (this.addressPostalCode.value && this.addressPostalCode.hasError('zipCodeError')) ||
      !this.firstName.value ||
      !this.lastName.value ||
      !this.editedUser.userCategoryId
    ) {
      this.submitButtonDisabledState = true;
    } else {
      this.submitButtonDisabledState = false;
    }
    if (
      this.addOrEdit === 'Add' &&
      (isNullOrUndefined(this.password.value) || this.password.value === '') &&
      (isNullOrUndefined(this.passwordConfirm.value) || this.passwordConfirm.value === '')
    ) {
      this.submitButtonDisabledState = true;
    }
  }

  updateUser() {
    if (this.isNew) {
      this.editedUser.clinicId = this.usersService.getUserClinicId();
      this.usersService
        .addUser(this.editedUser, this.password.value)
        .subscribe(
          () => {this.usersService.updateRefreshRequired(true);
            this.router.navigate(['/management/organization/clinics/users', { outlets: { 'action-panel': null } }]);},
          (error) => {
            this.serverValidationError = error.error;
          }
        );
    } else {
      this.usersService
        .updateUser(this.editedUser, this.password.value)
        .subscribe(
          () => {
            if (this.editedUser.id === this.usersService.loggedInUser.id) {
              // Be careful of ordering, this will update the editedUser (overriding avatar with a roSAS appended)
              this.usersService.loggedInUserUpdated(this.editedUser);
            }

            this.usersService.updateRefreshRequired(true);
            this.router.navigate(['/management/organization/clinics/users', { outlets: { 'action-panel': null } }]);
          },
          (error) => {
            this.serverValidationError = error.error;
          }
        );
    }
  }

  cancelUpdate() {
    this.usersService.updateRefreshRequired(false);
    this.router.navigate(['/management/organization/clinics/users', { outlets: { 'action-panel': null } }]);
  }

  initUser(theuser: User) {
    theuser = new User({
      ...theuser,
      id: '',
      firstName: '',
      lastName: '',
      avatar: '',
      phoneNumber: '',
      email: '',
      address: this.defaultAddress,
      userCategory: this.userCategories[0],
      userCategoryId: this.userCategories[0].userCategoryId,
      serviceProvider: false,
      clinicId: this.usersService.getUserClinicId(),
    });
    return theuser;
  }

  ngOnDestroy() {
    this.unsub.next();
    this.unsub.complete();
  }
}
